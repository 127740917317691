import { Link } from "react-router-dom";
import CommunityAssistant from "./CommunityAssistant";
import useTranslation from "../helpers/useTranslation";
import { MyAssistantsI18n } from "./MyAssistants.i18n";
import { AssistantDTO } from "../@DTO/assistant";

interface MyAssistantsProps {
  myAssistants: AssistantDTO[] | undefined;
  onSelectAssistant: (assistant) => void;
}

export function OwnerAssistants({
  myAssistants,
  onSelectAssistant,
}: MyAssistantsProps) {
  const { t } = useTranslation(MyAssistantsI18n);

  return (
    <>
      <div className="px-3 my-4 d-flex align-items-center justify-content-between">
        <h4 className="m-0">{t("page.title-my-own")}</h4>
      </div>
      {myAssistants ? (
        <div className="w-auto px-2">
          <CommunityAssistant
            assistants={myAssistants}
            onSelectAssistant={onSelectAssistant}
          />
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center mt-4 my-3 text-center  p-3 gap-1">
          <span>{t("page.empty-my-assistants")}</span>
          <Link
            to="/assistant/create"
            className="link-primary fs-4 fw-semibold"
          >
            {t("link.create-assistant-secondary")}{" "}
            <i className="bx bx-bot bx-tada fw-semibold fon"></i>
          </Link>
        </div>
      )}
    </>
  );
}
