import { Col } from "reactstrap";
import { getUser } from "../../../../helpers/userHelper";
import AffiliateCard from "../AffiliateCard";
import AffiliateProgramTaximeter from "../AffiliateProgramTaximeter";

export function Affiliate() {
  const user = getUser();

  return (
    <div className="d-flex flex-wrap flex-lg-nowrap gap-4">
      {user?.user_metadata?.affiliate && (
        <>
          <div>
            <AffiliateCard />
          </div>
          <div className="mt-3">
            <AffiliateProgramTaximeter />
          </div>
        </>
      )}
    </div>
  );
}
