export const FooterHomeI18n = {
  "pt-BR": {
    listingOne: {
      title: "Saber Mais",
      link1: "Sobre Nós",
      link2: "Documentos",
    },
    listingTwo: {
      title: "Explorar",
      link1: "GitHub",
      link2: "Token AIZ",
      link3: "Basecan AIZ",
    },
    listingThree: {
      title: "Jurídico",
      link1: "Aviso de Isenção de Responsabilidade AIZ",
    },
    copyRight: "© 2021 AIZZY. Todos os direitos reservados.",
  },
  "en-US": {
    listingOne: {
      title: "Learn More",
      link1: "About Us",
      link2: "Docs",
    },
    listingTwo: {
      title: "Explore",
      link1: "GitHub",
      link2: "AIZ Token",
      link3: "Aiz Basecan",
    },
    listingThree: {
      title: "Legal",
      link1: "AIZ Disclaimer",
    },
    copyRight: "© 2024 Aizzy. All rights reserved.",
  },
  "es-ES": {
    listingOne: {
      title: "Aprende Más",
      link1: "Sobre Nosotros",
      link2: "Documentos",
    },
    listingTwo: {
      title: "Explorar",
      link1: "GitHub",
      link2: "Token AIZ",
      link3: "Basecan AIZ",
    },
    listingThree: {
      title: "Legal",
      link1: "Aviso de Exención de Responsabilidad AIZ",
    },
    copyRight: "© 2024 Aizzy. Todos los derechos reservados.",
  },
  "fil-PH": {
    listingOne: {
      title: "Matuto Pa",
      link1: "Tungkol Sa Amin",
      link2: "Mga Dokumento",
    },
    listingTwo: {
      title: "I-explore",
      link1: "GitHub",
      link2: "Token AIZ",
      link3: "Basecan AIZ",
    },
    listingThree: {
      title: "Legal",
      link1: "AIZ Pagbabawal ng Pananagutan",
    },
    copyRight: "© 2024 Aizzy. Lahat ng mga karapatan ay nirereserba.",
  },
};
