import { useState } from "react";
import { WhatsappShareButton } from "react-share";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { showSuccessNotification } from "../helpers/notifications";
import useTranslation from "../helpers/useTranslation";
import { DropdownShareAssistantI18n } from "./DropdownShareAssistant.i18n";
import { AssistantDTO } from "../@DTO/assistant";
import { useUser } from "@supabase/auth-helpers-react";

interface AssistantData extends AssistantDTO {
  objectID: string;
}
interface DropdownShareAssistantProps {
  assistant: AssistantData;
  iconSize?: number;
}

export function DropdownShareAssistant({
  assistant,
  iconSize = 4,
}: DropdownShareAssistantProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const user = useUser();

  const { t } = useTranslation(DropdownShareAssistantI18n);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const linkToShare = generateShareUrl(assistant);

  function generateShareUrl(assistant: AssistantData) {
    let baseUrl = process.env.REACT_APP_PUBLIC_SITE_URL || "https://aizzy.ai";
    const assistantId = assistant?._id || assistant?.objectID;

    if (!baseUrl.endsWith("/")) {
      baseUrl += "/";
    }

    return `${baseUrl}dashboard?assistantId=${assistantId}`;
  }

  return (
    <div>
      <Dropdown className="mt-auto" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle nav>
          <button className="btn btn-sm hover-primary m-0 p-0">
            <i className={`bx bxs-share bx-flip-horizontal fs-${iconSize}`} />
          </button>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <WhatsappShareButton
              url={linkToShare}
              title={
                assistant?.userId === user?.id
                  ? t("messagwWhatsappOwn")
                  : t("messageWhatsapp")
              }
              separator=" --> "
              className="w-100 d-flex align-items-center justify-content-between"
            >
              {t("button.share-wpp")}
              <i className="bx bxl-whatsapp text-muted ms-3" />
            </WhatsappShareButton>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              navigator.clipboard.writeText(linkToShare);
              showSuccessNotification(t("notifications.copy-link"));
            }}
            className="d-flex align-items-center justify-content-between"
          >
            {t("button.copy-link")} <i className="bx bx-link text-muted ms-3" />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
