import { APIClient } from "./apiCore";

const api = new APIClient();

const checkFeedback = () => {
  return api.get(process.env.REACT_APP_API_URL + "/api/survey/feedback/check");
};

const createFeedback = (payload) => {
  return api.create(process.env.REACT_APP_API_URL + "/api/survey/feedback", payload);
};

export {
  checkFeedback,
  createFeedback,
};
