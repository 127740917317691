import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

// hooks
import { useRedux } from "../../hooks/index";

// actions
import { changeSelectedChat, changeTab } from "../../redux/actions";

// costants
import { LAYOUT_MODES, TABS, TABS_VALUES } from "../../constants/index";

// menu
import { MENU_ITEMS, MenuItemType } from "./menu";

import { useUser } from "@supabase/auth-helpers-react";
import { ReactComponent as LogoAizzy } from "../../assets/images/logo.svg";
import logoPremium from "../../assets/images/icons/logo-dourado-2.png";

import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from "react-joyride";
import useTranslation from "../../helpers/useTranslation";
import { SideMenuI18n } from "./SideMenu.i18n";
import { DropdownLanguageMenu } from "../../components/DropdownLanguageMenu";
import { dataLayerPush } from "../../utils/gaEvents";
import clsx from "clsx";
import { getCurrentUserPlan } from "../../helpers/userHelper";
import { PLANS_AIZZY } from "../../helpers/constants/Plans";
import SideMenuMobile from "../../components/SideMenuMobile";

const LogoLightSVG = () => {
  return <LogoAizzy style={{ width: "50%", height: "auto" }} />;
};

const LogoDarkSVG = () => {
  return <LogoAizzy style={{ width: "50%", height: "auto" }} />;
};

const Logo = () => {
  return (
    <div className="navbar-brand-box">
      <Link to="#" className="logo logo-dark">
        <span className="logo-sm">
          <LogoLightSVG />
        </span>
      </Link>

      <Link to="#" className="logo logo-light">
        <span className="logo-sm">
          <LogoDarkSVG />
        </span>
      </Link>
    </div>
  );
};

const LogoPremium = () => {
  return (
    <Link to={"/"} className=" mx-2 mx-sm-0">
      <img
        src={logoPremium}
        alt=""
        style={{
          maxWidth: 26,
          maxHeight: 26,
        }}
      />
    </Link>
  );
};

interface MenuNavItemProps {
  item: MenuItemType;
  selectedTab: TABS_VALUES;
  isPremiumUser: boolean;
  onChangeTab: (item) => void;
}
const MenuNavItem = ({
  item,
  selectedTab,
  onChangeTab,
  isPremiumUser,
}: MenuNavItemProps) => {
  const onClick = () => {
    onChangeTab(item);
  };

  const isActive = selectedTab === item.tabId;

  const menuItem = MENU_ITEMS.map(item => item.description);

  return (
    <>
      <NavItem
        className={`d-flex flex-column align-items-center ${item.className}`}
        id={`${item.key}-container`}
      >
        <NavLink
          href="#"
          active={isActive}
          id={item.key}
          role="tab"
          className={clsx(
            "nav-link",
            isPremiumUser && isActive && "active-premium"
          )}
          onClick={onClick}
        >
          <i
            className={clsx(
              `${item.icon}`,
              isPremiumUser && "side-menu-core-premium",
              !isPremiumUser && "side-menu-core"
            )}
          />
        </NavLink>
        <p className="font-size-10">{item.description}</p>
      </NavItem>
    </>
  );
};

interface ProfileDropdownMenuProps {
  onChangeTab: (id: TABS_VALUES) => void;
  onChangeLayoutMode: (value: any) => void;
}
const ProfileDropdownMenu = ({
  onChangeTab,
  onChangeLayoutMode,
}: ProfileDropdownMenuProps) => {
  const navigate = useNavigate();

  const { t } = useTranslation(SideMenuI18n);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const user = useUser();

  const isDark = localStorage.getItem("layoutMode") === "dark";

  const avatarProfile = user?.user_metadata?.picture;

  const mode = isDark ? LAYOUT_MODES["LIGHT"] : LAYOUT_MODES["DARK"];

  return (
    <Dropdown
      nav
      isOpen={dropdownOpen}
      className="profile-user-dropdown d-flex justify-content-center "
      toggle={toggle}
    >
      <DropdownToggle
        nav
        className="bg-transparent px-0"
        style={{
          maxWidth: 36,
        }}
      >
        {avatarProfile ? (
          <img src={avatarProfile} alt="" className="profile-user" />
        ) : (
          <i className="bx bxs-user text-muted font-size-20" />
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          onClick={() => {
            onChangeTab(TABS.PROFILE);
            navigate("/profile");
          }}
        >
          {t("menu.account")}{" "}
          <i className="bx bx-user-circle text-muted ms-3" />
        </DropdownItem>
        {/* <DropdownItem
          className="d-flex align-items-center justify-content-between"
          onClick={() => onChangeLayoutMode(mode)}
        >
          {t("menu.theme")}
          <i className={`bx bx-${isDark ? "sun" : "moon"} ms-3`} />
        </DropdownItem> */}
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          onClick={() => {
            navigate("/logout");
          }}
        >
          {t("menu.logout")} <i className="bx bx-log-out text-muted ms-3" />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const SideMenu = ({ onChangeLayoutMode }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { dispatch, useAppSelector } = useRedux();
  const menuItems: MenuItemType[] = MENU_ITEMS;
  const { activeTab, layoutMode } = useAppSelector(state => ({
    activeTab: state.Layout.activeTab,
    layoutMode: state.Layout.layoutMode,
  }));
  const [selectedTab, setSelectedTab] = useState<TABS_VALUES>(
    TABS.ASSISTANT_NEW
  );
  const isMobile = window.matchMedia("(max-width: 700px)").matches;

  const isPremiumUser = getCurrentUserPlan() === PLANS_AIZZY.PREMIUM;
  const onChangeTab = item => {
    if (item.key === "pills-super-chat" && activeTab === TABS.ASSISTANT_CHATS) {
      return;
    }

    navigate(item?.navigate);
    setSelectedTab(item?.tabId);
    dispatch(changeTab(item?.tabId));
    dispatch(changeSelectedChat(null));
    dataLayerPush({ event: "click", button_label: `menu-${item?.tabId}` });
  };

  useEffect(() => {
    setSelectedTab(activeTab);
  }, [activeTab]);

  const [{ run, steps, stepIndex }, setState] = useState<any>({
    run: false,
    stepIndex: 0,
    steps: [
      {
        content: <h2>Welcome to aizzy!</h2>,
        locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
        placement: "center",
        target: "body",
        callBackAction: () => false,
      },
      {
        title: "Chat Messages",
        content:
          "Use chat messages to talk with assistants, attach files and urls (in document channels) to enrich your interactive.",
        target: "#pills-chat-tab-container",
        callBackAction: () => dispatch(changeTab(TABS.CHAT)),
      },
      {
        title: "Create new document",
        content:
          "Create new channels to talk with assistants and attach pdf documents and urls to enrich your assistant dialogue.",
        target: "#new-document-chat > button",
        callBackAction: () => dispatch(changeTab(TABS.CHAT)),
      },
      {
        title: "Direct messages",
        content:
          "Talk with assistants in private channels about any subject you want.",
        target: "#new-direct-message > button",
        callBackAction: () => dispatch(changeTab(TABS.CHAT)),
      },
      {
        title: "New channel",
        content:
          "Create a channel to describe an subject and talk with assistants about it.",
        target: "#new-channel > button",
        callBackAction: () => dispatch(changeTab(TABS.CHAT)),
      },
    ],
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type, step, action, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (
      step &&
      step["callBackAction"] &&
      typeof step["callBackAction"] === "function"
    ) {
      step["callBackAction"]();
    }

    if (([EVENTS.STEP_AFTER] as string[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (step.target === "#pills-chat-tab-container") {
        setState(state => ({ ...state, stepIndex: nextStepIndex }));
      } else {
        setState(state => ({ ...state, stepIndex: nextStepIndex }));
      }
    }

    if (finishedStatuses.includes(status)) {
      setState(state => ({ ...state, run: false, stepIndex: 0 }));
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991.95) {
        setIsOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="side-menu bg-body flex-lg-column px-3 px-md-2">
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        stepIndex={stepIndex}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      {/* MENU MOBILE */}
      <div className="w-100 justify-content-between align-items-center sidemenu-navigation-mobile">
        <button
          className="btn border-0 p-0 mb-1"
          onClick={() => {
            dispatch(changeSelectedChat(null));
            dispatch(changeTab(null));
            navigate("/");
          }}
        >
          {isPremiumUser ? <LogoPremium /> : <Logo />}
        </button>
        <button
          style={{ border: "none" }}
          className="btn btn-sm px-3 m-0"
          onClick={toggleMenu}
        >
          <i className="bx bx-menu font-size-24 mt-1"></i>
        </button>
        <Nav className="side-menu-nav w-auto" pills role="tablist">
          <ProfileDropdownMenu
            onChangeTab={item => {
              onChangeTab(item);
              setIsOpen(false);
            }}
            onChangeLayoutMode={onChangeLayoutMode}
          />
        </Nav>
      </div>
      {isOpen && (
        <SideMenuMobile
          children={
            <Nav pills className="side-menu-nav-mobile" role="tablist">
              {(menuItems || [])
                .filter(
                  (item: MenuItemType) =>
                    !isPremiumUser || item.tabId !== TABS.PLANS
                )
                .map((item: MenuItemType, key: number) => (
                  <MenuNavItem
                    item={item}
                    key={key}
                    selectedTab={selectedTab}
                    onChangeTab={() => {
                      onChangeTab(item);
                      setIsOpen(false);
                    }}
                    isPremiumUser={isPremiumUser}
                  />
                ))}
              <div className="mt-2">
                <DropdownLanguageMenu state={() => {}} />
              </div>
            </Nav>
          }
        />
      )}

      {/* MENU WEB */}
      <div className="bg-body d-flex flex-column my-0 sidemenu-navigation">
        <button
          style={{ height: 60 }}
          className="btn border-0 p-0 mt-3 "
          onClick={() => {
            dispatch(changeSelectedChat(null));
            dispatch(changeTab(null));
            navigate("/");
          }}
        >
          {isPremiumUser ? <LogoPremium /> : <Logo />}
        </button>
        <Nav pills className="side-menu-nav" role="tablist">
          {(menuItems || [])
            .filter(
              (item: MenuItemType) =>
                !isPremiumUser || item.tabId !== TABS.PLANS
            )
            .map((item: MenuItemType, key: number) => (
              <MenuNavItem
                item={item}
                key={key}
                selectedTab={selectedTab}
                onChangeTab={onChangeTab}
                isPremiumUser={isPremiumUser}
              />
            ))}

          {<DropdownLanguageMenu state={() => {}} />}
          {/* change mode */}
          {/* 
          <LightDarkMode
            layoutMode={layoutMode}
            onChangeLayoutMode={onChangeLayoutMode}
          /> */}
          {/* <NavItem id="tutorial-help">
            <NavLink
              className="nav-link light-dark"
              onClick={() =>
                setState(state => ({
                  ...state,
                  run: true,
                }))
              }
            >
              <i className="bx bx-help-circle"></i>{" "}
            </NavLink>{" "}
            <UncontrolledTooltip placement="right" target="tutorial-help">
              Show tutorial
            </UncontrolledTooltip>{" "}
          </NavItem> */}

          {/* profile menu dropdown */}
          <ProfileDropdownMenu
            onChangeTab={onChangeTab}
            onChangeLayoutMode={onChangeLayoutMode}
          />
          {/* <WalletDropdown style={{ color: "#8f9198" }} /> */}
        </Nav>
      </div>
      {/* end side-menu nav */}
    </div>
  );
};

export default SideMenu;
