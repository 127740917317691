import { TabContent, TabPane } from "reactstrap";
import { TABS } from "../../constants/index";
import { useRedux } from "../../hooks/index";
import Assistants from "./Assistants/index";
import Documents from "./Documents/index";
import AssistantChats from "./AssistantChats/index";
import YoutubeVideos from "./Youtube/index";
import classnames from "classnames";

interface LeftbarProps {}
const Leftbar = (props: LeftbarProps) => {
  const { useAppSelector } = useRedux();

  const { activeTab, isOpenLeftBar } = useAppSelector(state => ({
    activeTab: state.Layout.activeTab,
    isOpenLeftBar: state.Layout.isOpenLeftBar,
  }));

  return (
    <>
      <div
        className={classnames(`${"chat-leftsidebar"}`, {
          full: activeTab === TABS.ASSISTANT,
          small: !isOpenLeftBar
        })}
      >
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TABS.ASSISTANT} role="tabpanel">
            <Assistants />
          </TabPane>
          <TabPane tabId={TABS.ASSISTANT_CHATS} role="tabpanel">
            <AssistantChats />
          </TabPane>
          <TabPane tabId={TABS.DOCUMENTS} role="tabpanel">
            <Documents />
          </TabPane>
          <TabPane tabId={TABS.YOUTUBE} role="tabpanel">
            <YoutubeVideos />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default Leftbar;
