import { SelectedChatDTO } from "../../../@DTO/selectedChat";
import messageDeviceSvg from "../../../assets/images/device-message-com.svg";
import useTranslation from "../../../helpers/useTranslation";
import { DeviceInfoI18n } from "./ConversationUser.i18n";

import braveSvg from "../../../assets/images/browsers/brave.svg";
import chromeSvg from "../../../assets/images/browsers/chrome.svg";
import edgeSvg from "../../../assets/images/browsers/edge.svg";
import firefoxSvg from "../../../assets/images/browsers/firefox.svg";
import operaSvg from "../../../assets/images/browsers/opera.svg";
import safariSvg from "../../../assets/images/browsers/safari.svg";
import vivaldiSvg from "../../../assets/images/browsers/vivaldi.svg";
import duckDuckGoSvg from "../../../assets/images/browsers/duckduckgo.svg";

import desktopSvg from "../../../assets/images/devices/desktop.svg";
import mobileSvg from "../../../assets/images/devices/mobile.svg";

import appleOsSvg from "../../../assets/images/os/mac.svg";
import windowsSvg from "../../../assets/images/os/windows.svg";
import linuxSvg from "../../../assets/images/os/linux.svg";
import androidSvg from "../../../assets/images/os/android.svg";

import interrogationSvg from "../../../assets/images/interrogation.svg";

interface DeviceInfoProps {
  selectedChat: SelectedChatDTO;
}

export function DeviceInfo({ selectedChat }: DeviceInfoProps) {
  const { t } = useTranslation(DeviceInfoI18n);

  const deviceInfo = selectedChat?.deviceInfo;

  // const browserIcon = {
  //   chrome: chromeSvg,
  //   "chrome mobile": chromeSvg,
  //   firefox: firefoxSvg,
  //   safari: safariSvg,
  //   "mobile safari": safariSvg,
  //   edge: edgeSvg,
  //   opera: operaSvg,
  //   brave: braveSvg,
  //   vivaldi: vivaldiSvg,
  //   duckduckgo: duckDuckGoSvg,
  // };

  const deviceIcon = {
    desktop: desktopSvg,
    mobile: mobileSvg,
  };

  const osIcon = {
    "mac os x": appleOsSvg,
    macos: appleOsSvg,
    ios: appleOsSvg,
    windows: windowsSvg,
    linux: linuxSvg,
    android: androidSvg,
  };

  const getBrowserIcon = (browser: string) => {
    const lowerBrowser = browser.toLowerCase();
    if (lowerBrowser.includes("chrome")) return chromeSvg;
    if (lowerBrowser.includes("firefox")) return firefoxSvg;
    if (lowerBrowser.includes("safari")) return safariSvg;
    if (lowerBrowser.includes("edge")) return edgeSvg;
    if (lowerBrowser.includes("opera")) return operaSvg;
    if (lowerBrowser.includes("brave")) return braveSvg;
    if (lowerBrowser.includes("vivaldi")) return vivaldiSvg;
    if (lowerBrowser.includes("duckduckgo")) return duckDuckGoSvg;
    return interrogationSvg;
  };

  const getDeviceIcon = (device: string) => {
    return deviceIcon[device.toLowerCase()] || interrogationSvg;
  };

  const getOSIcon = (os: string) => {
    return osIcon[os.toLowerCase()] || interrogationSvg;
  };

  return (
    <div>
      <div className="text-center mt-5">
        <img
          src={messageDeviceSvg}
          width={80}
          height={80}
          alt="Empty chat"
          className="img-fluid"
        />
        <h5 className="mt-3 fs-3 fw-semibold">
          {t("otherDeviceMessage.title")}
        </h5>
        <p className="font-size-16">{t("otherDeviceMessage.message")}</p>

        <div className="d-flex flex-column align-items-center justify-content-center gap-2">
          {deviceInfo?.browser && (
            <div className="d-flex align-items-center gap-2">
              <img
                src={getBrowserIcon(deviceInfo.browser)}
                width={18}
                height={18}
                alt="Browser"
              />
              <span>{deviceInfo.browser}</span>
            </div>
          )}
          {deviceInfo?.device && (
            <div className="d-flex align-items-center gap-2">
              <img
                src={getDeviceIcon(deviceInfo.device)}
                width={18}
                height={18}
                alt="Device"
              />
              <span>{deviceInfo.device}</span>
            </div>
          )}
          {deviceInfo?.os && (
            <div className="d-flex align-items-center gap-2">
              <img
                src={getOSIcon(deviceInfo.os)}
                width={18}
                height={18}
                alt="OS"
              />
              <span>{deviceInfo.os}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
