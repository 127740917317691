import { useState, useRef } from "react";
import axios from "axios";
import {
  IconMicrophone,
  IconLoader2,
  IconPlayerPauseFilled,
  IconSend2,
  IconTrash,
} from "@tabler/icons-react";
import { showErrorNotification } from "../helpers/notifications";
import { useChatStore } from "../state/chat";

const AudioRecorder = ({ channel, onStopRecorder }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audioURL, setAudioURL] = useState(null);

  const { isReceivingMessage } = useChatStore();

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const streamRef = useRef(null);

  const channelId = channel._id;
  const userId = channel.userId;

  async function startRecording() {
    try {
      audioChunksRef.current = [];
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.ondataavailable = event => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.start();
      mediaRecorderRef.current = mediaRecorder;

      streamRef.current = stream;

      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording: ", error);
      showErrorNotification(error?.message || "Error starting recording");
    }
  }

  async function pauseRecording() {
    if (isRecording) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/mp3" });
      setAudioURL(URL.createObjectURL(audioBlob));
    }
  }

  async function resumeRecording() {
    if (mediaRecorderRef.current && isPaused) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
    }
  }

  async function stopRecording() {
    if (!isRecording) return;

    if (mediaRecorderRef.current) {
      setIsLoading(true);
      return new Promise(resolve => {
        mediaRecorderRef.current.onstop = async () => {
          try {
            const audioBlob = new Blob(audioChunksRef.current, {
              type: "audio/mp3",
            });
            const formData = new FormData();
            formData.append("audio", audioBlob, "audio.mp3");
            formData.append("channelId", channelId);
            formData.append("userId", userId);

            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/chat/upload-audio`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            const isAudioMessage = true;

            onStopRecorder(response.transcription, isAudioMessage);
          } catch (error) {
            console.error("Error uploading audio:", error);
            showErrorNotification(error?.message || "Error uploading audio");
          } finally {
            setIsLoading(false);
            audioChunksRef.current = [];
            setAudioURL(null);
            resolve();
          }
        };

        mediaRecorderRef.current.stop();
        setIsRecording(false);

        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
        }
      });
    } else {
      console.error("MediaRecorder not initialized.");
      showErrorNotification("MediaRecorder not initialized.");
    }
  }

  async function cancelRecording() {
    if (isRecording || isPaused) {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        audioChunksRef.current = [];
        setAudioURL(null);
        setIsRecording(false);
        setIsPaused(false);

        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
          streamRef.current = null;
        }
      }
    }
  }

  return (
    <div>
      {isRecording || isLoading ? (
        <div className="d-flex align-items-center gap-2">
          {/* {isPaused && audioURL ? <audio controls src={audioURL} /> : null} */}
          <button
            className="btn btn-primary btn-sm rounded-circle d-flex align-items-center justify-content-center"
            onClick={isPaused ? resumeRecording : pauseRecording}
            disabled={isLoading}
            style={{
              width: 46,
              height: 46,
              marginBottom: 6,
            }}
          >
            {isPaused ? (
              <IconMicrophone size={22} />
            ) : (
              <IconPlayerPauseFilled size={22} />
            )}
          </button>
          <button
            className="btn btn-secondary btn-sm rounded-circle d-flex align-items-center justify-content-center"
            onClick={cancelRecording}
            disabled={isLoading}
            style={{
              width: 46,
              height: 46,
              marginBottom: 6,
            }}
          >
            <IconTrash size={22} />
          </button>
          <button
            className="btn btn-primary btn-sm rounded-circle d-flex align-items-center justify-content-center"
            onClick={stopRecording}
            disabled={!isRecording || isLoading}
            style={{
              width: 46,
              height: 46,
              marginBottom: 6,
            }}
          >
            {isRecording ? (
              <IconSend2 size={22} />
            ) : (
              <IconLoader2 className="animate-spin" size={22} />
            )}
          </button>
        </div>
      ) : (
        <button
          className="btn btn-primary btn-sm rounded-circle d-flex align-items-center justify-content-center"
          onClick={startRecording}
          disabled={isRecording || isReceivingMessage}
          style={{
            width: 46,
            height: 46,
            marginBottom: 6,
          }}
        >
          <IconMicrophone size={22} />
        </button>
      )}
    </div>
  );
};

export default AudioRecorder;
