export const ModalPlanI18n = {
  "pt-BR": {
    modalTitle: "Limite de uso alcançado",
    text: "Você atingiu o limite de uso, recomendamos fazer upgrade para continuar aproveitando nossos serviços.",
    cta: "Assine Agora",
    description:
      "Eleve sua experiência com nosso Plano Premium. Desfrute da liberdade de chats, imagens, PDFs, vídeos e sites ilimitados. Além disso, aproveite tamanhos de PDF maiores e vídeos mais longos. Desperte seu potencial conosco.",
    errorType: {
      remainingPDFs:
        "Você excedeu o limite de {{planLimit}} PDFs para o plano {{planName}}.",
      remainingChats:
        "Você excedeu o limite de {{planLimit}} Chats para o plano {{planName}}.",
      remainingImages:
        "Você excedeu o limite de {{planLimit}} Imagens para o plano {{planName}}.",
      remainingVideos:
        "Você excedeu o limite de {{planLimit}} Vídeos para o plano {{planName}}.",
      remainingSites:
        "Você excedeu o limite de {{planLimit}} Sites para o plano {{planName}}.",
      channelMembers:
        "Você atingiu o limite de {{planLimit}} Membros para o plano {{planName}}.",
      pdfSize:
        "O arquivo PDF excedeu o limite de {{planLimit}}MB para o plano {{planName}}.",
      VIDEO_DURATION_LIMIT:
        "A duração do vídeo excede o limite de {{planLimit}} minutos para o plano {{planName}}.",
      VIDEO_LIMIT:
        "Você excedeu o limite de {{planLimit}} vídeos para o plano {{planName}}.",
      SITE_LIMIT:
        "Você excedeu o limite de {{planLimit}} sites para o plano {{planName}}.",
      PDF_SIZE_LIMIT_EXCEEDED:
        "O arquivo PDF excede o limite de {{planLimit}}MB para o plano {{planName}}.",
      PDF_LIMIT_EXCEEDED:
        "Você excedeu o limite de {{planLimit}} PDFs para o plano {{planoName}}.",
      PDF_NON_READABLE_IMAGES_OR_EMPTY_PAGES:
        "O arquivo enviado contém imagens não legíveis em excesso ou páginas vazias. Considere fazer upgrade para o plano premium ou escolher um arquivo diferente.",
      PDF_CANNOT_BE_EXTRACTED:
        "O arquivo que você enviou não pode ser extraído pelo nosso extrator de arquivos da comunidade. Considere fazer upgrade para o plano premium ou escolher outro arquivo.",
      MEMBER_LIMIT_REACHED:
        "Você atingiu o limite de {{planLimit}} membros para o plano {{planName}}.",
      premiumResource:
        "Este recurso está disponível apenas para usuários Premium.",
      IMAGE_LIMIT_EXCEEDED:
        "Você excedeu o limite de {{planLimit}} imagens para o plano {{planName}}.",
      upgradePlan:
        "Descubra todas as vantagens do plano Premium! Faça upgrade agora e aproveite todos os benefícios.",
      CHAT_LIMIT_EXCEEDED:
        "Você excedeu o limite de {{planLimit}} chats para o plano {{planName}}.",
    },
  },
  "en-US": {
    modalTitle: "Usage limit reached",
    text: "You have reached the usage limit, we recommend upgrading your plan to continue enjoying our services.",
    cta: "Upgrade Now",
    description:
      "Elevate your user experience with our Premium Plan. Bask in the freedom of unlimited chats, images, PDFs, videos, and site usage. Plus, benefit from extended PDF sizes and video duration allowances. Unleash your potential with us.",
    errorType: {
      remainingPDFs:
        "You have exceeded the limit of {{planLimit}} PDFs for the {{planName}} plan.",
      remainingChats:
        "You have exceeded the limit of {{planLimit}} Chats for the {{planName}} plan.",
      remainingImages:
        "You have exceeded the limit of {{planLimit}} Images for the {{planName}} plan.",
      remainingVideos:
        "You have exceeded the limit of {{planLimit}} Videos for the {{planName}} plan.",
      remainingSites:
        "You have exceeded the limit of {{planLimit}} Sites for the {{planName}} plan.",
      channelMembers:
        "You have reached the limit of {{planLimit}} Members for the {{planName}} plan.",
      pdfSize:
        "The PDF file exceeds the limit of {{planLimit}}MB for the {{planName}} plan.",
      VIDEO_DURATION_LIMIT:
        "Video duration exceeds the limit of {{planLimit}} minutes for the {{planName}} plan.",
      VIDEO_LIMIT:
        "You have exceeded the limit of {{planLimit}} Videos for the {{planName}} plan.",
      SITE_LIMIT:
        "You have exceeded the limit of {{planLimit}} Sites for the {{planName}} plan.",
      PDF_SIZE_LIMIT_EXCEEDED:
        "The PDF file exceeds the limit of {{planLimit}}MB for the {{planName}} plan.",
      PDF_LIMIT_EXCEEDED:
        "You have exceeded the limit of {{planLimit}} PDFs for the {{planName}} plan.",
      PDF_NON_READABLE_IMAGES_OR_EMPTY_PAGES:
        "The uploaded file contains excessive non-readable images or empty pages. Please consider upgrading to the premium plan or choosing a different file.",
      PDF_CANNOT_BE_EXTRACTED:
        "The file you have uploaded cannot be extracted by our community file extractor. Please consider upgrading to the premium plan or choosing another file.",
      MEMBER_LIMIT_REACHED:
        "You have reached the limit of {{planLimit}} Members for the {{planName}} plan.",
      premiumResource: "This resource is only available for Premium users.",
      IMAGE_LIMIT_EXCEEDED:
        "You have exceeded the limit of {{planLimit}} Images for the {{planName}} plan.",
      upgradePlan:
        "Discover all the advantages of the Premium plan! Upgrade now and enjoy all the benefits.",
      CHAT_LIMIT_EXCEEDED:
        "You have exceeded the limit of {{planLimit}} Chats for the {{planName}} plan.",
    },
  },
  "es-ES": {
    modalTitle: "Límite de uso alcanzado",
    text: "Has alcanzado el límite de uso, recomendamos actualizar tu plan para seguir disfrutando de nuestros servicios.",
    cta: "Actualizar Ahora",
    description:
      "Eleva tu experiencia con nuestro Plan Premium. Disfruta de la libertad de chats, imágenes, PDFs, videos y sitios ilimitados. Además, aprovecha tamaños de PDF más grandes y videos más largos. Despierta tu potencial con nosotros.",
    errorType: {
      remainingPDFs:
        "Has superado el límite de {{planLimit}} PDFs para el plan {{planName}}.",
      remainingChats:
        "Has superado el límite de {{planLimit}} Chats para el plan {{planName}}.",
      remainingImages:
        "Has superado el límite de {{planLimit}} Imágenes para el plan {{planName}}.",
      remainingVideos:
        "Has superado el límite de {{planLimit}} Vídeos para el plan {{planName}}.",
      remainingSites:
        "Has superado el límite de {{planLimit}} Sitios para el plan {{planName}}.",
      channelMembers:
        "Has alcanzado el límite de {{planLimit}} Miembros para el plan {{planName}}.",
      pdfSize:
        "El archivo PDF excede el límite de {{planLimit}}MB para el plan {{planName}}.",
      VIDEO_DURATION_LIMIT:
        "La duración del video excede el límite de {{planLimit}} minutos para el plan {{planName}}.",
      VIDEO_LIMIT:
        "Has excedido el límite de {{planLimit}} videos para el plan {{planName}}.",
      SITE_LIMIT:
        "Has excedido el límite de {{planLimit}} sitios para el plan {{planName}}.",
      PDF_SIZE_LIMIT_EXCEEDED:
        "El archivo PDF excede el límite de {{planLimit}} MB para el plan {{planName}}.",
      PDF_LIMIT_EXCEEDED:
        "Has excedido el límite de {{planLimit}} PDFs para el plan {{planName}}.",
      PDF_NON_READABLE_IMAGES_OR_EMPTY_PAGES:
        "El archivo subido contiene imágenes no legibles en exceso o páginas vacías. Por favor, considera actualizar al plan premium o elegir otro archivo.",
      PDF_CANNOT_BE_EXTRACTED:
        "El archivo que has subido no puede ser extraído por nuestro extractor de archivos de la comunidad. Por favor, considera actualizar al plan premium o elegir otro archivo.",
      MEMBER_LIMIT_REACHED:
        "Has alcanzado el límite de {{planLimit}} miembros para el plan {{planName}}.",
      premiumResource:
        "Este recurso solo está disponible para usuarios Premium.",
      IMAGE_LIMIT_EXCEEDED:
        "Has excedido el límite de {{planLimit}} imágenes para el plan {{planName}}.",
      upgradePlan:
        "¡Descubre todas las ventajas del plan Premium! Actualízate ahora y disfruta de todos los beneficios.",
      CHAT_LIMIT_EXCEEDED:
        "Has excedido el límite de {{planLimit}} imágenes para el plan {{planName}}.",
    },
  },
  "fil-PH": {
    modalTitle: "Naratukan ang limitasyon sa paggamit",
    text: "Naratukan mo na ang limitasyon sa paggamit, inirerekomenda namin ang pag-upgrade ng iyong plano upang magpatuloy sa pagtatamasa ng aming mga serbisyo.",
    cta: "I-upgrade Ngayon",
    description:
      "Itaas ang iyong karanasan sa aming Premium Plan. Magsidhi sa kalayaan ng walang hanggang chats, mga larawan, PDFs, mga video, at paggamit ng site. Bukod dito, makinabang mula sa mas malalaking sukat ng PDF at mas mahabang oras ng video. Ibukas ang iyong potensyal sa amin.",
    errorType: {
      remainingPDFs:
        "Lumampas ka sa limitasyon ng {{planLimit}} PDFs para sa plano ng {{planName}}.",
      remainingChats:
        "Lumampas ka sa limitasyon ng {{planLimit}} Chats para sa plano ng {{planName}}.",
      remainingImages:
        "Lumampas ka sa limitasyon ng {{planLimit}} Mga Larawan para sa plano ng {{planName}}.",
      remainingVideos:
        "Lumampas ka sa limitasyon ng {{planLimit}} Mga Video para sa plano ng {{planName}}.",
      remainingSites:
        "Lumampas ka sa limitasyon ng {{planLimit}} Mga Site para sa plano ng {{planName}}.",
      channelMembers:
        "Naratukan mo na ang limitasyon ng {{planLimit}} Mga Miyembro para sa plano ng {{planName}}.",
      pdfSize:
        "Ang file ng PDF ay lumampas sa limitasyon ng {{planLimit}}MB para sa plano ng {{planName}}.",
      VIDEO_DURATION_LIMIT:
        "Lumampas ang oras ng video sa limitasyon ng {{planLimit}} minuto para sa plano ng {{planName}}.",
      VIDEO_LIMIT:
        "Lumampas ka sa limitasyon ng {{planLimit}} Mga Video para sa plano ng {{planName}}.",
      SITE_LIMIT:
        "Lumampas ka sa limitasyon ng {{planLimit}} Mga Site para sa plano ng {{planName}}.",
      PDF_SIZE_LIMIT_EXCEEDED:
        "Ang file ng PDF ay lumampas sa limitasyon ng {{planLimit}}MB para sa plano ng {{planName}}.",
      PDF_LIMIT_EXCEEDED:
        "Lumampas ka sa limitasyon ng {{planLimit}} PDFs para sa plano ng {{planName}}.",
      PDF_NON_READABLE_IMAGES_OR_EMPTY_PAGES:
        "Ang na-upload na file ay naglalaman ng sobrang hindi mababasang mga larawan o mga pahinang walang laman. Mangyaring isaalang-alang ang pag-upgrade sa premium na plano o pagpili ng ibang file.",
      PDF_CANNOT_BE_EXTRACTED:
        "Ang file na iyong na-upload ay hindi maaring ma-extract ng aming community file extractor. Mangyaring isaalang-alang ang pag-upgrade sa premium na plano o pagpili ng ibang file.",
      MEMBER_LIMIT_REACHED:
        "Naratukan mo na ang limitasyon ng {{planLimit}} Mga Miyembro para sa plano ng {{planName}}.",
      premiumResource:
        "Ang resursong ito ay maaari lamang gamitin ng mga Premium na gumagamit.",
      IMAGE_LIMIT_EXCEEDED:
        "Lumampas ka sa limitasyon ng {{planLimit}} Mga Larawan para sa plano ng {{planName}}.",
      upgradePlan:
        "Alamin ang lahat ng mga kahalagahan ng Premium na plano! I-upgrade na ngayon at tamasahin ang lahat ng mga benepisyo.",
      CHAT_LIMIT_EXCEEDED:
        "Lumampas ka sa limitasyon ng {{planLimit}} Chats para sa plano ng {{planName}}.",
    },
  },
};
