import { APIClient } from "./apiCore";

const api = new APIClient();

export async function checkoutPix(amount) {
  return api.create(`${process.env.REACT_APP_API_URL}/api/payments/pix`, {
    amount,
  });
}

export async function manageUserSubscription() {
  return api.create(
    process.env.REACT_APP_API_URL + "/api/stripe/create-portal-link"
  );
}

export async function getUserSubscription() {
  return api.get(process.env.REACT_APP_API_URL + "/api/user/subscription");
}

export async function checkPixPayment() {
  return api.get(process.env.REACT_APP_API_URL + "/api/payments/pix");
}

export async function web3PaymentCheckToken(data) {
  return api.create(
    process.env.REACT_APP_API_URL + "/api/web3/payments/check-token",
    data
  );
}
