import { useContext, useState } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useRedux } from "../hooks";
import { hasYoutubeUrl } from "../utils/stringutils";
import useTranslation from "../helpers/useTranslation";
import { NewYoutubeVideoModalI18n } from "../pages/Dashboard/Youtube/components/NewYoutubeVideoModal.i18n";
import { createDocumentEmbedding, createVideoEmbedding } from "../api";
import { QUERY_KEYS, queryClient } from "../libs/react-query";
import { useMutation } from "react-query";
import { showErrorNotification } from "../helpers/notifications";
import RemainingCallsContext from "../freemium/RemainingCallsContext";
import { changeSelectedChat, getChannelJobStatus } from "../redux/actions";
import { ChannelDTO } from "../@DTO/channel";

// components

interface AddGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export function NewYoutubeVideoModal({ isOpen, onClose }: AddGroupModalProps) {
  const [youtubeLink, setYoutubeLink] = useState("");

  const { checkRemainingCallsMessage, isCheckingRemainingCallsMessage } =
    useContext(RemainingCallsContext);

  const { isLoading, mutateAsync } = useMutation(async (videoUrl: any) => {
    const response = await createVideoEmbedding(videoUrl);

    const channel = response.channel;

    if (!channel) {
      return;
    }

    const cachedChannels = queryClient.getQueryData<ChannelDTO[]>(
      QUERY_KEYS.CHANNELS
    );

    if (cachedChannels) {
      queryClient.setQueryData(QUERY_KEYS.CHANNELS, [
        ...cachedChannels,
        channel,
      ]);
    }

    dispatch(changeSelectedChat(channel));

    dispatch(getChannelJobStatus(channel._id));

    onClose();
  });

  const { dispatch } = useRedux();

  const { t } = useTranslation(NewYoutubeVideoModalI18n);

  function handleCloseModal() {
    if (isLoading) return;
    onClose();
  }

  // async function handleSubmitVideo() {
  //   // if (isCheckingRemainingCallsMessage) return;

  //   await checkRemainingCallsMessage(onSubmitVideo, "remainingVideos");
  // }

  async function onSubmitVideo() {
    try {
      if (isLoading) {
        return;
      }

      if (!hasYoutubeUrl(youtubeLink)) {
        showErrorNotification("Invalid youtube link");
        return setYoutubeLink("");
      }

      const params = {
        url: youtubeLink,
      };

      await mutateAsync(params);
    } catch (error: any) {
      showErrorNotification(error.message || "Error to send youtube video");
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmitVideo();
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleCloseModal}
      tabIndex={-1}
      centered
      scrollable
      id="addgroup-exampleModal"
      role="dialog"
      backdrop="static"
    >
      <ModalHeader className="modal-title-custom" toggle={handleCloseModal}>
        {t("page.label")}
      </ModalHeader>

      <ModalBody className="p-4">
        <div className="mb-4">
          <Label htmlFor="addgroupname-input" className="form-label">
            {t("input.title")} <span className="text-danger">*</span>
          </Label>
          <Input
            type="text"
            className="form-control"
            id="addgroupname-input"
            placeholder={t("input.placeholder")}
            value={youtubeLink}
            onChange={e => setYoutubeLink(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </div>
        {isLoading && (
          <div className="row g-3 d-flex justify-content-center">
            <div className="col-12 text-center">
              <i className="bx bx-loader bx-spin align-middle text-primary"></i>{" "}
              <h6 className="mb-3">{t("page.processingVideoTitle")}</h6>
              <img
                src={`https://img.youtube.com/vi/${
                  hasYoutubeUrl(youtubeLink)[1]
                }/mqdefault.jpg`}
                width={150}
                height={100}
                alt="Youtube video thumbnail"
              />
            </div>
          </div>
        )}
      </ModalBody>
      {!isLoading && (
        <ModalFooter>
          <Button color="link" type="button" onClick={onClose}>
            {t("button.close")}
          </Button>
          <Button
            type="button"
            className="btn d-flex align-items-center"
            style={{ background: "none", border: "none" }}
            onClick={onSubmitVideo}
            disabled={
              isLoading || isCheckingRemainingCallsMessage || youtubeLink === ""
            }
          >
            {isLoading ? (
              <i className="bx bx-loader bx-spin align-middle"></i>
            ) : (
              <>
                <div
                  className="icon btn-primary button-border-sm button-small d-flex align-items-center justify-content-center"
                >
                  <i
                    className={"bx bxs-message-square-add"}
                  />
                </div>
                <p style={{ color: "#000", marginLeft: 8 }}>
                  {t("button.create")}
                </p>
              </>
            )}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
}
