import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import { Link } from "react-router-dom";

import pixAnimation from "../../../assets/animations/pix-animation.json";
import useTranslation from "../../../helpers/useTranslation";
import { BannerPixI18n } from "./BannerPix.i18n";

export function BannerPix() {
  const { t } = useTranslation(BannerPixI18n);

  const isMobile = window.matchMedia("(max-width: 700px)").matches;

  return (
    <div className=" text-white p-4 shadow">
      <div className="d-flex align-content-center justify-content-around">
        <div className="d-flex flex-column">
          <h2
            className="display-7 fw-semibold"
            dangerouslySetInnerHTML={{ __html: t("banner.title") }}
          />
          <p
            className="lead mt-3 text-dark font-size-14"
            style={{
              maxWidth: 450,
            }}
          >
            {t("banner.description")}
          </p>
          <Link
            to="/plans"
            className="btn btn-primary w-75 mt-auto fw-semibold"
          >
            {t("banner.linkToPix")}
          </Link>
        </div>
        {!isMobile && (
          <Player
            autoplay
            loop
            keepLastFrame={true}
            className=""
            src={pixAnimation}
            style={{ height: "250px", width: "250px" }}
          />
        )}
      </div>
    </div>
  );
}
