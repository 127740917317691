export const AssistantsI18n = {
  "pt-BR": {
    page: {
      assistant: {
        "Super Chat": "Super Chat",
        "Social Media Manager": "Gerente de Mídias Sociais",
        "Marketing Manager": "Gerente de Marketing",
        Copywriter: "Copywriter",
        Secretary: "Secretário",
        "Sales Executive": "Executivo de Vendas",
        "Customer Support Specialist": "Especialista em Suporte ao Cliente",
        "Blockchain Visionary": "Visionário de Blockchain",
        "Game Developer": "Desenvolvedor de Jogos",
        "Python Developer": "Desenvolvedor Python",
        "Node.js Developer": "Desenvolvedor Node.js",
        "C# Developer": "Desenvolvedor C#",
        "Java Developer": "Desenvolvedor Java",
      },
      "title-community": "Assistentes da comunidade",
      "title-my-own": "Meus assistentes",
      "top-assistants":
        "Top {{communityAssistantsToShow}} Assistentes da Comunidade",
      "aizzy-assistants": "Assistentes da Aizzy",
      "create-assistant": "Criar assistente",
      "empty-community-assistant": "Nenhum assistente encontrado",
    },
    communityAssistants: {
      link: {
        viewAll: "Ver todos",
        "see-all-assistants": "Ver todos assistentes da comunidade",
      },
    },
    placeholder: {
      "search-community-assistant": "Digite um termo para buscar",
      "search-assistant": "Buscar por uma função",
    },
  },
  "en-US": {
    page: {
      assistant: {
        "Super Chat": "Super Chat",
        "Social Media Manager": "Social Media Manager",
        "Marketing Manager": "Marketing Manager",
        Copywriter: "Copywriter",
        Secretary: "Secretary",
        "Sales Executive": "Sales Executive",
        "Customer Support Specialist": "Customer Support Specialist",
        "Blockchain Visionary": "Blockchain Visionary",
        "Game Developer": "Game Developer",
        "Python Developer": "Python Developer",
        "Node.js Developer": "Node.js Developer",
        "C# Developer": "C# Developer",
        "Java Developer": "Java Developer",
      },
      "title-community": "Community Assistants",
      "title-my-own": "My Assistants",
      "top-assistants":
        "Top {{communityAssistantsToShow}} Commnunity Assistants",
      "aizzy-assistants": "Aizzy Assistants",
      "create-assistant": "Create asistente",
      "empty-community-assistant": "No assistant found",
    },
    communityAssistants: {
      link: {
        viewAll: "See all",
        "see-all-assistants": "See all Community Assistants",
      },
    },
    placeholder: {
      "search-community-assistant": "Enter a term to search",
      "search-assistant": "Search for a role",
    },
  },
  "es-ES": {
    page: {
      assistant: {
        "Super Chat": "Super Chat",
        "Social Media Manager": "Gestor de Redes Sociales",
        "Marketing Manager": "Gestor de Marketing",
        Copywriter: "Copywriter",
        Secretary: "Secretario",
        "Sales Executive": "Ejecutivo de Ventas",
        "Customer Support Specialist": "Especialista en Soporte al Cliente",
        "Blockchain Visionary": "Visionario de Blockchain",
        "Game Developer": "Desarrollador de Juegos",
        "Python Developer": "Desarrollador Python",
        "Node.js Developer": "Desarrollador Node.js",
        "C# Developer": "Desarrollador C#",
        "Java Developer": "Desarrollador Java",
      },
      "title-community": "Asistentes comunitarios",
      "title-my-own": "Mis asistentes",
      "top-assistants":
        "Top {{communityAssistantsToShow}} Asistentes de Comunidad",
      "aizzy-assistants": "Asistentes de Aizzy",
      "create-assistant": "Create assistant",
      "empty-community-assistant": "No se encontró ningún asistente",
    },
    communityAssistants: {
      link: {
        viewAll: "Ver todos",
        "see-all-assistants": "Ver todos Asistentes de Comunidad",
      },
    },
    placeholder: {
      "search-community-assistant": "Ingresa un término para buscar",
      "search-assistant": "Buscar un rol",
    },
  },
  "fil-PH": {
    page: {
      assistant: {
        "Super Chat": "Super Chat",
        "Social Media Manager": "Tagapamahala ng Social Media",
        "Marketing Manager": "Tagapamahala ng Marketing",
        Copywriter: "Copywriter",
        Secretary: "Sekretaryo",
        "Sales Executive": "Tagapamahala ng Benta",
        "Customer Support Specialist": "Espesyalistang Suporta sa Customer",
        "Blockchain Visionary": "Tagapangarap ng Blockchain",
        "Game Developer": "Tagapag-develop ng Laro",
        "Python Developer": "Tagapag-develop ng Python",
        "Node.js Developer": "Tagapag-develop ng Node.js",
        "C# Developer": "Tagapag-develop ng C#",
        "Java Developer": "Tagapag-develop ng Java",
      },
      "title-community": "Mga Assistant ng Komunidad",
      "title-my-own": "Aking Mga Assistant",
      "top-assistants":
        "Top {{communityAssistantsToShow}} Assistant ng Komunidad",
      "aizzy-assistants": "Assistant ng Aizzy",
      "create-assistant": "Lumikha ng assistant",
      "empty-community-assistant": "Walang natagpuang assistant",
    },
    communityAssistants: {
      link: {
        viewAll: "Tingnan lahat",
        "see-all-assistants": "Tingnan lahat ng Assistant ng Komunidad",
      },
    },
    placeholder: {
      "search-community-assistant": "Ilagay ang isang term para maghanap",
      "search-assistant": "Maghanap ng isang role",
    },
  },
};

export const AssistantsListHeaderI18n = {
  "pt-BR": {
    page: {
      label: "Selecione um assistente para conversar",
      description: "Eles podem ajudar você e o seu negócio.",
    },
    button: {
      "create-assistant": "Crie seu próprio assistente",
    },
    tooltip: {
      "super-chat":
        "Olá, eu sou o Bumble Chip, o Super Chat. Posso ajudar você com várias tarefas gerais. Clique aqui para conversar comigo e aumentar sua produtividade.",
    },
  },
  "en-US": {
    page: {
      label: "Select an assistant to chat",
      description: "They can help you and your business.",
    },
    button: {
      "create-assistant": "Create your own assistant",
    },
    tooltip: {
      "super-chat":
        "Hello, I'm Bumble Chip, the Super Chat. I can help you with various general tasks. Click here to chat with me and unlock your productivity.",
    },
  },
  "es-ES": {
    page: {
      label: "Selecciona un asistente para chatear",
      description: "Ellos pueden ayudarte a ti y a tu negocio.",
    },
    button: {
      "create-assistant": "Crea tu propio asistente",
    },
    tooltip: {
      "super-chat":
        "Hola, soy Bumble Chip, el Super Chat. Puedo ayudarte con diversas tareas generales, haz clic aquí para conversar conmigo y desbloquear tu productividad.",
    },
  },
  "fil-PH": {
    page: {
      label: "Pumili ng assistant para makipag-usap",
      description: "Maaari silang makatulong sa iyo at sa iyong negosyo.",
    },
    button: {
      "create-assistant": "Lumikha ng iyong sariling assistant",
    },
    tooltip: {
      "super-chat":
        "Kamusta, ako si Bumble Chip, ang Super Chat. Maaari kitang tulungan sa iba't ibang pangkalahatang gawain. Mag-click dito upang makipag-usap sa akin at buksan ang iyong produktibidad.",
    },
  },
};
