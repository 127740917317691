import { useUser } from "@supabase/auth-helpers-react";
import { HelpTarget, getHelpLink } from "../utils/help-links";

interface ButtonHelpLinkProps {
  target: HelpTarget;
}

export function ButtonHelpLink({ target }: ButtonHelpLinkProps) {
  const user = useUser();

  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={getHelpLink(target, user?.user_metadata?.language)}
    >
      <i className="bx bx-question-mark p-1 fs-4 border border-1 border-secondary border-opacity-50" />
    </a>
  );
}
