import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/home/aizzy-ai.png";
import close from "../assets/images/icons/close.svg";
import { SectionHeroHomeI18n } from "../pages/Home/SectionHeroHome.i18n";
import useTranslation from "../helpers/useTranslation";

const HomeMenuMobile = ({ onClick, dropdownLanguageMobile }) => {
  const { t } = useTranslation(SectionHeroHomeI18n);
  return (
    <section className="nav-mobile-home">
      <div className="w-75">
        <div className="d-flex justify-content-between">
          <img src={logo} alt="" width={100} height={50} />
          <div className="d-flex align-items-center">
            {dropdownLanguageMobile}
            <img
              src={close}
              alt=""
              onClick={onClick}
              className="cursor-pointer"
              style={{ marginLeft: "1rem" }}
            />
          </div>
        </div>
        <nav className="mt-5">
          <div>
            <Link
              target="_blank"
              className="btn-lp-dark color-white-buttons font-home mb-3"
              to={"https://www.aizzy.ai/aiz-token"}
            >
              Aiz Token
            </Link>
            <Link
              target="_blank"
              className="btn-lp-ligth color-white-buttons font-home"
              to={"/signin"}
            >
              {t("signIn")}
            </Link>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default HomeMenuMobile;
