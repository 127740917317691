export const AssistantsChatsI18n = {
  "pt-BR": {
    page: {
      label: "Conversando com",
      "purchased-assistant": "Assistente adquirido",
      description: "é um especialista assistente de IA",
      role: {
        "Super Chat": "Super Chat",
        "Social Media Manager": "Gerente de Mídias Sociais",
        "Marketing Manager": "Gerente de Marketing",
        Copywriter: "Copywriter",
        Secretary: "Secretário",
        "Sales Executive": "Executivo de Vendas",
        "Customer Support Specialist": "Especialista em Suporte ao Cliente",
        "Blockchain Visionary": "Visionário de Blockchain",
        "Game Developer": "Desenvolvedor de Jogos",
        "Python Developer": "Desenvolvedor Python",
        "Node.js Developer": "Desenvolvedor Node.js",
        "C# Developer": "Desenvolvedor C#",
        "Java Developer": "Desenvolvedor Java",
      },
    },
    button: {
      general: "Chat principal",
      "edit-assistant": "Editar Assistente",
      "buy-assistant": "Comprar Assistente",
      expand: "Expandir",
      newChat: "Novo chat",
      clearChat: "Limpar chat",
    },
    owner: {
      span: "Criado por",
      description: "Para obter suporte, entre em contato com o criador.",
    },
  },
  "en-US": {
    page: {
      label: "Chatting with",
      "purchased-assistant": "Purchased Assistant",
      description: "is an AI assistant specialist",
      role: {
        "Super Chat": "Super Chat",
        "Social Media Manager": "Social Media Manager",
        "Marketing Manager": "Marketing Manager",
        Copywriter: "Copywriter",
        Secretary: "Secretary",
        "Sales Executive": "Sales Executive",
        "Customer Support Specialist": "Customer Support Specialist",
        "Blockchain Visionary": "Blockchain Visionary",
        "Game Developer": "Game Developer",
        "Python Developer": "Python Developer",
        "Node.js Developer": "Node.js Developer",
        "C# Developer": "C# Developer",
        "Java Developer": "Java Developer",
      },
    },
    button: {
      general: "General chat",
      "edit-assistant": "Edit Assistant",
      "buy-assistant": "Buy Assistant",
      expand: "Expand",
      newChat: "New chat",
      clearChat: "Clear chat",
    },
    owner: {
      span: "Designed By",
      description: "For support, please get in touch with the creator.",
    },
  },
  "es-ES": {
    page: {
      label: "Chateando con",
      "purchased-assistant": "Asistente comprado",
      description: "es asistente de IA especialista",
      role: {
        "Super Chat": "Super Chat",
        "Social Media Manager": "Gestor de Redes Sociales",
        "Marketing Manager": "Gestor de Marketing",
        Copywriter: "Copywriter",
        Secretary: "Secretario",
        "Sales Executive": "Ejecutivo de Ventas",
        "Customer Support Specialist": "Especialista en Soporte al Cliente",
        "Blockchain Visionary": "Visionario de Blockchain",
        "Game Developer": "Desarrollador de Juegos",
        "Python Developer": "Desarrollador Python",
        "Node.js Developer": "Desarrollador Node.js",
        "C# Developer": "Desarrollador C#",
        "Java Developer": "Desarrollador Java",
      },
    },
    button: {
      general: "General chat",
      "edit-assistant": "Editar Asistente",
      "buy-assistant": "Comprar Asistente",
      clearChat: "Limpiar chat",
      expand: "Expandir",
      newChat: "Chat nuevo",
    },
    owner: {
      span: "Criado por",
      description: "Para obtener ayuda, póngase en contacto con el creador.",
    },
  },
  "fil-PH": {
    page: {
      label: "Nakikipag-usap kay",
      "purchased-assistant": "Biniling Assistant",
      description: "ay isang espesyalistang assistant ng AI",
      role: {
        "Super Chat": "Super Chat",
        "Social Media Manager": "Pangasiwaan ng Social Media",
        "Marketing Manager": "Pangasiwaan ng Marketing",
        Copywriter: "Copywriter",
        Secretary: "Sekretaryo",
        "Sales Executive": "Tagapagpaganap ng Benta",
        "Customer Support Specialist": "Espesyalistang Suporta sa Customer",
        "Blockchain Visionary": "Tagapangarap ng Blockchain",
        "Game Developer": "Tagapag-develop ng Laro",
        "Python Developer": "Tagapag-develop ng Python",
        "Node.js Developer": "Tagapag-develop ng Node.js",
        "C# Developer": "Tagapag-develop ng C#",
        "Java Developer": "Tagapag-develop ng Java",
      },
    },
    button: {
      general: "Pangkalahatang usapan",
      "edit-assistant": "I-edit ang Assistant",
      "buy-assistant": "Bumili ng Assistant",
      clearChat: "Linisin ang chat",
      expand: "Palawakin",
      newChat: "Bagong chat",
    },
    owner: {
      span: "Idinisenyo ni",
      description: "Para sa suporta, mangyaring makipag-ugnay sa may-akda.",
    },
  },
};

export const ChanelsI18n = {
  "pt-BR": {
    page: {
      label: "Meus chats",
    },
    button: {
      newChat: "Novo chat",
      "delete-all-chats": "Deletar todos os chats",
      "clear-chats": "Limpar chat",
    },
    notifications: {
      "success-delete-all-chats": "Chats deletados com sucesso!",
    },
  },
  "en-US": {
    page: {
      label: "My chats",
    },
    button: {
      newChat: "New chat",
      "delete-all-chats": "Delete all chats",
      "clear-chats": "Clear chat",
    },
    notifications: {
      "success-delete-all-chats": "Chats deleted successfully!",
    },
  },
  "es-ES": {
    page: {
      label: "Mio chats",
    },
    button: {
      newChat: "Nuevo chat",
      "delete-all-chats": "Eliminar todos los chats",
      "clear-chats": "Limpiar chat",
    },
    notifications: {
      "success-delete-all-chats": "¡Chats eliminados con éxito!",
    },
  },
  "fil-PH": {
    page: {
      label: "Mga Chat Ko",
    },
    button: {
      newChat: "Bagong Chat",
      "delete-all-chats": "I-delete ang lahat ng Chat",
      "clear-chats": "I-clear ang chat",
    },
    notifications: {
      "success-delete-all-chats": "Mga Chat na matagumpay na na-delete!",
    },
  },
};


export const ChatChannelsI18n = {
  "pt-BR": {
    notifications: {
      "confirm-leave-chat": "Deseja sair do chat compartilhado?",
      "leave-chat-success": "Você saiu do chat compartilhado com sucesso!",
    },
  },
  "en-US": {
    notifications: {
      "confirm-leave-chat": "Would you like to leave the shared chat?",
      "leave-chat-success": "You have successfully left the shared chat!",
    },
  },
  "es-ES": {
    notifications: {
      "confirm-leave-chat": "¿Deseas salir del chat compartido?",
      "leave-chat-success": "¡Has salido del chat compartido con éxito!",
    },
  },
  "fil-PH": {
    notifications: {
      "confirm-leave-chat": "Gusto mo bang umalis sa ibinahaging chat?",
      "leave-chat-success": "Matagumpay kang umalis sa ibinahaging chat!",
    },
  },
};
