export const CommunityAssistantSearchI18n = {
  "pt-BR": {
    labelIsSearch: "Buscando por",
  },
  "en-US": {
    labelIsSearch: "Searching for",
  },
  "es-ES": {
    labelIsSearch: "Buscando por",
  },
  "fil-PH": {
    labelIsSearch: "Naghahanap para sa",
  },
};
