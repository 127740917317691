// CommunityAssistantsI18n
export const CommunityAssistantsI18n = {
  "pt-BR": {
    page: {
      "title-my-own": "Meus assistentes",
      "other-assistants": "Outros assistentes",
      "empty-community-assistant": "Nenhum assistente encontrado",
    },
    button: {
      "load-more-assistants": "Carregar mais assistentes",
    },
    placeholder: {
      "search-community-assistant": "Digite um termo para buscar",
    },
  },
  "en-US": {
    page: {
      "title-my-own": "My Assistants",
      "other-assistants": "Other assistants",
      "empty-community-assistant": "No assistant found",
    },
    button: {
      "load-more-assistants": "Load more assistants",
    },
    placeholder: {
      "search-community-assistant": "Enter a term to search",
    },
  },
  "es-ES": {
    page: {
      "title-my-own": "Mis asistentes",
      "other-assistants": "Otros asistentes",
      "empty-community-assistant": "No se encontró ningún asistente",
    },
    button: {
      "load-more-assistants": "Cargar más asistentes",
    },
    placeholder: {
      "search-community-assistant": "Ingresa un término para buscar",
    },
  },
  "fil-PH": {
    page: {
      "title-my-own": "Aking Mga Assistant",
      "other-assistants": "Iba pang mga assistant",
      "empty-community-assistant": "Walang natagpuang assistant",
    },
    button: {
      "load-more-assistants": "Magdagdag pa ng mga assistant",
    },
    placeholder: {
      "search-community-assistant": "Maglagay ng term na i-search",
    },
  },
};

// HeaderI18n
export const HeaderI18n = {
  "pt-BR": {
    page: {
      label: "Comunidade",
      description:
        "Aqui você encontrará os assistentes da comunidade para atender diversos tipos de tarefas",
    },
    button: {
      "create-assistant": "Criar assistente",
    },
  },
  "en-US": {
    page: {
      label: "Community",
      description:
        "Here you will find community assistants to handle various types of tasks",
    },
    button: {
      "create-assistant": "Create assistant",
    },
  },
  "es-ES": {
    page: {
      label: "comunitarios",
      description:
        "Aquí encontrarás asistentes de la comunidad para manejar diversos tipos de tareas",
    },
    button: {
      "create-assistant": "Crea asistente",
    },
  },
  "fil-PH": {
    page: {
      label: "Komunidad",
      description:
        "Dito makakakita ka ng mga assistant sa komunidad na maaaring mag-handle ng iba't ibang uri ng mga gawain",
    },
    button: {
      "create-assistant": "Gumawa ng assistant",
    },
  },
};

// CommunityAssistantI18n
export const CommunityAssistantI18n = {
  "pt-BR": {
    card: {
      enabled: "Habilitado",
      disabled: "Desabilitado",
      createdByMe: "Criado por mim",
      owner: "Criado por",
      support: "Suporte",
      "description-helper":
        "Para obter suporte, entre em contato com o criador.",
    },
  },
  "en-US": {
    card: {
      enabled: "Enabled",
      disabled: "Disabled",
      createdByMe: "Created by me",
      owner: "Designed by",
      support: "Support",
      "description-helper":
        "For support, please get in touch with the creator.",
    },
  },
  "es-ES": {
    card: {
      enabled: "Habilitado",
      disabled: "Desabilitado",
      createdByMe: "Creado por mi",
      owner: "Criado por",
      support: "Ayuda",
      "description-helper":
        "Para obtener ayuda, póngase en contacto con el creador.",
    },
  },
  "fil-PH": {
    card: {
      enabled: "Pinagana",
      disabled: " Hindi pinagana",
      createdByMe: "Ginawa ko",
      owner: "Ginawa ni",
      support: "Suporta",
      "description-helper":
        "Para sa suporta, mangyaring makipag-ugnay sa gumawa.",
    },
  },
};
