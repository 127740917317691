export const PrincingI18n = {
  "pt-BR": {
    page: {
      label: "Nossos planos",
      description:
        "Comece a criar gratuitamente e, em seguida, adicione um plano para elevar seus resultados. Os planos desbloqueiam recursos adicionais.",
      aiMemoryNote:
        "refere-se à quantidade de conversas recentes que o Ai pode 'memorizar' durante uma única interação. 'Extended' significa que o Ai pode lhe recallar mais conversas, permitindo mais detalhado e intricado discussões.",

      aiMemory: "Memória da IA",
    },
    toastPremium: {
      title: "Atenção",
      message:
        "Ao concluir a assinatura do plano PREMIUM, pedimos por favor que faça logout, limpe o cache do seu navegador e realize o login novamente para evitar quaisquer possíveis problemas relacionados a isso.",
    },
    button: {
      monthly: "Plano Mensal",
      yearly: "Plano Anual",
    },
    "card-community": {
      description:
        "Acesso gratuito limitado a chat de IA, imagens, PDFs, sites e vídeos.",
      button: "Ativar",
    },
    "card-premium": {
      description:
        "Chats ilimitados, diversas imagens, mais PDFs, sites e vídeos.",
      "button-subscribe": "Assinar",
      "button-manage": "Gerenciar",
    },
  },
  "en-US": {
    page: {
      label: "Pricing Plans",
      description:
        "Start building for free, then add a site plan to go live. Account plans unlock additional features.",
      aiMemoryNote:
        "refers to how much of the recent conversation the AI can 'remember' during a single interaction. 'Extended' means that the AI can recall longer conversations, allowing for more detailed and intricate discussions.",

      aiMemory: "AI Memory",
    },
    toastPremium: {
      title: "Attention",
      message:
        "Upon completing your subscription to the PREMIUM plan, please log out, clear your browser cache, and log in again to avoid any potential issues related to this.",
    },
    button: {
      monthly: "Monthly billing",
      yearly: "Yearly billing",
    },
    "card-community": {
      description:
        "Limited free access to AI chat, images, pdfs, sites & videos.",
      button: "Active",
    },
    "card-premium": {
      description:
        "Unlimited chats, loads of images, more pdfs, sites & videos.",
      "button-subscribe": "Subscribe",
      "button-manage": "Manage",
    },
  },
  "es-ES": {
    page: {
      label: "Nuestros planes",
      description:
        "Empieza a construir de forma gratuita y luego agrega un plan para mejorar tus resultados. Los planes desbloquean características adicionales.",
      aiMemoryNote:
        "refiere a cómo muchas conversaciones recientes el AI puede recordar durante una sola interacción. 'Extended' significa que el AI puede recordar conversaciones más largas, permitiendo más detallada y intrica discusiones.",

      aiMemory: "Memoria del AI",
    },
    toastPremium: {
      title: "Atención",
      message:
        "Al finalizar la suscripción al plan PREMIUM, le pedimos que cierre sesión, borre la caché de su navegador y vuelva a iniciar sesión para evitar posibles problemas relacionados con esto.",
    },
    button: {
      monthly: "Plan mensual",
      yearly: "Plan anual",
    },
    "card-community": {
      description:
        "Acceso limitado gratuito a chat de IA, imágenes, PDFs, sitios y videos.",
      button: "Activar",
    },
    "card-premium": {
      description:
        "Chats ilimitados, muchas imágenes, más PDFs, sitios y videos.",
      "button-subscribe": "Suscribirse",
      "button-manage": "Administrar",
    },
  },
  "fil-PH": {
    page: {
      label: "Ang Aming Mga Plano",
      description:
        "Magsimula ng libre, pagkatapos ay magdagdag ng plano para mapabuti ang iyong mga resulta. Ang mga plano ay naglalayong magbukas ng karagdagang mga tampok.",
      aiMemoryNote:
        "ay tumutukoy sa dami ng mga kamakailang usapan na maari'ng 'maalala' ng AI sa isang solong pakikipag-ugma. 'Extended' ay nangangahulugang ang AI ay maari'ng maalala ang mas mahabang mga usapan, na nagbibigay daan sa mas detalyado at mas komplikado na mga diskusyon.",

      aiMemory: "Memorya ng AI",
    },
    toastPremium: {
      title: "Paalala",
      message:
        "Sa pagtatapos ng iyong subscription sa PREMIUM na plano, maari'ng itong mag-logout, linisin ang iyong cache sa browser, at mag-log in muli upang maiwasan ang anumang posibleng isyu kaugnay dito.",
    },
    button: {
      monthly: "Buwanang singil",
      yearly: "Taunang singil",
    },
    "card-community": {
      description:
        "Limitadong libreng access sa AI chat, mga imahe, pdfs, mga site at mga video.",
      button: "Aktibo",
    },
    "card-premium": {
      description:
        "Unlimited na mga chat, maraming mga imahe, karagdagang pdfs, mga site at mga video.",
      "button-subscribe": "Mag-subscribe",
      "button-manage": "Pamahalaan",
    },
  },
};
