import { useEffect } from "react";
import useTranslation from "../../helpers/useTranslation";

import { ImageI18n } from "./Image.i18n.ts";

import { ButtonHelpLink } from "../../components/ButtonHelpLink";
import { CreateNewImageModal } from "./components/CreateNewImageModal";
import { ImagesList } from "./components/ImagesList";
import { ImageCardSkeleton } from "../../components/ImageCardSkeleton";

import { useQuery } from "react-query";
import { QUERY_KEYS } from "../../libs/react-query";
import { createChannel, getChannelsAndDocuments } from "../../api";

import { ChannelDTO } from "../../@DTO/channel";
import { changeSelectedChat } from "../../redux/actions";
import { useRedux } from "../../hooks";

export function ImagesPage() {
  const { dispatch } = useRedux();

  const { t } = useTranslation(ImageI18n);

  const superChatAssistantId = "646d98a9440f2a9bf026d4b2";

  const {
    isLoading,
    data: channels,
    refetch,
    isRefetching,
  } = useQuery<ChannelDTO[]>(
    (QUERY_KEYS.CHANNELS, "images"),
    async () => {
      const data = await getChannelsAndDocuments();
      return data.channels ?? [];
    },
    {
      staleTime: Infinity,
      onSuccess: async _data => {
        const hasImageChannel = _data?.some(channel => channel.isImage);

        if (!hasImageChannel) {
          const imageChannel = {
            assistantId: superChatAssistantId,
            channelName: "Images",
            isImage: true,
          };

          await createChannel(imageChannel);

          await refetch();
        }
      },
    }
  );

  const imageChannel = channels?.find(channel => channel.isImage);

  useEffect(() => {
    if (!!imageChannel) {
      dispatch(changeSelectedChat(imageChannel));
    }
  }, [imageChannel]);

  return (
    <>
      <div
        className="chat-leftsidebar full p-3"
        style={{ height: "100vh", overflowX: "hidden" }}
      >
        <div>
          <div className="row d-flex justify-content-between mb-3">
            <div className="col-6 col-sm-8 col-md">
              <div className="d-flex align-items-center justify-content-between mb-0  p-3">
                <h4 className="m-0">{t("chat.label")}</h4>
                <ButtonHelpLink target="images" />
              </div>
              <p className=" mt-3 p-3 d-none d-sm-block">
                {t("chat.description")}
              </p>
            </div>
            <div className="col-6 col-sm-4 col-md-auto">
              <div className=" h-100 w-100 d-flex align-items-center justify-content-center text-nowrap">
                <CreateNewImageModal />
              </div>
            </div>
          </div>
        </div>

        {(isLoading && isRefetching) || !imageChannel ? (
          <ImageCardSkeleton />
        ) : (
          <ImagesList imageChannel={imageChannel!} />
        )}
      </div>
    </>
  );
}

// const onMint = async chatId => {
//   // now anyone can mint the NFT
//   const response = await fetch(
//     `${process.env.REACT_APP_API_URL}/api/web3/gen-sig`,
//     {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + getBearerToken(),
//       },
//       body: JSON.stringify({
//         address: address,
//         chatId: chatId,
//       }),
//     }
//   );
//   const signedPayload = await response.json();

//   try {
//     //const nft = await nftCollection?.signature.mint(signedPayload);
//     const nft = {};

//     showSuccessNotification("NFT Minted succesfully!");

//     await fetch(`${process.env.REACT_APP_API_URL}/api/web3/save-minted`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + getBearerToken(),
//       },
//       body: JSON.stringify({
//         chatId: chatId,
//         txid: "", //nft?.receipt?.transactionHash,
//         nftId: "", //nft?.id.toNumber(),
//       }),
//     });
//   } catch (err: any) {
//     showErrorNotification(
//       "NFT Minting failed! Please check you are connected to the right network and have enough funds."
//     );

//     //console.error("contract call failure", err);
//   }
// };

// if (!imageChannel) {
//   const superChatAssistantId = "646d98a9440f2a9bf026d4b2";
//   dispatch(
//     createChannel({
//       assistantId: superChatAssistantId,
//       channelName: "Images",
//       isDocument: false,
//       isImage: true,
//     })
//   );
// }
