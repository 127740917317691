import { useUser } from "@supabase/auth-helpers-react";
import { useEffect, useState } from "react";
import RemainingCallsContext from "./RemainingCallsContext";
import {
  getRemainingCalls,
  getRemainingCallsBySubscriptionPlan,
  updateRemaining,
} from "./remainingCalls";
import { useRedux } from "../hooks";
import {
  getCurrentUserLanguage,
  getCurrentUserPlan,
} from "../helpers/userHelper";
import { getBearerToken } from "../helpers/localStorageHelpers";
import {
  showErrorNotification,
  showWarningNotification,
} from "../helpers/notifications";
import { PLANS_AIZZY } from "../helpers/constants/Plans";

const RemainingCallsProvider = ({ children }) => {
  const { useAppSelector } = useRedux();
  const {
    createDocumentFileUploaded,
    isYoutubeChannelCreated,
    channelDocumentsUrlsUploaded,
  } = useAppSelector(state => ({
    createDocumentFileUploaded: state.Chats.createDocumentFileUploaded,
    isYoutubeChannelCreated: state.Chats.isYoutubeChannelCreated,
    channelDocumentsUrlsUploaded: state.Chats.channelDocumentsUrlsUploaded,
  }));
  const [remainingCalls, setRemainingCalls] = useState();
  const [remainingPDFs, setRemainingPDFs] = useState();
  const [remainingChats, setRemainingChats] = useState();
  const [remainingImages, setRemainingImages] = useState();
  const [remainingVideos, setRemainingVideos] = useState();
  const [remainingSites, setRemainingSites] = useState();
  const [pdfSizeLimit, setPdfSizeLimit] = useState();
  const [showFreemiumModal, setShowFreemiumModal] = useState("");

  const [isCheckingRemainingCallsMessage, setIsCheckingRemainingCallsMessage] =
    useState(false);

  const userLanguage = getCurrentUserLanguage();

  const userSubscription = getCurrentUserPlan();
  const isPremiumUser = userSubscription === PLANS_AIZZY.PREMIUM;

  const token = getBearerToken();

  useEffect(() => {
    const product_name = userSubscription;
    if (!product_name) return;
    const checkRemainingCalls = async () => {
      await getRemainingCalls().then(setRemainingCalls);
      await getRemainingCallsBySubscriptionPlan(
        "remainingPDFs",
        product_name
      ).then(setRemainingPDFs);
      await getRemainingCallsBySubscriptionPlan(
        "remainingChats",
        product_name
      ).then(setRemainingChats);
      await getRemainingCallsBySubscriptionPlan(
        "remainingImages",
        product_name
      ).then(setRemainingImages);
      await getRemainingCallsBySubscriptionPlan(
        "remainingVideos",
        product_name
      ).then(setRemainingVideos);
      await getRemainingCallsBySubscriptionPlan(
        "remainingSites",
        product_name
      ).then(setRemainingSites);
      await getRemainingCallsBySubscriptionPlan(
        "pdfSizeLimit",
        product_name
      ).then(setPdfSizeLimit);
    };
    checkRemainingCalls();
  }, [userSubscription, token]);

  useEffect(() => {
    if (!remainingCalls) return;
    if (createDocumentFileUploaded) {
      updateRemainingCallsByAction("remainingPDFs");
    }
    if (isYoutubeChannelCreated) {
      updateRemainingCallsByAction("remainingVideos");
    }
    if (channelDocumentsUrlsUploaded) {
      updateRemainingCallsByAction("remainingSites");
    }
  }, [
    remainingCalls,
    createDocumentFileUploaded,
    isYoutubeChannelCreated,
    channelDocumentsUrlsUploaded,
  ]);

  const updateRemainingCallsByAction = action => {
    // Updates the remaining calls and saves it to local storage
    updateRemaining(action, remainingCalls).then(updatedRemainingCalls => {
      setRemainingCalls(updatedRemainingCalls);
    });
  };

  const checkRemainingCallsMessage = async (callback, action) => {
    try {
      setIsCheckingRemainingCallsMessage(true);
      const product_name = userSubscription;
      const remaining = await getRemainingCallsBySubscriptionPlan(
        action,
        product_name
      );
      if (remaining <= 0) {
        if (action === "remainingImages" && isPremiumUser) {
          const imageResourceDepletedMessage = {
            en: "You have reached the limit of creating 50 images per month",
            pt: "Você atingiu o limite de criação de 50 imagens por mês",
            es: "Has alcanzado el límite de creación de 50 imágenes al mes",
          };

          showWarningNotification(imageResourceDepletedMessage[userLanguage]);
        }
        setShowFreemiumModal(action);
        return;
      }
      setShowFreemiumModal("");
      callback();
    } catch (error) {
      showErrorNotification(
        error.message || "Error when checking remaining calls"
      );
    } finally {
      setIsCheckingRemainingCallsMessage(false);
    }
  };

  return (
    <RemainingCallsContext.Provider
      value={{
        remainingCalls,
        updateRemainingCallsByAction,
        checkRemainingCallsMessage,
        showFreemiumModal,
        setShowFreemiumModal,
        remainingPDFs,
        remainingChats,
        remainingImages,
        remainingVideos,
        remainingSites,
        pdfSizeLimit,
        isCheckingRemainingCallsMessage,
      }}
    >
      {children}
    </RemainingCallsContext.Provider>
  );
};

export default RemainingCallsProvider;
