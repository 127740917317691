import React from "react";
import { Link } from "react-router-dom";
import logoAizzy from "../assets/images/logo.svg";
import AnimationPage404 from "./AnimationPage404";

const NotFoundPage = () => {
  return (
    <div
      id="container-page-not-found"
      className="d-flex align-items-center flex-column justify-content-center"
    >
      <AnimationPage404 />
      <div className="message-page-not-found mb-3">Page not found</div>
      <Link
        to={"/"}
        className="mt-5 rounded-3 btn button-page-not-found d-flex align-items-center gap-2"
        style={{
          maxWidth: "300px",
        }}
      >
        Return <img src={logoAizzy} width={26} height={26} />
      </Link>
    </div>
  );
};

export default NotFoundPage;
