import classnames from "classnames";
import { useEffect, useState } from "react";
import { useRedux } from "../../hooks/index";
import {
  changeAssistant,
  changeSelectedChat,
  changeTab,
} from "../../redux/actions";
import ConversationUser from "./ConversationUser/ConversationUser";
import Leftbar from "./Leftbar";
import UserProfileDetails from "./UserDocuments/index";
import { SelectedChatDTO } from "../../@DTO/selectedChat";
import { useLocation } from "react-router-dom";
import { TABS } from "../../constants";
import { getAssistant } from "../../api/assistants";
import { showErrorNotification } from "../../helpers/notifications";
import Loader from "../../components/Loader";
import { getAndUpdateUser, getUser } from "../../helpers/userHelper";
import { CompressedJobDTO } from "../../@DTO/embeddings";
import {
  DocumentRepository,
  makeDocumentId,
} from "../../helpers/indexedDb/document-repository";

interface IndexProps {}

const Index = (props: IndexProps) => {
  const [isLoadingSharedAssistant, setIsLoadingSharedAssistant] =
    useState(false);

  const { dispatch, useAppSelector } = useRedux();

  const user = getUser();

  const { search } = useLocation();
  const assistantId = new URLSearchParams(search).get("assistantId");

  const documentRepository = new DocumentRepository();

  const { channelJobs, selectedChat, channelsAndDocuments, isChatExpanded } =
    useAppSelector(state => ({
      channelJobs: state.Chats.channelJobs,
      selectedChat: state.Chats.selectedChat as SelectedChatDTO,
      channelsAndDocuments: state.Chats.channelsAndDocuments,
      isChatExpanded: state.Layout.isChatExpanded,
    }));

  const hasDocumentsProcessing =
    channelJobs?.filter(item => item?.processed === false)?.length > 0;

  const isMobile = window.matchMedia("(max-width: 767px)").matches;

  async function getSharedAssistant() {
    if (assistantId) {
      try {
        setIsLoadingSharedAssistant(true);
        const response = await getAssistant(assistantId);
        //@ts-ignore
        const assistant = response?.employees[0];

        dispatch(changeAssistant(assistant));
        dispatch(changeTab(TABS.ASSISTANT_CHATS));
        dispatch(changeSelectedChat(null));
      } catch (error: any) {
        showErrorNotification(error);
      } finally {
        setIsLoadingSharedAssistant(false);
      }
    }
  }

  async function saveCompressedDocumentsInIndexedDB() {
    const jobs: CompressedJobDTO[] = channelJobs;

    if (!jobs || (jobs && jobs.length === 0)) {
      return;
    }

    jobs.forEach(async job => {
      if (!job.processed) {
        return;
      }

      const compressedDocument = job.document;

      const documentId = makeDocumentId(
        selectedChat._id,
        selectedChat.userId,
        job._id
      );

      await documentRepository.saveDocument(documentId, compressedDocument);
    });
  }

  useEffect(() => {
    if (assistantId) {
      getSharedAssistant();
    }
  }, [assistantId]);

  useEffect(() => {
    async function loadUserData() {
      if (!user?.id) {
        await getAndUpdateUser();
      }
    }

    loadUserData();
  }, [user]);

  useEffect(() => {
    if (selectedChat && selectedChat.isDocument) {
      saveCompressedDocumentsInIndexedDB();
    }
  }, [channelJobs]);

  return (
    <>
      {isLoadingSharedAssistant ? (
        <Loader />
      ) : (
        <>
          <Leftbar />

          <div
            className={classnames(
              "user-chat w-100 d-flex justify-content-center",
              {
                "user-chat-show":
                  (!isMobile && selectedChat) ||
                  (isMobile && channelsAndDocuments?.channels?.length === 0) ||
                  selectedChat,
              }
            )}
            id="user-chat"
          >
            {selectedChat && (
              <>
                <div
                  className="h-100 w-100 d-flex flex-md-row"
                  style={{
                    maxWidth: isChatExpanded ? "100%" : 1024,
                  }}
                >
                  <div className="flex-grow-1 d-flex flex-column conversation-user px-2 px-lg-0">
                    <ConversationUser
                      hasDocumentsProcessing={hasDocumentsProcessing}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {selectedChat && (
            <>
              {selectedChat.channelName !== "general" && (
                <UserProfileDetails documentsProcessed={channelJobs} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Index;
