import useTranslation from "../../../helpers/useTranslation";
import { RemainingTableI18n } from "./RemainingTable.i18n";

const RemainingTable = ({ plan, hideRemainingCollumn = false }) => {
  const { t } = useTranslation(RemainingTableI18n);
  const remainingServices = [
    { name: "remainingChats", limit: "chatLimit" },
    { name: "remainingImages", limit: "imageLimit" },
    { name: "remainingPDFs", limit: "pdfLimit" },
    { name: "remainingSites", limit: "siteLimit" },
    { name: "remainingVideos", limit: "videoLimit" },
  ];
  const returnUnlimitedString = serviceName => {
    return plan[serviceName] > 1000 ? t("unlimited") : plan[serviceName];
  };
  const isCommunity = !hideRemainingCollumn && plan?.name === "Community";
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className=" font-size-16" style={{ fontWeight:700 }}>{t("service")}</th>
            {isCommunity && <th className=" font-size-16" style={{ fontWeight:700 }}>{t("remaining")}</th>}
            <th className=" font-size-16" style={{ fontWeight:700 }}>{t("limit")}</th>
          </tr>
        </thead>
        <tbody>
          {remainingServices.map((service, index) => (
            <tr key={index}>
              <td className="font-size-16">
                {service?.name?.replace("remaining", "")}
              </td>
              {isCommunity && (
                <td className="font-size-16">
                  {returnUnlimitedString(service?.name)}
                </td>
              )}
              <td className="font-size-16">
                {returnUnlimitedString(service.limit)}
              </td>
            </tr>
          ))}
          <tr>
            <td className="font-size-16">{t("pdfSize")}</td>
            {isCommunity && <td>-</td>}
            <td className="font-size-16">{plan.pdfSizeLimit} MB</td>
          </tr>
          <tr>
            <td className="font-size-16">{t("youtubeDuration")}</td>
            {isCommunity && <td>-</td>}
            <td className="font-size-16">{plan.videoDurationLimit} Min</td>
          </tr>
          <tr>
            <td className="font-size-16">{t("channelMembers")}</td>
            {isCommunity && <td>-</td>}
            <td className="font-size-16">{plan.channelMembers}</td>
          </tr>
          <tr>
            <td className="font-size-16">{t("aiMemory")}</td>
            {isCommunity && <td>-</td>}
            <td className="font-size-16">
              {plan?.name === "Community" ? <>Standard*</> : <>Extended*</>}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RemainingTable;
