export const MyAssistantsI18n = {
  "pt-BR": {
    page: {
      "title-my-own": "Meus assistentes",
      "empty-my-assistants":
        "Parece que você ainda não possuí nenhum assistente",
    },
    link: {
      "create-assistant-secondary":
        "Crie seu assistente exclusivo agora mesmo!",
      "create-assistant": "Criar assistente",
    },
  },
  "en-US": {
    page: {
      "title-my-own": "My Assistants",
      "empty-my-assistants": "It seems you don't have any assistant yet",
    },
    link: {
      "create-assistant-secondary": "Create your unique assistant right now!",
      "create-assistant": "Create Assistant",
    },
  },
  "es-ES": {
    page: {
      "title-my-own": "Mis asistentes",
      "empty-my-assistants": "Parece que aún no tienes ningún asistente",
    },
    link: {
      "create-assistant-secondary": "¡Crea tu asistente exclusivo ahora mismo!",
      "create-assistant": "Criar asistente",
    },
  },
  "fil-PH": {
    page: {
      "title-my-own": "Aking Mga Tulong",
      "empty-my-assistants": "Waring wala kang anumang tagapayo ngayon",
    },
    link: {
      "create-assistant-secondary":
        "Gumawa ng iyong natatanging tagapayo ngayon!",
      "create-assistant": "Gumawa ng Tagapayo",
    },
  },
};
