export const ModalFeedbackTransactionI18n = {
  "pt-BR": {
    modal: {
      insufficientFunds: {
        title: "Saldo insulficiente",
        description:
          "Parece que você não tem fundos suficientes para adquirir o plano pro.",
        button: "To go back",
      },
      sending: {
        title: "Enviando transação...",
        description:
          "Por favor, confirme a transação em sua carteira e aguarde até que ela seja processada.",
      },
      checking: {
        title: "Verificando transação...",
        description: "Aguarde um momento, estamos verificando sua transação",
      },
      refused: {
        title: "Você recusou essa transação",
        description: "Clique no botão abaixo e tente novamente.",
        buttonTryAgain: "Tentar novamente",
        button: "Voltar",
      },
      success: {
        title: "Transação confirmada!",
        description:
          "Parabéns! Você acaba de se tornar membro premium, aproveite os beneficios!",
        button: "Continuar",
        timeoutText:
          "A página será recarregada em {seconds} segundos caso você não clique no botão de continuar.",
      },
      error: {
        title: "Erro na transação",
        description:
          "Houve um erro ao processar sua transação. Por favor, tente novamente mais tarde.",
        button: "Voltar",
      },
    },
  },
  "en-US": {
    modal: {
      insufficientFunds: {
        title: "Insufficient funds",
        description:
          "It appears that you do not have enough funds to purchase the pro plan.",
        button: "To go back",
      },
      sending: {
        title: "Sending Transaction...",
        description:
          "Please confirm the transaction in your wallet and wait for it to be processed.",
      },
      checking: {
        title: "Checking Transaction...",
        description: "Please wait a moment, we are verifying your transaction",
      },
      refused: {
        title: "You declined this transaction",
        description: "Click the button below and try again.",
        buttonTryAgain: "Try again",
        button: "To go back",
      },
      success: {
        title: "Transaction confirmed!",
        description:
          "Congratulations! You have just become a premium member, enjoy the benefits!",
        button: "Continue",
        timeoutText:
          "The page will reload in {seconds} seconds if you do not click the continue button.",
      },
      error: {
        title: "Transaction Error",
        description:
          "There was an error processing your transaction. Please try again later.",
        button: "To go back",
      },
    },
  },
  "es-ES": {
    modal: {
      insufficientFunds: {
        title: "Saldo insuficiente",
        description:
          "Parece que no tienes suficientes fondos para adquirir el plan pro.",
        button: "Volver",
      },
      sending: {
        title: "Enviando transacción...",
        description:
          "Confirme la transacción en su billetera y espere a que se procese.",
      },
      checking: {
        title: "Verificando transacción...",
        description: "Espere un momento, estamos verificando su transacción",
      },
      refused: {
        title: "Fondos insuficientes",
        description:
          "Parece que no tienes suficientes fondos para comprar el plan pro.",
        buttonTryAgain: "Intentar de nuevo",
        button: "Volver",
      },
      success: {
        title: "Transacción confirmada!",
        description:
          "¡Felicidades! Te has convertido en miembro premium, ¡disfruta de los beneficios!",
        button: "Continuar",
        timeoutText:
          "La página se recargará en {seconds} segundos si no haces clic en el botón de continuar.",
      },
      error: {
        title: "Error en la transacción",
        description:
          "Hubo un error al procesar su transacción. Por favor, intente de nuevo más tarde.",
        button: "Volver",
      },
    },
  },
  "fil-PH": {
    modal: {
      insufficientFunds: {
        title: "Kakulangan sa Pondo",
        description:
          "Mukhang wala kang sapat na pondo para makabili ng pro plan.",
        button: "Bumalik",
      },
      sending: {
        title: "Nagpapadala ng Transaksyon...",
        description:
          "Pakitanggap ang transaksyon sa iyong wallet at hintayin itong maproseso.",
      },
      checking: {
        title: "Nagsusuri ng Transaksyon...",
        description:
          "Maghintay po lamang ng isang sandali, sinusuri namin ang iyong transaksyon",
      },
      refused: {
        title: "Kakulangan sa Pondo",
        description:
          "Mukhang wala kang sapat na pondo para makabili ng pro plan.",
        buttonTryAgain: "Subukang Muli",
        button: "Bumalik",
      },
      success: {
        title: "Kumpirmadong Transaksyon!",
        description:
          "Binabati kita! Ikaw ay naging premium member na, enjoyin ang mga benepisyo!",
        button: "Magpatuloy",
        timeoutText:
          "Ang pahina ay magre-reload sa {seconds} segundo kung hindi ka mag-click sa magpatuloy na button.",
      },
      error: {
        title: "Error sa Transaksyon",
        description:
          "May naganap na error sa pagproseso ng iyong transaksyon. Pakisubukang muli mamaya.",
        button: "Bumalik",
      },
    },
  },
};
