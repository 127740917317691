export const JobMetadaI18n = {
  "pt-BR": {
    viewDetails: "Ver detalhes",
    createdAt: "Criado em",
    totalPages: "Total de páginas",
    totalWords: "Total de palavras",
    totalChars: "Total de caracteres",
    processingTime: "Tempo de processamento",
    seconds: "segundos",
    numReadableImages: "Imagens lidas",
  },
  "en-US": {
    viewDetails: "View details",
    createdAt: "Created at",
    totalPages: "Total pages",
    totalWords: "Total words",
    totalChars: "Total characters",
    processingTime: "Processing time",
    seconds: "seconds",
    numReadableImages: "Readable images",
  },
  "es-ES": {
    viewDetails: "Ver detalles",
    createdAt: "Creado en",
    totalPages: "Total de páginas",
    totalWords: "Total de palabras",
    totalChars: "Total de caracteres",
    processingTime: "Tiempo de procesamiento",
    seconds: "segundos",
    numReadableImages: "Imágenes legibles",
  },
};
