import LogoImg from "../assets/images/home/aizzy-ai.png";

export const Logo = props => {
  return (
    <>
      <img src={LogoImg} alt="" width={100} height={50} />
    </>
  );
};

export default Logo;
