export const NewYoutubeVideoModalI18n = {
  "pt-BR": {
    page: {
      label: "Adicione um link de vídeo do youtube",
      processingVideoTitle:
        "Por favor, aguarde, estamos processando o vídeo do Youtube",
    },
    input: {
      title: "Link do vídeo",
      placeholder: "Youtube link...",
    },
    button: {
      close: "Fechar",
      create: "Criar chat e adicionar link",
    },
  },
  "en-US": {
    page: {
      label: "Add new Youtube link",
      processingVideoTitle: "Please wait, we're processing the Youtube video",
    },
    input: {
      title: "Video link",
      placeholder: "Youtube link...",
    },
    button: {
      close: "Close",
      create: "Create chat and add link",
    },
  },
  "es-ES": {
    page: {
      label: "Add new Youtube link",
      processingVideoTitle:
        "Por favor espera, estamos procesando el video de Youtube",
    },
    input: {
      title: "Enlace de vídeo",
      placeholder: "Youtube link...",
    },
    button: {
      close: "Cerrar",
      create: "Crear chat y agregar enlace",
    },
  },
  "fil-PH": {
    page: {
      label: "Magdagdag ng bagong link ng Youtube",
      processingVideoTitle:
        "Mangyaring maghintay, binoboto namin ang video ng Youtube",
    },
    input: {
      title: "Link ng video",
      placeholder: "Link ng Youtube...",
    },
    button: {
      close: "Isara",
      create: "Gumawa ng chat at idagdag ang link",
    },
  },
};
