export const ImageI18n = {
  "pt-BR": {
    chat: {
      label: "Galeria de Imagens",
      description:
        "Coloque sua criatividade para trabalhar criando imagens conosco, nós utilizamos o Open Journey 4 para a geração de imagens, ao digitar sua mensagem, se certifique de que está seguindo um bom modelo para gerar uma imagem que atenda mais o seu objetivo",
    },
  },
  "en-US": {
    chat: {
      label: "Images Gallery",
      description:
        "Put your creativity to work creating images with us, we use Open Journey 4 for image generation. When typing your message, make sure you're following a good model to generate an image that best meets your needs.",
    },
  },
  "es-ES": {
    chat: {
      label: "Galería de Imágenes",
      description:
        "Pon tu creatividad a trabajar creando imágenes con nosotros, utilizamos Open Journey 4 para la generación de imágenes. Al escribir tu mensaje, asegúrate de seguir un buen modelo para generar una imagen que satisfaga mejor tus necesidades.",
    },
  },
  "fil-PH": {
    chat: {
      label: "Galeriya ng mga Larawan",
      description:
        "Ilagay ang iyong abilidad sa paggawa ng mga larawan kasama kami, gumagamit kami ng Open Journey 4 para sa pagbuo ng mga larawan. Kapag nagtu-type ng iyong mensahe, siguruhing sinusundan mo ang isang magandang modelo upang makabuo ng isang larawan na pinakamainam na naaangkop sa iyong mga pangangailangan.",
    },
  },
};
