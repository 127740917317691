import axios from "axios";
import config from "../config";

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(config => {
  try {
    const token = JSON.parse(
      localStorage.getItem("sb.token") || "{}"
    )?.access_token;
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  } catch (error) {
    console.error("Erro when parse token:", error);
  }
  return config;
});

// intercepting to capture errors
axios.interceptors.response.use(
  function (response: any) {
    return response.data ? response.data : response;
  },
  function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error?.response?.status) {
      case 500:
        if (error?.response?.data?.error) {
          message = error?.response?.data?.error;
        } else {
          message = "Internal Server Error";
        }
        break;
      case 401:
        window.location.href = "/expirated";
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error?.response?.data?.message || error?.message || error;
    }
    return Promise.reject({
      message,
      success: error?.response?.data?.success,
      planLimit: error?.response?.data?.planLimit,
      code: error?.response?.data?.code,
    });
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url: string, params?: {}): any => {
    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url: string, data?: {}): any => {
    return axios.post(url, data);
  };

  /**
   * post given data to url
   */
  stream = (url: string, data?: {}): any => {
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data?: {}): any => {
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url: string, config?: {}): any => {
    return axios.delete(url, { ...config });
  };

  /*
   file upload update method
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.put(url, formData, config);
  };

  /*
   file upload post method
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };
}

export { APIClient, setAuthorization };
