import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";

const InfoModal = ({ isOpen, toggle, message, title, closeButtonText }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop centered>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody className="p-4 mb-1">
        <p dangerouslySetInnerHTML={{ __html: message }}></p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          {closeButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InfoModal;
