import { useState } from "react";
import { useQuery } from "react-query";
import { QUERY_KEYS } from "../../../../libs/react-query";
import { Input } from "reactstrap";

import Contact from "./Assistants";
import EmptyStateResult from "../../../../components/EmptyStateResult";

import { getCompanyAssistants } from "../../../../api/assistants";
import { AssistantDTO } from "../../../../@DTO/assistant";
import { AssistantCardSkeleton } from "../../../../components/AssistantCardSkeleton";

interface AizzyAssistantsProps {
  onSelectAssistant: (assistant: AssistantDTO) => void;
  t: any;
}
export function AizzyAssistants({
  onSelectAssistant,
  t,
}: AizzyAssistantsProps) {
  const [search, setSearch] = useState("");

  const { data, isLoading } = useQuery(
    QUERY_KEYS.AIZZY_ASSISTANTS,
    async () => {
      return await getCompanyAssistants({
        pageNumber: 0,
      });
    },
    {
      staleTime: 180 * 60 * 1000, // 3 hours,
      keepPreviousData: true,
    }
  );

  const lowercaseSearch = search.toLowerCase();

  const filteredAssistants = data?.assistants?.filter(
    ({ role, name }) =>
      !search ||
      role.toLowerCase().includes(lowercaseSearch) ||
      name.toLowerCase().includes(lowercaseSearch)
  );

  return (
    <div className="animate__animated animate__fadeInLeft animate__faster">
      <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between px-3 my-4 gap-3">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="m-0">{t("page.aizzy-assistants")}</h4>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div
          className="d-flex justify-content-between w-100 px-3"
          style={{ maxWidth: 350 }}
        >
          <Input
            type="text"
            disabled={!data?.assistants}
            className="form-control bg-body border-0 w-100 font-size-12"
            placeholder={t("placeholder.search-assistant")}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <div className="btn btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
            <i
              className="bx bx-search align-middle"
              id="button-searchcontactsaddon"
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="mt-4 mb-5">
          <AssistantCardSkeleton quantity={15} />
        </div>
      ) : (
        <div className="mt-4 mb-5">
          {search && (filteredAssistants || []).length === 0 ? (
            <EmptyStateResult searchedText={search} />
          ) : (
            data?.assistants && (
              <Contact
                assistants={filteredAssistants}
                onSelectAssistant={onSelectAssistant}
              />
            )
          )}
        </div>
      )}
    </div>
  );
}
