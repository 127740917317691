import React from "react";
import logo from "../assets/images/icons/aizzy-icon-dark.svg";
import TitlesHome from "./TitlesHome";
import ButtonsHome from "./ButtonsHome";
import useTranslation from "../helpers/useTranslation";
import { SectionHeroHomeI18n } from "./SectionHeroHome.i18n";

const SectionHeroHome = () => {
  const { t } = useTranslation(SectionHeroHomeI18n);

  return (
    <section className="section-hero-home">
      <div className="d-flex flex-column align-items-center gap-3">
        <img src={logo} alt="" width={50} height={50} />
        <TitlesHome text={t("title")} className={"mt-5 mb-2 text-center"} />
        <p
          className="size-paragrapy-landing-page text-center"
          style={{
            fontWeight: 500,
          }}
        >
          {t("paragraph1")}
          <br />
          {t("paragraph2")}
        </p>
        <ButtonsHome />
      </div>
    </section>
  );
};

export default SectionHeroHome;
