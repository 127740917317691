import React, { useState } from "react";
import { showErrorNotification } from "../helpers/notifications";
import { submitPhoneNumber } from "../api";

import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import "react-international-phone/style.css";

import { useUser } from "@supabase/auth-helpers-react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import qrcodeImage from "../assets/images/qrcode-wpp-superchat.jpeg";
import useTranslation from "../helpers/useTranslation";
import { ModalWhatsappNumberI18n } from "./ModalWhatsappNumber.i18n";
import { useNavigate } from "react-router-dom";
import {
  getAndUpdateUser,
  getCurrentUserLanguage,
  getCurrentUserLanguageWithCountry,
  getUser,
} from "../helpers/userHelper";
import { getHelpLink } from "../utils/help-links";
import clsx from "clsx";

const phoneUtil = PhoneNumberUtil.getInstance();

export function ModalWhatsappNumber() {
  const userData = getUser();

  const [phoneNumber, setPhoneNumber] = useState(userData?.phone ?? "");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [step, setStep] = useState(1);

  const navigate = useNavigate();
  const { t } = useTranslation(ModalWhatsappNumberI18n);
  const user = useUser();

  const steps = [1, 2, 3];

  const userLanguage = getCurrentUserLanguage();

  const isPhoneValid = validatePhone(phoneNumber!);

  function toggleModal() {
    if (!user?.id) {
      return navigate("/signin");
    }

    if (user?.phone) {
      setStep(2);
      return setOpen(!open);
    }

    setStep(1);
    setOpen(!open);
  }

  async function handleSubmitPhone() {
    if (!phoneNumber) {
      return;
    }

    try {
      setIsLoading(true);

      await submitPhoneNumber(phoneNumber);
      setStep(3);

      await getAndUpdateUser();
    } catch (error: any) {
      showErrorNotification(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  function validatePhone(phone: string) {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  }

  function selectCountryCodeByUser() {
    const countryIsoCode: string = user?.user_metadata?.ip_info?.country;

    if (!countryIsoCode) {
      const languageToCountryCode = {
        en: "us",
        pt: "br",
        es: "es",
      };

      return languageToCountryCode[userLanguage] || "br";
    }

    return countryIsoCode.toLowerCase();
  }

  return (
    <>
      <div className="d-flex align-content-center justify-content-center gap-2 w-100">
        {/* {userData?.phone && (
          <button
            onClick={toggleModalWithQRCode}
            className="btn btn-primary fw-semibold font-size-18 flex-grow-1"
          >
            {t("buttons.startConversation")}
          </button>
        )} */}
        <button
          className="btn p-0 d-flex align-items-center gap-3 border-0 align-self-start"
          onClick={toggleModal}
        >
          <div className="btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
            <i className="bx bxl-whatsapp" />
          </div>
          <h6 className="m-0">
            {t("buttons.startInteracting")}
          </h6>
        </button>
      </div>
      <Modal isOpen={open} backdrop centered toggle={toggleModal} size="md">
        <ModalHeader className="border-0 mb-0 pb-0" toggle={toggleModal} />

        <ModalBody className="px-4 mb-1">
          <div className="d-flex justify-content-center align-items-center mb-4">
            {steps.map((stepNumber, index) => (
              <React.Fragment key={index}>
                {index > 0 && (
                  <div
                    key={`divider-${index}`}
                    className={clsx("border-top border-2 mx-2", {
                      "border-primary": step >= stepNumber,
                    })}
                    style={{
                      width: 100,
                    }}
                  />
                )}
                <span
                  key={`step-${stepNumber}`}
                  className={clsx(
                    "px-3 py-2 font-size-18 fw-semibold rounded-circle border border-2 border-primary",
                    {
                      "bg-primary text-white": step >= stepNumber,
                    }
                  )}
                >
                  {stepNumber}
                </span>
              </React.Fragment>
            ))}
          </div>

          {step === 1 && (
            <div className="d-flex flex-column">
              <strong className="text-center font-size-20">
                {t("modal.stepOne.title")}
              </strong>

              <span className="text-dark text-opacity-75 mt-3 font-size-18">
                {t("modal.stepOne.crucialPoints")}
              </span>

              <ul className="d-flex flex-column gap-2 mt-3 font-size-16 list-unstyled">
                <li
                  style={{
                    lineHeight: "160%",
                  }}
                >
                  {t("modal.stepOne.pointTwo")}
                </li>
                <li
                  style={{
                    lineHeight: "160%",
                  }}
                >
                  {t("modal.stepOne.pointThree")}
                  <a
                    href={getHelpLink(
                      "whatsapp",
                      getCurrentUserLanguageWithCountry()
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    tutorial
                  </a>
                  .
                </li>
              </ul>

              <button
                className="mt-3 btn btn-primary"
                onClick={() => setStep(2)}
              >
                {t("modal.stepOne.next")}
              </button>
            </div>
          )}
          <div className="d-flex flex-column justify-content-center align-content-center">
            {step === 2 && (
              <div className="animate__animated animate__fadeInDown animate__faster">
                <label className="mb-1">{t("modal.stepTwo.input.label")}</label>
                <PhoneInput
                  defaultCountry={selectCountryCodeByUser()}
                  value={phoneNumber}
                  // @ts-ignore
                  onChange={setPhoneNumber}
                  inputClassName="w-100"
                />

                <div className="d-flex flex-column gap-2 mt-4">
                  <button
                    type="button"
                    onClick={handleSubmitPhone}
                    disabled={isLoading || !isPhoneValid}
                    className="btn btn-primary"
                  >
                    {isLoading ? (
                      <i className="bx bx-loader bx-spin" />
                    ) : (
                      t("modal.stepTwo.buttons.submitNumber")
                    )}
                  </button>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => setStep(1)}
                  >
                    {t("modal.stepTwo.buttons.goBack")}
                  </button>
                </div>
              </div>
            )}

            {step === 3 && (
              <>
                <div className="text-center font-size-18 d-flex flex-column justify-content-center align-content-center gap-2">
                  <span>{t("modal.stepThree.qrcodeLabel")}</span>
                  <img
                    src={qrcodeImage}
                    alt=""
                    className="mt-2 shadow animate__animated animate__fadeInDown animate__fast container-fluid"
                    style={{
                      maxWidth: 300,
                      maxHeight: 300,
                    }}
                  />
                  <div className="d-flex align-items-center justify-content-center my-2 mt-3 fs-4">
                    <hr style={{ flex: 1, marginRight: "16px" }} />
                    <span className="mb-1">{t("modal.stepThree.or")}</span>
                    <hr style={{ flex: 1, marginLeft: "16px" }} />
                  </div>

                  <a
                    href=" https://qrco.de/beRRLz"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover-primary link-primary font-size-20 fw-semibold"
                  >
                    {t("modal.stepThree.link.wppLink")}
                  </a>
                </div>

                <div className="d-flex mt-3">
                  <span
                    className="pb-2 mx-auto font-size-18 hover-primary"
                    onClick={() => setStep(2)}
                  >
                    {t("modal.stepThree.buttons.updateNumber")}{" "}
                    <i className="bx bx-edit-alt"></i>
                  </span>
                </div>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
