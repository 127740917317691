// constants
import { LAYOUT_MODES, TABS_VALUES } from "../../constants/index";

export enum LayoutActionTypes {
  CHANGE_TAB = "@@layout/CHANGE_TAB",
  CHANGE_LAYOUT_MODE = "@@layout/CHANGE_LAYOUT_MODE",
  TOGGLE_OPEN_LEFT_BAR = "@@layout/TOGGLE_OPEN_LEFT_BAR",
  TOGGLE_EXPAND_CHAT = "@@layout/TOGGLE_EXPAND_CHAT",
}
export interface LayoutState {
  isOpenLeftBar: boolean;
  isChatExpanded: boolean;
  layoutMode: LAYOUT_MODES;
  activeTab: TABS_VALUES | null | string;
}
