export function Card({ title, description, footer, children, className }) {
  return (
    <div className="">
      <div className={`d-flex flex-column p-3 h-100 ${className}`}>
        {title && (
          <h3 className="text-2xl mb-1 font-medium text-center">{title}</h3>
        )}
        {description && <p className="text-zinc-300">{description}</p>}
        {children}
      </div>
      {footer && (
        <div className="border-t border-zinc-700 bg-zinc-900 p-4 text-zinc-500">
          {footer}
        </div>
      )}
    </div>
  );
}
