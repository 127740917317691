// constants
import { TABS, LAYOUT_MODES } from "../../constants/index";

import { LayoutActionTypes, LayoutState } from "./types";

const isOnMainRoute = window.location.pathname === "/";


const lastTabSelected = localStorage.getItem("lastTabSelected");
const activeTab =
  (lastTabSelected && lastTabSelected === TABS.ASSISTANT_CHATS
    ? TABS.ASSISTANT
    : lastTabSelected) ?? TABS.ASSISTANT;


export const INIT_STATE: LayoutState = {
  activeTab: isOnMainRoute ? null : activeTab,
  isOpenLeftBar: true,
  isChatExpanded: false,
  layoutMode: LAYOUT_MODES.DARK,
};

const Layout = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case LayoutActionTypes.CHANGE_TAB:
      localStorage.setItem("lastTabSelected", action.payload);
      return {
        ...state,
        activeTab: action.payload,
        isOpenLeftBar: true,
      };

    case LayoutActionTypes.CHANGE_LAYOUT_MODE:
      return {
        ...state,
        layoutMode: action.payload.layoutMode,
      };

    case LayoutActionTypes.TOGGLE_OPEN_LEFT_BAR:
      return {
        ...state,
        isOpenLeftBar: action.payload.isOpenLeftBar,
      };

    case LayoutActionTypes.TOGGLE_EXPAND_CHAT:
      return {
        ...state,
        isChatExpanded: action.payload.isChatExpanded,
      };
    default:
      return { ...state };
  }
};

export default Layout;
