import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface AssistantCardSkeletonProps {
  quantity: number;
}

export function AssistantCardSkeleton({
  quantity = 10,
}: AssistantCardSkeletonProps) {

  return (
    <ul className="list-unstyled contact-list">
      {[...Array(quantity)].map((_, index) => (
        <li key={index}>
          <Skeleton
            height={100}
            baseColor={"#e4e4e7"}
            highlightColor={"#F1F1F1"}
            duration={1}
          />
        </li>
      ))}
    </ul>
  );
}
