import clsx from "clsx";
import { useEffect, useState } from "react";
import { checkFeedback, createFeedback } from "../../api/survey";
import { showSuccessNotification } from "../../helpers/notifications";
import useTranslation from "../../helpers/useTranslation";
import { SurveyNPSI18n } from "./SurveyNPS.i18n";
import styles from "./SurveyNPS.module.scss";
import { npsList } from "./npsList";
import { Modal, ModalBody } from "reactstrap";
import { getBearerToken } from "../../helpers/localStorageHelpers";

const SurveyNPS = () => {
  const { t } = useTranslation(SurveyNPSI18n);
  const [showCTA, setShowCTA] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [createFeedbackLoading, setCreateFeedbackLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const [interfaceRate, setInterfaceRate] = useState(false);

  const token = getBearerToken();

  useEffect(() => {
    if (!showModal) setData({});
  }, [showModal]);
  const onSubmit = () => {
    setCreateFeedbackLoading(true);
    createFeedback({
      ...data,
      navigator: {
        userAgent: navigator?.userAgent,
        platform: navigator?.platform,
        vendor: navigator?.vendor,
        language: navigator?.language,
        languages: navigator?.languages,
      },
    })
      .then(() => {
        setShowCTA(false);
        setShowModal(false);
        showSuccessNotification(t("thankyou"));
      })
      .finally(() => setCreateFeedbackLoading(false));
  };
  useEffect(() => {
    if (token) {
      checkFeedback().then((res: any) => {
        setShowCTA(!res.exists);
      });
    }
  }, [token]);
  return (
    <>
      {showCTA && (
        <div className="row" style={{ marginBottom: 64, marginTop: 94 }}>
          <div className="col-12">
            <div
              className="row d-flex justify-content-center mx-auto g-3"
              style={{ maxWidth: "100%" }}
            >
              <div className="col-12 text-center">
                <h5>{t("cta.title")}</h5>
                <p className="w-75 mx-auto">{t("cta.description")}</p>
                <button
                  className={clsx(styles.cta, "tab-links")}
                  onClick={() => {
                    setShowModal(!showModal);
                    setInterfaceRate(true);
                  }}
                >
                  <div className="btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
                    <i className="bx bxs-star" />
                  </div>
                  {t("cta.button")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal isOpen={showModal} centered>
        <ModalBody className={styles.modalbody_feedback}>
          {interfaceRate && (
            <div className="p-4 d-flex flex-column">
              <button
                className={styles.close}
                onClick={() => setShowModal(!showModal)}
              >
                <i className="bx bx-x" />
              </button>
              <h2 className={`"mt-4"${styles["h2"]}`}>{t("title")}</h2>
              <div className="mt-5 d-flex g-3 w-100 justify-content-between">
                {npsList.map(nps => (
                  <div
                    key={nps.id}
                    onClick={() => {
                      setData({ ...data, rate: nps.id });
                      setInterfaceRate(false);
                    }}
                  >
                    <nps.icon
                      className={clsx(styles.iconSVG, {
                        [styles.active]: data?.rate === nps.id,
                        [styles.inactive]: data?.rate && data?.rate !== nps.id,
                      })}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {data?.rate && (
            <>
              <textarea
                className={styles.textarea}
                placeholder={t("textareaPlaceholder")}
                value={data?.feedback}
                onChange={e => setData({ ...data, feedback: e.target.value })}
                disabled={createFeedbackLoading}
              />
              <div className="d-flex gap-3 w-100 align-items-center justify-content-between p-4">
                <p className={clsx(styles.disclaimer, "m-0")}>
                  {t("disclaimer")}
                </p>
                <button
                  className={clsx(styles.submit, "tab-links")}
                  disabled={!data?.feedback || createFeedbackLoading}
                  onClick={onSubmit}
                >
                  {createFeedbackLoading ? (
                    <i className="bx bx-loader bx-spin" />
                  ) : (
                    t("send")
                  )}
                </button>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default SurveyNPS;
