import { useEffect } from "react";
import { PaginationItem } from "./PaginationItem";

interface PaginationAssistantsProps {
  totalCountOfRegisters: number;
  registerPerPage?: number;
  currentPage?: number;
  onPageChange: (page: number) => void;
}

const siblingsCount = 1;

function generatePagesArray(from: number, to: number) {
  return [...new Array(to - from)]
    .map((_, index) => {
      return from + index + 1;
    })
    .filter(page => page > 0);
}

export function PaginationAssistants({
  totalCountOfRegisters,
  registerPerPage = 20,
  currentPage = 1,
  onPageChange,
}: PaginationAssistantsProps) {
  const lastPage = Math.ceil(totalCountOfRegisters / registerPerPage);

  const previousPages =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : [];

  const nextPages =
    currentPage < lastPage
      ? generatePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage)
        )
      : [];

  useEffect(() => {
    const handlePageChangeWithKeyboard = e => {
      if (e.key === "ArrowRight" && currentPage < lastPage) {
        onPageChange(currentPage + 1);
      } else if (e.key === "ArrowLeft" && currentPage > 1) {
        onPageChange(currentPage - 1);
      }
    };

    window.addEventListener("keydown", handlePageChangeWithKeyboard);

    return () => {
      window.removeEventListener("keydown", handlePageChangeWithKeyboard);
    };
  }, [currentPage, onPageChange]);

  return (
    <div className="d-flex align-items-center gap-2 mb-5 p-2 rounded-1">
      {currentPage > 1 + siblingsCount && (
        <>
          <PaginationItem onSelectItem={onPageChange} number={1} />
          {currentPage > 2 + siblingsCount && (
            <span
              style={{
                color: "#797c8c",
              }}
            >
              ...
            </span>
          )}
        </>
      )}

      {previousPages.length > 0 &&
        previousPages.map(page => (
          <PaginationItem
            onSelectItem={onPageChange}
            key={page}
            number={page}
          />
        ))}

      <PaginationItem
        onSelectItem={onPageChange}
        number={currentPage}
        isCurrent
      />

      {nextPages.length > 0 &&
        nextPages.map(page => (
          <PaginationItem
            onSelectItem={onPageChange}
            key={page}
            number={page}
          />
        ))}

      {lastPage > 1 + siblingsCount + currentPage && (
        <>
          {lastPage > currentPage + 1 + siblingsCount && (
            <span
              style={{
                color: "#797c8c",
              }}
            >
              ...
            </span>
          )}
          <PaginationItem onSelectItem={onPageChange} number={lastPage} />
        </>
      )}
    </div>

    // <Pagination className="me-4">
    //   <PaginationItem disabled={currentPage === 1}>
    //     <PaginationLink first onClick={() => onPageChange(1)} />
    //   </PaginationItem>
    //   <PaginationItem disabled={currentPage === 1}>
    //     <PaginationLink
    //       previous
    //       onClick={() => onPageChange(currentPage - 1)}
    //     />
    //   </PaginationItem>
    //   {Array.from({ length: totalCountOfRegisters }).map((_, idx) => {
    //     const page = idx + 1;
    //     return (
    //       <PaginationItem key={idx} active={currentPage === page}>
    //         <PaginationLink onClick={() => onPageChange(page)}>
    //           {page}
    //         </PaginationLink>
    //       </PaginationItem>
    //     );
    //   })}
    //   <PaginationItem>
    //     <PaginationLink next onClick={() => onPageChange(currentPage + 1)} />
    //   </PaginationItem>
    //   <PaginationItem>
    //     <PaginationLink href="#" last />
    //   </PaginationItem>
    // </Pagination>
  );
}
