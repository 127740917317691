import clsx from "clsx";
import {
  checkIfWeb3User,
  getCurrentUserPlan,
  getUser,
} from "../../../../helpers/userHelper";
import { PLANS_AIZZY } from "../../../../helpers/constants/Plans";
import RemainingTable from "../RemainingTable";
import { useContext, useEffect, useState } from "react";
import RemainingCallsContext from "../../../../freemium/RemainingCallsContext";
import {
  getUserSubscription,
  manageUserSubscription,
} from "../../../../api/subscription";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../../../helpers/useTranslation";
import { PlanI18n } from "./Plan.i18n";
import { formateDate } from "../../../../utils";

export function Plan() {
  const [isLoading, setIsLoading] = useState(false);

  const [currentPeriodEnd, setCurrentPeriodEnd] = useState(null);
  const [currentPeriodStart, setCurrentPeriodStart] = useState(null);

  const { remainingCalls } = useContext(RemainingCallsContext);

  const { t } = useTranslation(PlanI18n);

  const navigate = useNavigate();

  const userPlan = getCurrentUserPlan();
  const isPremiumUser = userPlan === PLANS_AIZZY.PREMIUM;

  async function handleManageSubscription() {
    setIsLoading(true);
    try {
      const res: any = await manageUserSubscription();

      window.location.assign(res.url);
    } catch (error) {
      if (error) return alert((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }

  function handleGoPremium() {
    navigate("/plans");
  }

  useEffect(() => {
    getUserSubscription()
      .then(res => {
        setCurrentPeriodEnd(res.current_period_end);
        setCurrentPeriodStart(res.current_period_start);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  return (
    <div>
      <div>
        <h4>
          {t("titles.currentPlan")}
          <strong
            className={clsx("", {
              "text-primary": !isPremiumUser,
              "active-premium": isPremiumUser,
            })}
          >
            {userPlan}
          </strong>
        </h4>
      </div>
      <div className="my-4">
        {remainingCalls && <RemainingTable plan={remainingCalls} />}
      </div>

      {isPremiumUser ? (
        <div>
          <>
            <h4 className="premium-message border-bottom pb-3">
              {t("titles.congratsTitle")}
            </h4>
            <p className=" border-bottom pb-3 pt-1">
              {t("timePlanStart")}{" "}
              <span className="text-muted">
                {formateDate(currentPeriodStart)}
              </span>
              <br />
              {t("timePlanEnd")}{" "}
              <span className="text-muted">
                {formateDate(currentPeriodEnd)}
              </span>
            </p>
          </>

          {!checkIfWeb3User() && (
            <button
              className="btn btn-primary fs-5 fw-semibold mt-2"
              onClick={handleManageSubscription}
            >
              {isLoading ? (
                <i
                  className="bx bx-loader bx-spin align-middle"
                  style={{
                    fontSize: 19,
                  }}
                />
              ) : (
                t("buttons.manageSubscription")
              )}
            </button>
          )}
        </div>
      ) : (
        <div>
          <button
            className="d-flex btn align-items-center gap-3 px-0"
            onClick={handleGoPremium}
          >
            <div className="button-go-premium button-border-sm button-small d-flex align-items-center justify-content-center">
              <i
                className="bx bxs-crown"
                style={{
                  color: "#fff",
                }}
              />
            </div>
            <h6
              style={{
                color: "#000",
                marginBottom: 0,
              }}
            >
              {t("buttons.goPremium")}
            </h6>
          </button>
        </div>
      )}
    </div>
  );
}
