import { Card, CardBody, CardTitle, CardText, CardFooter } from "reactstrap";
import { AssistantTranslationFormData } from "./ModalLanguage";
import useTranslation from "../../../helpers/useTranslation";
import { TranslationCardI18n } from "./CreateAssistant.i18n";

interface TranslationCardProps {
  translation: AssistantTranslationFormData;
  onDeleteTranslation: (language: string) => void;
  onEditTranslation: (language: string) => void;
}

export function TranslationCard({
  translation,
  onDeleteTranslation,
  onEditTranslation,
}: TranslationCardProps) {
  const { t } = useTranslation(TranslationCardI18n);

  const languageText = (() => {
    const languageTranslations = {
      pt: "portuguese",
      en: "english",
      es: "spanish",
    };

    return languageTranslations[translation.language];
  })();

  function limitText(text) {
    return text.length > 76 ? `${text.substring(0, 46)}...` : text;
  }

  function handleDeleteTranslation() {
    const confirm = window.confirm(t("alert"));
    if (confirm) {
      onDeleteTranslation(translation.language);
    }
  }

  function handleEditTranslation() {
    onEditTranslation(translation.language);
  }

  return (
    <Card
      style={{
        width: "18rem",
      }}
    >
      <CardBody>
        <CardTitle tag="h5">
          {t("card.title")}: {languageText}
        </CardTitle>
        <CardText>
          {t("form.role")}: {limitText(translation.role)}
        </CardText>
        <CardText>
          {t("form.prompt")}: {limitText(translation.prompt)}
        </CardText>
        <CardText className="card-text">
          {t("form.about")}: {limitText(translation.about)}
        </CardText>
        <CardText className="card-text">
          {t("form.greetings")}: {limitText(translation.defaultGreetings)}
        </CardText>
        <CardFooter className="d-flex align-items-center justify-content-center gap-3 bg-transparent">
          <button
            type="button"
            onClick={handleEditTranslation}
            className="d-flex align-items-center gap-1 btn btn-outline-secondary"
          >
            Edit
            <i className="bx bx-edit ms-1" />
          </button>
          <button
            onClick={handleDeleteTranslation}
            type="button"
            className="d-flex align-items-center gap-1 btn btn-outline-danger"
          >
            {t("button.delete")}
            <i className="bx bx-trash" />
          </button>
        </CardFooter>
      </CardBody>
    </Card>
  );
}
