import React, { useState } from "react";
import HeaderHome from "../../components/HeaderHome";
import SectionHeroHome from "../../components/SectionHeroHome";
import SectionFeatures from "../../components/SectionFeatures";
import SectionFaq from "../../components/SectionFaq";
import FooterHome from "../../components/FooterHome";
import SectionBuild from "../../components/SectionBuild";
import { DropdownLanguageMenu } from "../../components/DropdownLanguageMenu";

const Home: React.FC = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const handleLanguageChange = languageCode => {
    setSelectedLanguage(languageCode);
  };

  return (
    <section
      style={{
        background: "#ffffff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "75%",
            display: "flex",
            flexDirection: "column",
            gap: "6rem",
          }}
        >
          <HeaderHome
            dropdownLanguage={<DropdownLanguageMenu state={handleLanguageChange} />}
            dropdownLanguageMobile={<DropdownLanguageMenu state={handleLanguageChange} />}
          />
          <SectionHeroHome />
          <div
            className="d-flex flex-column"
            style={{
              gap: "8.5rem",
            }}
          >
            <SectionFeatures />
            <SectionBuild />
            <SectionFaq />
            <FooterHome />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
