import { useSupabaseClient } from "@supabase/auth-helpers-react";
import i18next from "i18next";
import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import FlagBrSvg from "../../../assets/images/icons/survey/flag-br.svg";
import FlagEsSvg from "../../../assets/images/icons/survey/flag-es.svg";
import FlagUsSvg from "../../../assets/images/icons/survey/flag-eus.svg";
import FlagFilSvg from "../../../assets/images/icons/survey/flag-fil.svg";
import { getBearerToken } from "../../../helpers/localStorageHelpers";
import { showErrorNotification } from "../../../helpers/notifications";

export function UpdateLanguageButton({ title }: { title: string }) {
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const supabaseClient = useSupabaseClient();

  const token = getBearerToken();

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const flagImages = {
    "en-US": FlagUsSvg,
    "pt-BR": FlagBrSvg,
    "es-ES": FlagEsSvg,
    // "fil-PH": FlagFilSvg,
  };
  const languages = [
    {
      name: "English",
      code: "en-US",
      image: flagImages["en-US"],
    },
    {
      name: "Português",
      code: "pt-BR",
      image: flagImages["pt-BR"],
    },
    {
      name: "Español",
      code: "es-ES",
      image: flagImages["es-ES"],
    },
    // {
    //   name: "Filipino",
    //   code: "fil-PH",
    //   image: flagImages["fil-PH"],
    // },
  ];

  const errorMessage = {
    "pt-BR":
      "Erro ao atualizar idioma, por favor tente novamente ou contate o suporte",
    "en-US": "Error updating language, please try again or contact support.",
    "es-ES":
      "Error al actualizar el idioma, por favor inténtelo de nuevo o póngase en contacto con el soporte",
  };

  async function handleUpdateLanguage(language) {
    try {
      setIsLoading(true);
      if (token) {
        await supabaseClient.auth.updateUser({
          data: {
            language: language.code,
          },
        });

        await supabaseClient.auth.refreshSession();
      }

      i18next.changeLanguage(language.code);
      if (token) {
        window.location.reload();
      }
    } catch (error: any) {
      console.error(error);
      showErrorNotification(error?.message ?? errorMessage[language.code]);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dropdown direction="end" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className="btn d-flex align-items-center gap-3 bg-body border-0 p-0">
        <div
          className="btn-primary button-border-sm d-flex align-items-center justify-content-center"
          style={{ width: 36, height: 36 }}
        >
          {isLoading ? (
            <i
              className="bx bx-loader bx-spin d-block"
              style={{
                width: 18,
                height: 18,
              }}
            />
          ) : (
            <img
              src={flagImages[i18next.language]}
              width={18}
              height={18}
              alt=""
            />
          )}
        </div>
        <h6 className="m-0" style={{ color: "#000" }}>
          {title}
        </h6>
      </DropdownToggle>
      <DropdownMenu>
        {languages.map((language, index) => (
          <DropdownItem
            key={index}
            className="d-flex align-items-center justify-content-between"
            onClick={() => handleUpdateLanguage(language)}
          >
            {language.name}
            <img src={flagImages[language.code]} alt="" />
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
