import React from "react";
import CardFeatureItem from "./CardFeatureItem";
import { SectionFeaturesI18n } from "./SectionFeatures.i18n";
import useTranslation from "../helpers/useTranslation";

const SectionFeatures = () => {
  const { t } = useTranslation(SectionFeaturesI18n);

  return (
    <section className="section-features">
      <div className="texts-features mb-5">
        <h1 className="font-home size-title-landing-page">{t("title")}</h1>
      </div>
      <div className="feature-list-itens mb-3">
        <CardFeatureItem
          img={true}
          h3={t("subTitleCard1")}
          paragraph={t("paragraphCard1")}
        />
        <CardFeatureItem
          img={true}
          h3={t("subTitleCard2")}
          paragraph={t("paragraphCard2")}
        />
        <CardFeatureItem
          img={true}
          h3={t("subTitleCard3")}
          paragraph={t("paragraphCard3")}
        />
        <CardFeatureItem
          img={true}
          h3={t("subTitleCard4")}
          paragraph={t("paragraphCard4")}
        />
      </div>
      {/* <Link className="mt-5 tab-links text-start" to={""}>
        Doc <img src={arrow} alt="" />
      </Link> */}
    </section>
  );
};

export default SectionFeatures;
