import { useState } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { showErrorNotification } from "../helpers/notifications";
import { renameChannel } from "../api";
import { useRedux } from "../hooks";
import useTranslation from "../helpers/useTranslation";
import { ModalChannelRenameI18n } from "./ModalChannelRename.i18n";
import { QUERY_KEYS, queryClient } from "../libs/react-query";
import { ChannelDTO } from "../@DTO/channel";
import { changeSelectedChat, getChannelJobStatus } from "../redux/actions";

interface ModalChannelRenameProps {
  isOpen: boolean;
  channel: ChannelDTO;
  onClose: () => void;
}

export function ModalChannelRename({
  isOpen,
  onClose,
  channel,
}: ModalChannelRenameProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [channelName, setChannelName] = useState("");

  const { t } = useTranslation(ModalChannelRenameI18n);

  const { useAppSelector, dispatch } = useRedux();

  const { assistant } = useAppSelector(state => ({
    assistant: state.Chats.assistant,
  }));

  const onSubmit = async () => {
    if (!channelName) {
      return;
    }

    try {
      setIsLoading(true);
      const params = {
        channelId: channel._id,
        assistantId: assistant?._id,
        channelName: channelName,
      };

      await renameChannel(params);

      const channelWithNewTitle: ChannelDTO = {
        ...channel,
        channelName,
      };

      dispatch(changeSelectedChat(channelWithNewTitle));
      dispatch(getChannelJobStatus(channelWithNewTitle._id));

      updateCachedChannels(channelWithNewTitle);

      onClose();
      setChannelName("");
    } catch (err) {
      // @ts-ignore
      showErrorNotification(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  function updateCachedChannels(channelWithNewTitle: ChannelDTO) {
    if (channel.isDocument || channel.isYoutube) {
      const cachedDocuments = queryClient.getQueryData<ChannelDTO[]>(
        QUERY_KEYS.DOCUMENTS
      );

      if (cachedDocuments) {
        const updatedDocuments = cachedDocuments.map((c: ChannelDTO) => {
          if (c._id === channel._id) {
            return channelWithNewTitle;
          }

          return c;
        });

        queryClient.setQueryData(QUERY_KEYS.DOCUMENTS, updatedDocuments);
      }
    } else {
      const cachedChannels = queryClient.getQueryData<ChannelDTO[]>(
        QUERY_KEYS.CHANNELS
      );

      if (cachedChannels) {
        const updatedChannels = cachedChannels.map((c: ChannelDTO) => {
          if (c._id === channel._id) {
            return channelWithNewTitle;
          }

          return c;
        });

        queryClient.setQueryData(QUERY_KEYS.CHANNELS, updatedChannels);
      }
    }
  }

  // function invalidateQueriesByChannelType() {
  //   if (channel.isDocument || channel.isYoutube) {
  //     return queryClient.invalidateQueries(QUERY_KEYS.DOCUMENTS);
  //   }

  //   queryClient.invalidateQueries(QUERY_KEYS.CHANNELS);
  // }

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit();
    }
  };

  const handleToggle = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleToggle}
      tabIndex={-1}
      centered
      scrollable
      id="addgroup-exampleModal"
      role="dialog"
      backdrop="static"
    >
      <ModalHeader className="modal-title-custom" toggle={handleToggle}>
        {t("page.label")}
      </ModalHeader>

      <ModalBody className="p-4">
        <div className="mb-4">
          <Label htmlFor="addgroupname-input" className="form-label">
            {t("input.title")} <span className="text-danger">*</span>
          </Label>
          <Input
            type="text"
            className="form-control"
            id="addgroupname-input"
            placeholder={t("input.placeholder")}
            value={channelName}
            onChange={e => setChannelName(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <Button color="link" type="button" onClick={onClose}>
          {t("button.close")}
        </Button>
        <button
          type="button"
          className="btn d-flex align-items-center gap-3"
          onClick={onSubmit}
          disabled={isLoading}
        >
          {isLoading ? (
            <i className="bx bx-loader bx-spin align-middle"></i>
          ) : (
            <>
              <div className="btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
                <i className="bx bx-edit" />
              </div>
              {t("button.rename")}
            </>
          )}
        </button>
      </ModalFooter>
    </Modal>
  );
}
