import { TABS } from "../../constants";

export interface ButtonLink {
  id: string;
  navigateUrl: string;
  iconClass?: string;
  label: string;
  title: string;
  tooltip: string;
  highlighted?: boolean;
  beta?: boolean;
  tabAssistant?: string;
  icon?: boolean;
}

export const buttonLinks: ButtonLink[] = [
  // {
  //   id: "plugin",
  //   navigateUrl: "/wordpress",
  //   iconClass: "bx bxl-wordpress p-2",
  //   label: "buttons.plugin.label",
  //   tooltip: "buttons.plugin.tooltip",
  //   highlighted: true,
  //   beta: true,
  // },
  {
    id: "assistant",
    navigateUrl: "/dashboard",
    tabAssistant: TABS.ASSISTANT,
    iconClass: "bx bx bxs-group p-2",
    title: "buttons.assistant.title",
    label: "buttons.assistant.label",
    tooltip: "buttons.assistant.tooltip",
  },
  {
    id: "create_assistant",
    navigateUrl: "/assistant/create",
    tabAssistant: TABS.ASSISTANT,
    icon: true,
    title: "buttons.create_assistant.title",
    label: "buttons.create_assistant.label",
    tooltip: "buttons.create_assistant.tooltip",
  },
  {
    id: "youtube",
    navigateUrl: "/dashboard",
    tabAssistant: TABS.YOUTUBE,
    iconClass: "bx bxl-youtube p-2",
    title: "buttons.youtube.title",
    label: "buttons.youtube.label",
    tooltip: "buttons.youtube.tooltip",
  },
  {
    id: "pdf",
    navigateUrl: "/dashboard",
    tabAssistant: TABS.DOCUMENTS,
    iconClass: "bx bxs-file-doc p-2",
    title: "buttons.pdf.title",
    label: "buttons.pdf.label",
    tooltip: "buttons.pdf.tooltip",
  },
  {
    id: "website",
    navigateUrl: "/dashboard",
    tabAssistant: TABS.DOCUMENTS,
    iconClass: "bx bx-globe p-2",
    title: "buttons.website.title",
    label: "buttons.website.label",
    tooltip: "buttons.website.tooltip",
  },
  {
    id: "image",
    navigateUrl: "/images",
    tabAssistant: TABS.IMAGES,
    iconClass: "bx bx-images p-2",
    title: "buttons.image.title",
    label: "buttons.image.label",
    tooltip: "buttons.image.tooltip",
  },
  {
    id: "affiliate",
    navigateUrl: "/profile",
    iconClass: "bx bxs-rocket p-2",
    title: "buttons.affiliate.title",
    label: "buttons.affiliate.label",
    tooltip: "buttons.affiliate.tooltip",
  },
];
