const TitlesHome = ({ text, className }) => {
  return (
    <div className={className}>
      <h1
        className={'size-title-landing-page' }
      >
        {text}
      </h1>
    </div>
  );
};

export default TitlesHome;
