import { useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { User } from "./TabContent/User";
import { Plan } from "./TabContent/Plan";
import { Security } from "./TabContent/Security";
import { Affiliate } from "./TabContent/Affiliate";
import useTranslation from "../../../helpers/useTranslation";
import { MenuI18n } from "./Menu.i18n";
import { Support } from "./TabContent/Support";
import { useLocation } from "react-router-dom";
import { ButtonLink } from "../../StarterPage/buttonLinks";
import { checkIfWeb3User } from "../../../helpers/userHelper";
import ButtonDefault from "../../../components/ButtonDefault";
import { buttonsMenuProfile } from "../../../components/ArrayMenuProfile";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

export function Menu() {
  const [activeTab, setActiveTab] = useState("1");

  const { state } = useLocation();
  const target = state?.target as ButtonLink;

  const { t } = useTranslation(MenuI18n);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (target && target.id === "affiliate") {
      setActiveTab("4");
    }
  }, []);

  return (
    <div
      className="d-lg-flex mt-4"
      style={{
        minHeight: "100vh",
      }}
    >
      <Nav
        pills
        vertical
        className="gap-2 fs-5 d-lg-flex d-none border-end border-bottom-0"
        style={{ paddingRight: "4rem", paddingLeft: "1rem" }}
      >
        {buttonsMenuProfile.map((item, index) => (
          <NavItem key={index}>
            <NavLink
              className={classnames(
                "d-flex align-items-center gap-3 px-0 cursor-pointer",
                {
                  "border-active": activeTab === item.id,
                }
              )}
              onClick={() => toggleTab(item.id)}
            >
              <div className=" btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
                <i className={item.iconClass} />
              </div>
              <h6 className="m-0">{t(item.title as any)}</h6>
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <div className="d-flex d-lg-none vw-100 my-4">
        <Swiper
          className="w-100 overflow-visible px-3 "
          slidesPerView={"auto"}
          onSlideChange={() => console.log("slide change")}
        >
          {buttonsMenuProfile.map((item, index) => (
            <SwiperSlide
              key={item.id}
              className="overflow-visible cursor-pointer w-auto"
              style={{ paddingRight: 45 }}
            >
              <div
                className={`gap-3 d-flex align-items-center justify-content-start  ${
                  activeTab === item.id ? "border-active" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => toggleTab(item.id)}
              >
                <div className="btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
                  <i className={item.iconClass} />
                </div>
                <h6 className="m-0">{t(item.title as any)}</h6>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* <Nav pills vertical className="gap-1 fs-5 border-end border-bottom-0 py-3" style={{ paddingRight: "2rem", paddingLeft: "1rem" }}>
        <NavItem
          style={{
            cursor: "pointer",
          }}
        >
          <NavLink
            className={classnames("d-flex align-items-center gap-3 px-0", {
              "border-active": activeTab === "1",
            })}
            onClick={() => toggleTab("1")}
          >
            <div className=" btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
              <i className="bx bxs-user font-size-16" />
            </div>
            <h6 className="m-0">{t("tabs.user")}</h6>
          </NavLink>
        </NavItem>
        <NavItem
          style={{
            cursor: "pointer",
          }}
        >
          <NavLink
            className={classnames("d-flex align-items-center gap-3 px-0", {
              "border-active": activeTab === "2",
            })}
            onClick={() => toggleTab("2")}
          >
            <div className=" btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
              <i className="bx bxs-crown font-size-16" />
            </div>
            <h6 className="m-0">{t("tabs.plan")}</h6>
          </NavLink>
        </NavItem>
        {!checkIfWeb3User() && (
          <NavItem
            style={{
              cursor: "pointer",
            }}
          >
            <NavLink
              className={classnames("d-flex align-items-center gap-3 px-0", {
                "border-active": activeTab === "3",
              })}
              onClick={() => toggleTab("3")}
            >
              <div className=" btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
                <i className="bx bxs-lock-alt font-size-16" />
              </div>
              <h6 className="m-0">{t("tabs.security")}</h6>
            </NavLink>
          </NavItem>
        )}

        <NavItem
          style={{
            cursor: "pointer",
          }}
        >
          <NavLink
            className={classnames("d-flex align-items-center gap-3 px-0", {
              "border-active": activeTab === "4",
            })}
            onClick={() => toggleTab("4")}
          >
            <div className=" btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
              <i className="bx bxs-donate-heart font-size-16" />
            </div>
            <h6 className="m-0">{t("tabs.affiliation")}</h6>
          </NavLink>
        </NavItem>
        <NavItem
          style={{
            cursor: "pointer",
          }}
        >
          <NavLink
            className={classnames("d-flex align-items-center gap-3 px-0", {
              "border-active": activeTab === "5",
            })}
            onClick={() => toggleTab("5")}
          >
            <div className=" btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
              <i className="bx bx-support font-size-16" />
            </div>
            <h6 className="m-0">{t("tabs.support")}</h6>
          </NavLink>
        </NavItem>
      </Nav> */}

      <TabContent activeTab={activeTab} className="px-3 px-lg-4 w-100">
        <TabPane tabId="1">
          <User />
        </TabPane>
        <TabPane tabId="2">
          <Plan />
        </TabPane>
        {!checkIfWeb3User() && (
          <TabPane tabId="3">
            <Security />
          </TabPane>
        )}
        <TabPane tabId="4">
          <Affiliate />
        </TabPane>
        <TabPane tabId="5">
          <Support />
        </TabPane>
      </TabContent>
    </div>
  );
}
