import { useEffect, useState } from "react";
import { showErrorNotification } from "../../../helpers/notifications";
import { WebsiteJobDTO } from "../../../@DTO/embeddings";
import { StatusBadge } from "./StatusBadge";
import { createWebsiteEmbeddings } from "../../../api/embeddings";
import { getChannelJobStatus } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { getCurrentUserLanguage } from "../../../helpers/userHelper";
import { JobMetadata } from "./JobMetadata";
import InfoModal from "../../../components/modal/ModalFiles";

const saveModalState = (state: {
  isOpen: boolean;
  message: string;
  title: string;
  closeButtonText: string;
}) => {
  localStorage.setItem("modalStateWebsite", JSON.stringify(state));
};

const getSavedModalState = () => {
  const savedState = localStorage.getItem("modalStateWebsite");
  return savedState ? JSON.parse(savedState) : null;
};

const WebsiteCard = ({
  website,
  onDeleteJob,
  getDocumentContent,
}: {
  website: WebsiteJobDTO;
  onDeleteJob: (id: string) => Promise<void>;
  getDocumentContent: (id: string) => Promise<any>;
}) => {
  const [deleteJobLoading, setDeleteJobLoading] = useState(false);

  const [modalState, setModalState] = useState(
    getSavedModalState() || {
      isOpen: false,
      message: "",
      title: "",
      closeButtonText: "",
    }
  );

  const userLanguage = getCurrentUserLanguage();
  const dispatch = useDispatch();

  async function handleDeleteJob(id: string) {
    try {
      setDeleteJobLoading(true);
      await onDeleteJob(id);
    } catch (error: any) {
      showErrorNotification(error.message);
    } finally {
      setDeleteJobLoading(false);
    }
  }

  async function resendJob() {
    try {
      const content = await getDocumentContent(website._id);

      if (
        (!content?.document || content?.document?.length === 0) &&
        website.processed
      ) {
        const messages = {
          pt: {
            message: `O conteúdo do site <strong>${website.url}</strong> não foi encontrado nesse dispositivo ou browser. Por favor, envie o site novamente para perguntar sobre ele.`,
            title: "Aviso",
            closeButton: "Fechar",
          },
          en: {
            message: `The website ${website.url} content was not found on this device or browser. Please upload the website again to ask questions about it.`,
            title: "Warning",
            closeButton: "Close",
          },
          es: {
            message: `El contenido del sitio web <strong>${website.url}</strong> no se encontró en este dispositivo o navegador. Por favor, suba el sitio web nuevamente para hacer preguntas sobre él.`,
            title: "Aviso",
            closeButton: "Cerrar",
          },
        };

        const newModalState = {
          isOpen: true,
          message: messages[userLanguage].message,
          title: messages[userLanguage].title,
          closeButtonText: messages[userLanguage].closeButton,
        };

        await handleDeleteJob(website._id);

        setModalState(newModalState);
        saveModalState(newModalState);

        await createWebsiteEmbeddings({
          url: website.url,
          channelId: website.channel_id,
        });

        dispatch(getChannelJobStatus(website.channel_id));
      }
    } catch (error: any) {
      showErrorNotification(error.message);
    }
  }

  useEffect(() => {
    resendJob();
  }, []);

  useEffect(() => {
    if (website.processed) {
      if (modalState.isOpen) {
        const closedModalState = {
          ...modalState,
          isOpen: false,
        };
        setModalState(closedModalState);
        saveModalState(closedModalState);
      }
    }
  }, [modalState, website.processed]);

  const toggleModal = () => {
    const newState = { ...modalState, isOpen: !modalState.isOpen };
    setModalState(newState);
    saveModalState(newState);
  };

  return (
    <div
      className="my-4 flex-grow-1 position-relative px-0 pe-3"
      key={website.url}
    >
      <a
        href={website.url}
        target="_blank"
        rel="noreferrer"
        className="d-block mt-3"
        style={{ wordBreak: "break-all", fontSize: 12, fontWeight: 500 }}
      >
        {website.url}
      </a>
      {website.jobId && (
        <button
          className="btn p-0 position-absolute"
          style={{ top: -20, right: 4, zIndex: 99 }}
          disabled={deleteJobLoading}
          onClick={() => handleDeleteJob(website._id)}
        >
          {deleteJobLoading ? (
            <i className="bx bx-loader bx-spin align-middle"></i>
          ) : (
            <i className="bx bx-x fs-4"></i>
          )}
        </button>
      )}

      <div className="d-flex align-items-center justify-content-between">
        <StatusBadge item={website} />

        {website.processed && <JobMetadata job={website} />}
      </div>

      <InfoModal
        isOpen={modalState.isOpen}
        toggle={toggleModal}
        message={modalState.message}
        title={modalState.title}
        closeButtonText={modalState.closeButtonText}
      />
    </div>
  );
};

export default WebsiteCard;
