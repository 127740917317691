export const ModalInviteMemberI18n = {
  "pt-BR": {
    page: {
      label: "Convidar membro para o seu chat",
    },

    input: {
      title: "Digite o email da pessoa que deseja convidar",
      placeholder: "email@example.com",
    },

    button: {
      close: "Fechar",
      rename: "Convidar",
    },
    notifications: {
      success: "Membro convidado com sucesso!",
      "valid-email": "Digite um email válido, por favor",
      "duplicate-member": "Usuário já adicionado como membro",
    },
    confirm: "Deseja convidar mais alguém ao chat?",
  },
  "en-US": {
    page: {
      label: "Invite member to your chat",
    },

    input: {
      title: "Enter the email of the person you want to invite",
      placeholder: "email@example.com",
    },

    button: {
      close: "Close",
      rename: "Invite",
    },
    notifications: {
      success: "Invited members successfully!",
      "valid-email": "Please, enter a valid email.",
      "duplicate-member": "User already added as a member",
    },
    confirm: "Want to invite someone else to the chat?",
  },
  "es-ES": {
    page: {
      label: "Invitar miembro a tu chat",
    },

    input: {
      title: "Ingresa el correo electrónico de la persona que deseas invitar",
      placeholder: "email@example.com",
    },

    button: {
      close: "Cerrar",
      rename: "Invitar",
    },
    notifications: {
      success: "Miembros invitados con éxito!",
      "valid-email": "Por favor, ingresa un correo electrónico válido.",
      "duplicate-member": "Usuario ya agregado como miembro",
    },
    confirm: "¿Quieres invitar a alguien más a chatear?",
  },
  "fil-PH": {
    page: {
      label: "Mag-imbita ng miyembro sa iyong chat",
    },

    input: {
      title: "Ilagay ang email ng taong nais mong imbitahin",
      placeholder: "email@example.com",
    },

    button: {
      close: "Isara",
      rename: "Imbitahin",
    },
    notifications: {
      success: "Tagumpay na na-imbitahan ang miyembro!",
      "valid-email": "Mangyaring ilagay ang isang wastong email.",
      "duplicate-member": "Ang user ay naka-angkin na bilang isang miyembro",
    },
    confirm: "Gusto mo bang imbitahan ang iba pang tao sa chat?",
  },
};
