import { TABS } from "../constants/layout";

export interface ButtonMenuProfile {
  id: string;
  iconClass?: string;
  title: string;
}

export const buttonsMenuProfile: ButtonMenuProfile[] = [
  // {
  //   id: "plugin",
  //   navigateUrl: "/wordpress",
  //   iconClass: "bx bxl-wordpress",
  //   label: "buttons.plugin.label",
  //   tooltip: "buttons.plugin.tooltip",
  //   highlighted: true,
  //   beta: true,
  // },
  {
    id: "1",
    iconClass: "bx bxs-user",
    title: "tabs.user",
  },
  {
    id: "2",
    iconClass: "bx bxs-crown",
    title: "tabs.plan",
  },
  {
    id: "3",
    iconClass: "bx bxs-lock-alt",
    title: "tabs.security",
  },
  {
    id: "4",
    iconClass: "bx bxs-donate-heart",
    title: "tabs.affiliation",
  },
  {
    id: "5",
    iconClass: "bx bx-support",
    title: "tabs.support",
  },
];
