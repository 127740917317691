import { Navigate, Route, Routes } from "react-router-dom";
import DefaultLayout from "../layouts/Default/index";
import NonAuthLayout from "../layouts/NonAuth/index";
import { AuthProtected } from "./AuthProtected";
import { privateRoutes, publicRoutes } from "./allRoutes";
import { useUserStore } from "../state/user";

import StarterPage from "../pages/StarterPage/index";
import Home from "../pages/Home";

const Index = () => {
  const { isLogged } = useUserStore();

  return (
    <Routes>
      {publicRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={
            route.path === "/" ? (
              <DefaultLayout>
                <StarterPage />
              </DefaultLayout>
            ) : (
              <NonAuthLayout>{route.component}</NonAuthLayout>
            )
          }
          key={idx}
        />
      ))}
      {privateRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={
            <AuthProtected>
              <DefaultLayout>{route.component}</DefaultLayout>
            </AuthProtected>
          }
          key={idx}
        />
      ))}
      <Route path="/account" element={<Navigate replace to="/profile" />} />
    </Routes>
  );
};

export default Index;
