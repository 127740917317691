import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { channelExport, deleteChannel } from "../api";
import { showWarningNotification } from "../helpers/notifications";
import { useRedux } from "../hooks";
import { changeSelectedChat } from "../redux/actions";
import { ModalChannelRename } from "./ModalChannelRename";
import useTranslation from "../helpers/useTranslation";
import { ChatMenuPopUpI18n } from "./ChatMenuPopUp.i18n";
import { ModalInviteMember } from "./ModalInviteMember";
import { QUERY_KEYS, queryClient } from "../libs/react-query";
import { ChannelDTO } from "../@DTO/channel";

import { useChannels } from "../hooks/useChannels";
import {
  ChatRepository,
  makeChatId,
} from "../helpers/indexedDb/chat-repository";
import { DocumentRepository } from "../helpers/indexedDb/document-repository";

interface ChatMenuPopUpProps {
  channelId: string;
  channel: ChannelDTO;
  blockClearChannel?: boolean;
}

export function ChatMenuPopUp({ channelId, channel }: ChatMenuPopUpProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showModalRenameChannel, setShowModalRenameChannel] = useState(false);
  const [showModalInviteMember, setShowModalInviteMember] = useState(false);

  const { handleChannelClear } = useChannels();

  const { t } = useTranslation(ChatMenuPopUpI18n);

  const chatRepository = new ChatRepository();
  const documentRepository = new DocumentRepository();

  const { dispatch } = useRedux();

  const isGeneralChat = channel?.channelName?.toLowerCase() === "general";

  const toggle = () => setDropdownOpen(!dropdownOpen);

  async function handleChannelDelete() {
    const confirmed = window.confirm(t("notifications.delete.confirm"));
    if (confirmed) {
      showWarningNotification(t("notifications.delete.deleting"));

      await deleteChannel(channelId);

      await chatRepository.delete(
        makeChatId(channel?._id, channel?.assistantId, channel?.userId)
      );

      await documentRepository.deleteDocumentsByChannelId(channelId);

      updateCacheByChannelId(channelId);

      dispatch(changeSelectedChat(null));
    }
  }

  function updateCacheByChannelId(channelId: string) {
    const documents = queryClient.getQueryData<ChannelDTO[]>(
      QUERY_KEYS.DOCUMENTS
    );
    const channels = queryClient.getQueryData<ChannelDTO[]>(
      QUERY_KEYS.CHANNELS
    );

    if (documents && (channel.isDocument || channel.isYoutube)) {
      const newDocuments = documents.filter(c => c._id !== channelId);
      return queryClient.setQueryData(QUERY_KEYS.DOCUMENTS, newDocuments);
    }

    if (channels) {
      const newChannels = channels.filter(c => c._id !== channelId);
      queryClient.setQueryData(QUERY_KEYS.CHANNELS, newChannels);
    }
  }

  async function handleChannelExport() {
    showWarningNotification(t("notifications.export"));

    const messages = await chatRepository.getMessagesByChatId(
      makeChatId(channel?._id, channel?.assistantId, channel?.userId)
    );

    await channelExport({ channelId, messages: messages || [] });
  }

  function onChannelRename() {
    if (showModalRenameChannel) {
      return setShowModalRenameChannel(false);
    }
    setShowModalRenameChannel(true);
  }

  function onInviteMember() {
    if (showModalInviteMember) {
      return setShowModalInviteMember(false);
    }
    setShowModalInviteMember(true);
  }

  return (
    <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
      <ModalChannelRename
        channel={channel}
        isOpen={showModalRenameChannel}
        onClose={onChannelRename}
      />
      <ModalInviteMember
        channel={channel}
        isOpen={showModalInviteMember}
        onClose={onInviteMember}
      />
      <DropdownToggle
        nav
        className="p-0"
        style={{
          marginTop: 2,
        }}
      >
        <button className="btn p-0">
          <i className="bx hover-primary bx-menu-alt-left fs-4" />
        </button>
      </DropdownToggle>
      <DropdownMenu style={{ minWidth: 200 }}>
        {/* {!isGeneralChat && (
          <DropdownItem
            onClick={onInviteMember}
            className="d-flex align-items-center justify-content-between"
          >
            {t("menu.invite-member")}
            <i className="bx bxs-user-plus text-muted ms-1" />
          </DropdownItem>
        )} */}
        <DropdownItem
          onClick={handleChannelExport}
          className="d-flex align-items-center justify-content-between"
        >
          {t("menu.export")}
          <i className="bx bxs-file-export text-muted ms-1" />
        </DropdownItem>
        {!isGeneralChat && (
          <DropdownItem
            onClick={onChannelRename}
            className="d-flex align-items-center justify-content-between"
          >
            {t("menu.rename")} <i className="bx bx-edit text-muted ms-1" />
          </DropdownItem>
        )}
        <DropdownItem
          onClick={handleChannelClear}
          className="d-flex align-items-center justify-content-between"
        >
          {t("menu.clear")} <i className="bx bxs-message-x text-muted ms-1" />
        </DropdownItem>

        {!isGeneralChat && (
          <DropdownItem
            onClick={handleChannelDelete}
            className="d-flex align-items-center justify-content-between"
          >
            {t("menu.delete")} <i className="bx bx-trash" />
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}
