import classnames from "classnames";
import { Button, UncontrolledTooltip } from "reactstrap";
import useTranslation from "../../../../helpers/useTranslation";
import { ChatInputSectionI18n } from "./ChatInputSection.i18n";

interface AttachButtonProps {
  isOpen: boolean;
  onToggle: () => void;
  isDocument: boolean;
}
const AttachButton = ({ isOpen, onToggle, isDocument }: AttachButtonProps) => {
  const { t } = useTranslation(ChatInputSectionI18n);

  return (
    <div className="chat-input-links me-md-2">
      {isDocument && (
        <>
          <div className="links-list-item" id="more-menu">
            <Button
              type="button"
              className="btn btn-link text-decoration-none btn-lg waves-effect"
              onClick={onToggle}
              color="none"
            >
              <i
                className={classnames("bx bx-paperclip align-middle", {
                  "text-primary": isOpen,
                })}
              ></i>
              <span>{t("button.label")}</span>
            </Button>
          </div>
          <UncontrolledTooltip target="more-menu" placement="top">
            {t("button.label")}
          </UncontrolledTooltip>
        </>
      )}
    </div>
  );
};

export default AttachButton;
