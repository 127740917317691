import { AssistantDTO } from "../@DTO/assistant";
import { SelectedChatDTO } from "../@DTO/selectedChat";
import { getCurrentUserLanguage } from "../helpers/userHelper";
import { useRedux } from "./useRedux";
import { clearChannel, createChannel } from "../api";
import { QUERY_KEYS, queryClient } from "../libs/react-query";
import { ChannelDTO } from "../@DTO/channel";
import { changeSelectedChat } from "../redux/actions";
import { showErrorNotification } from "../helpers/notifications";
import { useChatStore } from "../state/chat";
import { useState } from "react";

import {
  ChatRepository,
  makeChatId,
} from "../helpers/indexedDb/chat-repository";

export function useChannels(channels?: ChannelDTO[]) {
  const [loadingState, setIsLoading] = useState({
    createChannel: false,
    clearChannel: false,
  });

  const { dispatch, useAppSelector } = useRedux();

  const { setMessages } = useChatStore();

  const { assistant, selectedChat } = useAppSelector(state => ({
    assistant: state.Chats.assistant as AssistantDTO,
    selectedChat: state.Chats.selectedChat as SelectedChatDTO,
  }));

  const channelsByAssistant = channels?.filter(
    channel => channel?.assistantId === assistant?._id
  );

  const userLanguage = getCurrentUserLanguage();

  const chatRepository = new ChatRepository();

  const channelNameByLanguage = {
    en: "New chat",
    pt: "Novo chat",
    es: "Nuevo chat",
    fil: "Bagong chat",
  };

  async function handleCreateChannel() {
    try {
      setIsLoading({ clearChannel: false, createChannel: true });
      const channelsCountWithoutGeneralChat =
        channelsByAssistant?.filter(
          channel => channel.channelName !== "general"
        )?.length || 0;

      const channelName = `${channelNameByLanguage[userLanguage]} ${channelsCountWithoutGeneralChat}`;

      const channel = {
        channelName: channelName,
        assistantId: assistant?._id,
        isDocument: false,
      };

      const channelCreated = await createChannel(channel);

      const cachedChannels = queryClient.getQueryData<ChannelDTO[]>(
        QUERY_KEYS.CHANNELS
      );

      if (cachedChannels) {
        queryClient.setQueryData(QUERY_KEYS.CHANNELS, [
          ...cachedChannels,
          channelCreated,
        ]);
      }

      dispatch(changeSelectedChat(channelCreated));
    } catch (error: any) {
      showErrorNotification(error?.message || "Error creating chat");
      console.error(error);
    } finally {
      setIsLoading({ clearChannel: false, createChannel: false });
    }
  }

  async function handleChannelClear() {
    if (loadingState.createChannel) return;

    try {
      setIsLoading({ clearChannel: true, createChannel: false });

      const clearChatConfirmText = {
        en: "Would you like to clear the messages from the current chat?",
        pt: "Deseja limpar as mensagens do chat atual?",
        es: "¿Desea borrar los mensajes del chat actual?",
        fil: "Gusto mo bang burahin ang mga mensahe mula sa kasalukuyang chat?",
      };

      const confirmed = window.confirm(clearChatConfirmText[userLanguage]);
      if (confirmed) {
        const hasIndexedDBChat = await chatRepository.getMessagesByChatId(
          makeChatId(
            selectedChat._id,
            selectedChat.assistantId,
            selectedChat.userId
          )
        );

        if (!hasIndexedDBChat) {
          await clearChannel(selectedChat?._id);
        }

        await chatRepository.clearChatById(
          makeChatId(
            selectedChat._id,
            selectedChat.assistantId,
            selectedChat.userId
          )
        );

        setMessages([]);

        // dispatch(getChannelJobStatus(selectedChat?._id));
      }
    } catch (error: any) {
      showErrorNotification(error?.message || "Error clearing chat");
      console.error(error);
    } finally {
      setIsLoading({ clearChannel: false, createChannel: false });
    }
  }

  return {
    handleCreateChannel,
    handleChannelClear,
    loadingState,
  };
}
