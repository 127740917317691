import clsx from "clsx";
import { dataLayerPush } from "../../../../utils/gaEvents";
import { supportList } from "../../data";
import { SocialMediaLanguages } from "../../../../components/SocialMediaLanguages";
import useTranslation from "../../../../helpers/useTranslation";
import { SupportI18n } from "./Support.i18n";
import { showSuccessNotification } from "../../../../helpers/notifications";
import { useState } from "react";

export function Support() {
  const [isCopied, setIsCopied] = useState(false);

  const { t } = useTranslation(SupportI18n);

  function handleCopyEmail() {
    navigator.clipboard.writeText("support@aizzy.ai");
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2500);
  }

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-column">
          <h4>{t("titles.wppSupport")}</h4>
          <span
            className="font-size-16"
            style={{
              maxWidth: 900,
            }}
          >
            {t("description.wppSupport")}
          </span>
        </div>
        <div className="d-flex flex-row align-items-start justify-content-center justify-content-md-start flex-sm-nowrap gap-4">
          {supportList.map(item => {
            const { isEnUS, isEsES, isPtBR } = item.languages;
            return (
              <div
                className="d-flex flex-column align-items-start gap-2"
                style={{
                  width: 200,
                  background: "none",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-sm d-flex flex-column justify-content-center align-items-center fs-5 p-0 border-0"
                    onClick={() =>
                      dataLayerPush({
                        event: "click",
                        button_label: `${item.iconClassname.replace(
                          "bxl-",
                          ""
                        )} ${item.name}`,
                      })
                    }
                  >
                    <i
                      className={clsx("mb-2 bx", item.iconClassname)}
                      style={{ fontSize: 36 }}
                    />
                    <span className="fw-semibold text-primary font-size-14 text-opacity-75 mb-2">
                      {item.name}
                    </span>
                  </a>

                  <SocialMediaLanguages
                    isEnUS={isEnUS}
                    isEsES={isEsES}
                    isPtBR={isPtBR}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="d-flex flex-column gap-3 mt-3 align-items-start">
        <div className="d-flex flex-column">
          <h4>{t("titles.discordSupport")}</h4>
          <span
            className="font-size-16"
            style={{
              maxWidth: 900,
            }}
          >
            {t("description.discordSupport")}
          </span>
        </div>
        <a
          className="link-primary d-flex flex-column align-items-center justify-content-center"
          href="https://discord.com/invite/YCwY4GtdWv"
          target="_blank"
          rel="noreferrer"
        >
          <i className="bx bxl-discord" style={{ fontSize: 36 }} />
          <p>Discord Aizzy</p>
        </a>
      </div>

      <div className="d-flex flex-column mt-3">
        <h4>{t("titles.emailSupport")}</h4>
        <div className="d-flex align-items-center gap-2">
          <span>support@aizzy.ai</span>
          <button
            className="btn btn-sm d-flex gap-2 align-items-center"
            onClick={handleCopyEmail}
          >
            {isCopied ? (
              <>
                <div
                  className="btn-primary button-border-sm d-flex align-items-center justify-content-center"
                  style={{ width: 36, height: 36 }}
                >
                  <i className="bx bx-check" />
                </div>
                <p>{t("buttons.copied")}</p>
              </>
            ) : (
              <>
                <div
                  className="btn-primary button-border-sm d-flex align-items-center justify-content-center"
                  style={{ width: 36, height: 36 }}
                >
                  <i className="bx bx-copy" style={{ fontSize: 19 }} />
                </div>
                <p>{t("buttons.copy-code")}</p>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
