import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getAssistantImages } from "../../../api/assistants";
import useTranslation from "../../../helpers/useTranslation";
import { ModalImagesI18n } from "./CreateAssistant.i18n";

interface ModalImagesProps {
  onSelectGeneratedImage: (imageUrl: string) => void;
  currentImageSelected: string;
}

export function GenerateImagesButton(props: ModalImagesProps) {
  const { t } = useTranslation(ModalImagesI18n);
  const { onSelectGeneratedImage, currentImageSelected } = props;
  const [loadingImage, setLoadingImage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [generatedImages, setGeneratedImages] = useState<any>([]);

  const closeModal = () => {
    setShowModal(false);
    setGeneratedImages([]);
    setLoadingImage(false);
  };

  const handleGenerateImage = async () => {
    setShowModal(true);
    setLoadingImage(true);
    getAssistantImages()
      .then(images => setGeneratedImages(images))
      .finally(() => setLoadingImage(false));
  };

  return (
    <>
      <button
        className="d-block btn btn-outline-primary"
        type="button"
        onClick={handleGenerateImage}
        disabled={loadingImage}
      >
        {loadingImage ? (
          <i className="bx bx-loader bx-spin align-middle" />
        ) : currentImageSelected ? (
          t("button.create-another")
        ) : (
          t("button.create-image")
        )}
      </button>
      <Modal isOpen={showModal} toggle={closeModal} centered size="lg">
        <ModalHeader className="border-0" toggle={closeModal}>
          {loadingImage ? t("modal.loading") : t("modal.label")}
        </ModalHeader>
        <ModalBody className="d-flex flex-column flex-md-row align-items-center gap-3">
          {loadingImage && (
            <div className="col-12 my-4 d-flex align-items-center justify-content-center">
              <i className="bx bx-loader bx-spin text-primary fs-3" />
            </div>
          )}
          {!loadingImage &&
            generatedImages.map((image, index) => (
              <>
                {image.url ? (
                  <button
                    onClick={() => {
                      onSelectGeneratedImage(image.url);
                      closeModal();
                    }}
                    className="button-select-assistant-image"
                    key={index}
                  >
                    <img className="ratio" src={image.url} alt="" />
                  </button>
                ) : (
                  <div className="col-12 col-md-4 my-4 d-flex flex-column g-3 align-items-center justify-content-center">
                    <i className="bx bxs-error text-primary fs-3" />
                    <small>{t("error_on_generate")}</small>
                  </div>
                )}
              </>
            ))}
        </ModalBody>
        <ModalFooter>
          <Button
            className="d-flex align-items-center gap-1"
            color="primary"
            disabled={loadingImage}
            outline
            onClick={handleGenerateImage}
          >
            {t("button.recreate")}
            <i className="bx bx-refresh fs-5" />
          </Button>
          <Button color="secondary" onClick={closeModal}>
            {t("button.close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
