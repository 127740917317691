import { useNavigate } from "react-router-dom";
import useTranslation from "../../../helpers/useTranslation";
import { HeaderI18n } from "../CommunityAssistant.i18n";

export function Header() {
  const navigate = useNavigate();

  const { t } = useTranslation(HeaderI18n);

  const isMobile = window.matchMedia("(max-width: 576px)").matches;

  return (
    <div className="row">
      <div className="col-6 col-sm-8 col-md">
        <h4 className="p-3">{t("page.label")}</h4>
        <p className="px-3 d-none d-sm-block">
          {t("page.description")}
        </p>
      </div>
      <div
        className="col-6 col-sm-4 col-md-auto"
        style={{
          maxHeight: isMobile ? 81 : "100%",
        }}
      >
        <div className="text-nowrap p-3 px-3 h-100 w-100 d-flex align-items-center justify-content-center">
          <button
            onClick={() => {
              navigate("/assistant/create");
            }}
            className="btn flex-column gap-2"
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
              <i className="bx bxs-message-square-add"/>
            </div>
            {t("button.create-assistant")}
          </button>
        </div>
      </div>
    </div>
  );
}
