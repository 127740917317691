export const ModalPixI18n = {
  "pt-BR": {
    buttonPix: "Escolher esse plano",
    modalTitle: "Seu QR CODE do PIX foi gerado!",
    modalDescription:
      "Complete a assinatura de forma rápida e fácil escaneando o QR CODE abaixo",
    buttonCopyPix: "Copiar código do PIX",
    buttonPixCopied: "Código copiado!",
    waitingPaymentMessage: "Estamos aguardando o seu pagamento",
    textPaidPix: "Seu pagamento foi confirmado!",
    buttonLogout: "Sair da conta",
    messageLogoutLogin: "Agradecemos pela sua assinatura!",
    messageRedirect:
      "Você será redirecionado para a página inicial em {{ seconds }} segundos",
    alerts: {
      copySuccessMessage: "Código copiado para a área de transferência",
    },
  },
  "en-US": {
    buttonPix: "Choose this plan",
    modalTitle: "Your PIX QR CODE has been generated!",
    modalDescription:
      "Complete the signature quickly and easily by scanning the QR CODE below",
    buttonCopyPix: "Copy PIX code",
    buttonPixCopied: "Code copied!",
    waitingPaymentMessage: "We are awaiting your payment",
    textPaidPix: "Your payment has been confirmed!",
    buttonLogout: "Log out of the account",
    messageLogoutLogin: "Thank you for your subscription!",
    messageRedirect:
      "You will be redirected to the home page in {{ seconds }} seconds",
    alerts: {
      copySuccessMessage: "Code copied to clipboard",
    },
  },
  "es-ES": {
    buttonPix: "Elegir este plan",
    modalTitle: "¡Su código QR de PIX ha sido generado!",
    modalDescription:
      "Complete la firma de forma rápida y sencilla escaneando el código QR a continuación",
    buttonCopyPix: "Copiar código PIX",
    buttonPixCopied: "¡Código copiado!",
    waitingPaymentMessage: "Estamos esperando su pago",
    textPaidPix: "¡Se ha confirmado tu pago!",
    buttonLogout: "Salir de la cuenta",
    messageLogoutLogin: "¡Gracias por tu suscripción!",
    messageRedirect:
      "Serás redirigido a la página de inicio en {{ seconds }} segundos",
    alerts: {
      copySuccessMessage: "Código copiado al portapapeles",
    },
  },
  "fil-PH": {
    buttonPix: "Piliin ang plano na ito",
    modalTitle: "Ang iyong PIX QR CODE ay na-generate na!",
    modalDescription:
      "Kumpletuhin ang pagsusumite nang mabilis at madali sa pamamagitan ng pag-scan ng QR CODE sa ibaba",
    buttonCopyPix: "Kopyahin ang PIX code",
    buttonPixCopied: "Kode na kopya!",
    waitingPaymentMessage: "Naghihintay kami sa iyong bayad",
    textPaidPix: "Ang iyong bayad ay na-confirm na!",
    buttonLogout: "Mag-log out mula sa account",
    messageLogoutLogin: "Salamat sa iyong subscription!",
    messageRedirect:
      "Ikaw ay ireredirekta sa home page sa loob ng {{ seconds }} segundo",
    alerts: {
      copySuccessMessage: "Kode ay na-kopya sa clipboard",
    },
  },
};
