import { useEffect, useState } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from "../helpers/notifications";
import { inviteMemberToChannel } from "../api";
import { useRedux } from "../hooks";
import {
  changeSelectedChat,
  chatsApiResponseError,
  getChannelsAndDocuments,
} from "../redux/actions";
import useTranslation from "../helpers/useTranslation";
import { ModalInviteMemberI18n } from "./ModalInviteMember.i18n";
import { validateEmail } from "../utils/stringutils";
import { useUser } from "@supabase/auth-helpers-react";
import { ChannelDTO } from "../@DTO/channel";

interface ModalInviteMemberProps {
  isOpen: boolean;
  channel: ChannelDTO;
  onClose: () => void;
}

export interface InviteDataTypes {
  invitedEmail?: string;
  channelId: string;
}

export function ModalInviteMember({
  isOpen,
  onClose,
  channel,
}: ModalInviteMemberProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState<InviteDataTypes>({
    channelId: channel._id,
    invitedEmail: undefined,
  });

  const user = useUser();

  const { t } = useTranslation(ModalInviteMemberI18n);

  const { dispatch } = useRedux();

  const onDataChange = (field: "invitedEmail", value: any) => {
    let modifiedData: InviteDataTypes = { ...data };
    modifiedData[field] = value;
    setData(modifiedData);
  };

  const onSubmit = async () => {
    if (!data?.invitedEmail) {
      return;
    }

    if (user?.email === data?.invitedEmail) {
      return showWarningNotification(t("notifications.valid-email"));
    }

    if (data.invitedEmail) {
      const isValidEmail = validateEmail(data.invitedEmail);

      if (!isValidEmail) {
        return showWarningNotification(t("notifications.valid-email"));
      }
    }

    try {
      setIsLoading(true);
      const params = {
        email: data.invitedEmail,
        channelId: channel._id,
      };

      await inviteMemberToChannel(params);

      setData({
        channelId: "",
        invitedEmail: "",
      });
      onClose();
      showSuccessNotification(t("notifications.success"));
    } catch (error: any) {
      // @ts-ignore
      if (
        error.message.indexOf(
          "E11000 duplicate key error collection: aizzy.channelmembers"
        ) !== -1
      ) {
        return showErrorNotification(t("notifications.duplicate-member"));
      }
      showErrorNotification(error.message);
      dispatch(
        chatsApiResponseError("planLimitError", {
          planLimit: error.planLimit,
          planLimitErrorType: "channelMembers",
        })
      );
    } finally {
      setData({
        channelId: "",
        invitedEmail: "",
      });
      setIsLoading(false);
    }
  };

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit();
    }
  };

  const handleToggle = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleToggle}
      tabIndex={-1}
      centered
      scrollable
      id="addgroup-exampleModal"
      role="dialog"
      backdrop="static"
    >
      <ModalHeader className="modal-title-custom" toggle={handleToggle}>
        {t("page.label")}
      </ModalHeader>

      <ModalBody className="p-4">
        <div className="mb-4">
          <Label htmlFor="addgroupname-input" className="form-label">
            {t("input.title")} <span className="text-danger">*</span>
          </Label>
          <Input
            type="email"
            className="form-control"
            id="addgroupname-input"
            placeholder={t("input.placeholder")}
            value={data.invitedEmail || ""}
            onChange={(e: any) => onDataChange("invitedEmail", e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <Button color="link" type="button" onClick={onClose}>
          {t("button.close")}
        </Button>
        <Button
          type="button"
          className="btn btn-primary"
          onClick={onSubmit}
          disabled={!data.invitedEmail || isLoading}
        >
          {isLoading ? (
            <i className="bx bx-loader bx-spin align-middle"></i>
          ) : (
            t("button.rename")
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
