import { ComponentProps, memo } from "react";
import { isMobile } from "../../../utils/helpers";

interface ButtonGoFinalChatProps extends ComponentProps<"button"> {}

export const ButtonGoFinalChat = memo(({ ...rest }: ButtonGoFinalChatProps) => {
  return (
    <div
      className="position-absolute mb-2"
      style={{
        bottom: isMobile ? 100 : 140,
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 999,
      }}
    >
      <button
        className="btn btn-sm btn-secondary rounded-circle d-flex justify-content-center align-items-center"
        style={{
          width: 34,
          height: 34,
        }}
        {...rest}
      >
        <i className="bx bx-down-arrow-alt font-size-24" />
      </button>
    </div>
  );
});
