import { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import useTranslation from "../../helpers/useTranslation";
import { ModalPlanI18n } from "./ModalPlan.i18n";
import { useNavigate } from "react-router-dom";
import RemainingCallsContext from "../RemainingCallsContext";
import { useRedux } from "../../hooks";
import { useUser } from "@supabase/auth-helpers-react";
import { SubscriptionPage } from "../../pages/Subscription";
import { dataLayerPush } from "../../utils/gaEvents";
import { getCurrentUserPlan } from "../../helpers/userHelper";
import { PLANS_AIZZY } from "../../helpers/constants/Plans";

const ModalPlan = () => {
  const { showFreemiumModal, setShowFreemiumModal, remainingCalls } =
    useContext(RemainingCallsContext);
  const user = useUser();
  const { useAppSelector } = useRedux();
  const { planLimit, planLimitErrorType } = useAppSelector(state => ({
    planLimit: state.Chats.planLimit,
    planLimitErrorType: state.Chats.planLimitErrorType,
  }));
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(ModalPlanI18n);
  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
    setShowFreemiumModal("");
  };

  const navigate = useNavigate();

  const isMobile = window.matchMedia("(max-width: 767px)").matches;

  const userPlan = getCurrentUserPlan();
  const isPremiumUser = userPlan === PLANS_AIZZY.PREMIUM;

  const currentPath = window.location.pathname;

  const handleClickUpgrade = () => {
    setShowFreemiumModal("");
    setIsOpen(false);
    navigate("/plans");
  };
  useEffect(() => {
    if (showFreemiumModal || planLimit) {
      if (currentPath === "/assistant/create") {
        return setIsOpen(false);
      }
      setIsOpen(true);
      dataLayerPush({
        event: "open.modal_plan",
        userId: user?.id,
        limitType: planLimitErrorType || showFreemiumModal,
      });
    }
  }, [showFreemiumModal, planLimit, currentPath]);
  const limitSizes = {
    remainingPDFs: remainingCalls?.pdfLimit,
    remainingImages: remainingCalls?.imageLimit,
    remainingVideos: remainingCalls?.videoLimit,
    remainingSites: remainingCalls?.siteLimit,
    channelMembers: remainingCalls?.channelMembers,
    pdfSize: remainingCalls?.pdfSizeLimit,
    VIDEO_DURATION_LIMIT: remainingCalls?.videoDurationLimit,
    VIDEO_LIMIT: remainingCalls?.videoLimit,
    SITE_LIMIT: remainingCalls?.siteLimit,
    PDF_SIZE_LIMIT_EXCEEDED: remainingCalls?.pdfSizeLimit,
    PDF_LIMIT_EXCEEDED: remainingCalls?.pdfLimit,
    MEMBER_LIMIT_REACHED: remainingCalls?.channelMembers,
    IMAGE_LIMIT_EXCEEDED: remainingCalls?.imageLimit,
    CHAT_LIMIT_EXCEEDED: remainingCalls?.chatLimit,
  };

  const title =
    planLimitErrorType || showFreemiumModal
      ? t(`errorType.${planLimitErrorType || showFreemiumModal}` as any, {
          planLimit: limitSizes[planLimitErrorType || showFreemiumModal],
          planName: userPlan,
        })
      : t("text");

  if (isPremiumUser) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      centered
      toggle={toggleIsOpen}
      size={isMobile ? "" : "xl"}
      className="my-0 mx-auto modalPlan"
    >
      <ModalHeader
        className="modal-title-plan text-center"
        toggle={toggleIsOpen}
      >
        {isMobile ? t("modalTitle") : title}
      </ModalHeader>
      <ModalBody className="">
        {isMobile && (
          <div className="row text-center">
            <div className="col-12">
              <p className="font-size-18">{title}</p>
            </div>
            <div className="my-4 col-12">
              <button className="btn btn-primary" onClick={handleClickUpgrade}>
                {t("cta")}
              </button>
            </div>
            <div className="col-12 text-center">
              <p>{t("description")}</p>
            </div>
          </div>
        )}
        {!isMobile && (
          <SubscriptionPage hideLogo hideTitle description={t("description")} />
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalPlan;
