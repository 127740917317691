import React from "react";
import TitlesHome from "./TitlesHome";
import ListCardBuild from "./ListCardBuild";
import useTranslation from "../helpers/useTranslation";
import { SectionBuildI18n } from "./SectionBuild.i18n";

const SectionBuild = () => {
  const { t } = useTranslation(SectionBuildI18n);

  return (
    <section className="section-build">
      <div className="texts-build">
        <div>
          <TitlesHome text={t("title")} className={""} />
        </div>
        <div className="mt-3">
          <p className="size-paragrapy-landing-page mb-2">{t("paragraph")}</p>
        </div>
        <ListCardBuild />
      </div>
    </section>
  );
};

export default SectionBuild;
