export type HelpTarget = "pdf" | "youtube" | "images" | "assistant" | "whatsapp";

enum HelpLanguage {
  "en-us" = "en-us",
  "pt-br" = "pt-br"
}

export const helpLinks = {
  pdf: {
    pt: {
      createChatWithPdf: "https://youtu.be/lkkOmsgVCPk",
    },
    en: {
      createChatWithPdf: "https://youtu.be/prIszHjZbdM",
    },
  },
  youtube: {
    pt: {
      createChatWithYoutube: "https://youtu.be/OyxSaVKiMq0",
    },
    en: {
      createChatWithYoutube: "https://youtu.be/FRwPqLQJY14",
    },
  },
  images: {
    pt: {
      createGalleryImage: "https://youtu.be/F5XNM3oxH_0",
    },
    en: {
      createGalleryImage: "https://youtu.be/FZ1dlfkG-4s",
    },
  },
  assistant: {
    pt: {
      createAssistant: "https://youtu.be/3Hdkd8GV86c",
    },
    en: {
      createAssistant: "https://www.youtube.com/watch?v=-okOKn1H3d4",
    },
  },
  whatsapp: {
    pt: "https://youtube.com/shorts/a2ORPDinams?feature=share",
    en: "https://youtube.com/shorts/a2ORPDinams?feature=share",
  }
};

export function getHelpLink(target: HelpTarget, language: string) {
  const languagesInLowerCase = language?.toLowerCase();

  switch (target) {
    case "pdf":
      if (languagesInLowerCase === HelpLanguage["en-us"]) {
        return helpLinks.pdf.en.createChatWithPdf;
      } else {
        return helpLinks.pdf.pt.createChatWithPdf;
      }

    case "youtube":
      if (languagesInLowerCase === HelpLanguage["en-us"]) {
        return helpLinks.youtube.en.createChatWithYoutube;
      } else {
        return helpLinks.youtube.pt.createChatWithYoutube;
      }

    case "images":
      if (languagesInLowerCase === HelpLanguage["en-us"]) {
        return helpLinks.images.en.createGalleryImage;
      } else {
        return helpLinks.images.pt.createGalleryImage;
      }

    case "assistant":
      if (languagesInLowerCase === HelpLanguage["en-us"]) {
        return helpLinks.assistant.en.createAssistant;
      } else {
        return helpLinks.assistant.pt.createAssistant;
      }

    case "whatsapp":
      if (languagesInLowerCase === HelpLanguage["en-us"]) {
        return helpLinks.whatsapp.en;
      } else {
        return helpLinks.whatsapp.pt;
      }
  }
}
