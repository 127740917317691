export const SocialCommunityI18n = {
  "pt-BR": {
    page: {
      joincommunity: "Junte-se à nossa comunidade",
    },
    socialList: {
      tooltipLabel: "Idioma(s)",
    },
  },
  "en-US": {
    page: {
      joincommunity: "Join our community",
    },
    socialList: {
      tooltipLabel: "Language(s)",
    },
  },
  "es-ES": {
    page: {
      joincommunity: "Únete a nuestra comunidad",
    },
    socialList: {
      tooltipLabel: "Idioma(s)",
    },
  },
  "fil-PH": {
    page: {
      joincommunity: "Sumali sa aming komunidad",
    },
    socialList: {
      tooltipLabel: "Wika/Idioma",
    },
  },
};
