import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import useTranslation from "../helpers/useTranslation";
import { ImageMenuPopUpI18n } from "./ImageMenuPopUp.i18n";
import { useState } from "react";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../helpers/notifications";
import { WhatsappShareButton } from "react-share";
import { removeMarkdownImageSyntax } from "../utils/helpers";
import { deleteGalleryImage } from "../api";
import { QUERY_KEYS, queryClient } from "../libs/react-query";

interface ImageMenuPopUpProps {
  image: any;
  chatId: string;
}

export function ImageMenuPopUp({ image, chatId }: ImageMenuPopUpProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation(ImageMenuPopUpI18n);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  function handleCopyImage(imageUrl) {
    setIsLoading(true);
    imageUrl = imageUrl.replace(
      "https://images.aizzy.ai/",
      "https://aizzy.s3.us-east-2.amazonaws.com/"
    );

    fetch(imageUrl)
      .then(response => response.arrayBuffer())
      .then(buffer => {
        const blob = new Blob([buffer], { type: "image/png" });
        const item = new ClipboardItem({ "image/png": blob });

        navigator.clipboard
          .write([item])
          .then(function () {
            showSuccessNotification(t("notifications.copy-image"));
          })
          .catch(function (error) {
            console.error(
              "Ocorreu um erro ao copiar a imagem para a área de transferência:",
              error
            );
          });
      })
      .catch(error => {
        console.error("Ocorreu um erro ao obter o buffer da imagem:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleDownloadImage(imageUrl: string) {
    setIsLoading(true);
    imageUrl = imageUrl.replace(
      "https://images.aizzy.ai/",
      "https://aizzy.s3.us-east-2.amazonaws.com/"
    );

    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "aizzy-image.png";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error("Ocorreu um erro ao obter o blob da imagem:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleDeleteImage = async () => {
    const confirmed = window.confirm(t("notifications.delete-image"));
    if (confirmed) {
      try {
        setIsLoading(true);
        await deleteGalleryImage(chatId);
        queryClient.invalidateQueries(QUERY_KEYS.IMAGES_CHANNEL);
      } catch (e: any) {
        showErrorNotification(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle nav disabled={isLoading}>
        <button
          name="image-menu"
          className="d-flex align-items-center justify-content-center btn btn-dark border-0 rounded-2 bg-black p-1 hover-primary"
        >
          {isLoading ? (
            <i className={`bx bx-loader bx-spin font-size-20`} />
          ) : (
            <i className={`bx bx-menu-alt-left font-size-22`} />
          )}
        </button>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => handleCopyImage(removeMarkdownImageSyntax(image))}
          className="d-flex align-items-center justify-content-between"
        >
          {t("button.copy-image")}
          <i className="bx bx-image-alt ms-1" />
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            navigator.clipboard.writeText(removeMarkdownImageSyntax(image));
            showSuccessNotification(t("notifications.copy-link"));
          }}
          className="d-flex align-items-center justify-content-between"
        >
          {t("button.copy-link")} <i className="bx bx-link text-muted ms-1" />
        </DropdownItem>
        <DropdownItem
          onClick={() => handleDownloadImage(removeMarkdownImageSyntax(image))}
          className="d-flex align-items-center justify-content-between"
        >
          {t("button.download-image")}{" "}
          <i className="bx bx-download text-muted ms-1" />
        </DropdownItem>

        <DropdownItem>
          <WhatsappShareButton
            url={image}
            title={"Imagem gerada pela Aizzy"}
            separator=":: "
            className="w-100 d-flex align-items-center justify-content-between"
          >
            {t("button.share-wpp")}
            <i className="bx bxl-whatsapp text-muted ms-1" />
          </WhatsappShareButton>
        </DropdownItem>
        <DropdownItem
          onClick={handleDeleteImage}
          aria-label="delete-image"
          className="d-flex align-items-center justify-content-between"
        >
          {t("button.delete")} <i className="bx bx-trash text-muted ms-1" />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
