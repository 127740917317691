import { useEffect, useState } from "react";
import { CommunityAssistantSearch } from "../../../../components/CommunityAssistantSearch";
import { useQuery } from "react-query";
import { QUERY_KEYS } from "../../../../libs/react-query";
import { getCommunityAssistants } from "../../../../api/assistants";
import { getCurrentUserLanguage } from "../../../../helpers/userHelper";
import { Link } from "react-router-dom";
import { AssistantDTO } from "../../../../@DTO/assistant";
import CommunityAssistant from "../../../../components/CommunityAssistant";
import { FetchCommunityAssistantResponse } from "../../../CommunityAssistants/types";
import { AssistantCardSkeleton } from "../../../../components/AssistantCardSkeleton";

interface CommunityAssistantsProps {
  onSelectAssistant: (assistant: AssistantDTO) => void;
  t: any;
}

const FIRST_PAGE = 0;

export function CommunityAssistants({
  onSelectAssistant,
  t,
}: CommunityAssistantsProps) {
  const [isSearchingCommunityAssistant, setIsSearchingCommunityAssistant] =
    useState(false);

  const userLanguage = getCurrentUserLanguage();

  const { data, isLoading, refetch, isRefetching } =
    useQuery<FetchCommunityAssistantResponse>(
      [QUERY_KEYS.COMMUNITY_ASSISTANTS, FIRST_PAGE],
      async () => {
        return await getCommunityAssistants({
          pageNumber: FIRST_PAGE,
          language: userLanguage,
        });
      },
      {
        staleTime: 30 * 60 * 1000, // 30 minutes
        keepPreviousData: true,
      }
    );

  const isMobile = window.matchMedia("(max-width: 500px)").matches;

  const communityAssistantsToShow = isMobile ? 5 : 10;

  function onSearchCommunityAssistant(query: string) {
    if (query) {
      return setIsSearchingCommunityAssistant(true);
    }
    setIsSearchingCommunityAssistant(false);
  }

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div className="animate__animated animate__fadeInLeft animate__faster">
      <div className="d-flex align-items-center justify-content-between px-3 gap-3 my-4">
        <h4 className="m-0">
          {t("page.top-assistants", {
            communityAssistantsToShow,
          })}
        </h4>
        <Link
          to="/community-assistants"
          className="btn py-0 d-flex align-items-center text-nowrap gap-3"
          style={{
            maxWidth: 300,
            fontWeight: 500,
          }}
        >
          <div className="btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
            <i className="bx bx bxs-group"></i>
          </div>
          {t("communityAssistants.link.viewAll")}
        </Link>
      </div>
      <CommunityAssistantSearch
        onSearchAssistant={onSearchCommunityAssistant}
      />
      {isLoading || isRefetching ? (
        <div className="mt-3">
          <AssistantCardSkeleton quantity={10} />
        </div>
      ) : (
        <div className="w-auto mt-3">
          <CommunityAssistant
            assistants={
              isSearchingCommunityAssistant
                ? []
                : data?.assistants?.slice(0, communityAssistantsToShow)
            }
            onSelectAssistant={onSelectAssistant}
          />
          <div className="col-12 p-3 text-center mb-4">
            <Link
              to="/community-assistants"
              className="btn mx-auto d-flex align-items-center justify-content-center mb-4 gap-3"
              style={{
                maxWidth: 400,
              }}
            >
              <div className="btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
                <i className="bx bxs-group" />
              </div>
              {t("communityAssistants.link.see-all-assistants")}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
