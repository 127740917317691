export const PlanI18n = {
  "pt-BR": {
    titles: {
      currentPlan: "Seu plano atual é ",
      congratsTitle: "Parabéns por se tornar um usuário Premium na Aizzy!",
    },
    timePlanStart: "Sua assinatura premium iniciou em:",
    timePlanEnd: "E estará ativa até o dia:",
    buttons: {
      goPremium: "Quero ser Premium",
      manageSubscription: "Gerenciar minha assinatura",
    },
  },
  "en-US": {
    titles: {
      currentPlan: "Your current plan is ",
      congratsTitle: "Congratulations on becoming a Premium user at Aizzy!",
    },
    timePlanStart: "Your premium subscription started on:",
    timePlanEnd: "And will be active until:",
    buttons: {
      goPremium: "Go Premium",
      manageSubscription: "Manage my subscription",
    },
  },
  "es-ES": {
    titles: {
      currentPlan: "Su plan actual es ",
      congratsTitle: "Felicidades por convertirte en usuario Premium en Aizzy!",
    },
    timePlanStart: "Tu suscripción premium comenzó el:",
    timePlanEnd: "Y estará activa hasta el día:",
    buttons: {
      goPremium: "Quiero ser Premium",
      manageSubscription: "Administrar mi suscripción",
    },
  },
  "fil-PH": {
    titles: {
      currentPlan: "Ang iyong kasalukuyang plano ay ",
      congratsTitle: "Binabati ka sa pagiging isang Premium na user sa Aizzy!",
    },
    timePlanStart: "Ang iyong premium subscription ay nagsimula noong:",
    timePlanEnd: "At magiging aktibo hanggang sa araw:",
    buttons: {
      goPremium: "Gusto kong maging Premium",
      manageSubscription: "Pamahalaan ang aking subscription",
    },
  },
};
