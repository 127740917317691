import { ReactComponent as IconHate } from "../../assets/images/icons/survey/hate.svg";
import { ReactComponent as IconDislike } from "../../assets/images/icons/survey/dislike.svg";
import { ReactComponent as IconRegular } from "../../assets/images/icons/survey/regular.svg";
import { ReactComponent as IconLike } from "../../assets/images/icons/survey/like.svg";
import { ReactComponent as IconLove } from "../../assets/images/icons/survey/love.svg";

export const npsList = [
  {
    id: 1,
    name: "Hate",
    icon: IconHate,
  },
  {
    id: 2,
    name: "Dislike",
    icon: IconDislike,
  },
  {
    id: 3,
    name: "Regular",
    icon: IconRegular,
  },
  {
    id: 4,
    name: "Like",
    icon: IconLike,
  },
  {
    id: 5,
    name: "Love",
    icon: IconLove,
  },
];
