export const NewDocumentModalI18n = {
  "pt-BR": {
    page: {
      label: "Novo chat",
      or: "ou",
    },

    docs: {
      title: "Escolha entre um arquivo",
      typesSupported: "Ver tipos suportados",
      placeholder: "Carregue ou arraste um arquivo aqui mesmo\n  ",
      notifications: {
        youtubeWarn:
          "Por favor, evite usar links do YouTube aqui; você pode utilizar a aba do YouTube para isso",
        invalidUrl: "Essa URL não é válida, por favor insira uma válida",
        error:
          "O tamanho do arquivo excedeu o limite de {{pdfSizeLimit}}MB para o plano.",
      },
    },

    link: {
      title: "Link de um site",
      placeholder: "Link do site...",
    },

    processing: {
      title: "Please wait, we are processing your request",
      itemProcessing: "Processando o item: ",
      creatingChat: "Criando chat",
      sendingContent: "Enviando conteúdo selecionado",
    },

    button: {
      close: "Fechar",
      create: "Criar",
    },
  },
  "en-US": {
    page: {
      label: "New chat",
      or: "or",
    },

    docs: {
      title: "Choose from a file",
      typesSupported: "View supported types",
      placeholder: "Upload or drop a file right here",
      notifications: {
        youtubeWarn:
          "Please refrain from using YouTube links here; you can utilize the YouTube tab for that purpose",
        invalidUrl: "This URL is invalid, please insert a valid one",
        error: "PDF size exceeds the limit of {{pdfSizeLimit}}MB for the plan.",
      },
    },

    link: {
      title: "Link from a website",
      placeholder: "Website link...",
    },

    processing: {
      title: "Please wait, we are processing your request",
      itemProcessing: "Processing item: ",
      creatingChat: "Creating chat",
      sendingContent: "Sending selected content",
    },

    button: {
      close: "Close",
      create: "Create",
    },
  },
  "es-ES": {
    page: {
      label: "Nuevo chat",
      or: "o",
    },

    docs: {
      title: "Elija de un archivo",
      typesSupported: "Ver tipos soportados",
      placeholder: "Sube o arrastra un archivo justo aquí",
      notifications: {
        youtubeWarn:
          "Por favor, evite usar enlaces de YouTube aquí; puedes utilizar la pestaña de YouTube para eso",
        invalidUrl: "Esta URL no es válida, por favor inserte una válida",
        error:
          "El tamaño del PDF excede el límite de {{pdfSizeLimit}}MB MB para el plan.",
      },
    },

    link: {
      title: "Enlace de un sitio web",
      placeholder: "Enlace del sitio web...",
    },

    processing: {
      title: "Por favor, espere, estamos procesando su solicitud",
      itemProcessing: "Procesando el ítem: ",
      creatingChat: "Creando chat",
      sendingContent: "Enviando contenido seleccionado",
    },

    button: {
      close: "Cerrar",
      create: "Crear",
    },
  },
  "fil-PH": {
    page: {
      label: "Bagong chat",
      or: "o",
    },

    docs: {
      title: "Pumili mula sa isang file",
      typesSupported: "Tingnan ang mga suportadong uri",
      placeholder: "I-upload o i-drop ang isang file dito mismo",
      notifications: {
        youtubeWarn: "",
        error:
          "Ang laki ng PDF ay lumampas sa limitasyon ng {{pdfSizeLimit}}MB para sa plano.",
      },
    },

    link: {
      title: "Link mula sa isang website",
      placeholder: "Link ng website...",
    },

    processing: {
      title: "Mangyaring maghintay, kami ay nasa proseso ng iyong kahilingan",
      itemProcessing: "Nagpo-proseso ng item: ",
      creatingChat: "Lumilikha ng chat",
      sendingContent: "Nagpapadala ng piniling nilalaman",
    },

    button: {
      close: "Isara",
      create: "Gumawa",
    },
  },
};
