const AffiliateCardI18n = {
  "pt-BR": {
    title: "Programa de afiliados",
    text: "🎉 Estamos empolgados em apresentar nosso programa de afiliados! Como afiliado, você terá a oportunidade de compartilhar nossa IA com sua rede de contatos. E o melhor de tudo? Você receberá uma comissão de 10% por cada assinatura realizada através do seu link exclusivo. <br/><br/> É simples! Clique no botão abaixo para copiar seu link de afiliado e comece a compartilhá-lo em suas redes sociais, blog, site ou qualquer outro canal que preferir. Quanto mais assinaturas forem realizadas através do seu link, maior será a sua remuneração.",
    copyLink: "Copiar meu link de afiliado",
    footer:
      "Ao utilizar o link de afiliado, você concorda com os termos e condições do nosso programa de afiliados. Para obter mais informações, consulte nossa <a target='_blank' href='https://aizzy-8b5d01.ingress-comporellon.ewp.live/privacy-policy/'>Política de Privacidade</a>.",
  },
  "en-US": {
    title: "Affiliate program",
    text: "🎉 We are excited to introduce our affiliate program! As an affiliate, you will have the opportunity to share our AI with your network. And the best part? You will receive a 10% commission for every subscription made through your exclusive link. <br/><br/> It's simple! Click the button below to copy your affiliate link and start sharing it on your social media, blog, website, or any other preferred channel. The more subscriptions are made through your link, the higher your earnings.",
    copyLink: "Copy my affiliate link",
    footer:
      "By using the affiliate link, you agree to the terms and conditions of our affiliate program. For more information, please refer to our <a target='_blank' href='https://aizzy-8b5d01.ingress-comporellon.ewp.live/privacy-policy/'>Privacy Policy</a>.",
  },
  "es-ES": {
    title: "Programa de afiliados",
    text: "🎉 ¡Estamos emocionados de presentar nuestro programa de afiliados! Como afiliado, tendrás la oportunidad de compartir nuestra IA con tu red de contactos. ¿Y lo mejor de todo? Recibirás una comisión del 10% por cada suscripción realizada a través de tu enlace exclusivo. <br/><br/> ¡Es fácil! Haz clic en el botón de abajo para copiar tu enlace de afiliado y comienza a compartirlo en tus redes sociales, blog, sitio web o cualquier otro canal que prefieras. Cuantas más suscripciones se realicen a través de tu enlace, mayor será tu remuneración.",
    copyLink: "Copiar mi enlace de afiliado",
    footer:
      "Al utilizar el enlace de afiliado, aceptas los términos y condiciones de nuestro programa de afiliados. Para obtener más información, consulta nuestra <a target='_blank' href='https://aizzy-8b5d01.ingress-comporellon.ewp.live/privacy-policy/'>Política de Privacidad</a>.",
  },
  "fil-PH": {
    title: "Programang Kaugnayan",
    text: "🎉 Masaya kaming ipakilala ang aming programa ng affiliate! Bilang isang affiliate, magkakaroon ka ng pagkakataon na ibahagi ang aming AI sa iyong network. At ang pinakamahusay na bahagi? Makakatanggap ka ng komisyon na 10$ para sa bawat subscription na ginawa sa pamamagitan ng iyong eksklusibong link. <br/><br/> Madali lang! I-click ang button sa ibaba para kopyahin ang iyong affiliate link at simulan itong ibahagi sa iyong social media, blog, website, o anumang iba pang channel na iyong nais. Mas maraming subscriptions na maganap sa iyong link, mas mataas ang iyong kita.",
    copyLink: "Kopyahin ang aking affiliate link",
    footer:
      "Sa paggamit ng affiliate link, sumasang-ayon ka sa mga terms at kondisyon ng aming programa ng affiliate. Para sa karagdagang impormasyon, mangyaring tingnan ang aming <a target='_blank' href='https://aizzy-8b5d01.ingress-comporellon.ewp.live/privacy-policy/'>Privacy Policy</a>.",
  },
};

export default AffiliateCardI18n;
