import clsx from "clsx";
import { SocialMediaLanguages } from "../../../components/SocialMediaLanguages";
import { Card } from "../../../components/Card";
import { dataLayerPush } from "../../../utils/gaEvents";
import { socialList } from "../socialList";

import { ReactComponent as TikTokSvg } from "../../../assets/images/icons/survey/tiktok-svg.svg";
import useTranslation from "../../../helpers/useTranslation";
import { SocialCommunityI18n } from "./SocialCommunity.i18n";
import React from "react";

export function SocialCommunity({
  center = true,
  className,
}: {
  center?: boolean;
  className?: string;
}) {
  const { t } = useTranslation(SocialCommunityI18n);

  return (
    <div className={`row ${className}`}>
      <div className="col-12">
        <div
          className={`row d-flex ${
            center ? "justify-content-center" : ""
          } mx-auto g-3`}
        >
          <div className={`w-100 p-0 ${center ? "text-center" : ""} mb-2`}>
            <h4 className="bg-opacity-25 rounded-2">
              {t("page.joincommunity")}
            </h4>
          </div>
          <div
            className={`d-flex mt-0 p-0 ${center ? "justify-content-center" : ""}`}
            style={{ gap: 30 }}
          >
            {socialList.map((item, index) => {
              return (
                <React.Fragment key={`social-item-${index}`}>
                  {index === 5 && (
                    <div key={`social-item-${index}-special`}>
                      <Card
                        title={null}
                        description={""}
                        footer={null}
                        className={""}
                      >
                        <div className="d-flex flex-column align-items-center">
                          <a
                            href="https://www.tiktok.com/@aizzy.ai?is_from_webapp=1&sender_device=pc"
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-sm d-flex flex-column p-0 justify-content-center align-items-center text-decoration-none"
                          >
                            <TikTokSvg
                              className="mb-2"
                              fill="#797C8C"
                              style={{ fontSize: 36 }}
                            />
                            <span className="text-primary text-opacity-75 hover-primary">
                              Aizzy.ia
                            </span>
                          </a>
                          <SocialMediaLanguages isEnUS />
                        </div>
                      </Card>
                    </div>
                  )}
                  <div key={`social-item-${index}`}>
                    <div className="d-flex flex-column align-items-center">
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-sm d-flex flex-column p-0 justify-content-center align-items-center"
                        onClick={() =>
                          dataLayerPush({
                            event: "click",
                            button_label: `${item.iconClassname.replace(
                              "bxl-",
                              ""
                            )} ${item.name}`,
                          })
                        }
                      >
                        <i
                          className={clsx("mb-2 bx", item.iconClassname)}
                          style={{ fontSize: 36 }}
                        />
                        <span className="text-primary text-opacity-75 hover-primary">
                          {item.name}
                        </span>
                      </a>
                      {/* <SocialMediaLanguages
                        isEnUS={isEnUS}
                        isEsES={isEsES}
                        isPtBR={isPtBR}
                      /> */}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
