// interface
import useTranslation from "../../../helpers/useTranslation";
import { ChanelsI18n } from "./AssistantsChats.i18n";

// components
import ChatChannel from "./ChatChannel";
import { ButtonDeleteAllChats } from "../../../components/ButtonDeleteAllChats";

import { SelectedChatDTO } from "../../../@DTO/selectedChat";
import { AssistantDTO } from "../../../@DTO/assistant";
import { ChannelDTO } from "../../../@DTO/channel";
import { useRedux } from "../../../hooks";
import { useMutation } from "react-query";
import { changeSelectedChat } from "../../../redux/actions";
import { QUERY_KEYS, queryClient } from "../../../libs/react-query";
import { showErrorNotification } from "../../../helpers/notifications";
import { createChannel } from "../../../api";
import { getCurrentUserLanguage } from "../../../helpers/userHelper";

import {
  ChatRepository,
  makeChatId,
} from "../../../helpers/indexedDb/chat-repository";

interface ChanelsProps {
  channels: ChannelDTO[];
  assistant: AssistantDTO;
  selectedChat: SelectedChatDTO | null;
  onSelectChat: (channel: ChannelDTO, isChannel?: boolean) => void;
}
const Chanels = ({
  channels,
  assistant,
  selectedChat,
  onSelectChat,
}: ChanelsProps) => {
  const { t } = useTranslation(ChanelsI18n);

  const { isLoading: isCreatingChannel, mutateAsync: createNewChannel } =
    useMutation(async (channel: any) => {
      return await createChannel(channel);
    });

  const { dispatch } = useRedux();

  const chatRepository = new ChatRepository();

  const userLanguage = getCurrentUserLanguage();

  const channelNameByLanguage = {
    en: "New chat",
    pt: "Novo chat",
    es: "Nuevo chat",
    fil: "Bagong chat",
  };

  async function handleCreateChannel() {
    try {
      const channelsCountWithoutGeneralChat =
        channels?.filter(channel => channel.channelName !== "general")
          ?.length || 0;

      const channelName = `${channelNameByLanguage[userLanguage]} ${
        channelsCountWithoutGeneralChat + 1
      }`;

      const channel = {
        channelName: channelName,
        assistantId: assistant?._id,
        isDocument: false,
      };

      const channelCreated = await createNewChannel(channel);

      const cachedChannels = queryClient.getQueryData<ChannelDTO[]>(
        QUERY_KEYS.CHANNELS
      );

      if (cachedChannels) {
        queryClient.setQueryData(QUERY_KEYS.CHANNELS, [
          ...cachedChannels,
          channelCreated,
        ]);
      }

      await chatRepository.add({
        id: makeChatId(
          channelCreated._id,
          channelCreated.assistantId,
          channelCreated.userId
        ),
        messages: [],
      });

      dispatch(changeSelectedChat(channelCreated));
    } catch (error: any) {
      showErrorNotification(error?.message || "Error creating chat");
      console.error(error);
    }
  }

  return (
    <>
      <div className="d-flex align-items-center px-3 my-3">
        <div className="flex-grow-1">
          <h4 className="mb-0 font-size-11 text-muted text-uppercase px-2">
            {t("page.label")}
          </h4>
        </div>
      </div>
      <div className="d-flex flex-column align-items-center gap-2 py-3">
        {assistant?.enabled && (
          <button
            className="w-100 btn d-flex align-items-center justify-content-start gap-2"
            onClick={handleCreateChannel}
            disabled={isCreatingChannel}
          >
            {isCreatingChannel ? (
              <span>
                <i className="bx bx-loader bx-spin align-middle"></i>
              </span>
            ) : (
              <div className="d-flex align-items-center justify-content-center gap-2 btn-primary button-border-sm button-small">
                <i className="bx bx-message-add" />
              </div>
            )}
            {t("button.newChat")}
          </button>
        )}
        <ButtonDeleteAllChats
          assistantId={assistant?._id}
          disabled={channels?.length === 0}
        />
      </div>
      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list mb-3 px-3 gap-2 d-flex flex-column">
          {channels?.map((channel: ChannelDTO) => (
            <ChatChannel
              channel={channel}
              key={channel?._id}
              selectedChat={selectedChat}
              onSelectChat={onSelectChat}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default Chanels;
