export const TabsAssistantsI18n = {
  "pt-BR": {
    page: {
      label: "Assitentes",
    },
    tabs: {
      myAssistants: "Meus",
      communityAssistants: "Comunidade",
      aizzyAssistants: "Aizzy",
      createAssistant: "Criar Assistente",
    },
    myAssistants: {
      "empty-my-assistants":
        "Parece que você ainda não possuí nenhum assistente",
      "create-assistant": "Crie seu assistente exclusivo agora mesmo!",
    },
  },
  "en-US": {
    page: {
      label: "Assistants",
    },
    tabs: {
      myAssistants: "My",
      communityAssistants: "Community",
      aizzyAssistants: "Aizzy",
      createAssistant: "Create Assistant",
    },
    myAssistants: {
      "empty-my-assistants": "It seems you don't have any assistant yet",
      "create-assistant": "Create your unique assistant right now!",
    },
  },
  "es-ES": {
    page: {
      label: "Asistentes",
    },
    tabs: {
      myAssistants: "Mis",
      communityAssistants: "Comunitarios",
      aizzyAssistants: "Aizzy",
      createAssistant: "Crear Asistente",
    },
    myAssistants: {
      "empty-my-assistants": "Parece que aún no tienes ningún asistente",
      "create-assistant": "¡Crea tu asistente exclusivo ahora mismo!",
    },
  },
  "fil-PH": {
    page: {
      label: "Mga Asistente",
    },
    tabs: {
      myAssistants: "Mga Aking",
      communityAssistants: "Komunidad",
      aizzyAssistants: "Aizzy ",
      createAssistant: "Lumikha ng Assistant",
    },
    myAssistants: {
      "empty-my-assistants": "Waring wala kang asistente pa",
      "create-assistant": "Lumikha ng iyong natatanging asistente ngayon din!",
    },
  },
};
