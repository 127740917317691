export const SectionHeroHomeI18n = {
  "pt-BR": {
    signIn: "Entrar",
    title: "IA Anônima + DeFi em uma plataforma",
    paragraph1:
      "Torne-se um fantasma de IA e proteja seu IP com Aizzy.ai e o token AIZ.",
    paragraph2:
      "Armazene seu material de inferência privadamente e de forma segura, e acesse a IA com apenas uma carteira mantendo suas prompts particulares.",
  },
  "en-US": {
    signIn: "Sign In",
    title: "Anonymous AI + DeFi in one platform",
    paragraph1:
      "Become an AI ghost and protect your IP with Aizzy.ai and the AIZ Token.",
    paragraph2:
      "Store your inference material privately and securely, and access AI with only a wallet keeping your prompts private.",
  },
  "es-ES": {
    signIn: "Iniciar sesión",
    title: "IA Anónima + DeFi en una plataforma",
    paragraph1:
      "Conviértete en un fantasma de IA y protege tu IP con Aizzy.ai y el token AIZ.",
    paragraph2:
      "Almacena tu material de inferencia de forma privada y segura, y accede a la IA con solo una billetera manteniendo tus prompts privados.",
  },
  "fil-PH": {
    signIn: "Mag-log in",
    title: "Anonymous AI + DeFi sa isang platform",
    paragraph1:
      "Maging isang multo ng AI at protektahan ang iyong IP sa Aizzy.ai at ang token AIZ.",
    paragraph2:
      "I-store ang iyong materiyal ng inferensya ng pribado at seguridad, at access ang AI sa tanging isang wallet na tinatago ang iyong mga prompts ng pribado.",
  },
};
