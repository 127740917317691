import { showSuccessNotification } from "../../../helpers/notifications";
import useTranslation from "../../../helpers/useTranslation";
import { MessagesdI18n } from "./ConversationUser.i18n";

interface ButtonCopyMessageProps {
  message: string;
}

export function ButtonCopyMessage({ message }: ButtonCopyMessageProps) {
  const { t } = useTranslation(MessagesdI18n);

  return (
    <button
      style={{
        backgroundColor: "transparent",
        borderStyle: "none",
        outlineStyle: "none",
        color: "inherit",
        width: 16,
        height: 16,
        marginLeft: "auto",
        marginBottom: 0,
        marginTop: 0,
      }}
      onClick={() => {
        navigator.clipboard.writeText(message);
        showSuccessNotification(t("notifications.successCopyClipboard"));
      }}
    >
      <i className="bx bx-copy" />
    </button>
  );
}
