// YoutubeI18n
export const YoutubeI18n = {
  "pt-BR": {
    page: {
      label: "Vídeos do Youtube",
      description:
        "Crie um novo chat para perguntar sobre seu vídeo do Youtube.",
    },
    button: {
      label: "Novo vídeo",
    },
  },
  "en-US": {
    page: {
      label: "Youtube videos",
      description: "Create new chat to ask about your Youtube video.",
    },
    button: {
      label: "New video",
    },
  },
  "es-ES": {
    page: {
      label: "Videos de Youtube",
      description:
        "Crea un nuevo chat para preguntar sobre tu video de Youtube.",
    },
    button: {
      label: "Nuevo video",
    },
  },
  "fil-PH": {
    page: {
      label: "Mga Video sa Youtube",
      description:
        "Gumawa ng bagong chat upang itanong ang iyong Youtube video.",
    },
    button: {
      label: "Bagong video",
    },
  },
};
