import classnames from "classnames";
import { useEffect, useState } from "react";
import { useUser } from "@supabase/auth-helpers-react";
import useTranslation from "../helpers/useTranslation";
import { CommunityAssistantI18n } from "../pages/CommunityAssistants/CommunityAssistant.i18n";
import { FooterCommunityAssistant } from "./FooterCommunityAssistant";
import { htmlDecode } from "../utils/helpers";
import { AssistantDTO } from "../@DTO/assistant";

interface CommunityCharacterItemProps {
  assistant: AssistantDTO;
  onSelectAssistant: (assistant: AssistantDTO) => void;
}

const CommunityCharacterItem = ({
  assistant,
  onSelectAssistant,
}: CommunityCharacterItemProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const { t } = useTranslation(CommunityAssistantI18n);

  const user = useUser();
  const isMine = user?.id === assistant?.userId;

  function reduceRoleAssistant(value: string): string {
    if (value.length >= 60) {
      return value.substring(0, 60) + "...";
    }
    return value;
  }

  function reduceNameAssistant(value: string): string {
    if (value.length >= 40) {
      return value.substring(0, 40) + "...";
    }
    return value;
  }

  useEffect(() => {
    setIsLoaded(true);
  }, [user?.id]);

  return (
    <li
      className={classnames("card-assistants", {
        "card-assistants-loaded": isLoaded,
      })}
      style={{
        maxHeight: 150,
      }}
    >
      <div>
        <div
          onClick={() => onSelectAssistant(assistant)}
          className="d-flex align-items-center gap-2 py-2"
        >
          <div className="flex-shrink-0 me-2">
            <div className="avatar-lg overflow-hidden rounded-1">
              <img
                src={assistant.profileImage}
                alt=""
                className="img-fluid avatar radius-avatar"
              />
            </div>
          </div>
          <div className="flex-grow-1 gap-2 justify-content-between">
            {isMine && (
              <span className="badge p-0 badge-pill badge-primary mb-2 text-dark text-opacity-50">
                {t("card.createdByMe")}
              </span>
            )}
            <p className="font-size-18 m-0" style={{ lineHeight: "110%", fontWeight: 600 }}>
              {reduceRoleAssistant(htmlDecode(assistant?.role))}
            </p>
            <p className="font-size-14 m-0 text-dark text-opacity-75">
              {reduceNameAssistant(htmlDecode(assistant?.name))}
            </p>
          </div>
        </div>
        <FooterCommunityAssistant assistant={assistant} />
      </div>
    </li>
  );
};
interface CommunityCharacterProps {
  assistants: AssistantDTO[] | undefined;
  onSelectAssistant: (assistant: AssistantDTO) => void;
}
const CommunityAssistant = ({
  assistants,
  onSelectAssistant,
}: CommunityCharacterProps) => {
  return (
    <ul className="list-unstyled contact-list px-3" id="contact_list">
      {(assistants || []).map((assistant, key: number) => (
        <CommunityCharacterItem
          assistant={assistant}
          key={key}
          onSelectAssistant={onSelectAssistant}
        />
      ))}
    </ul>
  );
};

export default CommunityAssistant;
