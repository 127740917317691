import { useState } from "react";
import {
  showSuccessNotification,
  showErrorNotification,
} from "../helpers/notifications";
import { useRedux } from "../hooks";
import { changeSelectedChat } from "../redux/actions";
import { ButtonDeleteAllChatsI18n } from "./ButtonDeleteAllChats.i18n";
import useTranslation from "../helpers/useTranslation";
import { deleteAllChats } from "../api";
import { QUERY_KEYS, queryClient } from "../libs/react-query";
import { ChannelDTO } from "../@DTO/channel";
import { ChatRepository } from "../helpers/indexedDb/chat-repository";

interface ButtonDeleteAllChatsProps {
  assistantId?: string;
  isYoutube?: boolean;
  isDocument?: boolean;
  disabled?: boolean;
  collapsed?: boolean;
}

export function ButtonDeleteAllChats({
  assistantId,
  disabled,
  isDocument,
  isYoutube,
  collapsed,
}: ButtonDeleteAllChatsProps) {
  const [isLoading, setIsLoading] = useState(false);

  const { dispatch } = useRedux();

  const chatRepository = new ChatRepository();

  const { t } = useTranslation(ButtonDeleteAllChatsI18n);

  async function handleDeleteAllChats() {
    try {
      setIsLoading(true);

      const confirm = window.confirm(t(`confirm.delete-all-chats`));

      if (confirm) {
        if (assistantId) {
          await deleteAllChats(assistantId, false, false);
        }

        if (isDocument) {
          await deleteAllChats(null, true, false);
        }

        if (isYoutube) {
          await deleteAllChats(null, false, true);
        }

        dispatch(changeSelectedChat(null));
        invalidateQueriesByChannelType(isDocument!, isYoutube!);

        showSuccessNotification(t("notifications.success-delete-all-chats"));

        if (!isDocument && !isYoutube) {
          const chachedChannels = queryClient.getQueryData(
            QUERY_KEYS.CHANNELS
          ) as ChannelDTO[];

          const generalChat = chachedChannels.find(
            channel =>
              channel.channelName === "general" &&
              channel.assistantId === assistantId
          );

          await chatRepository.deleteChatsByAssistantId(
            assistantId!,
            generalChat!._id
          );
        }
      }
    } catch (error: any) {
      showErrorNotification(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function invalidateQueriesByChannelType(
    isDocument: boolean,
    isYoutube: boolean
  ) {
    if (isDocument || isYoutube) {
      await queryClient.invalidateQueries(QUERY_KEYS.DOCUMENTS);
      return;
    }

    const cachedChannels = queryClient.getQueryData<ChannelDTO[]>(
      QUERY_KEYS.CHANNELS
    );

    if (cachedChannels) {
      const channels = cachedChannels.filter(
        channel =>
          channel.channelName === "general" &&
          channel.assistantId === assistantId
      );

      queryClient.setQueryData(QUERY_KEYS.CHANNELS, channels);
    }
  }

  return (
    <>
      {collapsed ? (
        <button
          className="btn fs-4 m-0 p-0 border-0"
          onClick={handleDeleteAllChats}
          disabled={isLoading || disabled}
        >
          {isLoading ? (
            <i className="bx bx-loader-circle bx-spin" />
          ) : (
            <i className="bx bxs-message-square-x" />
          )}
          <p className=" font-size-10">{t("button.delete-all-chats")}</p>
        </button>
      ) : (
        <button
          onClick={handleDeleteAllChats}
          disabled={isLoading || disabled}
          className="w-100 btn border-0 d-flex align-items-center gap-2"
        >
          {isLoading ? (
            <i className="bx bx-loader bx-spin align-middle" />
          ) : (
            <div className="m-0 d-flex align-items-center justify-content-center gap-2 btn-primary button-small button-border-sm">
              <i className="m-0 bx bx-trash" />
            </div>
          )}
          {t("button.delete-all-chats")}
        </button>
      )}
    </>
  );
}
