const ActionButton = ({ onClick, title, classNameBx }) => {
  return (
    <button
      className="btn d-flex align-items-center justify-content-start gap-2"
      onClick={onClick}
    >
      <div className="button-border-sm btn-primary button-small d-flex align-items-center justify-content-center">
        <i className={classNameBx} />
      </div>
      {title}
    </button>
  );
};

export default ActionButton;
