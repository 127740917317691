// interface
import useTranslation from "../../../../helpers/useTranslation";

// component
import VideoLink from "./VideoLink";
import { YoutubeI18n } from "../Youtube.i18n";
import { ButtonDeleteAllChats } from "../../../../components/ButtonDeleteAllChats";
import { SelectedChatDTO } from "../../../../@DTO/selectedChat";
import { ChannelDTO } from "../../../../@DTO/channel";
import ActionButton from "../../../../components/ActionButton";
interface FavouritesProps {
  videos: ChannelDTO[];
  openAddContact: () => void;
  selectedChat: SelectedChatDTO | null;
  onSelectChat: (channel: ChannelDTO) => void;
}
const Documents = ({
  videos,
  openAddContact,
  selectedChat,
  onSelectChat,
}: FavouritesProps) => {
  const { t } = useTranslation(YoutubeI18n);

  return (
    <>
      <div className="p-3 d-flex flex-column gap-2">
        <ActionButton
        onClick={openAddContact}
        title={t("button.label")}
        classNameBx="bx bx-message-add p-2"
        />
        <ButtonDeleteAllChats collapsed={false} isYoutube disabled={videos?.length === 0} />
      </div>

      <div className="chat-message-list px-3">
        <ul className="list-unstyled chat-list chat-user-list px-3 d-flex flex-column gap-2">
          {(videos || []).map((video: any, key: number) => (
            <VideoLink
              user={video}
              key={`${video._id}`}
              selectedChat={selectedChat}
              onSelectChat={onSelectChat}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default Documents;
