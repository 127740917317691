import { useSupabaseClient } from "@supabase/auth-helpers-react";
import i18next from "i18next";
import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import FlagBrSvg from "../assets/images/icons/survey/flag-br.svg";
import FlagEsSvg from "../assets/images/icons/survey/flag-es.svg";
import FlagUsSvg from "../assets/images/icons/survey/flag-eus.svg";

import clsx from "clsx";
import { getBearerToken } from "../helpers/localStorageHelpers";
import { showErrorNotification } from "../helpers/notifications";

export function DropdownLanguageMenu({ state }) {
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const supabaseClient = useSupabaseClient();

  const errorMessage = {
    "pt-BR":
      "Erro ao atualizar idioma, por favor tente novamente ou contate o suporte",
    "en-US": "Error updating language, please try again or contact support.",
    "es-ES":
      "Error al actualizar el idioma, por favor inténtelo de nuevo o póngase en contacto con el soporte",
  };

  const token = getBearerToken();

  const toggle = () => setDropdownOpen(!dropdownOpen);
  const flagImages = {
    "en-US": FlagUsSvg,
    "pt-BR": FlagBrSvg,
    "es-ES": FlagEsSvg,
    // "fil-PH": FlagFilSvg,
  };
  const languages = [
    {
      name: "English",
      code: "en-US",
      image: flagImages["en-US"],
    },
    {
      name: "Português",
      code: "pt-BR",
      image: flagImages["pt-BR"],
    },
    {
      name: "Español",
      code: "es-ES",
      image: flagImages["es-ES"],
    },
    // {
    //   name: "Filipino",
    //   code: "fil-PH",
    //   image: flagImages["fil-PH"],
    // },
  ];

  async function handleUpdateLanguage(language) {
    try {
      setIsLoading(true);
      if (token) {
        await supabaseClient.auth.updateUser({
          data: {
            language: language.code,
          },
        });

        await supabaseClient.auth.refreshSession();
      }

      state(language.code);
      i18next.changeLanguage(language.code);
      if (token) {
        window.location.reload();
      }
    } catch (error: any) {
      console.error(error);
      showErrorNotification(error?.message ?? errorMessage[language.code]);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dropdown
      className="mt-auto mb-1 dropdown-class-name"
      direction="end"
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle nav className={clsx("flag-button d-flex align-items-center justify-content-center")}>
        {isLoading ? (
          <i className="bx bx-loader bx-spin align-middle fs-4"></i>
        ) : (
          <img src={flagImages[i18next.language]} alt="" />
        )}
      </DropdownToggle>
      <DropdownMenu className="p-2">
        {languages.map((language, index) => (
          <DropdownItem
            key={index}
            className="d-flex align-items-center justify-content-between p-1"
            onClick={() => handleUpdateLanguage(language)}
          >
            {language.name}
            <img src={flagImages[language.code]} alt="" />
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
