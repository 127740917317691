import { useNavigate } from "react-router-dom";
import { dataLayerPush } from "../../../utils/gaEvents";
import { Card } from "../../../components/Card";
import { ButtonLink, buttonLinks } from "../buttonLinks";
import { useRedux } from "../../../hooks";
import { changeTab } from "../../../redux/actions";
import { ReactComponent as IconCreateAssistant } from "../../../assets/images/icons/survey/robot-icon.svg";
import { Badge, UncontrolledTooltip } from "reactstrap";
import useTranslation from "../../../helpers/useTranslation";
import { AizzyActionsI18n } from "./AizzyActions.i18n";
import { IconInfoSquareRounded } from "@tabler/icons-react";
import { isMobile } from "../../../utils/helpers";
import { useState } from "react";
import { SectionFeaturesI18n } from "../../../components/SectionFeatures.i18n";
import { SocialCommunity } from "./SocialCommunity";
import { Support } from "./Support";

function ButtonLinkCard({
  item,
  fontSize = 12,
  isActive,
  setActiveCard,
}: {
  item: ButtonLink;
  fontSize?: number;
  isActive: boolean;
  setActiveCard: (id: string | null) => void;
}) {
  const { dispatch } = useRedux();
  const navigate = useNavigate();
  const { t } = useTranslation(AizzyActionsI18n);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const isMobile = window.matchMedia("(max-width: 700px)").matches;

  return (
    <div
      className="card_action"
      id={`assistant-chat-button-${item.id}`}
      onClick={() => {
        dataLayerPush({ event: "click", button_label: item.label });
        navigate(item.navigateUrl, {
          state: {
            target: item,
          },
        });
        item.tabAssistant && dispatch(changeTab(item.tabAssistant));
      }}
      style={{ cursor: "pointer", position: "relative", height: "100%" }}
    >
      {isActive && (
        <>
          <button
            className="btn rounded-circle px-2 py-0"
            style={{
              position: "absolute",
              zIndex: 100,
              top: 5,
              right: 5,
              color: "#000000",
              border: "1px solid #000000",
              fontSize: 13,
            }}
            id={`tooltip-button-${item.id}`}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
          >
            i
          </button>
          <UncontrolledTooltip
            target={`tooltip-button-${item.id}`}
            isOpen={tooltipOpen}
            toggle={() => setTooltipOpen(!tooltipOpen)}
            placement="right"
          >
            {t(item.tooltip as any)}
          </UncontrolledTooltip>
        </>
      )}
      <div className="icon">
        {item.icon ? (
          <IconCreateAssistant
            className="p-0"
            stroke={"#ffffff"}
            fontWeight={200}
          />
        ) : (
          <i
            className={`${item.iconClass}`}
            style={{ fontSize: 46, color: "#ffffff" }}
          />
        )}
      </div>
      <div className="text">
        <h6>{t(item.title as any)}</h6>
        <p className="m-0 font-size-14" style={{ fontSize }}>
          {t(item.label as any)}
        </p>
      </div>
    </div>
  );
}

export function AizzyActions() {
  const [activeCard, setActiveCard] = useState<string | null>(null);
  const { t } = useTranslation(AizzyActionsI18n);

  return (
    <>
      <div className={"group_assistants_home"}>
        <div className="box_text">
          <h2 style={{ marginTop: 20 }}>{t("title")}</h2>
          <h3
            className={
              "mt-5 text-center text-lg-start justify-content-center justify-content-lg-start d-inline-flex"
            }
          >
            {t("about.title")}
            <IconInfoSquareRounded
              color={"#3b3c45"}
              stroke={2}
              className="ms-2 d-inline"
              size={20}
            />
          </h3>
          <p
            className={"text-center mt-1 text-lg-start"}
            style={{ fontSize: 14 }}
          >
            {t("about.description")}
            <a href="/about" className="d-block mt-3">
              <u>{t("buttons.learnMore")}</u>
            </a>
          </p>
          <SocialCommunity
            center={window.matchMedia("(max-width: 992px)").matches}
            className="mt-5"
          />
          <Support
            center={window.matchMedia("(max-width: 992px)").matches}
            className="mt-5"
          />
        </div>
        <div
          className="list-cards ms-lg-4 justify-content-center justify-content-sm-start"
          style={{ marginTop: 20 }}
        >
          {buttonLinks.map((item, index) => (
            <div
              className="p-0 card-main"
              onMouseEnter={() => setActiveCard(item.id)}
              onMouseLeave={() => setActiveCard(null)}
              key={index}
            >
              <ButtonLinkCard
                item={item}
                isActive={isMobile ? true : activeCard === item.id}
                setActiveCard={setActiveCard}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
