import { useNavigate } from "react-router-dom";
import useTranslation from "../../../helpers/useTranslation";
import { AuthHeaderI18n } from "./AuthHeader.i18n";

export function AuthHeader() {
  const { t } = useTranslation(AuthHeaderI18n);

  const navigate = useNavigate();

  function handleGoSignIn() {
    navigate("signin");
  }

  function handleGoSignUp() {
    navigate("signin", {
      state: {
        signUp: true,
      },
    });
  }

  return (
    <header className="w-100 py-3 auth-header position-fixed px-4 d-flex align-items-center justify-content-end gap-3">
      <button onClick={handleGoSignIn} className="btn btn-primary">
        {t("buttons.signIn")}
      </button>
      <button onClick={handleGoSignUp} className="btn btn-outline-primary">
        {t("buttons.signUp")}
      </button>
    </header>
  );
}
