// ModalBuyAssistantI18n
// export const UserHeadI18n = {
//   "pt-BR": {
//     header: {
//       title: "",
//     },
//   },
//   "en-US": {
//     header: {
//       title: "",
//     },
//   },
//   "es-ES": {
//     header: {
//       title: "",
//     },
//   },
//   "fil-PH": {
//     header: {
//       title: "",
//     },
//   },
// };

export const PinnedAlertI18n = {
  "pt-BR": {
    alert: {
      title:
        "Estamos processando seu documento, por favor, aguarde até que ele seja processado para interagir",
    },
  },
  "en-US": {
    alert: {
      title:
        " We are processing your document, please wait until it is processed to interact",
    },
  },
  "es-ES": {
    alert: {
      title:
        "Estamos procesando su documento, por favor espere hasta que sea procesado para interactuar",
    },
  },
  "fil-PH": {
    alert: {
      title:
        "Kami ay nagproseso ng iyong dokumento, mangyaring maghintay hanggang ito ay maisaayos bago makipag-ugnayan",
    },
  },
};
