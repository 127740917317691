export enum JobType {
  Document = "document",
  Website = "website",
  Video = "video",
}

export interface CompressedJobDTO {
  _id: string;
  jobId: string;
  user_id: string;
  channel_id: string;
  processed: boolean;
  status: string;
  document: {
    postgresId: string;
    compressedContent: string;
  };
}

export interface DocumentJobDTO {
  _id: string;
  jobId: string;
  user_id: string;
  channel_id: string;
  processed: boolean;
  type: JobType;
  datetime: number;
  bucket: string;
  key: string;
  status: string;
}

export interface YoutubeJobDTO {
  _id: string;
  jobId: string;
  user_id: string;
  channel_id: string;
  processed: boolean;
  type: JobType;
  url: string;
  datetime: number;
  isVideo: boolean;
  key?: string;
  bucket?: string;
  status: string;
}

export interface WebsiteJobDTO {
  _id: string;
  jobId: string;
  user_id: string;
  channel_id: string;
  processed: boolean;
  type: JobType;
  url: string;
  datetime: number;
  stauts: string;
}
