export const CreateAssistantI18n = {
  "pt-BR": {
    page: {
      label: "Criação de assistente",
      "edit-assistant": "Edição do assistente",
      description:
        "Você pode criar um assistente totalmente personalizado para atender seus requisitos",
      alertPremium:
        "Atenção, a criação de assistentes virtuais está disponível apenas para usuários Premium, para fazer o upgrade do seu plano clique aqui.",
    },
    form: {
      name: "Nome",
      "assistant-image": "Avatar do assistente",
      "assistant-language": "Idioma do assistente",
      role: "Função",
      prompt: "Prompt",
      about: "Sobre",
      "default-greetings": "Saudações padrão",
      translation: "Tradução",
      privateLabelMain: "Controle de visibilidade do Assistente",
      privateLabel: "Privado (Somente você poderá visualizá-lo)",
      publicLabel: "Público (Qualquer usuário poderá visualizá-lo)",
      enabledLabel:
        " Se você desativar essa função o seu assistente não estará disponível para uso",
      enabled: "Habilitado",
      disabled: "Desabilitado",
      price: "Preço USD",
      priceDetails: "(Sua comissão será de 50% do preço para cada venda)",
      priceDescription: "Preço do assistente",
      priceUser: "Valor que você irá receber:",
      priceTooltip:
        "Por exemplo, considerando que o valor do meu Assistente seja de 10 dólares, ao ser vendido, 5 dólares serão destinados a você, enquanto os outros 5 dólares serão para o Aizzy.",
      details: {
        "image-selected": "Avatar selecionado",
      },
      button: {
        save: "Salvar Assistente",
        "add-new-language": "Adicionar novo idioma",
      },
      placeholder: {
        name: "Nome do seu assistente",
        "assistant-language": "Selecionar idioma",
        role: "Função do seu assistente",
        prompt:
          "Descreva detalhadamente quais serão as atividades do seu assistente...",
        about: "Sobre o seu assistente",
        "default-greetings":
          "Descreva quais serão as saudações iniciais do seu assistente...",
      },
      errors: {
        "name-required": "Nome é obrigatório",
        "assistant-image-required": "Imagem do assistente é obrigatória",
        "assistant-language-required": "Idioma do assistente é obrigatório",
        "role-required": "Função é obrigatória",
        "prompt-required": "Prompt é obrigatório",
        "about-required": "Sobre é obrigatório",
      },
    },
    notifications: {
      "without-language":
        "Por favor, selecione um idioma antes de adicionar um novo",
      "created-translation": "Tradução criada com sucesso!",
      "edited-translation": "Tradução editada com sucesso!",
      "deleted-translation": "Tradução deletada com sucesso!",
      "already-exist-translation": "Já existe uma tradução nesse idioma",
      "generating-images":
        "Aguarde, estamos gerando imagens para o seu assistente...",
      "created-assistant":
        "Obrigado por criar seu assistente, veja na galeria!",
      "edited-assistant": "Seu assistente foi editado!",
      "require-image": "Por favor crie uma imagem para o seu assistente",
      "minimum-price": "O preço do seu assistente deve ser no mínimo 5 dólares",
    },
    "modal-translation": {
      label: "Tradução do assistente",
      "edit-assistant": "Editar assistente",
      buttons: {
        close: "Fechar",
        add: "Adicionar",
        edit: "Editar",
      },
    },
  },
  "en-US": {
    page: {
      label: "Assistant Creation",
      "edit-assistant": "Assistant edit",
      description:
        "You can create a fully customized assistant to meet your requirements",
      alertPremium:
        "Attention, the creation of virtual assistants is only available for Premium users, to upgrade your plan click here.",
    },
    form: {
      name: "Name",
      "assistant-image": "Assistant's avatar",
      "assistant-language": "Assistant language",
      role: "Role",
      prompt: "Prompt",
      about: "About",
      "default-greetings": "Default greetings",
      translation: "Translation",
      privateLabelMain: "Assistant Visibility Control",
      privateLabel: "Private (Only you will be able to view it)",
      publicLabel: "Public (Any user will be able to view it)",
      enabledLabel:
        "If you disable this function your assistant will not be available for use",
      enabled: "Enabled",
      disabled: "Disabled",
      price: "Price USD",
      priceDetails: "(Your commission will be 50% of the price for each sale)",
      priceDescription: "Assistant price",
      priceUser: "Amount you will receive:",
      priceTooltip:
        "For example, considering that the value of my Assistant is $10, when sold, $5 will be allocated to you, while the other $5 will go to Aizzy.",
      details: {
        "image-selected": "Avatar selected",
      },
      button: {
        save: "Save Assistant",
        "add-new-language": "Add new language",
      },
      placeholder: {
        name: "Your assistant name",
        "assistant-language": "Select language",
        role: "Your assistant role",
        prompt:
          "Describe in detail what will be the activities of your assistant...",
        about: "About your assistant",
        "default-greetings":
          "Describe what will be the initial greetings of your assistant...",
      },
      errors: {
        "name-required": "Name is required",
        "assistant-image-required": "Assistant image is required",
        "assistant-language-required": "Assistant language is required",
        "role-required": "Role is required",
        "prompt-required": "Prompt is required",
        "about-required": "About is required",
      },
    },
    notifications: {
      "without-language": "Please select a language before add new",
      "created-translation": "Translation created successfully!",
      "edited-translation": "Translation edited successfully!",
      "deleted-translation": "Translation deleted successfully!",
      "already-exist-translation":
        "There is already a translation in that language",
      "generating-images":
        "Please wait, we are generating images for your assistant...",
      "created-assistant":
        "Thank you for creating your assistant, check it out in the gallery!",
      "edited-assistant": "Your assistant has been edited!",
      "require-image": "Please create an image for your assistant.",
      "minimum-price": "The price of your assistant must be at least 5 dollars",
    },
    "modal-translation": {
      label: "Assistant's translation",
      buttons: {
        close: "Close",
        add: "Add",
        edit: "Edit",
      },
    },
  },
  "es-ES": {
    page: {
      label: "Creación de asistente",
      "edit-assistant": "Edición del Asistente",
      description:
        "Puedes crear un asistente completamente personalizado para cumplir con tus requisitos",
      alertPremium:
        "Atención, la creación de asistentes virtuales solo está disponible para usuarios Premium, para actualizar tu plan haz clic aquí.",
    },
    form: {
      name: "Nombre",
      "assistant-image": "Avatar del asistente",
      "assistant-language": "Idioma del asistente",
      role: "Rol",
      prompt: "Prompt",
      about: "Acerca de",
      "default-greetings": "Saludos predeterminados",
      translation: "Traducción",
      privateLabelMain: "Control de Visibilidad del Asistente",
      privateLabel: "Privado (Solo tú podrás verlo)",
      publicLabel: "Público (Cualquier usuario podrá verlo)",
      enabledLabel:
        "Si desactiva esta función su asistente no estará disponible para su uso",
      enabled: "Habilitado",
      disabled: "Deshabilitado",
      price: "Precio USD",
      priceDetails: "(Tu comisión será del 50% del precio por cada venta)",
      priceDescription: "Precio del asistente",
      priceUser: "La cantidad que recibirás:",
      priceTooltip:
        "Por ejemplo, considerando que el valor de mi Asistente es de 10 dólares, al ser vendido, 5 dólares se destinarán a usted, mientras que los otros 5 dólares serán para Aizzy.",
      details: {
        "image-selected": "Avatar seleccionado",
      },
      button: {
        save: "Guardar Asistente",
        "add-new-language": "Agregar nuevo idioma",
      },
      placeholder: {
        name: "Nombre de tu asistente",
        "assistant-language": "Seleccionar idioma",
        role: "Rol de tu asistente",
        prompt:
          "Describe en detalle cuáles serán las actividades de tu asistente...",
        about: "Acerca de tu asistente",
        "default-greetings":
          "Describe cuáles serán los saludos iniciales de tu asistente...",
      },
      errors: {
        "name-required": "El nombre es obligatorio",
        "assistant-image-required": "Se requiere una imagen del asistente",
        "assistant-language-required": "Se requiere el idioma del asistente",
        "role-required": "Se requiere el rol",
        "prompt-required": "Se requiere un prompt",
        "about-required": "Se requiere información sobre el asistente",
      },
    },
    notifications: {
      "without-language":
        "Por favor selecciona un idioma antes de agregar uno nuevo",
      "created-translation": "¡Traducción creada exitosamente!",
      "edited-translation": "¡Traducción editada exitosamente!",
      "deleted-translation": "¡Traducción eliminada exitosamente!",
      "already-exist-translation": "Ya existe una traducción en ese idioma",
      "generating-images":
        "Por favor espera, estamos generando imágenes para tu asistente...",
      "created-assistant":
        "Gracias por crear tu asistente, ¡compruébalo en la galería!",
      "edited-assistant": "Tu asistente ha sido editado!",
      "require-image": "Por favor crea una imagen para tu asistente",
      "minimum-price":
        "El precio de tu asistente debe ser de al menos 5 dólares",
    },
    "modal-translation": {
      label: "Traducción del asistente",
      buttons: {
        close: "Cerrar",
        add: "Agregar",
        edit: "Editar",
      },
    },
  },
  "fil-PH": {
    page: {
      label: "Paggawa ng Assistant",
      "edit-assistant": "Edita ang Assistant",
      description:
        "Maaari kang lumikha ng ganap na pina-personal na assistant na maayon sa iyong mga kinakailangan",
      alertPremium:
        "Paalala, ang paglikha ng mga virtual na assistant ay magagamit lamang para sa mga Premium na user, upang mag-upgrade ng iyong plano, i-click dito.",
    },
    form: {
      name: "Pangalan",
      "assistant-image": "Avatar ng Assistant",
      "assistant-language": "Wika ng Assistant",
      role: "Papel",
      prompt: "Prompt",
      about: "Tungkol sa",
      "default-greetings": "Default na pagbati",
      translation: "Pagsasalin",
      privateLabelMain: "Control ng Visibility ng Assistant",
      privateLabel: "Pribado (Tanging ikaw lamang ang makakakita nito)",
      publicLabel: "Pampubliko (Makikita ito ng anumang user)",
      enabledLabel:
        "Kung i-disable mo ang function na ito, ang iyong assistant ay hindi magiging magagamit para sa paggamit",
      enabled: "Pinagana",
      disabled: "Hindi pinagana",
      price: "Presyo USD",
      priceDetails: "(Ang iyong komisyon ay 50% ng presyo para sa bawat benta)",
      priceDescription: "Presyo ng Assistant",
      priceUser: "Halaga na matatanggap mo:",
      priceTooltip:
        "Halimbawa, kung ang halaga ng aking Assistant ay $10, kapag ito'y binebenta, $5 ay ibinabayad sa iyo, habang ang iba pang $5 ay mapupunta sa Aizzy.",
      details: {
        "image-selected": "Piniling Avatar",
      },
      button: {
        save: "I-save ang Assistant",
        "add-new-language": "Magdagdag ng Bagong Wika",
      },
      placeholder: {
        name: "Pangalan ng iyong Assistant",
        "assistant-language": "Pumili ng Wika",
        role: "Papel ng iyong Assistant",
        prompt: "Ilarawan nang maayos ang mga gagawin ng iyong Assistant...",
        about: "Tungkol sa iyong Assistant",
        "default-greetings":
          "Ilarawan kung ano ang mga pangunahing pagbati ng iyong Assistant...",
      },
      errors: {
        "name-required": "Ang pangalan ay kinakailangan",
        "assistant-image-required": "Kinakailangan ang imahe ng Assistant",
        "assistant-language-required": "Kinakailangan ang wika ng Assistant",
        "role-required": "Kinakailangan ang papel",
        "prompt-required": "Kinakailangan ang Prompt",
        "about-required": "Kinakailangan ang Tungkol sa",
      },
    },
    notifications: {
      "without-language": "Mangyaring pumili ng wika bago magdagdag ng bago",
      "created-translation": "Tagumpay na nilikha ang Pagsasalin!",
      "edited-translation": "Tagumpay na inedit ang Pagsasalin!",
      "deleted-translation": "Tagumpay na ini-delete ang Pagsasalin!",
      "already-exist-translation":
        "Mayroon nang isang pagsasalin sa wikang iyon",
      "generating-images":
        "Mangyaring maghintay, ginagawa namin ang mga imahe para sa iyong Assistant...",
      "created-assistant":
        "Salamat sa paglikha ng iyong Assistant, tingnan ito sa gallery!",
      "edited-assistant": "Naedit na ang iyong Assistant!",
      "require-image": "Mangyaring lumikha ng imahe para sa iyong Assistant",
      "minimum-price":
        "Ang presyo ng iyong Assistant ay dapat na hindi bababa sa $5",
    },
    "modal-translation": {
      label: "Pagsasalin ng Assistant",
      buttons: {
        close: "Isara",
        add: "Idagdag",
        edit: "I-edit",
      },
    },
  },
};

export const ModalImagesI18n = {
  "pt-BR": {
    modal: {
      label: "Escolha uma imagem para o seu assistente",
      loading: "Gerando imagens...",
    },
    button: {
      close: "Fechar",
      "create-image": "Criar imagem",
      "create-another": "Criar outra",
      recreate: "Recriar",
    },
    error_on_generate: "Erro ao gerar imagem",
  },
  "en-US": {
    modal: {
      label: "Choose an image for your assistant",
      loading: "Generating images...",
    },
    button: {
      close: "Close",
      "create-image": "Create image",
      "create-another": "Create another",
      recreate: "Recreate",
    },
    error_on_generate: "Error on generate image",
  },
  "es-ES": {
    modal: {
      label: "Elige una imagen para tu asistente",
      loading: "Generando imágenes...",
    },
    button: {
      close: "Cerrar",
      "create-image": "Crear imagen",
      "create-another": "Crear otro",
      recreate: "Recrear",
    },
    error_on_generate: "Error al generar imagen",
  },
  "fil-PH": {
    modal: {
      label: "Pumili ng larawan para sa iyong assistant",
      loading: "Nag-gegenerate ng mga imahe...",
    },
    button: {
      close: "Isara",
      "create-image": "Gumawa ng larawan",
      "create-another": "Gumawa ng isa pang",
      recreate: "Gawing muli",
    },
    error_on_generate: "Error sa pag-generate ng larawan",
  },
};

export const TranslationCardI18n = {
  "pt-BR": {
    card: {
      title: "Idioma",
    },
    form: {
      role: "Função",
      prompt: "Prompt",
      about: "Sobre",
      greetings: "Saudações",
    },
    button: {
      edit: "Editar",
      delete: "Excluir",
    },
    alert: "Tem certeza que deseja excluir essa tradução?",
  },
  "en-US": {
    card: {
      title: "Language",
    },
    form: {
      role: "Role",
      prompt: "Prompt",
      about: "About",
      greetings: "Greetings",
    },
    button: {
      edit: "Edit",
      delete: "Delete",
    },
    alert: "Are you sure you want to delete this translation?",
  },
  "es-ES": {
    card: {
      title: "Idioma",
    },
    form: {
      role: "Rol",
      prompt: "Prompt",
      about: "Acerca de",
      greetings: "Saludos",
    },
    button: {
      edit: "Editar",
      delete: "Borrar",
    },
    alert: "¿Está seguro de que desea eliminar esta traducción?",
  },
  "fil-PH": {
    card: {
      title: "Wika",
    },
    form: {
      role: "Papel",
      prompt: "Prompt",
      about: "Tungkol sa",
      greetings: "Mga Bati",
    },
    button: {
      edit: "I-edit",
      delete: "Tanggalin",
    },
    alert: "Sigurado ka bang nais mong tanggalin ang pagsasalin wika na ito?",
  },
};
