export const DropdownShareAssistantI18n = {
  "pt-BR": {
    button: {
      "copy-link": "Copiar link do assistente",
      "share-wpp": "Compartilhar no WhatsApp",
    },
    messageWhatsapp:
      "Explore a plataforma Aizzy e desfrute da oportunidade de interagir com este assistente incrível criado por nossa comunidade.",
    messagwWhatsappOwn:
      "Explore a plataforma Aizzy e desfrute da oportunidade de interagir com este meu assistente incrível.",
    notifications: {
      "copy-link": "Link do assistente copiado com sucesso",
    },
  },
  "en-US": {
    button: {
      "copy-link": "Copy assistant link",
      "share-wpp": "Share on WhatsApp",
    },
    messageWhatsapp:
      "Explore the Aizzy platform and enjoy the opportunity to interact with this amazing assistant created by our community.",
    messagwWhatsappOwn:
      "Explore the Aizzy platform and enjoy the opportunity to interact with this amazing assistant of mine.",
    notifications: {
      "copy-link": "Assistant link successfully copied",
    },
  },
  "es-ES": {
    button: {
      "copy-link": "Copiar enlace de lo asistente",
      "share-wpp": "Compartir en WhatsApp",
    },
    messageWhatsapp:
      "Explora la plataforma Aizzy y disfruta de la oportunidad de interactuar con este asistente increíble creado por nuestra comunidad.",
    messagwWhatsappOwn:
      "Explora la plataforma Aizzy y disfruta de la oportunidad de interactuar con este increíble asistente mío.",
    notifications: {
      "copy-link": "Enlace de lo asistente copiado correctamente",
    },
  },
  "fil-PH": {
    button: {
      "copy-link": "Kopyahin ang link ng assistant",
      "share-wpp": "Ibahagi sa WhatsApp",
    },
    messageWhatsapp:
      "Tuklasin ang plataporma ng Aizzy at tamasahin ang pagkakataon na makipag-ugnayan sa kamangha-manghang assistant na ito na likha ng aming komunidad.",
    messagwWhatsappOwn:
      "Tuklasin ang plataporma ng Aizzy at tamasahin ang pagkakataon na makipag-ugnayan sa kamangha-manghang assistant na ito sa akin.",
    notifications: {
      "copy-link": "Matagumpay na kinopya ang link ng assistant",
    },
  },
};
