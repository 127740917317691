import React from "react";
import Logo from "./Logo";

interface AuthHeaderProps {
  title?: string;
  subtitle?: string;
  logo?: boolean;
}
const AuthHeader = ({ title, subtitle, logo }: AuthHeaderProps) => {
  return (
    <div className="text-center">
      {logo && <Logo />}
      {title && <h3>{title}</h3>}
      {subtitle && <p className="text-muted">{subtitle}</p>}
    </div>
  );
};

export default AuthHeader;
