export const SurveyNPSI18n = {
  "pt-BR": {
    cta: {
      title: "Sua experiência é muito importante para nós!",
      description:
        "Queremos garantir que sua interação com a plataforma seja a melhor possível. Agradecemos por dedicar alguns momentos para nos fornecer seu feedback!",
      button: "Enviar feedback",
    },
    title: "Como você classificaria sua experiência?",
    textareaPlaceholder: "Conte-nos sobre sua experiência usando AIZZY...",
    disclaimer:
      "⚠️ Atenção! Isso é apenas para feedback e não é monitorado pelo Suporte.",
    send: "Enviar",
    thankyou: "Obrigado pelo seu feedback!",
  },
  "en-US": {
    cta: {
      title: "Your experience is very important to us!",
      description: "We want to ensure that your interaction with the platform is the best possible. Thank you for taking a few moments to provide us with your feedback!",
      button: "Send feedback",
    },
    title: "How would you rate your experience?",
    textareaPlaceholder: "Tell us about your experience with AIZZY...",
    disclaimer:
      "⚠️ Heads up! This is for feedback only and not monitored by Support.",
    send: "Send",
    thankyou: "Thank you for your feedback!",
  },
  "es-ES": {
    cta: {
      title: "¡Tu experiencia es muy importante para nosotros!",
      description: "Queremos asegurarnos de que tu interacción con la plataforma sea la mejor posible. ¡Gracias por dedicar unos momentos para darnos tu opinión!",
      button: "Enviar opinión",
    },
    title: "¿Cómo calificarías tu experiencia?",
    textareaPlaceholder: "Cuéntanos sobre tu experiencia con AIZZY...",
    disclaimer:
      "⚠️ ¡Atención! Esto es solo para comentarios y no es monitoreado por Soporte.",
    send: "Enviar",
    thankyou: "¡Gracias por tu opinión!",
  },
};
