import classnames from "classnames";
import { ReactComponent as AizzyLogo } from "../../../assets/images/logo.svg";
import MarkdownRenderer from "../../../components/MarkdownRenderer";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../helpers/notifications";
import useTranslation from "../../../helpers/useTranslation";
import { MessagesdI18n } from "./ConversationUser.i18n";

import { deleteAssistantMessage } from "../../../api";
import { useRedux } from "../../../hooks";
import { ButtonCopyMessage } from "./ButtonCopyMessage";
import { SelectedChatDTO } from "../../../@DTO/selectedChat";
import { memo, useEffect, useState } from "react";
import { AssistantDTO } from "../../../@DTO/assistant";
import { ChatData } from "./types/ChatData";
import { TABS } from "../../../constants";
import { useChatStore } from "../../../state/chat";
import { isMobile } from "../../../utils/helpers";
import {
  ChatRepository,
  makeChatId,
} from "../../../helpers/indexedDb/chat-repository";

interface MessageProps {
  message: ChatData;
}

const Message = ({ message }: MessageProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { removeMessage, isReceivingMessage } = useChatStore();

  const { useAppSelector } = useRedux();

  const { assistant, activeTab, selectedChat } = useAppSelector(state => ({
    selectedChat: state.Chats.selectedChat as SelectedChatDTO,
    assistant: state.Chats.assistant as AssistantDTO,
    activeTab: state.Layout.activeTab,
  }));

  const chatRepository = new ChatRepository();

  const { t } = useTranslation(MessagesdI18n);

  const blockDeleteMessage =
    assistant?.userId && assistant?.userPrice > 0 && !assistant?.hired;

  async function handleDeleteMessage(messageId: string) {
    if (isReceivingMessage) return;
    const confirmed = window.confirm(t("confirm"));
    if (confirmed) {
      try {
        setIsLoading(true);

        if (selectedChat?.isDocument || selectedChat?.isYoutube) {
          await deleteAssistantMessage(messageId);
        }

        if (!selectedChat?.isDocument) {
          await chatRepository.deleteMessageById(
            makeChatId(
              selectedChat?._id,
              selectedChat?.assistantId,
              selectedChat?.userId
            ),
            messageId
          );
        }

        removeMessage(messageId);

        showSuccessNotification(t("notifications.successDeleteMessage"));
      } catch (e: any) {
        showErrorNotification(e);
      } finally {
        setIsLoading(false);
      }
    }
  }

  if (
    (activeTab === TABS.DOCUMENTS || activeTab === TABS.YOUTUBE) &&
    message?.chat_response?.role === "internal"
  ) {
    return <></>;
  }

  if (message?.chat_response?.content === "" && message.channelId) {
    return null;
  }

  return (
    <>
      <div
        className={classnames("chat-list mb-4")}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          className="conversation-list rounded-2"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {message?.chat_request && (
            <div
              className="p-3 gap-4"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div className=" align-self-start">
                <span
                  className="fw-bold"
                  style={{
                    fontSize: 18,
                  }}
                >
                  {selectedChat?.members?.length > 1
                    ? message?.userEmail?.split("@")[0]
                    : t("chat.user")}
                </span>
              </div>
              <div className="col d-flex align-items-center justify-content-between request-message">
                <MarkdownRenderer
                  className="mb-0 markdown"
                  content={message?.chat_request}
                />
              </div>
              <div className="">
                <div className="d-flex align-items-center gap-2">
                  <ButtonCopyMessage message={message?.chat_request} />
                </div>
              </div>
            </div>
          )}

          <section
            className="d-flex flex-column flex-md-row p-3"
            style={{
              gap: isMobile ? 12 : 32,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
            }}
          >
            <div className="d-flex flex-row flex-md-column justify-content-between align-items-center gap-3 text-center pb-3 pb-md-1">
              <AizzyLogo width={24} height={24} />

              {isMobile && (
                <div className="d-flex gap-3 font-size-16">
                  <ButtonCopyMessage
                    message={message?.chat_response?.content}
                  />
                  {!blockDeleteMessage && (
                    <button
                      style={{
                        backgroundColor: "transparent",
                        borderStyle: "none",
                        outlineStyle: "none",
                        color: "inherit",
                        width: 16,
                        height: 16,
                        marginLeft: "auto",
                      }}
                      onClick={() => handleDeleteMessage(message._id)}
                      disabled={isLoading || isReceivingMessage}
                    >
                      {isLoading ? (
                        <i className="bx bx-loader bx-spin" />
                      ) : (
                        <i className="bx bx-message-square-x" />
                      )}
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="col">
              {!message?.chat_response?.content && (
                <i className="bx bx-loader-circle bx-spin" />
              )}

              {message?.chat_response?.content !== "" && (
                <MarkdownRenderer
                  className="mb-0 markdown"
                  content={message?.chat_response?.content as string}
                />
              )}
            </div>
            {!isMobile && (
              <div className="col-auto">
                {message?.chat_response?.content && (
                  <div className="d-flex align-items-center gap-2">
                    <ButtonCopyMessage
                      message={message?.chat_response?.content}
                    />
                    {!blockDeleteMessage && (
                      <button
                        style={{
                          backgroundColor: "transparent",
                          borderStyle: "none",
                          outlineStyle: "none",
                          color: "inherit",
                          width: 16,
                          height: 16,
                          marginLeft: "auto",
                        }}
                        onClick={() => handleDeleteMessage(message._id)}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <i className="bx bx-loader bx-spin" />
                        ) : (
                          <i className="bx bx-message-square-x" />
                        )}
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </section>
        </div>
      </div>
    </>
  );
};

export default memo(Message);
