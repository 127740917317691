import { useSession } from "@supabase/auth-helpers-react";
import { nanoid } from "nanoid";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { showWarningNotification } from "../../../helpers/notifications";
import useTranslation from "../../../helpers/useTranslation";
import { useRedux } from "../../../hooks";
import useChatWebSocket from "../../../hooks/useChatWebSocket";
import RemainingCallsContext from "../../../freemium/RemainingCallsContext";
import { SelectedChatDTO } from "../../../@DTO/selectedChat";
import { CreateNewImageModalI18n } from "./CreateNewImageModal.i18n";
import { SliderSelectRatio } from "./SliderSelectRatio";
import { useChatStore } from "../../../state/chat";
import { getCurrentUserPlan } from "../../../helpers/userHelper";
import { PLANS_AIZZY } from "../../../helpers/constants/Plans";
import { QUERY_KEYS, queryClient } from "../../../libs/react-query";

export function CreateNewImageModal() {
  const [open, setOpen] = useState(false);

  const [prompt, setPrompt] = useState("");
  const [selectedRatio, setSelectedRatio] = useState("896x1152");

  const { setIsGeneratingImage } = useChatStore();

  const isGeneratingImage = useChatStore(state => state.isGeneratingImage);

  const session = useSession();

  const {
    updateRemainingCallsByAction,
    checkRemainingCallsMessage,
    isCheckingRemainingCallsMessage,
    remainingImages,
    setShowFreemiumModal,
  } = useContext(RemainingCallsContext);

  const { useAppSelector } = useRedux();
  const { selectedChat } = useAppSelector(state => ({
    selectedChat: state.Chats.selectedChat as SelectedChatDTO,
  }));

  const isPremiumUser = getCurrentUserPlan() === PLANS_AIZZY.PREMIUM;

  const { sendWebsocketMessage } = useChatWebSocket();

  const { t } = useTranslation(CreateNewImageModalI18n);

  function onSend() {
    if (isCheckingRemainingCallsMessage) return;

    if (!prompt) {
      return showWarningNotification(
        "Por favor, descreva uma imagem antes de criar"
      );
    }

    const superChatAssistantId = "646d98a9440f2a9bf026d4b2";

    const messageId = nanoid();
    const message = JSON.stringify({
      route: "createChatMessageWithStream",
      body: {
        message: prompt && dataTextFormatted(prompt),
        assistantId: superChatAssistantId,
        channelId: selectedChat._id,
        messageId,
        selectedRatio: selectedRatio,
      },
      jwt: session?.access_token,
    });

    sendWebsocketMessage(message);

    setIsGeneratingImage(true);

    updateRemainingCallsByAction("remainingImages");
  }

  function dataTextFormatted(text: string) {
    if (text && !text.includes("/create")) {
      return `/create ${text}`;
    }
    return text;
  }

  function handleExamplePrompt() {
    setPrompt(
      "Glamour shot of queen of the damned a femme fatale, morbide, dark, very detailed, wet, dense atmospheric, epic, dramatic, empty, creepy, photorealistic, hyperornate details, photographed by Irakli Nadar and Reylia Slaby, intricate details, sharp focus"
    );
  }

  useEffect(() => {
    if (!isGeneratingImage) {
      setOpen(false);
      setPrompt("");
      queryClient.refetchQueries(QUERY_KEYS.IMAGES_CHANNEL);
    }
  }, [isGeneratingImage]);

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        name="open-modal"
        className="btn align-items-center btn-create-image"
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <div className="btn-primary button-border-sm d-flex button-small align-items-center justify-content-center">
          <i className="bx bx-image-add" />{" "}
        </div>
        <h6 className="m-0"> {t("button.create-image")}</h6>
      </button>

      <Modal
        isOpen={open}
        toggle={() => setOpen(false)}
        tabIndex={-1}
        centered
        scrollable
        id="addgroup-exampleModal"
        role="dialog"
        backdrop="static"
      >
        <ModalHeader
          toggle={() => setOpen(false)}
          className="modal-title-custom"
        >
          {t("page.label")}
        </ModalHeader>

        <ModalBody className="p-4 d-flex flex-column align-items-center">
          <div className="mb-4 w-100">
            <Input
              type="textarea"
              className="input-image"
              id="chat-input"
              placeholder={t("input.placeholder")}
              onChange={e => setPrompt(e.target.value)}
              autoComplete="off"
              value={prompt}
              autoFocus={true}
              disabled={isGeneratingImage}
              rows={1}
              onKeyUp={(evt: any) => {
                evt.target.style.height = "1px";
                evt.target.style.height = evt.target.scrollHeight + "px";
              }}
              onKeyDown={(evt: any) => {
                if (evt.keyCode === 13 && !evt.shiftKey) {
                  evt.preventDefault();
                  onSend();
                }
              }}
            />
          </div>
          <button
            className="btn btn-sm btn-outline-secondary m-auto mb-3"
            onClick={handleExamplePrompt}
            disabled={isGeneratingImage}
          >
            {t("button.example")}
          </button>
          <div className="w-100 border-top border-light pt-3">
            <SliderSelectRatio
              selectedRatio={selectedRatio}
              setSelectedRatio={setSelectedRatio}
              disabled={isGeneratingImage}
            />
          </div>
        </ModalBody>
        {!isGeneratingImage && (
          <ModalFooter>
            <button
              type="button"
              className="btn border-0 d-flex gap-3 align-items-center"
              name="create-image"
              disabled={!prompt || isCheckingRemainingCallsMessage}
              onClick={() => {
                if (remainingImages === 0 && !isPremiumUser) {
                  setShowFreemiumModal("remainingImages");
                  return;
                }

                checkRemainingCallsMessage(onSend, "remainingImages");
              }}
            >
              <div className="btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
                <i className="bx bx-image-add" />
              </div>
              {t("button.create")}
            </button>
          </ModalFooter>
        )}
        {isGeneratingImage && (
          <div className="row g-3 d-flex justify-content-center">
            <div className="col-12 text-center">
              <i className="bx bx-loader bx-spin align-middle text-primary"></i>{" "}
              <h6 className="mb-3">{t("page.processingImageTitle")}</h6>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
