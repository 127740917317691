export const ImagesListI18n = {
  "pt-BR": {
    emptyImages: {
      message: "Atualmente você está sem nenhuma imagem criada...",
    },
  },
  "en-US": {
    emptyImages: {
      message: "Currently you have no created images...",
    },
  },
  "es-ES": {
    emptyImages: {
      message: "Actualmente no tienes ninguna imagen creada...",
    },
  },
  "fil-PH": {
    emptyImages: {
      message: "Sa ngayon wala kang nilikhang larawan...",
    },
  },
};
