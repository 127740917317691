import { useEffect } from "react";

import "./assets/scss/theme.scss";
import ReferralChecker from "./components/ReferralChecker";
import Routes from "./routes";
import { ThirdwebProvider } from "thirdweb/react";
import { useUserStore } from "./state/user";
import { getBearerToken } from "./helpers/localStorageHelpers";

const App = () => {
  const { setIsLogged } = useUserStore();

  const token = getBearerToken();

  useEffect(() => {
    if (token) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }, [token]);

  // useEffect(() => {
  //   var getLayoutMode = localStorage.getItem("layoutMode");
  //   if (getLayoutMode) {
  //     dispatch(changelayoutMode(getLayoutMode));
  //   } else {
  //     dispatch(changelayoutMode(layoutMode));
  //   }
  // }, [layoutMode, dispatch]);

  return (
    <ThirdwebProvider>
      <ReferralChecker>
        <Routes />
      </ReferralChecker>
    </ThirdwebProvider>
  );
};

// force deploy

export default App;
