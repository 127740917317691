import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface ImageCardSkeletonProps {
  quantity?: number;
}

export function ImageCardSkeleton({ quantity = 20 }: ImageCardSkeletonProps) {

  const heights = [
    200, 300, 150, 200, 300, 150, 300, 350, 350, 200, 200, 300, 150, 200, 300,
    150, 300, 350, 350, 200,
  ];

  function getRandomHeight(index: number) {
    return heights[index];
  }

  return (
    <ul className="grid-images list-unstyled px-3">
      {[...Array(quantity)].map((_, index) => (
        <li key={index}>
          <Skeleton
            height={getRandomHeight(index)}
            baseColor={"#e4e4e7"}
            highlightColor={"#F1F1F1"}
            duration={1}
            className="mb-2"
          />
        </li>
      ))}
    </ul>
  );
}
