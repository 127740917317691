// costants
import { TABS, TABS_VALUES } from "../../constants/index";
import { SUPER_CHAT_ID } from "../../utils/assistants";

export interface MenuItemType {
  id: number;
  key: string;
  icon: string;
  tooltipTitle: string;
  className?: string;
  tabId: TABS_VALUES;
  navigate: string;
  description: string;
}

const MENU_ITEMS: MenuItemType[] = [
  {
    id: 1,
    key: "pills-super-chat",
    icon: "bx bxs-message",
    className: "mt-md-3 pills-super-chat",
    tooltipTitle: "Chat",
    tabId: TABS.ASSISTANT_CHATS,
    navigate: `/dashboard?assistantId=${SUPER_CHAT_ID}`,
    description: "Chat",
  },
  {
    id: 2,
    key: "pills-assistants",
    className: "pills-assistants",
    icon: "bx bxs-group",
    tooltipTitle: "Assistants",
    tabId: TABS.ASSISTANT,
    navigate: "/dashboard",
    description: "Assistants",
  },
  {
    id: 3,
    key: "pills-document-tab",
    className: "pills-document-tab",
    icon: "bx bxs-file-doc",
    tooltipTitle: "PDF",
    tabId: TABS.DOCUMENTS,
    navigate: "/dashboard",
    description: "Documents",

  },
  {
    id: 4,
    key: "pills-youtube-tab",
    className: "pills-youtube-tab",
    icon: "bx bxl-youtube",
    tooltipTitle: "Youtube Vídeos",
    tabId: TABS.YOUTUBE,
    navigate: "/dashboard",
    description: "Youtube",
  },
  {
    id: 5,
    key: "pills-image-tab",
    className: "pills-image-tab",
    icon: "bx bx-images",
    tooltipTitle: "Images",
    tabId: TABS.IMAGES,
    navigate: "/images",
    description: "Images",
  },
  // {
  //   id: 5,
  //   key: "pills-wordpress",
  //   icon: "bx bxl-wordpress",
  //   tooltipTitle: "Plugin with WordPress",
  //   tabId: TABS.WORDPRESS,
  //   navigate: "/wordpress",
  // },
  {
    id: 6,
    key: "pills-plans",
    className: "pills-plans",
    icon: "bx bxs-crown side-menu-core-premium active-premium",
    tooltipTitle: "Go Premium",
    tabId: TABS.PLANS,
    navigate: "/plans",
    description: "Premium",
  },
];

export { MENU_ITEMS };
