import { useEffect, useState } from "react";

import { showErrorNotification } from "../../../helpers/notifications";
import { StatusBadge } from "./StatusBadge";
import { getCurrentUserLanguage } from "../../../helpers/userHelper";
import { JobMetadata } from "./JobMetadata";
import InfoModal from "../../../components/modal/ModalFiles";

const DocumentCard = ({ item, onDeleteJob, getDocumentContent }) => {
  const [deleteJobLoading, setDeleteJobLoading] = useState(false);
  const [hasDocument, setHasDocument] = useState(true);
  const [modalState, setModalState] = useState({
    isOpen: false,
    message: "",
    title: "",
    closeButtonText: "",
  });

  const userLanguage = getCurrentUserLanguage();

  const documentName =
    item.filename === ""
      ? reduceString(
          item?.key?.replace(`${item.user_id}/${item.channel_id}/`, "")
        )
      : item.filename;

  async function handleDeleteJob(id: string) {
    try {
      setDeleteJobLoading(true);
      await onDeleteJob(id);
    } catch (error: any) {
      showErrorNotification(error.message);
    } finally {
      setDeleteJobLoading(false);
    }
  }

  async function verifyDocument() {
    try {
      const content = await getDocumentContent(item._id);

      if (
        (!content?.document || content?.document?.length === 0) &&
        item.processed
      ) {
        const messages = {
          pt: {
            message: `O conteúdo do documento <strong>${documentName}</strong> não foi encontrado nesse dispositivo ou browser. Por favor, envie o documento novamente para perguntar sobre ele.`,
            title: "Aviso",
            closeButton: "Fechar",
          },
          en: {
            message: `The document <strong>${documentName}</strong> content was not found on this device or browser. Please upload the document again to ask questions about it.`,
            title: "Warning",
            closeButton: "Close",
          },
          es: {
            message: `El contenido del documento <strong>${documentName}</strong> no se encontró en este dispositivo o navegador. Por favor, suba el documento nuevamente para hacer preguntas sobre él.`,
            title: "Aviso",
            closeButton: "Cerrar",
          },
        };

        setModalState({
          isOpen: true,
          message: messages[userLanguage].message,
          title: messages[userLanguage].title,
          closeButtonText: messages[userLanguage].closeButton,
        });

        setHasDocument(false);
      }
    } catch (error: any) {
      showErrorNotification(error.message);
    }
  }

  function reduceString(str: string) {
    if (str?.length > 25) {
      return str.substring(0, 25) + "...";
    }
    return str;
  }

  useEffect(() => {
    verifyDocument();
  }, []);

  const toggleModal = () =>
    setModalState(prevState => ({ ...prevState, isOpen: !prevState.isOpen }));

  return (
    <div className="card mb-2  mt-1" key={item._id}>
      <div className="card-body py-3 px-0 pe-3">
        <div className="d-flex align-items-center justify-content-between gap-2">
          <div className="flex-grow-1">
            <h6>{documentName}</h6>
            {hasDocument ? (
              <div className="d-flex align-items-center justify-content-between">
                <StatusBadge item={item} />
                {item.processed && <JobMetadata job={item} />}
              </div>
            ) : (
              <span
                className="badge text-black"
                style={{
                  backgroundColor: "#fbbf24",
                }}
              >
                {userLanguage === "pt"
                  ? "Documento não encontrado"
                  : userLanguage === "en"
                  ? "Document not found"
                  : "Documento no encontrado"}
              </span>
            )}
          </div>
        </div>
      </div>

      <button
        className="btn p-0 position-absolute"
        style={{ top: 8, right: 8 }}
        disabled={deleteJobLoading}
        onClick={() => handleDeleteJob(item._id)}
      >
        {deleteJobLoading ? (
          <i className="bx bx-loader bx-spin align-middle"></i>
        ) : (
          <i className="bx bx-x fs-4"></i>
        )}
      </button>

      <InfoModal
        isOpen={modalState.isOpen}
        toggle={toggleModal}
        message={modalState.message}
        title={modalState.title}
        closeButtonText={modalState.closeButtonText}
      />
    </div>
  );
};

export default DocumentCard;
