import classnames from "classnames";
import { getInitials } from "../../../utils/stringutils";
import useTranslation from "../../../helpers/useTranslation";
import { AssistantsChatsI18n } from "./AssistantsChats.i18n";
import { DropdownShareAssistant } from "../../../components/DropdownShareAssistant";
import { htmlDecode } from "../../../utils/helpers";

import "animate.css";

interface AssistantItemProps {
  assistant: any;
}
const AssistantHeader = ({ assistant }: AssistantItemProps) => {
  const { t } = useTranslation(AssistantsChatsI18n);

  // const user = useUser();
  // const isOwnerAssistant = user?.id === assistant?.userId;

  return (
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0 me-2 ">
        <div className="avatar-md">
          {assistant?.profileImage ? (
            <img
              src={assistant?.profileImage}
              alt=""
              className="img-fluid radius-avatar"
            />
          ) : (
            <span
              className={classnames(
                "avatar-title",
                "",
                "text-uppercase",
                "bg-soft-primary",
                "text-primary"
              )}
            >
              {getInitials(assistant?.role)}
            </span>
          )}
        </div>
      </div>
      <div className="flex-grow-1">
        <p className="font-size-12 m-0 text-dark text-opacity-75">
          {t("page.label")}
        </p>
        <p className="font-size-15 m-0">{htmlDecode(assistant?.name)}</p>
        {/* {assistant?.hired && !isOwnerAssistant && (
          <span className="small text-primary">
            {t("page.purchased-assistant")}
          </span>
        )} */}
      <div className="mt-1">
        {assistant?.userId && (
          <DropdownShareAssistant assistant={assistant} iconSize={5} />
        )}
      </div>
      </div>
    </div>
  );
};

export default AssistantHeader;
