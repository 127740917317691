import { useState } from "react";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";
import { useRedux } from "../../../hooks/index";
import {
  changeSelectedChat,
  toggleExpandChat,
  toggleUserDetailsTab,
} from "../../../redux/actions";
import { ChatMenuPopUp } from "../../../components/ChatMenuPopUp";
import useTranslation from "../../../helpers/useTranslation";
import { UserHeaderI18n } from "./ConversationUser.i18n";
import { SelectedChatDTO } from "../../../@DTO/selectedChat";
import { AssistantDTO } from "../../../@DTO/assistant";
import { PinnedAlertI18n } from "./UserHead.i18n";
import { ChannelDTO } from "../../../@DTO/channel";
import { useSharedChat } from "../../../hooks/useSharedChat";
import { useChannels } from "../../../hooks/useChannels";
import { QUERY_KEYS, queryClient } from "../../../libs/react-query";

interface ProfileImageProps {
  onCloseConversation: () => any;
  selectedChat: ChannelDTO;
}
const ProfileImage = ({
  onCloseConversation,
  selectedChat,
}: ProfileImageProps) => {
  const channelName: string =
    selectedChat.channelName.length > 19
      ? selectedChat?.channelName?.substring(0, 20).concat("...")
      : selectedChat.channelName;

  return (
    <div className="row align-items-center">
      <div className="col-auto d-block d-lg-none">
        <Link
          to="#"
          onClick={onCloseConversation}
          className="user-chat-remove text-muted font-size-24 p-0"
        >
          <i className="bx bx-chevron-left align-middle"></i>
        </Link>
      </div>
      <div className="col">
        <h6 className="m-0 font-size-16">
          {channelName === "general" ? "General Chat" : channelName}
        </h6>
      </div>
    </div>
  );
};
interface PinnedAlertProps {
  onOpenPinnedTab: () => void;
}
const PinnedAlert = ({ onOpenPinnedTab }: PinnedAlertProps) => {
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);

  const { t } = useTranslation(PinnedAlertI18n);

  return (
    <Alert
      color="warning"
      isOpen={visible}
      toggle={onDismiss}
      className="topbar-bookmark p-1 px-3 px-lg-4 pe-lg-5 pe-5 alert-dismiss-custom"
      role="alert"
    >
      <div className="d-flex align-items-start bookmark-tabs">
        <div className="tab-list-link">
          <button className="btn tab-links" onClick={onOpenPinnedTab}>
            <i className="ri-pushpin-fill align-middle me-1"></i>{" "}
            {t("alert.title")}
          </button>
        </div>
      </div>
    </Alert>
  );
};

interface UserHeadProps {
  assistant: AssistantDTO;
  selectedChat: SelectedChatDTO;
  hasDocumentsInProcessing: boolean;
  isHiredAssistant: boolean;
  onToggleAttachDocument: () => void;
}
const UserHead = ({
  assistant,
  selectedChat,
  hasDocumentsInProcessing = false,
  onToggleAttachDocument,
}: UserHeadProps) => {
  const { dispatch, useAppSelector } = useRedux();

  const { handleLeaveSharedChat } = useSharedChat();

  const channelsCreated = queryClient.getQueryData<ChannelDTO[]>(
    QUERY_KEYS.CHANNELS
  );

  const { handleCreateChannel, loadingState } = useChannels(
    channelsCreated || []
  );

  const { isOpenUserDetails, isChatExpanded } = useAppSelector(state => ({
    isOpenUserDetails: state.Chats.isOpenUserDetails,
    isChatExpanded: state.Layout.isChatExpanded,
  }));

  const { t } = useTranslation(UserHeaderI18n);

  // const blockClearChannel =
  //   (!selectedChat?.isDocument || !selectedChat?.isYoutube) &&
  //   !!assistant?.userId &&
  //   !isHiredAssistant &&
  //   assistant?.userPrice > 0;

  const onCloseConversation = () => {
    dispatch(changeSelectedChat(null));
  };

  const handleOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(!isOpenUserDetails));
  };

  const handleToggleExpandChat = () => {
    dispatch(toggleExpandChat(!isChatExpanded));
  };

  return (
    <div
      className="d-flex align-items-center justify-content-between px-0 px-md-3 py-0 py-sm-2 mt-md-3 mb-3 me-0 me-lg-3 user-chat-topbar"
      style={{ borderRadius: 8 }}
    >
      <div className="row align-items-center flex-grow-1">
        <div className="col">
          <ProfileImage
            onCloseConversation={onCloseConversation}
            selectedChat={selectedChat}
          />
        </div>
        <div className="col-auto justify-content-end">
          <ul className="list-inline user-chat-nav text-end mb-0 align-items-center">
            <li className="list-inline-item d-inline-block me-2 ms-0">
              <div className="d-flex align-items-center gap-3">
                {(selectedChat?.isYoutube || selectedChat?.isDocument) && (
                  <span
                    onClick={onToggleAttachDocument}
                    className="d-md-none d-flex align-items-center text-secondary"
                  >
                    <i className="bx bx-paperclip fs-4" />
                  </span>
                )}
                <button
                  className="btn m-0 p-0 d-none d-lg-block text-light"
                  onClick={handleToggleExpandChat}
                >
                  <div
                    style={{
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                    onMouseEnter={e => {
                      // @ts-ignore
                      e.currentTarget.firstChild.style.fill = "#15AE9D";
                    }}
                    onMouseLeave={e => {
                      // @ts-ignore
                      e.currentTarget.firstChild.style.fill = "#3B3C45";
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style={{
                        marginBottom: 6,
                        fill: "#3B3C45",
                        transition: "fill 0.2s",
                      }}
                    >
                      <path d="M8.29 5.64 1.93 12l6.36 6.36 1.42-1.41L4.76 12l4.95-4.95-1.42-1.41zm6 1.41L19.24 12l-4.95 4.95 1.42 1.41L22.07 12l-6.36-6.36-1.42 1.41z"></path>
                    </svg>
                  </div>
                </button>

                {!selectedChat?.isDocument && !selectedChat?.isYoutube && (
                  <button
                    className="d-block d-md-none btn fs-5 m-0 p-0 border-0"
                    onClick={handleCreateChannel}
                    disabled={loadingState.createChannel}
                  >
                    {loadingState.createChannel ? (
                      <i className="bx bx-loader-circle bx-spin" />
                    ) : (
                      <i className="bx bxs-message-square-add" />
                    )}
                  </button>
                )}

                {!selectedChat?.isSharedWithMe && (
                  <ChatMenuPopUp
                    channelId={selectedChat._id}
                    channel={selectedChat}
                    // blockClearChannel={blockClearChannel}
                  />
                )}
                {selectedChat?.isSharedWithMe && (
                  <div className="d-flex flex-column align-items-end">
                    <span className="text-secondary small text-nowrap d-none d-sm-block">
                      {t("header.shared-chat")}
                    </span>
                    <span
                      onClick={() => handleLeaveSharedChat(selectedChat._id)}
                      className="text-danger d-flex align-items-center gap-1 small button-leave-chat-header"
                    >
                      <span className="d-none d-sm-block">
                        {t("button.leave-shared-chat")}
                      </span>
                      <i className="bx bx-log-out fs-5" />
                    </span>
                  </div>
                )}
                {selectedChat?.channelName !== "general" && (
                  <button
                    onClick={handleOpenUserDetails}
                    className="p-0 m-0 btn"
                  >
                    {isOpenUserDetails ? (
                      <i className="bx hover-primary bxs-right-arrow fs-5" />
                    ) : (
                      <i className="bx hover-primary bxs-left-arrow fs-5" />
                    )}
                  </button>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
      {hasDocumentsInProcessing && (
        <PinnedAlert onOpenPinnedTab={handleOpenUserDetails} />
      )}
      {assistant && !assistant?.enabled && (
        <Alert
          color="warning"
          isOpen={true}
          className="topbar-bookmark p-1 px-3 px-lg-4 pe-lg-5 pe-5 alert-dismiss-custom"
          role="alert"
        >
          <div className="d-flex align-items-start bookmark-tabs">
            <div className="tab-list-link">{t("header.assistantDisabled")}</div>
          </div>
        </Alert>
      )}
    </div>
  );
};

export default UserHead;
