import { useEffect, useState } from "react";

import { showErrorNotification } from "../../../helpers/notifications";

import { hasYoutubeUrl } from "../../../utils/stringutils";
import { StatusBadge } from "./StatusBadge";
import { createYoutubeVideoEmbeddings } from "../../../api/embeddings";
import { getChannelJobStatus } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { getCurrentUserLanguage } from "../../../helpers/userHelper";
import { JobMetadata } from "./JobMetadata";
import InfoModal from "../../../components/modal/ModalFiles";

const saveModalState = (state: {
  isOpen: boolean;
  message: string;
  title: string;
  closeButtonText: string;
}) => {
  localStorage.setItem("modalStateYoutube", JSON.stringify(state));
};

const getSavedModalState = () => {
  const savedState = localStorage.getItem("modalStateYoutube");
  return savedState ? JSON.parse(savedState) : null;
};

const YoutubeCard = ({ video, onDeleteJob, getDocumentContent }) => {
  const [deleteJobLoading, setDeleteJobLoading] = useState(false);

  const [modalState, setModalState] = useState(
    getSavedModalState() || {
      isOpen: false,
      message: "",
      title: "",
      closeButtonText: "",
    }
  );

  const userLanguage = getCurrentUserLanguage();

  const dispatch = useDispatch();

  async function handleDeleteJob(v) {
    try {
      setDeleteJobLoading(true);
      await onDeleteJob(video._id);
    } catch (error: any) {
      showErrorNotification(error.message);
    } finally {
      setDeleteJobLoading(false);
    }
  }

  async function resendJob() {
    try {
      const content = await getDocumentContent(video._id);

      if (
        (!content?.document || content?.document?.length === 0) &&
        video.processed
      ) {
        const messages = {
          pt: {
            message: `O conteúdo do vídeo <strong>${video.url}</strong> não foi encontrado nesse dispositivo ou browser. Vamos reprocessar o vídeo para você fazer perguntas sobre ele.`,
            title: "Aviso",
            closeButton: "Fechar",
          },
          en: {
            message: `The video <strong>${video.url}</strong> content was not found on this device or browser. We'll reprocess the video so you can ask questions about it.`,
            title: "Warning",
            closeButton: "Close",
          },
          es: {
            message: `El contenido del video <strong>${video.url}</strong> web no se encontró en este dispositivo o navegador. Vamos a reprocesar el video web para que pueda hacer preguntas sobre él.`,
            title: "Aviso",
            closeButton: "Cerrar",
          },
        };

        const newModalState = {
          isOpen: true,
          message: messages[userLanguage].message,
          title: messages[userLanguage].title,
          closeButtonText: messages[userLanguage].closeButton,
        };

        await handleDeleteJob(video._id);

        setModalState(newModalState);
        saveModalState(newModalState);

        await createYoutubeVideoEmbeddings({
          url: video.url,
          channelId: video.channel_id,
        });

        dispatch(getChannelJobStatus(video.channel_id));
      }
    } catch (error: any) {
      showErrorNotification(error.message);
    }
  }

  useEffect(() => {
    resendJob();
  }, []);

  useEffect(() => {
    if (video.processed) {
      if (modalState.isOpen) {
        const closedModalState = {
          ...modalState,
          isOpen: false,
        };
        setModalState(closedModalState);
        saveModalState(closedModalState);
      }
    }
  }, [modalState, video.processed]);

  const toggleModal = () => {
    const newState = { ...modalState, isOpen: !modalState.isOpen };
    setModalState(newState);
    saveModalState(newState);
  };

  return (
    <div className="card mb-2" key={video.url}>
      <div className="card-body py-3 px-0 pe-3">
        <div className="row d-flex align-items-center">
          <a
            href={video.url}
            target="_blank"
            rel="noreferrer"
            className="d-flex gap-3 align-items-center"
          >
            {hasYoutubeUrl(video.url) && (
              <div className="col-auto">
                <img
                  src={`https://img.youtube.com/vi/${
                    hasYoutubeUrl(video.url)[1]
                  }/mqdefault.jpg`}
                  alt="Youtube video thumbnail"
                  style={{
                    objectFit: "cover",
                    width: 80,
                    height: 80,
                    borderRadius: 10,
                  }}
                />
              </div>
            )}
            <div className="col flex-grow-1">
              <span
                className="d-block"
                style={{
                  wordBreak: "break-all",
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                {video.url}
              </span>
            </div>
          </a>
          <div className="d-flex align-items-center justify-content-between">
            <StatusBadge item={video} />
            {video.processed && <JobMetadata job={video} />}
          </div>
        </div>
      </div>
      <button
        className="btn p-0 position-absolute"
        style={{ top: -4, right: 8 }}
        disabled={deleteJobLoading}
        onClick={() => handleDeleteJob(video._id)}
      >
        {deleteJobLoading ? (
          <i className="bx bx-loader bx-spin align-middle"></i>
        ) : (
          <i className="bx bx-x fs-4"></i>
        )}
      </button>

      <InfoModal
        isOpen={modalState.isOpen}
        toggle={toggleModal}
        message={modalState.message}
        title={modalState.title}
        closeButtonText={modalState.closeButtonText}
      />
    </div>
  );
};

export default YoutubeCard;
