import classnames from "classnames";
import { useEffect } from "react";
import { useRedux } from "../../../hooks/index";
import { getChannelJobStatusReset, toggleUserDetailsTab } from "../../../redux/actions";
import AppSimpleBar from "../../../components/AppSimpleBar";
import useTranslation from "../../../helpers/useTranslation";
import { UserDocumentsI18n } from "./UserDocuments.i18n";
import { ChannelDTO } from "../../../@DTO/channel";
import {
  DocumentJobDTO,
  JobType,
  WebsiteJobDTO,
  YoutubeJobDTO,
} from "../../../@DTO/embeddings";
import DocumentCard from "./DocumentCard";
import YoutubeCard from "./YoutubeCard";
import WebsiteCard from "./WebsiteCard";
import { deleteJob } from "../../../api";
import {
  DocumentRepository,
  makeDocumentId,
} from "../../../helpers/indexedDb/document-repository";
import { showErrorNotification } from "../../../helpers/notifications";

interface IndexProps {
  documentsProcessed: Array<any>;
}

const Index = ({ documentsProcessed }: IndexProps) => {
  const { t } = useTranslation(UserDocumentsI18n);
  const { dispatch, useAppSelector } = useRedux();

  const {
    selectedChat,
    isOpenUserDetails,
    channelJobs,
    channelDocumentsUrlsLoading,
    activeTab,
  } = useAppSelector(state => ({
    selectedChat: state.Chats.selectedChat as ChannelDTO,
    chatUserDetails: state.Chats.chatUserDetails,
    getUserDetailsLoading: state.Chats.getUserDetailsLoading,
    isOpenUserDetails: state.Chats.isOpenUserDetails,
    channelJobs: state.Chats.channelJobs,
    channelDocumentsUrls: state.Chats.channelDocumentsUrls,
    channelDocumentsUrlsLoading: state.Chats.channelDocumentsUrlsLoading,
    activeTab: state.Layout.activeTab,
  }));

  const documentRepository = new DocumentRepository();

  const documents: DocumentJobDTO[] = channelJobs.filter(
    (job: DocumentJobDTO) =>
      job.type === JobType.Document ||
      // legacy code
      (job.key !== "" && job.bucket === "aizzy")
  );

  const seenYoutubeKeys = new Set<string>();

  const videos: YoutubeJobDTO[] = channelJobs.filter((job: YoutubeJobDTO) => {
    if (job.key) {
      if (seenYoutubeKeys.has(job.key)) {
        return false;
      }
      seenYoutubeKeys.add(job.key);
      job.url = job.key;
    }
    return (
      job.type === JobType.Video ||
      // legacy code
      job.isVideo
    );
  });

  const websites: WebsiteJobDTO[] = channelJobs.filter(
    (job: WebsiteJobDTO) => job.type === JobType.Website
  );

  async function getDocumentContent(documentId: string) {
    const repositoryDocumentId = makeDocumentId(
      selectedChat?._id,
      selectedChat?.userId,
      documentId
    );

    const document = await documentRepository.get(repositoryDocumentId);

    if (!document) {
      return null;
    }

    return document;
  }

  async function handleDeleteJob(jobId: string) {
    try {
      const repositoryDocumentId = makeDocumentId(
        selectedChat?._id,
        selectedChat?.userId,
        jobId
      );

      await deleteJob(jobId);

      await documentRepository.deleteDocumentById(repositoryDocumentId);

      dispatch(
        // @ts-ignore
        getChannelJobStatusReset(channelJobs?.filter(job => job._id !== jobId))
      );
    } catch (error: any) {
      showErrorNotification(error.message);
    }
  }

  const onCloseUserDetails = () => {
    dispatch(toggleUserDetailsTab(false));
  };

  useEffect(() => {
    if (activeTab !== "pills-documents") return;
    if (!selectedChat.isDocument && selectedChat.assistantId) {
      onCloseUserDetails();
    }
  }, [selectedChat, activeTab]);

  useEffect(() => {
    if (
      window?.matchMedia("(min-width: 768px)")?.matches &&
      selectedChat?.channelName !== "general" &&
      selectedChat.isDocument
    ) {
      dispatch(toggleUserDetailsTab(true));
    } else {
      dispatch(toggleUserDetailsTab(false));
    }
  }, [selectedChat]);

  return (
    <>
      <div
        className={classnames("user-profile-sidebar shadow-left-bar", {
          "d-block": isOpenUserDetails,
        })}
      >
        <div className="position-relative h-100 p-3">
          <div className="button-close-documents">
            <button className="btn m-0 p-0" onClick={onCloseUserDetails}>
              <i className="bx bx-x fs-4"></i>
            </button>
          </div>

          {(selectedChat?.isDocument || selectedChat?.isYoutube) && (
            <>
              <div className="my-3 d-flex justify-content-between align-items-center">
                <h5 className="m-0">{t("title")}</h5>
              </div>

              <AppSimpleBar className="user-profile-desc">
                {channelDocumentsUrlsLoading ? (
                  <div className="d-flex justify-content-center w-100 mb-2">
                    <i className="bx bx-loader bx-spin align-middle" />
                  </div>
                ) : documents.length > 0 ? (
                  documents.map(document => (
                    <DocumentCard
                      key={document._id}
                      item={document}
                      onDeleteJob={handleDeleteJob}
                      getDocumentContent={getDocumentContent}
                    />
                  ))
                ) : (
                  <span className="my-2 text-center text-secondary small">
                    {t("empty-document")}
                  </span>
                )}
              </AppSimpleBar>

              <div className="mt-4 d-flex justify-content-between align-items-center">
                <h5 className="m-0">{t("videosTitle")}</h5>
              </div>

              <AppSimpleBar className="user-profile-desc mt-3 mb-2">
                {channelDocumentsUrlsLoading ? (
                  <div className="d-flex justify-content-center w-100 mb-2">
                    <i className="bx bx-loader bx-spin align-middle" />
                  </div>
                ) : videos.length > 0 ? (
                  videos.map(video => (
                    <YoutubeCard
                      key={video._id}
                      video={video}
                      onDeleteJob={handleDeleteJob}
                      getDocumentContent={getDocumentContent}
                    />
                  ))
                ) : (
                  <span className="my-2 text-center text-secondary small">
                    {t("empty-video")}
                  </span>
                )}
              </AppSimpleBar>

              <div className="mt-4 d-flex justify-content-between align-items-center">
                <h5 className="m-0">{t("websitesTitle")}</h5>
              </div>

              <AppSimpleBar className="user-profile-desc mt-3 mb-2">
                {channelDocumentsUrlsLoading ? (
                  <div className="d-flex justify-content-center w-100 mb-2">
                    <i className="bx bx-loader bx-spin align-middle" />
                  </div>
                ) : websites.length > 0 ? (
                  websites.map(website => (
                    <WebsiteCard
                      key={website._id}
                      website={website}
                      onDeleteJob={handleDeleteJob}
                      getDocumentContent={getDocumentContent}
                    />
                  ))
                ) : (
                  <span className="my-2 text-center text-secondary small">
                    {t("empty-link")}
                  </span>
                )}
              </AppSimpleBar>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
