import { useUser } from "@supabase/auth-helpers-react";
import { showSuccessNotification } from "../../../helpers/notifications";
import useTranslation from "../../../helpers/useTranslation";
import AffiliateCardI18n from "./AffiliateCard.i18n";
import { Card } from "../../../components/Card";

export const AffiliateDisclaimerInfo = () => {
  const user = useUser();
  const { t } = useTranslation(AffiliateCardI18n);

  return (
    <>
      <p
        className="my-auto mt-3"
        style={{
          border: "1px dashed #38bcaf",
          padding: "26px 16px",
          textAlign: "center",
          borderRadius: "8px",
          background: "#38bcaf12",
        }}
      >
        <strong>
          https://aizzy.ai?referral=
          {user?.user_metadata?.affiliate?.userNanoId}
        </strong>
      </p>
      <button
        type="button"
        className="my-3 btn d-flex gap-3 justify-content-center align-items-center w-100"
        onClick={() =>
          navigator.clipboard
            .writeText(
              `https://aizzy.ai?referral=${user?.user_metadata?.affiliate?.userNanoId}`
            )
            .then(() => showSuccessNotification("Link copiado com sucesso"))
        }
      >
        <div className="btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
          <i className="bx  bx-copy" />
        </div>
        <h6 className="mb-0">{t("copyLink")}</h6>
      </button>
      <small
        className="d-block"
        dangerouslySetInnerHTML={{ __html: t("footer") }}
      />
    </>
  );
};
const AffiliateCard = () => {
  const { t } = useTranslation(AffiliateCardI18n);
  return (
    <div>
      <h4>{t("title")}</h4>
      <div className="d-flex flex-column h-100">
        <p
          className="mt-2"
          dangerouslySetInnerHTML={{
            __html: t("text"),
          }}
        />
        <AffiliateDisclaimerInfo />
      </div>
    </div>
  );
};

export default AffiliateCard;
