export const ImageMenuPopUpI18n = {
  "pt-BR": {
    button: {
      "copy-link": "Copiar link da imagem",
      "copy-image": "Copiar imagem",
      "download-image": "Baixar imagem",
      "share-wpp": "Compartilhar no WhatsApp",
      delete: "Deletar",
    },
    notifications: {
      "copy-link": "Link da imagem copiado com sucesso",
      "copy-image": "Imagem copiada com sucesso",
      "delete-image": "Deletando a imagem, por favor espere...",
    },
  },
  "en-US": {
    button: {
      "copy-link": "Copy image link",
      "copy-image": "Copy image",
      "download-image": "Download image",
      "share-wpp": "Share on WhatsApp",
      delete: "Delete",
    },
    notifications: {
      "copy-link": "Image link successfully copied",
      "copy-image": "Image successfully copied",
      "delete-image": "Deleting image, please wait",
    },
  },
  "es-ES": {
    button: {
      "copy-link": "Copiar enlace de la imagen",
      "copy-image": "Copiar imagen",
      "download-image": "Descargar imagen",
      "share-wpp": "Compartir en WhatsApp",
      delete: "Eliminar",
    },
    notifications: {
      "copy-link": "Enlace de la imagen copiado correctamente",
      "copy-image": "Imagen copiada correctamente",
      "delete-image": "Eliminando imagen, por favor espere",
    },
  },
  "fil-PH": {
    button: {
      "copy-link": "Kopyahin ang link ng imahe",
      "copy-image": "Kopyahin ang imahe",
      "download-image": "I-download ang imahe",
      "share-wpp": "Ibahagi sa WhatsApp",
      delete: "I-delete",
    },
    notifications: {
      "copy-link": "Tagumpay na nakopya ang link ng imahe",
      "copy-image": "Tagumpay na nakopya ang imahe",
      "delete-image": "Nagde-delete ng imahe, mangyaring maghintay",
    },
  },
};
