export const MenuI18n = {
  "pt-BR": {
    tabs: {
      user: "Usuário",
      plan: "Plano",
      security: "Segurança",
      affiliation: "Afiliação",
      support: "Suporte",
    },
  },
  "en-US": {
    tabs: {
      user: "User",
      plan: "Plan",
      security: "Security",
      affiliation: "Affiliation",
      support: "Support",
    },
  },
  "es-ES": {
    tabs: {
      user: "Usuario",
      plan: "Plan",
      security: "Seguridad",
      affiliation: "Afiliación",
      support: "Soporte",
    },
  },
  "fil-PH": {
    tabs: {
      user: "User",
      plan: "Plano",
      security: "Seguridad",
      affiliation: "Kaugnayan",
      support: "Suporta",
    },
  },
};
