import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useState } from "react";
import useTranslation from "../../../helpers/useTranslation";
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from "../../../helpers/notifications";
import { UpdatePasswordI18n } from "./UpdatePassword.i18n";

export function UpdatePassword() {
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const supabaseClient = useSupabaseClient();

  const { t } = useTranslation(UpdatePasswordI18n);

  async function handleUpdatePassword() {
    try {
      if (!newPassword) {
        return;
      }
      if (newPassword?.length < 6) {
        return showWarningNotification(t("notifications.smallPassword"));
      }

      setIsLoading(true);
      const { error } = await supabaseClient.auth.updateUser({
        password: newPassword,
      });

      if (error?.message) {
        return showErrorNotification(error?.message);
      }

      showSuccessNotification(t("notifications.success"));
    } catch (error: any) {
      showErrorNotification(error);
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <div>
      <h4 className="mb-0">{t("titles.updatePassword")}</h4>
      <div className="text-xl mt-8 mb-4 font-semibold mt-3 d-flex flex-column">
        <div className="h-8 mb-6">
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="new_password"
              onChange={e => setNewPassword(e.target.value)}
            />
            <label htmlFor="new_password">{t("form.password")}</label>
          </div>
          <button
            className="btn d-flex border-0 align-items-center gap-3 p-0"
            disabled={!newPassword || isLoading}
            onClick={handleUpdatePassword}
          >
            {isLoading ? (
              <i className="bx bx-loader bx-spin align-middle" />
            ) : (
              <div className="btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
                <i className="bx bx-refresh" />
              </div>
            )}
            <h6 className="mb-0">{t("button.title")}</h6>
          </button>
        </div>
      </div>
    </div>
  );
}
