import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import * as yup from "yup";
import { ErrorText } from "../../../components/ErrorText";
import useTranslation from "../../../helpers/useTranslation";
import { CreateAssistantI18n } from "./CreateAssistant.i18n";
import { useEffect } from "react";

interface ModalLanguageProps {
  showModal: boolean;
  languageDefault: string;
  translationToEdit: AssistantTranslationFormData;
  onClose: () => void;
  onCreateTranslation: (translation: AssistantTranslationFormData) => void;
  onSendEditedTranslation: (translation: AssistantTranslationFormData) => void;
}

const TEXT_AREA_HEIGHT = 80;

const assistantTranslationFormSchema = yup.object({
  language: yup.string().required("Language is required"),
  prompt: yup.string().required("Prompt is required"),
  role: yup.string().required("Role is required"),
  about: yup.string().required("About is required"),
  defaultGreetings: yup.string().nullable(),
});

export type AssistantTranslationFormData = yup.InferType<
  typeof assistantTranslationFormSchema
>;

export function ModalLanguage({
  showModal,
  onClose,
  languageDefault,
  translationToEdit,
  onCreateTranslation,
  onSendEditedTranslation,
}: ModalLanguageProps) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,

    formState: { errors },
  } = useForm<AssistantTranslationFormData>({
    resolver: yupResolver(assistantTranslationFormSchema),
  });

  const { t } = useTranslation(CreateAssistantI18n);

  function onSendTranslation(data: AssistantTranslationFormData) {
    if (translationToEdit) {
      onSendEditedTranslation(data);
      return reset();
    }

    onCreateTranslation(data);
    reset();
  }

  useEffect(() => {
    if (translationToEdit) {
      setValue("about", translationToEdit.about);
      setValue("defaultGreetings", translationToEdit.defaultGreetings);
      setValue("role", translationToEdit.role);
      setValue("language", translationToEdit.language);
      return setValue("prompt", translationToEdit.prompt);
    }
    reset();
  }, [translationToEdit]);

  return (
    <Modal isOpen={showModal} toggle={onClose} centered>
      <ModalHeader toggle={onClose} className="">
        {t("modal-translation.label")}
      </ModalHeader>
      <ModalBody className="">
        <Form id="translation-form" onSubmit={handleSubmit(onSendTranslation)}>
          <FormGroup>
            <Label>{t("form.assistant-language")}</Label>
            <select
              disabled={!!translationToEdit}
              className={`form-control bg-secondary bg-opacity-10 border-opacity-100 ${
                errors.language && "border-1 border-danger"
              }`}
              {...register("language")}
            >
              <option value="">
                {t("form.placeholder.assistant-language")}
              </option>
              {languageDefault !== "en" && <option value="en">English</option>}
              {languageDefault !== "pt" && (
                <option value="pt">Portuguese</option>
              )}
              {languageDefault !== "es" && <option value="es">Spanish</option>}
            </select>
            {errors.language && (
              <ErrorText>{errors.language.message}</ErrorText>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="role">{t("form.role")}</Label>
            <input
              className={`form-control bg-secondary bg-opacity-10 border-opacity-100 ${
                errors.role && "border-1 border-danger"
              }`}
              id="role"
              placeholder={t("form.placeholder.role")}
              {...register("role")}
            />
            {errors.role && <ErrorText>{errors.role.message}</ErrorText>}
          </FormGroup>

          <FormGroup>
            <Label for="prompt">{t("form.prompt")}</Label>
            <textarea
              className={`form-control bg-secondary bg-opacity-10 border-opacity-100 ${
                errors.prompt && "border-1 border-danger"
              }`}
              id="prompt"
              placeholder={t("form.placeholder.prompt")}
              style={{
                minHeight: TEXT_AREA_HEIGHT,
                resize: "vertical",
              }}
              {...register("prompt")}
            />
            {errors.prompt && <ErrorText>{errors.prompt.message}</ErrorText>}
          </FormGroup>

          <FormGroup>
            <Label for="about">{t("form.about")}</Label>
            <textarea
              className={`form-control bg-secondary bg-opacity-10 border-opacity-100 ${
                errors.about && "border-1 border-danger"
              }`}
              id="about"
              placeholder={t("form.placeholder.about")}
              style={{
                minHeight: TEXT_AREA_HEIGHT,
                resize: "vertical",
              }}
              {...register("about")}
            />
            {errors.about && <ErrorText>{errors.about.message}</ErrorText>}
          </FormGroup>

          <FormGroup>
            <Label for="greetings">{t("form.default-greetings")}</Label>
            <textarea
              className="form-control bg-secondary bg-opacity-10 border-opacity-100"
              id="greetings"
              placeholder={t("form.placeholder.default-greetings")}
              style={{
                minHeight: TEXT_AREA_HEIGHT,
                resize: "vertical",
              }}
              {...register("defaultGreetings")}
            />
            {errors.defaultGreetings && (
              <ErrorText>{errors.defaultGreetings.message}</ErrorText>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter className="">
        <Button color="secondary" onClick={onClose}>
          {t("modal-translation.buttons.close")}
        </Button>
        <button
          form="translation-form"
          className="btn btn-primary"
          type="submit"
        >
          {translationToEdit
            ? t("modal-translation.buttons.edit")
            : t("modal-translation.buttons.add")}
        </button>
      </ModalFooter>
    </Modal>
  );
}
