import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Input,
  Col,
} from "reactstrap";
import useTranslation from "../helpers/useTranslation";
import { PlanPremiumI18n } from "./PlanPremium.i18n";
import { useEffect, useState } from "react";
import { getUser } from "../helpers/userHelper";
import { web3Service } from "../helpers/web3";
import { ModalFeedbackTransaction } from "./ModalFeedbackTransaction";
import { toast } from "sonner";
import { web3PaymentCheckToken } from "../api/subscription";
import STATES from "../assets/thirdweb/statesTransaction";
import { AES } from "crypto-js";
import { getAIZPriceInUSDC, getAmountOfAIZPerUsdc } from "../api/web3";
import { formatPriceInUSD } from "../utils/helpers";

export function AIZPlanPremium() {
  const [isLoading, setIsLoading] = useState(false);
  const [showModalFeedback, setShowModalFeedback] = useState(false);

  const [monthsQuantity, setMonthsQuantity] = useState(1);

  const [aiz, setAIZ] = useState(0);
  const [aizPriceInUsdc, setAizPriceInUsdc] = useState(0);

  const [stateTransaction, setStateTransaction] = useState("");
  const [transactionHash, setTransactionHash] = useState("");

  const { t } = useTranslation(PlanPremiumI18n);

  const user = getUser();

  function handleMonthsChangeToken(event: any) {
    const month = parseInt(event.target.value);
    setMonthsQuantity(month);
  }

  async function handleTransferAiz() {
    try {
      const BASE_CHAIN_ID = "8453";

      const userWalletAddress = user?.email.split("@erc20.com")[0];
      const connectedWalletAddress = await web3Service.getCurrentAddress();

      if (
        connectedWalletAddress.toLowerCase() !==
        userWalletAddress?.toLowerCase()
      ) {
        alert(t("handleTransferAiz.alerts.walletMismatch"));
        return;
      }

      const currentNetwork = await web3Service.getCurrentNetwork();

      if (currentNetwork.chainId.toString() !== BASE_CHAIN_ID) {
        alert(t("handleTransferAiz.alerts.networkError"));
        await web3Service.changeNetwork(BASE_CHAIN_ID);
        return;
      }

      setIsLoading(true);
      setShowModalFeedback(true);
      setStateTransaction(STATES.SENDING);


      // const to = "0xaBaB20e0511594971787295FAfaf5356E88830B1";

      const to = process.env.REACT_APP_AIZ_TOKEN_ADDRESS?.trim() || "";

      const amount = aiz;

      const receipt = await web3Service.transferToken(to, amount);

      const encryptedData = AES.encrypt(
        JSON.stringify({ txId: receipt.hash }),
        ""
      ).toString();

      // const bytes = AES.decrypt(encryptedData, "");
      // const decryptedData = JSON.parse(bytes.toString(Utf8));
      // console.log("decryptedData", decryptedData);
      setStateTransaction(STATES.CHECKING);

      await web3PaymentCheckToken({ encryptedData });

      setStateTransaction(STATES.SUCCESS);
      setTransactionHash(receipt.hash);
      toast.success(t("handleTransferAiz.alerts.success"));

      setTimeout(() => {
        localStorage.removeItem("user-subscription");
        localStorage.removeItem("user");
        window.location.reload();
      }, 16200);
    } catch (error: any) {
      setShowModalFeedback(false);

      if (error?.reason === "ERC20: transfer amount exceeds balance") {
        return toast.error(t("handleTransferAiz.alerts.insufficientFunds"));
      }

      if (error?.reason === "rejected") {
        return toast.error(t("handleTransferAiz.alerts.transactionRejected"));
      }

      toast.error(
        (typeof error?.message === "string" && error.message) ||
          (typeof error?.reason === "string" && error.reason) ||
          "An error occurred while processing the transaction, please try again or contact support."
      );

      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const aizYearlyPrice = 59;
  const aizMonthlyPrice = 5.99;

  async function fetchAIZPriceInUSDC() {
    try {
      const amountAizToPay =
        monthsQuantity === 12
          ? aizYearlyPrice
          : monthsQuantity * aizMonthlyPrice;

      setIsLoading(true);
      const response = await getAmountOfAIZPerUsdc(amountAizToPay);
      setAIZ(response.amountOfAIZPerUsdc);
    } catch (error) {
      console.error("Failed to fetch AIZ price in USDC:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const fetchAIZPriceInUSDC = async () => {
      try {
        const response = await getAIZPriceInUSDC();
        setAizPriceInUsdc(response.aizPriceInUsdc);
      } catch (error) {
        console.error("Failed to fetch AIZ price in USDC:", error);
      }
    };

    fetchAIZPriceInUSDC();
  }, []);

  useEffect(() => {
    fetchAIZPriceInUSDC();
  }, [monthsQuantity]);

  return (
    <>
      {showModalFeedback && (
        <ModalFeedbackTransaction
          onClose={() => setShowModalFeedback(false)}
          stateTransaction={stateTransaction}
          transactionHash={transactionHash}
        />
      )}
      <Col sm={12} md={6} className="mb-4 p-4">
        <Card
          className="h-100 text-center border border-2 border-primary shadow"
          style={{
            minHeight: 340,
            maxHeight: 340,
            transform: "scale(1.05)",
          }}
        >
          <CardBody className="d-flex flex-column justify-content-between">
            <CardTitle className="fs-5">{t("cards.token.title")}</CardTitle>
            <div>
              <CardSubtitle className="mb-3 text-dark text-opacity-75">
                {t("cards.token.description", {
                  months: monthsQuantity,
                })}
              </CardSubtitle>
              <Input
                className="custom-aiz-input"
                type="range"
                min="1"
                max="12"
                step="1"
                value={monthsQuantity}
                onChange={handleMonthsChangeToken}
              />
              <CardSubtitle tag="h6" className="mb-4 text-dark">
                {t("cards.token.priceByMonth", {
                  tokenPrice: aiz,
                })}
              </CardSubtitle>
              {/* {aizPrice === 12 && (
                <CardSubtitle tag="h6" className="mb-3 text-primary">
                  {t("cards.token.discountMessage", {
                    discount: aizDiscountPrice,
                  })}
                </CardSubtitle>
              )} */}
            </div>
            <span className="d-block">
              1 <span className="text-primary">AIZ</span> ={" "}
              {aizPriceInUsdc ? (
                formatPriceInUSD(aizPriceInUsdc)
              ) : (
                <i className="bx bx-loader bx-spin align-middle"></i>
              )}
            </span>

            <Button
              color="primary"
              className="fw-semibold font-size-18"
              disabled={isLoading}
              onClick={handleTransferAiz}
            >
              {t("cards.token.planButton")}
            </Button>
          </CardBody>
          <small className="pb-3 px-3">{t("cards.token.billingNotice")}</small>
        </Card>
      </Col>
    </>
  );
}
