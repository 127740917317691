/* eslint-disable no-useless-escape */
import { nanoid } from "nanoid";
import { memo, useContext, useEffect, useState } from "react";
import RemainingCallsContext from "../../../freemium/RemainingCallsContext";
import { useRedux } from "../../../hooks/index";
import useChatWebSocket from "../../../hooks/useChatWebSocket";
import { getChannelJobStatus } from "../../../redux/actions";
import ChatInputSection from "./ChatInputSection/index";
import Conversation from "./Conversation";
import UserHead from "./UserHead";
import { SelectedChatDTO } from "../../../@DTO/selectedChat";
import {
  showErrorNotification,
  showWarningNotification,
} from "../../../helpers/notifications";
import useTranslation from "../../../helpers/useTranslation";
import { ConversationUserI18n } from "./ConversationUser.i18n";
import { AssistantDTO } from "../../../@DTO/assistant";
import { getUser } from "../../../helpers/userHelper";
import { useQuery } from "react-query";
import { QUERY_KEYS } from "../../../libs/react-query";
import {
  clearChannel,
  getChatUserConversationsChannels,
  getChatUserConversationsDocuments,
} from "../../../api";
import { useChatStore } from "../../../state/chat";
import { MessageData } from "../../../@DTO/chat";
import Loader from "../../../components/Loader";
import { getBearerToken } from "../../../helpers/localStorageHelpers";

import { cryptoService } from "../../../helpers/crypto";
import {
  createDocumentEmbeddingUpload,
  createTextEmbeddings,
  createWebsiteEmbeddings,
  createYoutubeVideoEmbeddings,
} from "../../../api/embeddings";
import {
  ChatRepository,
  makeChatId,
} from "../../../helpers/indexedDb/chat-repository";
import { DocumentRepository } from "../../../helpers/indexedDb/document-repository";

interface WebsocketDocumentBody {
  route: string;
  body: {
    question: string;
    assistantId: string;
    channelId: string;
    messageId: string;
  };
  jwt: string;
}

interface ConversationUserProps {
  hasDocumentsProcessing: boolean;
}

const ConversationUser = ({
  hasDocumentsProcessing,
}: ConversationUserProps) => {
  const user = getUser();

  const { checkRemainingCallsMessage, isCheckingRemainingCallsMessage } =
    useContext(RemainingCallsContext);

  const chatRepository = new ChatRepository();
  const documentRepository = new DocumentRepository();

  const [isLoadingMessagesByIndexedDB, setIsLoadingMessagesByIndexedDB] =
    useState(false);

  const { setMessages, setNewMessage, chatOption } = useChatStore();

  const { dispatch, useAppSelector } = useRedux();

  const { selectedChat, assistant } = useAppSelector(state => ({
    createDocumentEmbeddingPayload: state.Chats.createDocumentEmbeddingPayload,
    selectedChat: state.Chats.selectedChat as SelectedChatDTO,
    assistant: state.Chats.assistant as AssistantDTO,
  }));

  const {
    isLoading: isLoadingConversation,
    isFetching: isFetchingConversation,
  } = useQuery<MessageData[]>(
    [QUERY_KEYS.MESSAGES, selectedChat._id],
    async () => {
      const response = await getChatUserConversationsDocuments({
        assistantId: selectedChat.assistantId,
        channelId: selectedChat._id,
      });

      const chatTarget = Object.keys(
        response.chats_by_channel_and_assistant_id
      )[0];

      const messages: MessageData[] =
        response.chats_by_channel_and_assistant_id[chatTarget];

      return messages?.reverse();
    },
    {
      onSuccess: data => {
        setMessages(data);
      },
      onError: (error?: any) => {
        showErrorNotification(error?.message || "Error when getting messages");
      },
      enabled: selectedChat?.isDocument === true,
      refetchOnWindowFocus: false,
    }
  );

  const { t } = useTranslation(ConversationUserI18n);

  const { sendWebsocketMessage, cancelMessage } = useChatWebSocket();

  const isHiredAssistant = checkHiredAssistant();

  function checkHiredAssistant() {
    const isCommunityAssistant = !!assistant?.userId;

    if (!isCommunityAssistant) {
      return true;
    }

    return assistant?.userId === user?.id || assistant?.hired;
  }

  const [isOpenAttachDocument, setIsOpenAttachDocument] = useState(false);

  const onToggleAttachDocument = () => {
    setIsOpenAttachDocument(!isOpenAttachDocument);
  };

  const onSend = async (question: string, isAudioMessage?: boolean) => {
    let requestMessage;
    let route;
    let messageBody;

    const messageId = nanoid();

    let message;

    switch (selectedChat?.isDocument) {
      case true:
        requestMessage = `${question}`;
        route = "createChatMessageForDocumentsWithStream";
        messageBody = {
          question: requestMessage,
          assistantId: null,
          isAudioMessage: isAudioMessage,
          channelId: selectedChat?._id,
          messageId,
        };

        message = {
          route,
          body: messageBody,
          jwt: getBearerToken(),
        };

        sendDocumentMessage(message);
        break;
      case false:
        const routeByChatOption = {
          CHAT: "createChatMessageV2",
          CODE: "createCodeMessage",
        };

        requestMessage = question;
        route = routeByChatOption[chatOption];
        messageBody = {
          message: requestMessage,
          isAudioMessage: isAudioMessage,
          assistantId: assistant?._id ?? null,
          channelId: selectedChat?._id,
          messageId,
        };

        message = {
          route,
          body: messageBody,
          jwt: getBearerToken(),
        };

        sendChatMessage(message);
        break;
    }

    try {
      const messageData = {
        mId: messageId,
        chat_request: requestMessage,
        chat_response: {
          content: "",
        },
      };

      setNewMessage(messageData);
    } catch (error: any) {
      showWarningNotification(error);
    } finally {
    }
  };

  const sendChatMessage = async requestBody => {
    try {
      const messages = await chatRepository.getMessagesByChatId(
        makeChatId(
          selectedChat._id,
          selectedChat.assistantId,
          selectedChat.userId
        )
      );

      const lastMessages = messages?.reverse().slice(0, 7).reverse();

      const responseMessages = lastMessages?.map(message => {
        return message.chat_response?.content;
      });

      const data = {
        route: requestBody.route,
        body: {
          assistantId: requestBody.body.assistantId,
          isAudioMessage: requestBody.body.isAudioMessage,
          channelId: requestBody.body.channelId,
          lastMessages: responseMessages || [],
          question: requestBody.body.message,
          messageId: requestBody.body.messageId,
        },
        jwt: requestBody.jwt,
      };

      const message = JSON.stringify({
        route: data.route,
        jwt: data.jwt,
        body: cryptoService.encrypt(JSON.stringify(data.body)),
      });

      sendWebsocketMessage(message);
    } catch (error: any) {
      showWarningNotification(error);
    }
  };

  const sendDocumentMessage = async (requestBody: WebsocketDocumentBody) => {
    try {
      const { question } = requestBody.body;

      const { documents } = await createTextEmbeddings({
        channelId: selectedChat._id,
        text: question,
      });

      const postgresIds = documents.map(document => document.id);

      const compressedContent =
        await documentRepository.getCompressedDocumentsByPostgresIds(
          postgresIds
        );

      const message = {
        route: requestBody.route,
        jwt: requestBody.jwt,
        body: {
          ...requestBody.body,
          compressedDocuments: compressedContent ?? null,
        },
      };

      sendWebsocketMessage(JSON.stringify(message));
    } catch (error: any) {
      showWarningNotification(error);
    }
  };

  // const checkRemainingCallsOnSend = (question: string) => {
  //   return onSend(question);
  //   let actionToCheck = "remainingChats";
  //   if (selectedChat.assistantId && question.startsWith("/create")) {
  //     actionToCheck = "remainingImages";
  //   }

  //   if (isCheckingRemainingCallsMessage) return;

  //   checkRemainingCallsMessage(() => onSend(question), actionToCheck);
  // };

  const onAttachDocumentByUrl = async (url: string) => {
    if (hasDocumentsProcessing) {
      return showWarningNotification(t("notifications.documentProcessing"), {
        duration: 5000,
      });
    }

    try {
      const isYoutubeUrl = /https?:\/\/(?:www\.)?youtube\.com\/[^\s]+/gi.test(
        url
      );

      if (isYoutubeUrl) {
        showWarningNotification(t("notifications.sendingContent"), {
          duration: 1500,
        });

        await createYoutubeVideoEmbeddings({
          url,
          channelId: selectedChat._id,
        });

        dispatch(getChannelJobStatus(selectedChat._id));

        return;
      }

      const isValidUrl =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w \.-]*)*\/?\??([^#\n\r]*)?#?([^#\n\r]*)?$/.test(
          url
        );

      if (!isValidUrl) {
        return showErrorNotification(t("notifications.invalidUrl"));
      }

      showWarningNotification(t("notifications.sendingContent"), {
        duration: 1500,
      });

      await createWebsiteEmbeddings({
        url: url,
        channelId: selectedChat._id,
      });

      dispatch(getChannelJobStatus(selectedChat._id));
    } catch (error: any) {
      showErrorNotification(error.message || "Error when sending content");
    }
  };

  const onAttachDocumentFile = async (file: File) => {
    if (hasDocumentsProcessing) {
      return showWarningNotification(t("notifications.documentProcessing"), {
        duration: 5000,
      });
    }
    try {
      showWarningNotification(t("notifications.sendingContent"));
      await createDocumentEmbeddingUpload(selectedChat._id, file);

      dispatch(getChannelJobStatus(selectedChat._id));
    } catch (error: any) {
      showErrorNotification(error.message || "Error when sending content");
    }
  };

  useEffect(() => {
    const getMessages = async () => {
      try {
        setIsLoadingMessagesByIndexedDB(true);

        const chatId = makeChatId(
          selectedChat._id,
          selectedChat.assistantId,
          selectedChat.userId
        );
        const dbMessages = await chatRepository.getMessagesByChatId(chatId);

        let messages = dbMessages;

        if (!messages || messages.length === 0) {
          const response = await getChatUserConversationsChannels({
            assistantId: selectedChat.assistantId,
            channelId: selectedChat._id,
          });

          const chatTarget = Object.keys(
            response.chats_by_channel_and_assistant_id
          )[0];
          messages =
            response.chats_by_channel_and_assistant_id[chatTarget]?.reverse();

          const existingChat = await chatRepository.get(chatId);

          if ((!messages || messages.length === 0) && existingChat) {
            setMessages([]);
            return;
          }

          const cryptoMessages = messages?.map(message =>
            cryptoService.encrypt(JSON.stringify(message))
          );

          if (!existingChat) {
            await chatRepository.add({
              id: chatId,
              messages: cryptoMessages || [],
            });

            await clearChannel(selectedChat._id);
          } else {
            await chatRepository.updateChatWithMessage(chatId, messages![0]);
          }
        }

        setMessages(messages || []);
      } catch (error: any) {
        showErrorNotification(error?.message || "Error when getting messages");
      } finally {
        setIsLoadingMessagesByIndexedDB(false);
      }
    };

    if (!selectedChat?.isDocument) {
      getMessages();
    }
  }, [selectedChat]);

  return (
    <>
      <UserHead
        assistant={assistant}
        selectedChat={selectedChat}
        hasDocumentsInProcessing={hasDocumentsProcessing}
        onToggleAttachDocument={onToggleAttachDocument}
        isHiredAssistant={isHiredAssistant}
      />

      {isLoadingMessagesByIndexedDB ||
      isLoadingConversation ||
      isFetchingConversation ? (
        <Loader />
      ) : (
        <>
          <Conversation selectedChat={selectedChat} />

          <div>
            <ChatInputSection
              onSend={onSend}
              onCancel={cancelMessage}
              onToggleAttachDocument={onToggleAttachDocument}
              isOpenAttachDocument={isOpenAttachDocument}
              selectedChat={selectedChat}
              onAttachDocumentByUrl={onAttachDocumentByUrl}
              onAttachDocumentFile={onAttachDocumentFile}
            />
          </div>
        </>
      )}
      {/* <ModalBuyAssistant
          assistant={assistant}
          isOpen={blockChatIfAssistantNotHired()}
        /> */}
    </>
  );
};

export default ConversationUser;

// const isOwnerAssistant = assistant?.userId === user?.id;

//     const blockChatIfAssistantNotHired = () => {
//       if (
//         !assistant?.userId ||
//         isOwnerAssistant ||
//         assistant?.hired ||
//         assistant?.userPrice <= 0 ||
//         !!!assistant?.userPrice
//       ) {
//         return false;
//       }

//       const channelByAssistantId = channels?.find(
//         channel => channel?.assistantId === selectedChat?.assistantId
//       );

//       if (channelByAssistantId?.channelName !== "general") {
//         return false;
//       }

//       const generalChatId = channelByAssistantId?._id;

//       const isGeneralChatMessage = messages?.some(
//         message => message?.channelId === generalChatId
//       );

//       if (!isGeneralChatMessage) {
//         return false;
//       }

//       const messages = messages;

//       const messagesAmount = messages?.length;

//       const hasDefaultGreetings =
//         messages &&
//         messages[0]?.chat_request === null &&
//         messages[0]?.chat_response?.content !== null;

//       const maxTrialMessages =
//         Number(process.env.REACT_APP_MAX_TRIAL_ASSISTANT_MSG) +
//         (hasDefaultGreetings ? 1 : 0);

//       if (messagesAmount < maxTrialMessages) {
//         return false;
//       }

//       return true;
//     };
