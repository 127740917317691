import { useChatStore } from "../../../../state/chat";

interface SubmitChatButtonProps {
  onSubmit: () => void;
  onCancel: () => void;
  disabled: boolean;
}

const SubmitChatButton = ({
  onSubmit,
  onCancel,
  disabled,
}: SubmitChatButtonProps) => {
  const { isReceivingMessage, disableCancelMessage } = useChatStore();

  return (
    <>
      {!isReceivingMessage ? (
        <button
          type="submit"
          disabled={disabled}
          className="btn btn-primary d-flex align-items-center justify-content-center button-border-lg"
          onClick={onSubmit}
          style={{ width: 38, height: 42 }}
        >
          <i className="bx bxs-send align-middle " />
        </button>
      ) : (
        <button
          type="button"
          className="btn btn-danger d-flex align-items-center justify-content-center button-border-lg"
          disabled={disableCancelMessage}
          onClick={onCancel}
          style={{ width: 38, height: 42 }}
        >
          <i
            className="bx bx-stop-circle align-middle"
            style={{ height: 16 }}
          />
        </button>
      )}
    </>
  );
};

export default SubmitChatButton;
