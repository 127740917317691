export const BannerPixI18n = {
  "pt-BR": {
    banner: {
      title:
        "Torne-se " +
        '<span class="text-primary">Premium</span> através do PIX!',
      description:
        "Aprimore sua experiência com nossos serviços Premium e aproveite uma série de benefícios exclusivos. Ao realizar um PIX, você terá acesso ilimitado a chats, documentos e vídeos. Além disso, também poderá contar com a comodidade de conversar diretamente com um assistente através do seu WhatsApp.",
      linkToPix: "Conhecer plano PIX",
    },
  },
  "en-US": {
    banner: {
      title:
        "Become " + '<span class="text-primary">Premium</span> through PIX!',
      description:
        "Enhance your experience with our Premium services and enjoy a range of exclusive benefits. By using PIX, you'll have unlimited access to chats, documents, and videos. Additionally, you'll also have the convenience of directly chatting with an assistant through your WhatsApp.",
      linkToPix: "Learn about PIX plan",
    },
  },
  "es-ES": {
    banner: {
      title:
        "¡Conviértete en " +
        '<span class="text-primary">Premium</span> a través de PIX!',
      description:
        "Mejora tu experiencia con nuestros servicios Premium y disfruta de una variedad de beneficios exclusivos. Al utilizar PIX, tendrás acceso ilimitado a chats, documentos y videos. Además, también disfrutarás de la comodidad de chatear directamente con un asistente a través de tu WhatsApp.",
      linkToPix: "Conocer el plan PIX",
    },
  },
  "fil-PH": {
    banner: {
      title:
        "Maging " +
        '<span class="text-primary">Premium</span> sa pamamagitan ng PIX!',
      description:
        "I-enhance ang iyong karanasan sa aming mga Premium na serbisyo at tamasahin ang iba't ibang mga eksklusibong benepisyo. Sa paggamit ng PIX, magkakaroon ka ng walang hanggang access sa mga chats, dokumento, at mga video. Bukod dito, maaari mo rin masiyahan ang kaginhawahan ng direktaang pag-uusap sa isang assistant sa pamamagitan ng iyong WhatsApp.",
      linkToPix: "Alamin ang PIX plan",
    },
  },
};
