function getInitials(string) {
  if (!string || typeof string !== "string") return string;
  const words = string?.split(" ");
  const initials = words
    ?.slice(0, 2)
    ?.map(word => word.charAt(0))
    ?.join("");
  return initials?.toUpperCase();
}

const hasYoutubeUrl = _string => {
  const regex =
    /(?:https?:\/\/(?:www\.)?youtube\.com\/watch\?v=|https?:\/\/youtu.be\/)([a-zA-Z0-9_-]{11})/;
  return _string.match(regex);
};

function validateEmail(email: string): boolean {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

function cleanUrlForChannelName(url: string): string {
  if (!validateUrl(url)) {
    return "";
  }

  const urlObj = new URL(url);

  const paths = urlObj.pathname.split("/").filter(path => path !== "");
  const formattedPaths = paths
    .map(path => path.replace(/[_/]/g, " "))
    .join(" ");

  const domainParts = urlObj.hostname.split(".");
  let formattedDomain = domainParts.slice(0, -1).join(" ");

  if (formattedDomain.startsWith("www")) {
    formattedDomain = formattedDomain.substring(4);
  }

  let urlFormatted =
    formattedDomain + (formattedPaths ? " " + formattedPaths : "");

  urlFormatted = urlFormatted.replace(/[^\w\s]/g, "");

  return urlFormatted.substring(0, 20);
}

function cleanFileForChannelName(value: string): string {
  const cleanedValue = value.replace(/[^\w\s.]/g, " ");
  const parts = cleanedValue.split(".");
  parts.pop();
  const valueWithoutExtension = parts.join(".").replace(/[_-]/g, " ");
  return valueWithoutExtension;
}

function validateUrl(url: string): boolean {
  const urlRegex =
    /^(https?:\/\/)?([a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+\/?)([a-zA-Z0-9_\-.,@?^=%&;:/~+#]*[a-zA-Z0-9_\-@?^=%&;/~+#])?$/;

  return urlRegex.test(url);
}

function extractUsernameOfEmail(email) {
  if (!validateEmail(email)) {
    return "Invalid email format";
  }

  const parts = email.split("@");
  const username = parts[0];

  return username;
}

export {
  getInitials,
  hasYoutubeUrl,
  validateEmail,
  cleanFileForChannelName,
  cleanUrlForChannelName,
  validateUrl,
  extractUsernameOfEmail,
};
