export const ModalWhatsappNumberI18n = {
  "pt-BR": {
    modal: {
      stepOne: {
        title: "Vamos levar a sua experiência com o Aizzy para outro nível?",
        crucialPoints:
          "Mas antes, saiba desses pontos cruciais para iniciarmos:",
        pointTwo:
          "1. Certifique-se de que, após concluir todas as etapas, você será redirecionado para o seu WhatsApp pessoal, e não para o WhatsApp Business, caso o tenha instalado. Caso contrário, a ação pode não funcionar adequadamente.",
        pointThree:
          "2. Se estiver tendo dificuldades procure ajuda na nossa comunidade ou veja esse ",
        next: "Próximo",
      },
      stepTwo: {
        input: {
          label: "Digite o seu número do Whatsapp completo abaixo",
          errors: {
            invalidPhoneNumber: "Número de telefone inválido",
          },
        },
        buttons: {
          submitNumber: "Enviar número",
          goBack: "Voltar",
        },
      },
      stepThree: {
        link: {
          wppLink: "Clique aqui para iniciar sua interação",
          helpLink:
            "Se estiver com dúvidas de como utilizar, clique aqui para ver um vídeo explicativo",
        },
        qrcodeLabel:
          "Escaneie o QRCODE abaixo para começar a interagir com o Aizzy no Whatsapp",
        or: "Ou",
        buttons: {
          updateNumber: "Atualizar número",
        },
      },
    },

    buttons: {
      startInteracting: "Interagir com ZapBot",
      startConversation: "Iniciar interação",
      changeNumber: "Mudar número",
    },
  },
  "en-US": {
    modal: {
      stepOne: {
        title: "Are we going to take your Aizzy experience to another level?",
        crucialPoints: "But first, be aware of these crucial points to start:",
        pointTwo:
          "1. Ensure that after completing all the steps, you will be redirected to your personal WhatsApp and not WhatsApp Business if you have it installed. Otherwise, the action may not work correctly.",
        pointThree:
          "2. If you are having difficulties, seek help in our community or watch this ",
        next: "Next",
      },
      stepTwo: {
        input: {
          label: "Enter your complete WhatsApp number below",
          errors: {
            invalidPhoneNumber: "Invalid phone number",
          },
        },
        buttons: {
          submitNumber: "Submit number",
          goBack: "Back",
        },
      },
      stepThree: {
        link: {
          wppLink: "Click here to start your interaction",
          helpLink:
            "If you have any doubts on how to use it, click here to watch an explanatory video",
        },
        qrcodeLabel:
          "Scan the QR code below to start interacting with Aizzy on WhatsApp",
        or: "Or",
        buttons: {
          updateNumber: "Update number",
        },
      },
    },
    buttons: {
      startInteracting: "Interacting with ZapBot",
      startConversation: "Start interaction",
      changeNumber: "Change number",
    },
  },
  "es-ES": {
    modal: {
      stepOne: {
        title: "¿Vamos a llevar tu experiencia con Aizzy a otro nivel?",
        crucialPoints:
          "Pero primero, ten en cuenta estos puntos cruciales para comenzar:",
        pointTwo:
          "1. Asegúrate de que, después de completar todos los pasos, serás redirigido a tu WhatsApp personal y no a WhatsApp Business si lo tienes instalado. De lo contrario, la acción puede no funcionar correctamente.",
        pointThree:
          "2. Si tienes dificultades, busca ayuda en nuestra comunidad o mira este ",
        next: "Próximo",
      },
      stepTwo: {
        input: {
          label: "Ingrese su número completo de WhatsApp a continuación",
          errors: {
            invalidPhoneNumber: "Número de teléfono inválido",
          },
        },
        buttons: {
          submitNumber: "Enviar número",
          goBack: "Volver",
        },
      },
      stepThree: {
        link: {
          wppLink: "Haga clic aquí para comenzar su interacción",
          helpLink:
            "Si tienes dudas sobre cómo usarlo, haz clic aquí para ver un video explicativo",
        },
        qrcodeLabel:
          "Escanee el código QR a continuación para comenzar a interactuar con Aizzy en WhatsApp",
        or: "O",
        buttons: {
          updateNumber: "Actualizar number",
        },
      },
    },
    buttons: {
      startInteracting: "Interactuando con ZapBot",
      changeNumber: "Cambiar número",
      startConversation: "Iniciar interacción",
    },
  },
  "fil-PH": {
    modal: {
      stepOne: {
        title:
          "Dadalahin ba natin ang iyong karanasan sa Aizzy sa ibang antas?",
        crucialPoints:
          "Ngunit bago ang lahat, maging maingat sa mga mahahalagang punto na ito para magsimula:",
        pointTwo:
          "1. Siguruhing pagkatapos ng lahat ng hakbang, ikaw ay ireredirekta sa iyong personal na WhatsApp at hindi sa WhatsApp Business kung ito ay naka-install mo. Kung hindi, maaaring hindi maayos gumana ang aksyon.",
        pointThree:
          "2. Kung mayroon kang mga kahirapan, humingi ng tulong sa aming komunidad o panoorin itong ",
        next: "Susunod",
      },
      stepTwo: {
        input: {
          label: "Ilagay ang iyong kumpletong numero ng WhatsApp sa ibaba",
          errors: {
            invalidPhoneNumber: "Hindi wastong numero ng telepono",
          },
        },
        buttons: {
          submitNumber: "Isumite ang numero",
          goBack: "Bumalik",
        },
      },
      stepThree: {
        link: {
          wppLink: "I-click dito upang simulan ang iyong pakikipag-ugnayan",
          helpLink:
            "Kung mayroon kang anumang duda kung paano gamitin ito, i-click dito upang manood ng paliwanag na video",
        },
        qrcodeLabel:
          "I-scan ang QR code sa ibaba upang simulan ang pakikipag-ugnayan kay Aizzy sa WhatsApp",
        or: "O",
        buttons: {
          updateNumber: "I-update ang numero",
        },
      },
    },
    buttons: {
      startInteracting: "Makikipag-ugnayan kay ZapBot",
      startConversation: "Simulan ang pakikipag-ugnayan",
      changeNumber: "Palitan ang numero",
    },
  },
};
