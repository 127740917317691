import { useContext, useEffect, useRef, useState } from "react";
import SubmitChatButton from "./SubmitChatButton";
import InputSection from "./InputSection";
import AttachButton from "./AttachButton";
import AttachDocumentMenu from "./AttachDocumentMenu";
import { hasYoutubeUrl } from "../../../../utils/stringutils";
import { useRedux } from "../../../../hooks";
import RemainingCallsContext from "../../../../freemium/RemainingCallsContext";
import { SelectedChatDTO } from "../../../../@DTO/selectedChat";

import HCaptcha from "@hcaptcha/react-hcaptcha";

import { getCurrentUserLanguage } from "../../../../helpers/userHelper";
import { useCaptcha } from "../../../../contexts/CaptchaContext";
import { useChatStore } from "../../../../state/chat";
import AudioRecorder from "../../../../components/AudioRecorder";
import SelectAssistants from "../../../../components/SelectAssistants";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";


interface IndexProps {
  onSend: (data: any) => void;
  onCancel: () => void;
  selectedChat: SelectedChatDTO;
  isOpenAttachDocument: boolean;
  onAttachDocumentByUrl: (url: string) => void;
  onAttachDocumentFile: (file: any) => void;
  onToggleAttachDocument: () => void;

  createDocumentEmbeddingPayload?: any;
}

const Index = ({
  onSend,
  onCancel,
  onToggleAttachDocument,
  isOpenAttachDocument,
  selectedChat,
  onAttachDocumentByUrl,
  onAttachDocumentFile,
}: IndexProps) => {
  const [message, setMessage] = useState("");
  const [selectedChatOption, setSelectedChatOption] = useState<"CHAT" | "CODE">(
    "CHAT"
  );
  const [isBuscaNaWeb, setIsBuscaNaWeb] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const { isReceivingMessage, changeChatOption } = useChatStore();

  const { useAppSelector } = useRedux();
  const { assistant } = useAppSelector(state => ({
    assistant: state.Chats.assistant,
  }));

  const { captchaToken, handleVerificationSuccess, onSubmitChat, showCaptcha } =
    useCaptcha();

  const { checkRemainingCallsMessage, isCheckingRemainingCallsMessage } =
    useContext(RemainingCallsContext);

  const captchaRef = useRef<HCaptcha>(null);

  const hcaptchaSiteKey = process.env.REACT_APP_HCAPTCHA_SITE_KEY;

  function onToggle() {
    onToggleAttachDocument();
  }

  function onChangeText(value: string) {
    setMessage(value);
  }

  function handleChatOptionChange(event) {
    if (isReceivingMessage) {
      return;
    }

    setSelectedChatOption(event.target.value);
  }

  function onSubmit() {
    if (!message) {
      return;
    }

    onSubmitChat();

    if (!captchaToken && showCaptcha) {
      return captchaRef?.current?.execute();
    }

    onSend(message);
    setMessage("");
  }

  useEffect(() => {
    if (!isReceivingMessage) {
      changeChatOption(selectedChatOption);
    }
  }, [selectedChatOption]);

  return (
    <div className="me-0 me-lg-3 mt-1 mb-3 mb-md-4">
      {isOpenAttachDocument && (
        <AttachDocumentMenu
          isOpen={isOpenAttachDocument}
          onToggle={onToggle}
          onAttachDocumentByUrl={url => {
            const _hasYoutubeUrl = hasYoutubeUrl(url);

            if (_hasYoutubeUrl) {
              onAttachDocumentByUrl(url);
            } else {
              onAttachDocumentByUrl(url);
            }

            onToggle();
          }}
          onAttachDocumentFile={file => {
            onAttachDocumentFile(file);
            onToggle();
          }}
        />
      )}

      <div className="mx-0 mx-md-3">
        <div className="d-none d-md-block">
          <AttachButton
            isOpen={isOpenAttachDocument}
            onToggle={onToggle}
            isDocument={selectedChat?.isDocument}
          />
        </div>
        {showCaptcha && (
          <HCaptcha
            sitekey={hcaptchaSiteKey!}
            onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
            theme={"light"}
            languageOverride={getCurrentUserLanguage()}
            ref={captchaRef}
          />
        )}

          {!selectedChat?.isDocument && (
              <Swiper
                breakpoints={{
                  0: {
                    slidesPerView: 2.5,
                  },
                  576: {
                    slidesPerView: 3.3,
                  },
                  768: {
                    slidesPerView: 5,
                  },
                }}
                className=" overflow-visible"
              >
                <SwiperSlide>
                  <SelectAssistants
                    dropdownOpen={dropdownOpen}
                    toggle={toggle}
                  />
                </SwiperSlide>
                <SwiperSlide className="d-flex justify-content-center">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input small"
                      type="checkbox"
                      checked={selectedChatOption === "CHAT"}
                      onChange={handleChatOptionChange}
                      value="CHAT"
                      disabled={isReceivingMessage}
                    />
                    <label
                      className="form-check-label d-flex align-items-center gap-2 small user-select-auto"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      <i className="bx bxs-message-rounded-dots"></i>
                      Chat
                    </label>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="d-flex justify-content-center">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input small"
                      type="checkbox"
                      checked={selectedChatOption === "CODE"}
                      onChange={handleChatOptionChange}
                      value="CODE"
                      disabled={isReceivingMessage}
                    />
                    <label
                      className="form-check-label d-flex align-items-center gap-2 small"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      <i className="bx bx-code-alt"></i>
                      Code
                    </label>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="d-flex justify-content-center">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input small"
                      type="checkbox"
                      checked={isBuscaNaWeb}
                      onChange={() => setIsBuscaNaWeb(!isBuscaNaWeb)}
                      value="BUSCA_NA_WEB"
                    />
                    <label
                      className="form-check-label d-flex align-items-center gap-2 small"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      <i className="bx bx-search"></i>
                      Web Search
                    </label>
                  </div>
                </SwiperSlide>
              </Swiper>
          )}

          <div className="d-flex align-items-end gap-3">
            <div
              className="d-flex align-items-end pe-2 gap-2 w-100"
              style={{
                border: "1px solid #d7d7d7",
                borderRadius: 12,
                paddingTop: 4,
                paddingBottom: 8,
              }}
            >
              <InputSection
                value={message}
                onChange={onChangeText}
                assistantDisabled={assistant && !assistant?.enabled}
                onSubmit={onSubmit}
                selectedChat={selectedChat}
                selectedChatOption={selectedChatOption}
              />

              <SubmitChatButton
                onSubmit={onSubmit}
                onCancel={onCancel}
                disabled={
                  (assistant && !assistant?.enabled) ||
                  !message?.trim() ||
                  isReceivingMessage
                }
              />
            </div>
            <AudioRecorder channel={selectedChat} onStopRecorder={onSend} />
          </div>
        </div>
      </div>
  );
};

export default Index;
