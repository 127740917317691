import { LoginPage } from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import { CreateAssistantPage } from "../pages/CreateAssistant";
import { CommunityAssistantsPage } from "../pages/CommunityAssistants";
import Dashboard from "../pages/Dashboard/index";
import { ImagesPage } from "../pages/Images/index";
import { ProfilePage } from "../pages/Profile";
import StarterPage from "../pages/StarterPage/index";
import { MyUserContextProvider } from "../utils/useUser";
import { SubscriptionPage } from "../pages/Subscription";
import NotFoundPage from "../components/NotFoundPage";
import Home from "../pages/Home";
import { getBearerToken } from "../helpers/localStorageHelpers";
import BrandAssets from "../pages/BandAssets";

interface RouteProps {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

const token = getBearerToken();

const publicRoutes: Array<RouteProps> = [
  { path: "/signin", component: <LoginPage /> },
  { path: "/logout", component: <Logout /> },
  { path: "/expirated", component: <Logout /> },
  { path: "/about", component: <Home /> },
  { path: "/", component: <StarterPage /> },
  { path: "*", component: <NotFoundPage /> },
  { path: "brand-assets", component: <BrandAssets /> },
];

const privateRoutes: Array<RouteProps> = [
  { path: "/images", component: <ImagesPage /> },
  { path: "/", component: <StarterPage /> },
  { path: "/assistant/create", component: <CreateAssistantPage /> },
  { path: "/community-assistants", component: <CommunityAssistantsPage /> },
  {
    path: "/profile",
    component: (
      <MyUserContextProvider>
        <ProfilePage />
      </MyUserContextProvider>
    ),
  },
  { path: "/plans", component: <SubscriptionPage /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/dashboard?assistantId=:assistantId", component: <Dashboard /> },
  { path: "*", component: <NotFoundPage /> },
];

export { privateRoutes, publicRoutes };
