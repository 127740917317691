export const SectionFaqI18n = {
  "pt-BR": {
    title: "Perguntas Frequentes",
    paragraph: "Perguntas frequentes sobre Aizzy.ai.",
    button: "Começar Agora",
    faqs: {
      faq1: "O que é Aizzy.ai?",
      response1:
        "Aizzy.ai é uma plataforma alimentada por IA projetada para melhorar a produtividade e eficiência no manuseio de documentos e realização de tarefas variadas, com um forte foco em anonimato e privacidade. Ele oferece ferramentas para interação de documento anônima, busca inteligente, assistência virtual, criação de conteúdo e muito mais.",
      faq2: "Como Aizzy.ai garante meu anonimato e privacidade?",
      response2:
        "Aizzy.ai utiliza protocolos de privacidade avançados e se integra com MetaMask para permitir que os usuários interajam com modelos de IA e agentes sem revelar informações pessoais. Todas as atividades, desde o manuseio de documentos até chats em grupo, são projetadas para serem privadas e seguras.",
      faq3: "Quais recursos Aizzy.ai oferece com anonimato e privacidade?",
      response3: {
        strongLine1:
          "Aizzy.ai oferece uma variedade de recursos focalizados em manter seu anonimato e privacidade, incluindo:",
        strongLine2: "Manuseio de Documento Anônimo:",
        paragrapyline2:
          "Interaja com PDFs, conteúdo da web e vídeos anonimamente.",
        strongLine3: "Busca Inteligente Privada:",
        paragrapyline3:
          "Encontre informações específicas dentro de documentos e mídias, garantindo que suas pesquisas sejam privadas.",
        strongLine4: "Assistência Virtual Anônima:",
        paragrapyline4:
          "Resuma conteúdo de vídeos, sites e PDFs de forma eficiente e anônima.",
        strongLine5: "Criação de Conteúdo Habilitada por IA Privada:",
        paragrapyline5:
          "Crie material atraente, como copy de marketing e posts de blog, enquanto protege sua propriedade intelectual.",
        strongLine6: "Ferramentas Adicionais Anônimas:",
        paragrapyline6:
          "Detecção de texto gerado por IA, tradução de linguagem e conversão de arquivos enquanto mantém anonimato do usuário.",
        strongLine7: "Chats em Grupo Anônimos e Privados: ",
        paragrapyline7:
          "Colabore anonimamente com amigos, colegas ou equipes, garantindo que todas as interações sejam seguras.",
      },
      faq4: "Como posso ganhar tokens AIZ anonimamente?",
      response4:
        "Os usuários podem ganhar tokens AIZ trabalhando, marketing, testando ou contribuindo para Aizzy.ai, tudo enquanto mantém seu anonimato. Engajando-se com as various features da plataforma e participando da comunidade também pode lhe render tokens AIZ sem comprometer sua privacidade.",
      faq5: "O que é o token AIZ usado em termos de privacidade?",
      response5:
        "O token AIZ é o token de utilidade dentro do ecossistema Aizzy.ai. É usado para acessar recursos e serviços da plataforma enquanto garante que suas atividades permaneçam privadas. Ele também pode ser apostado para participar na validação de agentes e modelos e para participar de decisões de governança, mantendo seu anonimato em todos os momentos.",
      faq6: "Como Aizzy.ai lida com meus dados em termos de privacidade?",
      response6:
        "Aizzy.ai prioriza a segurança de dados e privacidade. Todos os materiais de treinamento, dados de inferência e interações de usuário são armazenados criptografados e anonimamente, garantindo que seus dados e identidade permaneçam protegidos a todos os momentos.",
      faq7: "Como começar a usar Aizzy.ai anonimamente?",
      response7:
        "Para começar a usar Aizzy.ai anonimamente, visite nosso site oficial [Aizzy.ai], conecte sua carteira MetaMask e explore os recursos da plataforma. Você pode começar a interagir com documentos, realizar buscas inteligentes, criar conteúdo e muito mais, tudo enquanto mantém sua privacidade e ganha tokens AIZ.",
      faq8: "Posso usar Aizzy.ai sem conhecimento técnico enquanto mantenho anonimato?",
      response8:
        "Sim, Aizzy.ai é projetada para ser acessível a todos. Você pode treinar e utilizar agentes de IA sem precisar de expertise técnica, tudo enquanto mantém seu anonimato. A plataforma lida com as complexidades, permitindo que você se concentre em suas tarefas privadas.",
      faq9: "Como posso contatar o suporte Aizzy.ai anonimamente?",
      response9:
        "Para obter suporte, você pode visitar nosso site oficial e usar o formulário de contato ou contatar nossa equipe de suporte através de nossos canais de mídias sociais. Estamos aqui para ajudar com qualquer pergunta ou problema que você possa ter, tudo enquanto mantém sua privacidade.",
    },
  },
  "en-US": {
    title: "FAQs",
    paragraph: "Frequently Asked Questions.",
    button: "Get Started",
    faqs: {
      faq1: "What is Aizzy.ai?",
      response1:
        "Aizzy.ai is an AI-powered platform designed to enhance productivity and efficiency in handling documents and performing various tasks, with a strong focus on anonymity and privacy. It offers tools for anonymous document interaction, smart search, virtual assistance, content creation, and more.",
      faq2: "How does Aizzy.ai ensure my anonymity and privacy?",
      response2:
        "Aizzy.ai uses advanced privacy protocols and integrates with MetaMask to allow users to interact with AI models and agents without revealing personal information. All activities, from document handling to group chats, are designed to be private and secure.",
      faq3: "What features does Aizzy.ai offer with anonymity and privacy?",
      response3: {
        strongLine1:
          "Aizzy.ai offers a range of features focused on maintaining your anonymity and privacy, including:",
        strongLine2: "Anonymous Document Handling:",
        paragrapyline2:
          "Interact with PDFs, web content, and videos anonymously.",
        strongLine3: "Private Smart Search:",
        paragrapyline3:
          "Quickly find specific information within documents and media, ensuring your searches are private.",
        strongLine4: "Anonymous Virtual Assistance:",
        paragrapyline4:
          "Summarize content from videos, websites, and PDFs efficiently and anonymously.",
        strongLine5: "Private AI-Enabled Content Creation:",
        paragrapyline5:
          "Create engaging material such as marketing copy and blog posts while protecting your intellectual property.",
        strongLine6: "Anonymous Additional Tools:",
        paragrapyline6:
          "AI-generated text detection, language translation, and file conversion while maintaining user anonymity.",
        strongLine7: "APrivate and Anonymous Group Chats: ",
        paragrapyline7:
          "Collaborate anonymously with friends, colleagues, or teams, ensuring all interactions are secure.",
      },
      faq4: "How can I earn AIZ tokens anonymously?",
      response4:
        "Users can earn AIZ tokens by working, marketing, testing, or contributing to Aizzy.ai, all while maintaining their anonymity. Engaging with the platform’s various features and participating in the community can also earn you AIZ tokens without compromising your privacy.",
      faq5: "What is the AIZ token used for in terms of privacy?",
      response5:
        "The AIZ token is the utility token within the Aizzy.ai ecosystem. It is used to access platform features and services while ensuring your activities remain private. It can also be staked for participation in agent and model validation and to participate in governance decisions, maintaining your anonymity throughout.",
      faq6: "How does Aizzy.ai handle my data with privacy?",
      response6:
        "Aizzy.ai prioritizes data security and privacy. All training materials, inference data, and user interactions are stored encrypted and anonymously, ensuring that your data and identity remain protected at all times.",
      faq7: "How do I start using Aizzy.ai anonymously?",
      response7:
        "To start using Aizzy.ai anonymously, visit our official website [Aizzy.ai], connect your MetaMask wallet, and explore the platform's features. You can start interacting with documents, performing smart searches, creating content, and more, all while maintaining your privacy and earning AIZ tokens.",
      faq8: "Can I use Aizzy.ai without technical knowledge while staying anonymous?",
      response8:
        "Yes, Aizzy.ai is designed to be user-friendly and accessible to everyone. You can train and utilize AI agents without needing technical expertise, all while maintaining your anonymity. The platform handles the complexities, allowing you to focus on your tasks privately.",
      faq9: "How can I contact Aizzy.ai support anonymously?",
      response9:
        "For support, you can visit our official website and use the contact form or reach out to our support team through our social media channels. We are here to help with any questions or issues you may have, all while ensuring your privacy is maintained.",
    },
  },
  "es-ES": {
    title: "Preguntas Frecuentes",
    paragraph: "Preguntas frecuentes sobre Aizzy.ai.",
    button: "Empezar Ahora",
    faqs: {
      faq1: "¿Qué es Aizzy.ai?",
      response1:
        "Aizzy.ai es una plataforma impulsada por IA diseñada para mejorar la productividad y eficiencia en el manejo de documentos y realización de tareas variadas, con un fuerte enfoque en anonimato y privacidad. Ofrece herramientas para interacción de documentos anónima, búsqueda inteligente, asistencia virtual, creación de contenido y mucho más.",
      faq2: "¿Cómo Aizzy.ai garantiza mi anonimato y privacidad?",
      response2:
        "Aizzy.ai utiliza protocolos de privacidad avanzados y se integra con MetaMask para permitir que los usuarios interactúen con modelos de IA y agentes sin revelar información personal. Todas las actividades, desde el manejo de documentos hasta chats en grupo, están diseñadas para ser privadas y seguras.",
      faq3: "¿Qué recursos ofrece Aizzy.ai con anonimato y privacidad?",
      response3: {
        strongLine1:
          "Aizzy.ai ofrece una variedad de recursos focalizados en mantener su anonimato y privacidad, incluyendo:",
        strongLine2: "Manejo de Documento Anónimo:",
        paragrapyline2:
          "Interactúe con PDFs, contenido de la web y vídeos anonimamente.",
        strongLine3: "Búsqueda Inteligente Privada:",
        paragrapyline3:
          "Encuentre información específica dentro de documentos y medios, garantizando que sus pesquisas sean privadas.",
        strongLine4: "Asistencia Virtual Anónima:",
        paragrapyline4:
          "Resume contenido de vídeos, sitios y PDFs de forma eficiente y anónima.",
        strongLine5: "Creación de Contenido Habilitada por IA Privada:",
        paragrapyline5:
          "Cree material atractivo, como copias de marketing y posts de blog, mientras protege su propiedad intelectual.",
        strongLine6: "Herramientas Adicionales Anónimas:",
        paragrapyline6:
          "Detección de texto generado por IA, traducción de lenguaje y conversión de archivos mientras mantiene anonimato del usuario.",
        strongLine7: "Chats en Grupo Anónimos y Privados: ",
        paragrapyline7:
          "Colabore anonimamente con amigos, colegas o equipos, garantizando que todas las interacciones sean seguras.",
      },
      faq4: "¿Cómo puedo ganar tokens AIZ anonimamente?",
      response4:
        "Los usuarios pueden ganar tokens AIZ trabajando, marketinando, probando o contribuyendo a Aizzy.ai, todo mientras mantienen su anonimato. Engajando-se con las diversas características de la plataforma y participando de la comunidad también puede rendir tokens AIZ sin comprometer su privacidad.",
      faq5: "¿Qué es el token AIZ usado en términos de privacidad?",
      response5:
        "El token AIZ es el token de utilidad dentro del ecosistema Aizzy.ai. Es utilizado para acceder a recursos y servicios de la plataforma mientras garantiza que sus actividades permanecen privadas. También puede ser apostado para participar en la validación de agentes y modelos y para participar de decisiones de gobernación, manteniendo su anonimato en todos los momentos.",
      faq6: "¿Cómo Aizzy.ai lida con mis datos en términos de privacidad?",
      response6:
        "Aizzy.ai prioriza la seguridad de datos y privacidad. Todos los materiales de entrenamiento, datos de inferencia y interacciones de usuario son almacenados cifrados y anonimamente, garantizando que sus datos y identidad permanecen protegidos en todos los momentos.",
      faq7: "¿Cómo empezar a usar Aizzy.ai anonimamente?",
      response7:
        "Para empezar a usar Aizzy.ai anonimamente, visite nuestro sitio web oficial [Aizzy.ai], conecte su billetera MetaMask y explore los recursos de la plataforma. Puede empezar a interactuar con documentos, realizar búsquedas inteligentes, crear contenido y mucho más, todo mientras mantiene su privacidad y gana tokens AIZ.",
      faq8: "¿Puedo usar Aizzy.ai sin conocimiento técnico mientras mantengo anonimato?",
      response8:
        "Sí, Aizzy.ai es diseñada para ser accesible a todos. Puede entrenar y utilizar agentes de IA sin necesidad de experiencia técnica, todo mientras mantiene su anonimato. La plataforma lida con las complejidades, permitiéndole concentrarse en sus tareas privadas.",
      faq9: "¿Cómo puedo contactar el soporte Aizzy.ai anonimamente?",
      response9:
        "Para obtener soporte, puede visitar nuestro sitio web oficial y utilizar el formulario de contacto o contactar nuestra equipe de soporte a través de nuestros canales de redes sociales. Estamos aquí para ayudar con cualquier pregunta o problema que pueda tener, todo mientras mantiene su privacidad.",
    },
  },
  "fil-PH": {
    title: "Mga Tanong na Madalas",
    paragraph: "Mga tanong na madalas tungkol sa Aizzy.ai.",
    button: "Simula Ngayon",
    faqs: {
      faq1: "Ano ang Aizzy.ai?",
      response1:
        "Aizzy.ai ay isang platform na pinapatakbo ng AI na disenyo para improved ang productivity at efficiency sa pamamahala ng mga dokumento at paggawa ng mga gawain, na may malakas na pokus sa anonimato at privacy. Nagbibigay ito ng mga tool para sa interaksyon ng dokumento na anonymous, smart search, virtual assistance, content creation, at marami pa.",
      faq2: "Paano ang Aizzy.ai nagpaprotekta ng aking anonimato at privacy?",
      response2:
        "Aizzy.ai ay gumagamit ng mga protocol ng privacy at nag-iintegrate sa MetaMask upang hayaan ang mga user na makipag-ugnay sa mga modelo ng IA at mga ahente na hindi nagpapakita ng impormasyong personal. Lahat ng mga aktibidad, mula sa pamamahala ng mga dokumento hanggang sa mga chat sa grupo, ay disenyo upang maging private at secure.",
      faq3: "Ano ang mga resource na inoff gerektir sa Aizzy.ai na may anonimato at privacy?",
      response3: {
        strongLine1:
          "Aizzy.ai ay nagbibigay ng mga resource na nakapokus sa pagpapanatili ng iyong anonimato at privacy, kabilang ang:",
        strongLine2: "Anonymous Document Handling:",
        paragrapyline2:
          "Makipag-ugnay sa mga PDF, nilalaman ng web at mga video sa anonymous way.",
        strongLine3: "Private Smart Search:",
        paragrapyline3:
          "Maghanap ng mga impormasyong specific sa loob ng mga dokumento at mga midya, habang pinoprotektahan ang iyong mga search.",
        strongLine4: "Anonymous Virtual Assistance:",
        paragrapyline4:
          "Summarize ang mga nilalaman ng mga video, mga website at mga PDF sa効biased at anonymous way.",
        strongLine5: "Private Content Creation:",
        paragrapyline5:
          "Lumikha ng mga materiyal na atraktibo, tulad ng mga kop retrofit at mga post ng blog, habang pinoprotektahan ang iyong mga intellectual property.",
        strongLine6: "Additional Anonymous Tools:",
        paragrapyline6:
          "Detection ng mga text generated ng AI, language translation at file conversion habang pinoprotektahan ang iyong anonimato.",
        strongLine7: "Anonymous Group Chats:",
        paragrapyline7:
          "Makipag-ugnay sa mga kaibigan, mga kolega o mga team sa anonymous way, habang pinoprotektahan ang mga interaksiyon.",
      },
      faq4: "Paano ako makakakuha ng mga tokens AIZ sa anonymous way?",
      response4:
        "Ang mga user ay makakakuha ng mga tokens AIZ sa pamamagitan ng paggawa, marketing, testing o pagkontribyute sa Aizzy.ai, lahat ng mga ito ay ginagawa sa anonymous way. Ang makipag-ugnay sa mga feature ng platform at sa mga aktibidad ng komunidad ay makakakuha ng mga tokens AIZ na hindi nagpapakita ng impormasyong personal.",
      faq5: "Ano ang mga token AIZ na ginagamit sa mga terminong privacy?",
      response5:
        "Ang mga token AIZ ay ang mga token ng utility sa loob ng ecosistema ng Aizzy.ai. Ginagamit ito upang makapag-access sa mga resource at mga serbisyo ng platform habang pinoprotektahan ang mga aktibidad sa mga private way. Makakapag-participate din ito sa mga desisyon ng governance habang pinoprotektahan ang anonimato sa lahat ng mga oras.",
      faq6: "Paano ang Aizzy.ai nakikipag-ugnay sa mga datos ko sa mga terminong privacy?",
      response6:
        "Aizzy.ai ay nagpapokus sa seguridad ng mga datos at privacy. Lahat ng mga materiyal ng pagtuturo, mga datos ng inference at mga interaksiyon ng user ay nakastore sa mga encrypted at anonymous way, habang pinoprotektahan ang mga datos at identidad sa lahat ng mga oras.",
      faq7: "Paano ako makakapagsimula sa paggamit ng Aizzy.ai sa anonymous way?",
      response7:
        "Upang makapagsimula sa paggamit ng Aizzy.ai sa anonymous way, bisitahin ang aming opisyal na website [Aizzy.ai], i-connect ang iyong wallet na MetaMask at explorin ang mga resource ng platform. Makakapagsimula na sa pag-interact sa mga dokumento, pag-search ng mga impormasyon, paglikha ng mga materiyal, at marami pa, lahat ng mga ito ay ginagawa sa anonymous way at makakakuha ng mga tokens AIZ.",
      faq8: "Puwede akong gamitin ang Aizzy.ai na hindi nakakaalam ng teknikal na kaalaman habang pinoprotektahan ang anonimato?",
      response8:
        "Oo, Aizzy.ai ay disenyo upang maging accessible sa lahat. Puwede kang mag-train at gamitin ang mga modelo ng IA na hindi naangailangan ng teknikal na kaalaman, lahat ng mga ito ay ginagawa sa anonymous way. Ang platform ay naglilinis ng mga kompleksidad, habang pinoprotektahan ang iyong mga gawain.",
      faq9: "Paano ako makakapag-contact sa support ng Aizzy.ai sa anonymous way?",
      response9:
        "Upang makapag-contact sa support, bisitahin ang aming opisyal na website at gamitin ang formulario ng contact o kontakin ang aming team ng support sa mga kanal ng social media. Nandito kami upang tulungan ka sa anumang mga katanungan o mga problema na mayroon ka, lahat ng mga ito ay ginagawa sa anonymous way.",
    },
  },
};
