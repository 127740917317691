import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useTranslation from "../helpers/useTranslation";
import { SectionFaqI18n } from "./SectionFaq.i18n";

const SectionFaq = () => {
  const { t } = useTranslation(SectionFaqI18n);

  return (
    <section className="section-faq pt-5">
      <div className="justify-content-between">
        <div className="d-flex flex-column">
          <h1 className="font-home size-title-landing-page">{t("title")}</h1>
          <p className="font-home my-4 size-paragrapy-landing-page">
            {t("paragraph")}
          </p>
          <Link
            target="_blank"
            className="btn-lp-dark color-white-buttons font-home"
            to={"https://apb14rabtpp.typeform.com/aizzy-contact"}
          >
            {t("button")}
          </Link>
        </div>
        <div className="mt-5 mt-md-0">
          <div className="accordion" id="accordionExample">
            <div className="card">
              <div className="card-header" id="headingOne">
                <button
                  className=" text-start collapsed size-paragrapy-landing-page"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  {t("faqs.faq1")}
                </button>
              </div>

              <div
                id="collapseOne"
                className="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div className="card-body size-paragrapy-landing-page">
                  {t("faqs.response1")}
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingTwo">
                <button
                  className=" text-start collapsed size-paragrapy-landing-page"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  {t("faqs.faq2")}
                </button>
              </div>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
              >
                <div className="card-body size-paragrapy-landing-page">
                  {t("faqs.response2")}
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingThree">
                <button
                  className=" text-start collapsed size-paragrapy-landing-page"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {t("faqs.faq3")}
                </button>
              </div>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div className="card-body size-paragrapy-landing-page">
                  <p>
                    <strong>{t("faqs.response3.strongLine1")}</strong>
                  </p>
                  <p>
                    <strong>{t("faqs.response3.strongLine2")}</strong>{" "}
                    {t("faqs.response3.paragrapyline2")}
                  </p>
                  <p>
                    <strong>{t("faqs.response3.strongLine3")}</strong>{" "}
                    {t("faqs.response3.paragrapyline3")}
                  </p>
                  <p>
                    <strong>{t("faqs.response3.strongLine4")}</strong>{" "}
                    {t("faqs.response3.paragrapyline4")}
                  </p>
                  <p>
                    <strong>{t("faqs.response3.strongLine5")}</strong>{" "}
                    {t("faqs.response3.paragrapyline5")}
                  </p>
                  <p>
                    <strong>{t("faqs.response3.strongLine6")}</strong>{" "}
                    {t("faqs.response3.paragrapyline6")}
                  </p>
                  <p>
                    <strong>{t("faqs.response3.strongLine7")}</strong>{" "}
                    {t("faqs.response3.paragrapyline7")}
                  </p>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingFour">
                <button
                  className=" text-start collapsed size-paragrapy-landing-page"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  {t("faqs.faq4")}
                </button>
              </div>
              <div
                id="collapseFour"
                className="collapse"
                aria-labelledby="headingFour"
                data-parent="#accordionExample"
              >
                <div className="card-body size-paragrapy-landing-page">
                  {t("faqs.response4")}
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingFive">
                <button
                  className=" text-start collapsed size-paragrapy-landing-page"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  {t("faqs.faq5")}
                </button>
              </div>
              <div
                id="collapseFive"
                className="collapse"
                aria-labelledby="headingFive"
                data-parent="#accordionExample"
              >
                <div className="card-body size-paragrapy-landing-page">
                  {t("faqs.response5")}
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingSix">
                <button
                  className=" text-start collapsed size-paragrapy-landing-page"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  {t("faqs.faq6")}
                </button>
              </div>
              <div
                id="collapseSix"
                className="collapse"
                aria-labelledby="headingSix"
                data-parent="#accordionExample"
              >
                <div className="card-body size-paragrapy-landing-page">
                  {t("faqs.response6")}
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingSeven">
                <button
                  className=" text-start collapsed size-paragrapy-landing-page"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  {t("faqs.faq7")}
                </button>
              </div>
              <div
                id="collapseSeven"
                className="collapse"
                aria-labelledby="headingSeven"
                data-parent="#accordionExample"
              >
                <div className="card-body size-paragrapy-landing-page">
                  {t("faqs.response7")}
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingEight">
                <button
                  className=" text-start collapsed size-paragrapy-landing-page"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  {t("faqs.faq8")}
                </button>
              </div>
              <div
                id="collapseEight"
                className="collapse"
                aria-labelledby="headingEight"
                data-parent="#accordionExample"
              >
                <div className="card-body size-paragrapy-landing-page">
                  {t("faqs.response8")}
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header" id="headingNine">
                <button
                  className=" text-start collapsed size-paragrapy-landing-page"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  {t("faqs.faq9")}
                </button>
              </div>
              <div
                id="collapseNine"
                className="collapse"
                aria-labelledby="headingNine"
                data-parent="#accordionExample"
              >
                <div className="card-body size-paragrapy-landing-page">
                  {t("faqs.response9")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionFaq;
