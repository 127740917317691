import { Modal, ModalBody } from "reactstrap";
import useTranslation from "../../../helpers/useTranslation";
import { ImageI18n } from "../Image.i18n.ts";
import { useChatStore } from "../../../state/chat";

interface ModalImageProps {
  image: any;
  showModal: boolean;
  setShowModal: (isOpen: boolean) => void;
  createNft?: (chatId: string) => void;
  chatId?: any;
}

export function ModalImage({
  image,
  showModal,
  setShowModal,
}: ModalImageProps) {
  const { t } = useTranslation(ImageI18n);

  const { reset, lastImageGenerated } = useChatStore();

  function toggleModal() {
    setShowModal(false);

    setTimeout(() => {
      if (lastImageGenerated) {
        reset();
      }
    }, 500);
  }

  return (
    <Modal
      isOpen={showModal}
      toggle={toggleModal}
      centered
      className="modal-image"
    >
      <ModalBody className="p-0 m-0 rounded-4 border-0 w-100">
        <img src={image} className="amplified-image" alt="" />
      </ModalBody>
    </Modal>
  );
}
/*
<div className="d-flex align-items-center gap-3 mt-2">
          {/* {!image?.isMinted && (
            <Web3Button
              className="web3-button btn bg-primary text-light w-75 fw-semibold fs-6"
              contractAddress={process.env.REACT_APP_NFT_COLLECTION_ADDRESS!}
              action={() => createNft(chatId)}
            >
              Mint NFT
            </Web3Button>
          )}
          {false && image?.isMinted && (
            <a
              className="btn btn-primary fw-semibold fs-6"
              href={`https://${
                process.env.REACT_APP_NFT_CHAIN === "mumbai" ? "testnets." : ""
              }opensea.io/assets/${
                process.env.REACT_APP_NFT_CHAIN === "mumbai"
                  ? "mumbai"
                  : "matic"
              }/${process.env.REACT_APP_NFT_COLLECTION_ADDRESS}/${image.nftId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View NFT
            </a>
          )}
        </div>
*/
