import icon from "../assets/images/icons/aizzy-icon-dark.svg";

const CardFeatureItem = ({ h3, paragraph, img }) => {
  return (
    <div className="section-feature-item">
      <div>
        {img && <img src={icon} alt="" width={50} height={50} />}
        <h3 className="font-home">{h3}</h3>
        <p className="size-paragrapy-landing-page">{paragraph}</p>
      </div>
    </div>
  );
};

export default CardFeatureItem;
