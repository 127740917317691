import clsx from "clsx";
import { Card } from "../../../components/Card";
import { dataLayerPush } from "../../../utils/gaEvents";
import LogoDarkSvg from "../../../assets/images/icons/aizzy-icon-dark.svg";
import useTranslation from "../../../helpers/useTranslation";
import { SupportI18n } from "./Support.i18n";
import { getCurrentUserLanguage } from "../../../helpers/userHelper";

const supportList = {
  pt: [
    {
      name: "AizzyFale",
      iconClassname: "bxl-whatsapp",
      link: "https://chat.whatsapp.com/Evt1O26vsyt5ulXjAGo28S",
    },
    // {
    //   name: "Sobre nós",
    //   //iconClassname: "bxl-whatsapp",
    //   image: LogoDarkSvg,
    //   link: "/about",
    // },
  ],
  en: [
    {
      name: "AizzyTalk",
      iconClassname: "bxl-whatsapp",
      link: "https://chat.whatsapp.com/BrsIuQfRlqN7hyaKTv4PUx",
    },
  ],
  es: [
    {
      name: "AizzyHable",
      iconClassname: "bxl-whatsapp",
      link: "https://chat.whatsapp.com/KAWSdBPCG8TAw2aE0oscgZ",
    },
  ],
};

export function Support({
  center = true,
  className,
}: {
  center?: boolean;
  className?: string;
}) {
  const { t } = useTranslation(SupportI18n);
  const userLanguage = getCurrentUserLanguage();
  const defaultLanguage = "en";

  const currentSupportList =
    supportList[userLanguage] || supportList[defaultLanguage];

  return (
    <div className={`row ${className}`}>
      <div className="col-12">
        <div
          className={`row d-flex ${
            center ? "justify-content-center" : ""
          } mx-auto g-3`}
          style={{ columnGap: 20 }}
        >
          <div className={`w-100 ${center ? "text-center" : ""} p-0 mb-2`}>
            <h4 className="bg-opacity-25 rounded-2">{t("page.supportTile")}</h4>
          </div>
          {currentSupportList.map((item, index) => (
            <div
              className={`mt-0 p-0 col-auto d-flex ${
                center ? "justify-content-center" : ""
              }`}
              key={`social-item-${index}`}
            >
              <div className="d-flex flex-column align-items-center">
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-sm p-0 d-flex flex-column justify-content-center align-items-center"
                  onClick={() =>
                    dataLayerPush({
                      event: "click",
                      button_label: `${item.iconClassname.replace(
                        "bxl-",
                        ""
                      )} ${item.name}`,
                    })
                  }
                >
                  {item.iconClassname ? (
                    <i
                    className={clsx("mb-2 bx", item.iconClassname)}
                    style={{ fontSize: 36 }}
                  />
                  ) : (
                    <img src={item.image} className="mb-2" loading="lazy" height={36} width={36} alt="" />
                  )}
                  <span className="text-primary text-opacity-75 hover-primary">
                    {item.name}
                  </span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
