export const AuthHeaderI18n = {
  "pt-BR": {
    buttons: {
      signIn: "Entrar",
      signUp: "Criar conta",
    },
  },
  "en-US": {
    buttons: {
      signIn: "Sign In",
      signUp: "Sign Up",
    },
  },
  "es-ES": {
    buttons: {
      signIn: "Iniciar sesión",
      signUp: "Registrarse",
    },
  },
  "fil-PH": {
    buttons: {
      signIn: "Mag-sign In",
      signUp: "Mag-sign Up",
    },
  },
};
