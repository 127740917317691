import { useEffect, useState } from "react";
import { Card } from "../../../components/Card";
import { getAffiliateProgramReport } from "../../../api/profile";
import { formateDate } from "../../../utils";
import { AffiliateDisclaimerInfo } from "./AffiliateCard";
import useTranslation from "../../../helpers/useTranslation";
import { AffiliateProgramTaximeterI18n } from "./AffiliateProgramTaximeter.i18n";

const AffiliateProgramTaximeter = () => {
  const [report, setReport] = useState<any>();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(AffiliateProgramTaximeterI18n);

  useEffect(() => {
    setLoading(true);
    getAffiliateProgramReport()
      .then(data => setReport(data))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div>
        <h3>{t("title")}</h3>
        <div className="w-100 d-flex justify-content-center">
          <i className="bx bx-loader bx-spin align-middle text-primary" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <h4>{t("title")}</h4>
      <div>
        {report && report?.length > 0 && (
          <>
            <table className="table table-bordered">
              <thead>
                <tr className="align-items-center">
                  <th>{t("month")}</th>
                  <th>{t("amounToReceive")}</th>
                  <th>{t("users")}</th>
                </tr>
              </thead>
              <tbody>
                {report?.map((item: any) => (
                  <tr key={item?.month}>
                    <td>{formateDate(item?.month, "MM/yyyy")}</td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                      }).format(item?.amount_to_receive || 0)}{" "}
                      USDT
                    </td>
                    <td>{item?.number_of_users}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <small
              className="mt-5"
              dangerouslySetInnerHTML={{ __html: t("importantNotice") }}
            />
          </>
        )}
        {!report && (
          <div className="text-center">
            <p>{t("notice")}</p>
            <AffiliateDisclaimerInfo />
          </div>
        )}
      </div>
    </div>
  );
};

export default AffiliateProgramTaximeter;
