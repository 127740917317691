export const SliderSelectRatioI18n = {
  "pt-BR": {
    slider: {
      label: "Selecione a proporção da imagem:",
    },
  },
  "en-US": {
    slider: {
      label: "Select the image ratio:",
    },
  },
  "es-ES": {
    slider: {
      label: "Selecciona la proporción de la imagen:",
    },
  },
  "fil-PH": {
    slider: {
      label: "Pumili ng proporsyon ng larawan:",
    },
  },
};
