import AppSimpleBar from "../../components/AppSimpleBar";
import useTranslation from "../../helpers/useTranslation";
import { checkIfWeb3User, getUser } from "../../helpers/userHelper";
import { ProfileI18n } from "./Profile.i18n";
import { Menu } from "./components/Menu";

export function ProfilePage() {
  const user = getUser();

  const { t } = useTranslation(ProfileI18n);
  const userName = user?.email?.includes("@")
    ? user?.email.split("@")[0]
    : null;
  let userIdWallet = "";
  if (user?.email) {
    const atIndex = user.email.indexOf("@");
    if (atIndex !== -1) {
      userIdWallet = `${user.email.slice(0, 10)}...${user.email.slice(
        atIndex - 6,
        atIndex
      )}`;
    }
  }

  const displayName =
    user?.email && user.email.length > 40 ? userIdWallet : userName;

  return (
    <AppSimpleBar className="w-100" style={{ height: "100vh" }}>
      <div className="p-2 p-lg-4 w-100 animate__animated animate__fadeInLeft animate__fast mb-5 mb-md-0 overflow-hidden">
        <div className=" pb-3 pb-md-0">
          <div className="d-flex flex-column px-3 pt-3">
            <span className="text-primary fs-2">
              {t("page.greetings.hello")}
              <strong>{displayName}!</strong>
            </span>
            <span>{t("page.greetings.greeting")}</span>
          </div>
          <div className="mb-5">
            <Menu />
          </div>
        </div>
      </div>
    </AppSimpleBar>
  );
}
