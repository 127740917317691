import { useEffect, useState } from "react";

// hooks
import { useRedux } from "../../../hooks/index";

// actions
import { changeSelectedChat, toggleOpenLeftBar } from "../../../redux/actions";

// interfaces

// components
import AppSimpleBar from "../../../components/AppSimpleBar";

import Loader from "../../../components/Loader";
import { TABS } from "../../../constants";
import useTranslation from "../../../helpers/useTranslation";
import AssistantHeader from "./AssistantHeader";
import { AssistantsChatsI18n } from "./AssistantsChats.i18n";
import Chanels from "./Chanels";
import { useUser } from "@supabase/auth-helpers-react";
import { Link } from "react-router-dom";
import { SelectedChatDTO } from "../../../@DTO/selectedChat";
import { htmlDecode, isMobileMenuChat } from "../../../utils/helpers";

import "animate.css";
import { AssistantDTO } from "../../../@DTO/assistant";
import { useMutation, useQuery } from "react-query";
import { ChannelDTO } from "../../../@DTO/channel";
import { QUERY_KEYS, queryClient } from "../../../libs/react-query";
import { createChannel, getChannelsAndDocuments } from "../../../api";
import { useChannels } from "../../../hooks/useChannels";

import {
  ChatRepository,
  makeChatId,
} from "../../../helpers/indexedDb/chat-repository";

const Index = () => {
  // const [showModal, setShowModal] = useState(false);

  const { data: channels, isLoading: isLoadingChannels } = useQuery<
    ChannelDTO[]
  >(
    QUERY_KEYS.CHANNELS,
    async () => {
      const data = await getChannelsAndDocuments();
      return data.channels ?? [];
    },
    {
      staleTime: 180 * 60 * 1000,
    }
  );

  const chatRepository = new ChatRepository();

  const { isLoading: isCreatingGeneralChat, mutateAsync } = useMutation(
    async (channel: any) => {
      return await createChannel(channel);
    }
  );

  const { handleCreateChannel, loadingState, handleChannelClear } = useChannels(
    channels || []
  );

  const { dispatch, useAppSelector } = useRedux();
  const { assistant, selectedChat, activeTab, isOpenLeftBar } = useAppSelector(
    state => ({
      assistant: state.Chats.assistant as AssistantDTO,
      selectedChat: state.Chats.selectedChat as SelectedChatDTO,
      activeTab: state.Layout.activeTab,
      isOpenLeftBar: state.Layout.isOpenLeftBar,
      layoutMode: state.Layout.layoutMode,
    })
  );

  const { t } = useTranslation(AssistantsChatsI18n);

  const user = useUser();

  const isGeneralChat = selectedChat?.channelName?.toLowerCase() === "general";

  const isOwnerAssistant = assistant?.userId === user?.id;

  function onSelectChat(channel: ChannelDTO) {
    dispatch(changeSelectedChat(channel));
  }

  function handleToggleLeftBar() {
    dispatch(toggleOpenLeftBar(!isOpenLeftBar));
  }

  async function handleOpenGeneralChat() {
    if (!channels || (channels && channels.length === 0)) {
      return createGeneralChat();
    }

    const channel = channels.find(
      (channel: any) =>
        channel?.channelName === "general" &&
        channel?.assistantId === assistant?._id &&
        !selectedChat?.isDocument &&
        !selectedChat?.isYoutube
    );

    if (channel) {
      return onSelectChat(channel);
    }

    createGeneralChat();
  }

  async function createGeneralChat() {
    const channelData = {
      isDocument: false,
      assistantId: assistant?._id,
      channelName: "general",
    };

    const channelCreated = await mutateAsync(channelData);

    const cachedChannels = queryClient.getQueryData<ChannelDTO[]>(
      QUERY_KEYS.CHANNELS
    );

    queryClient.setQueryData<ChannelDTO[]>(QUERY_KEYS.CHANNELS, [
      ...cachedChannels!,
      channelCreated,
    ]);

    await chatRepository.add({
      id: makeChatId(
        channelCreated._id,
        channelCreated.assistantId,
        channelCreated.userId
      ),
      messages: [],
    });

    onSelectChat(channelCreated);
  }

  useEffect(() => {
    if (activeTab === TABS.ASSISTANT_CHATS && !isLoadingChannels) {
      handleOpenGeneralChat();
    }
  }, [activeTab, isLoadingChannels]);

  useEffect(() => {
    if (activeTab === TABS.ASSISTANT_CHATS) {
      dispatch(toggleOpenLeftBar(!isOpenLeftBar));
    }
  }, [activeTab]);

  return (
    <>
      <div>
        {/* {createChannelLoading && <Loader />} */}
        {isOpenLeftBar || isMobileMenuChat ? (
          <AppSimpleBar className="chat-room-list animate__animated animate__fadeInDown animate__fast">
            <div className="p-3">
              <div className="d-flex align-items-start">
                <div className="flex-grow-1 d-flex align-items-center gap-1 justify-content-between">
                  <AssistantHeader assistant={assistant} />
                  <button
                    className="btn fs-3 m-0 p-0 d-lg-flex d-none"
                    onClick={handleToggleLeftBar}
                  >
                    <i className="bx bx-arrow-to-left" />
                  </button>
                </div>
              </div>
            </div>

            {assistant && (
              <div className="p-3">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1 animate__animated animate__fadeInDown">
                    {isOwnerAssistant && (
                      <Link
                        to={{
                          pathname: "/assistant/create",
                        }}
                        state={{
                          assistantId: assistant._id,
                        }}
                        className="mb-3 w-100 d-flex align-items-center justify-content-center gap-2 btn btn-primary"
                      >
                        {t("button.edit-assistant")}{" "}
                        <i className="bx bx-edit" />
                      </Link>
                    )}
                    <p className="font-size-14 m-0">
                      {htmlDecode(assistant?.name)} {t("page.description")}{" "}
                      <strong>{htmlDecode(assistant?.role)}</strong>.
                    </p>
                    {assistant?.userName && (
                      <div className="small mt-3 ">
                        <span className="px-3">
                          {t("owner.span")}{" "}
                          <span className="text-primary text-opacity-75 ">
                            {assistant?.userName}
                          </span>
                          {", "}
                          {t("owner.description")}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="py-3">
              <button
                className="w-100 btn border-0 d-flex align-items-center gap-2"
                onClick={handleOpenGeneralChat}
                disabled={isGeneralChat || isCreatingGeneralChat}
              >
                {isCreatingGeneralChat ? (
                  <i className="bx bx-loader bx-spin align-middle" />
                ) : (
                  <div className="d-flex align-items-center justify-content-center btn-primary button-border-sm button-small">
                    <i className="bx bx-chat" />
                  </div>
                )}
                {t("button.general")}
              </button>
            </div>

            {channels ? (
              <Chanels
                channels={channels.filter(
                  channel =>
                    channel?.assistantId === assistant?._id &&
                    channel?.channelName !== "general" &&
                    !channel.isImage
                )}
                selectedChat={selectedChat}
                onSelectChat={onSelectChat}
                assistant={assistant}
              />
            ) : (
              <Loader />
            )}
          </AppSimpleBar>
        ) : (
          <div
            className="p-3 d-flex flex-column align-items-start align-items-lg-center gap-2 bg-body vh-100 me-3 shadow-left-bar"
            style={{ width: 70 }}
          >
            <button
              className="btn fs-4 m-0 px-0 border-0"
              onClick={handleToggleLeftBar}
            >
              <i className="bx bx-arrow-to-right" />
              <p className=" font-size-10">{t("button.expand")}</p>
            </button>
            <button
              className="btn fs-4 m-0 px-0 border-0"
              onClick={handleCreateChannel}
              disabled={loadingState.createChannel}
            >
              {loadingState.createChannel ? (
                <i className="bx bx-loader-circle bx-spin" />
              ) : (
                <i className="bx bxs-message-square-add" />
              )}
              <p className=" font-size-10">{t("button.newChat")}</p>
            </button>
            <button
              className="btn fs-4 m-0 px-0 border-0 mt-1"
              onClick={handleChannelClear}
              disabled={loadingState.clearChannel}
            >
              {loadingState.clearChannel ? (
                <i className="bx bx-loader-circle bx-spin" />
              ) : (
                <i className="bx bxs-message-square-x" />
              )}
              <p className=" font-size-10">{t("button.clearChat")}</p>
            </button>
          </div>
        )}
      </div>

      {/* {showModal && (
        <NewAssistantChannelModal isOpen={showModal} onClose={toggleModal} />
      )} */}
    </>
  );
};

export default Index;
