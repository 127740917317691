import classnames from "classnames";
import { useEffect, useState } from "react";
import { AssistantDTO } from "../../../../@DTO/assistant";

interface ContactItemProps {
  assistant: AssistantDTO;
  onSelectAssistant: (assistant: AssistantDTO) => void;
}

const ContactItem = ({ assistant, onSelectAssistant }: ContactItemProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <li
      className={classnames("card-assistants", {
        "card-assistants-loaded": isLoaded,
      })}
      onClick={() => onSelectAssistant(assistant)}
    >
      <div className="d-flex align-items-center gap-2 py-2">
        <div className="flex-shrink-0 me-2">
          <div className="avatar-lg overflow-hidden rounded-1">
            <img
              src={assistant.profileImage}
              alt=""
              className="img-fluid avatar radius-avatar"
            />
          </div>
        </div>
        <div className="flex-grow-1 gap-2">
          <p className="font-size-18 m-0" style={{ fontWeight: 600 }}>
            {assistant.role}
          </p>
          <p className="font-size-14 m-0 text-dark text-opacity-75">
            {assistant.name}
          </p>
        </div>
      </div>
    </li>
  );
};
interface CharacterItemProps {
  assistants: AssistantDTO[] | undefined;
  onSelectAssistant: (assistant: AssistantDTO) => void;
}
const CharacterItem = ({
  assistants,
  onSelectAssistant,
}: CharacterItemProps) => {
  return (
    <ul className="list-unstyled contact-list px-3" id="contact_list">
      {(assistants || []).map((assistant, key: number) => (
        <ContactItem
          assistant={assistant}
          key={key}
          onSelectAssistant={onSelectAssistant}
        />
      ))}
    </ul>
  );
};

export default CharacterItem;
