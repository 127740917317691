export const ProfileI18n = {
  "pt-BR": {
    page: {
      greetings: {
        hello: "Olá ",
        greeting: "Seja bem-vindo(a) ao seu perfil",
      },
    },
  },
  "en-US": {
    page: {
      greetings: {
        hello: "Hello ",
        greeting: "Welcome to your profile",
      },
    },
  },
  "es-ES": {
    page: {
      greetings: {
        hello: "Hola ",
        greeting: "Bienvenido/a a tu perfil",
      },
    },
  },
  "fil-PH": {
    page: {
      greetings: {
        hello: "Kamusta ",
        greeting: "Maligayang pagdating sa iyong profile",
      },
    },
  },
};
