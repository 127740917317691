import LogoDarkSvg from "../../assets/images/icons/aizzy-icon-dark.svg";
import AppSimpleBar from "../../components/AppSimpleBar";

import "animate.css";

import { AizzyActions } from "./components/AizzyActions";
import { BannerPix } from "./components/BannerPix";
import { getBearerToken } from "../../helpers/localStorageHelpers";
import { AuthHeader } from "./components/AuthHeader";
import { checkIfWeb3User, getUser } from "../../helpers/userHelper";

import SurveyNPS from "../../components/survey-nps/SurveyNPS";

import useTranslation from "../../helpers/useTranslation";
import { SectionFeaturesI18n } from "../../components/SectionFeatures.i18n";
import { FreeBanner } from "./components/FreeBanner";

const StartPage = () => {
  const user = getUser();

  const token = getBearerToken();
  const { t } = useTranslation(SectionFeaturesI18n);

  const isMobile = window.matchMedia("(max-width: 700px)").matches;

  function setMarginTop() {
    if (token) {
      return 0;
    }

    return isMobile ? 90 : 60;
  }

  return (
    <AppSimpleBar className="w-100" style={{ height: "100vh" }}>
      <div>
        <main
          className="pb-5 mb-sm-0 pb-sm-5 mx-auto d-flex flex-column align-items-center px-2 px-xl-0 overflow-hidden"
          style={{
            maxWidth: window.matchMedia("(min-width: 1200px)").matches
              ? 1260
              : 1060,
          }}
        >
          {!token && <AuthHeader />}

          <div
            className={"row w-100"}
            style={{
              marginTop: setMarginTop(),
            }}
          >
            <div className="text-center mt-4 mt-sm-5">
              <img src={LogoDarkSvg} alt="logo" width="50" />
              <h2 style={{ marginTop: 20, textAlign: "center" }}>
                {t("title")}
              </h2>
              {/* <p className="font-size-16 mt-4 bg-primary bg-opacity-25 p-2">
                {t("page.description")}
              </p> */}
            </div>
          </div>

          {/* <FreeBanner /> */}

          <div
            className="row g-3 mt-4"
            style={{
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <div
              style={{
                marginTop: 32,
              }}
            >
              <AizzyActions />
            </div>

            {/* <section
              className={clsx(
                "mt-5 mb-3 mx-auto w-75 d-flex flex-column align-items-center justify-content-center gap-3",
                isMobile && "w-100"
              )}
            >
              <h4 className="text-center">
                {t("interactingWhatsapp.title")}{" "}
                <span className="text-primary m-0">
                  Whatsapp <i className="bx bxl-whatsapp fw-bold" />
                </span>
              </h4>
              <ModalWhatsappNumber />
            </section> */}

            {user?.user_metadata?.ip_info?.country_name === "Brazil" &&
              !checkIfWeb3User() && (
                <section
                  style={{
                    marginTop: 80,
                  }}
                >
                  <BannerPix />

                  {/* {!isPremiumUser && <BannerPremium />} */}
                </section>
              )}
          </div>
          <SurveyNPS />
          <p className="mb-5" style={{ paddingTop: 70 }}>
            &copy; {new Date().getFullYear()} AIZZY
          </p>
        </main>
      </div>

      {/* {!user?.id && <AizzyDetails />} */}
    </AppSimpleBar>
  );
};

export default StartPage;
