import { useQuery } from "react-query";
import { AssistantDTO } from "../../../../@DTO/assistant";
import { QUERY_KEYS } from "../../../../libs/react-query";
import { getPrivateCommunityAssistants } from "../../../../api/assistants";
import { OwnerAssistants } from "../../../../components/OwnerAssistants";
import { HiredCommunityAssistants } from "../../../../components/HiredCommunityAssistants";
import { AssistantCardSkeleton } from "../../../../components/AssistantCardSkeleton";
import { useEffect } from "react";
import { FetchMyAssistantsResponse } from "../types";

interface MyAssistantsProps {
  onSelectAssistant: (assistant: AssistantDTO) => void;
}

export function MyAssistants({ onSelectAssistant }: MyAssistantsProps) {
  const { data, isLoading, refetch, isRefetching } =
    useQuery<FetchMyAssistantsResponse>(
      QUERY_KEYS.MY_ASSISTANTS,
      async () => {
        return await getPrivateCommunityAssistants({});
      },
      {
        staleTime: 120 * 60 * 1000, // 2 houra
        keepPreviousData: true,
      }
    );

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <>
      {isLoading || isRefetching ? (
        <AssistantCardSkeleton quantity={16} />
      ) : (
        <div className="pb-5 animate__animated animate__fadeInLeft animate__faster">
          <OwnerAssistants
            myAssistants={data?.assistants || []}
            onSelectAssistant={onSelectAssistant}
          />
          {/* <HiredCommunityAssistants
            onSelectAssistant={onSelectAssistant}
            hiredAssistants={data?.hired}
          /> */}
        </div>
      )}
    </>
  );
}
