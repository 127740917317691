/* eslint-disable no-useless-escape */
import { useContext, useState } from "react";

import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";

// interfaaces
import { useRedux } from "../../../../hooks";
import {
  changeSelectedChat,
  getChannelJobStatus,
} from "../../../../redux/actions";

// components
import useTranslation from "../../../../helpers/useTranslation";
import { NewDocumentModalI18n } from "./NewDocumentModal.i18n";
import { FileUploader } from "react-drag-drop-files";
import {
  showErrorNotification,
  showWarningNotification,
} from "../../../../helpers/notifications";
import RemainingCallsContext from "../../../../freemium/RemainingCallsContext";
import { createChannel } from "../../../../api";
import {
  cleanFileForChannelName,
  cleanUrlForChannelName,
} from "../../../../utils/stringutils";
import { getCurrentUserPlan } from "../../../../helpers/userHelper";
import { PLANS_AIZZY } from "../../../../helpers/constants/Plans";
import { ChannelDTO } from "../../../../@DTO/channel";
import { delayInMs } from "../../../../utils/helpers";
import {
  createDocumentEmbeddingUpload,
  createWebsiteEmbeddings,
} from "../../../../api/embeddings";
import { QUERY_KEYS, queryClient } from "../../../../libs/react-query";

type AttachmentParams = {
  channelName: string;
  assistantId: string | undefined;
  isDocument: boolean;
  itemTitle: string;
};

interface NewDocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export function NewDocumentModal({ isOpen, onClose }: NewDocumentModalProps) {
  const [url, setUrl] = useState<string>("");

  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    isChannelCreated: false,
    isDocumentCreated: false,
  });

  const [attachmentBeingCreated, setAttachmentBeingCreated] = useState("");

  const { pdfSizeLimit, setShowFreemiumModal } = useContext(
    RemainingCallsContext
  );

  const { dispatch } = useRedux();

  const { t } = useTranslation(NewDocumentModalI18n);

  const isPremiumUser = getCurrentUserPlan() === PLANS_AIZZY.PREMIUM;

  const typesSupported = [
    "pdf",
    "docx",
    "pptx",
    "odt",
    "odp",
    "jpg",
    "jpeg",
    "png",
    "bmp",
    "gif",
    "tif",
    "tiff",
    "tex",
    "txt",
    "rtf",
  ];

  function handleCloseModal() {
    if (loadingState.isLoading) return;
    onClose();
  }

  async function handleAttachFile(file: File) {
    try {
      setLoadingState({
        isLoading: true,
        isChannelCreated: false,
        isDocumentCreated: false,
      });

      setAttachmentBeingCreated(file.name);

      const channel: ChannelDTO = await createChannel({
        channelName: cleanFileForChannelName((file as File).name),
        assistantId: null,
        isDocument: true,
        itemTitle: (file as File).name.substring(0, 60),
      });

      updateChannelsCache(channel);

      setLoadingState({
        isLoading: true,
        isChannelCreated: true,
        isDocumentCreated: false,
      });

      await createDocumentEmbeddingUpload(channel._id, file);

      setLoadingState({
        isLoading: true,
        isChannelCreated: true,
        isDocumentCreated: true,
      });

      await delayInMs(1000);

      dispatch(changeSelectedChat(channel));
      dispatch(getChannelJobStatus(channel._id));

      onClose();
    } catch (error: any) {
      console.error(error);
      showErrorNotification(
        error?.message ??
          "Error when sending file, please try again or contact support."
      );
    } finally {
      setLoadingState({
        isLoading: false,
        isChannelCreated: false,
        isDocumentCreated: false,
      });
    }
  }

  async function handleAttachUrl(url: string) {
    try {
      const isYoutube = /https?:\/\/(?:www\.)?youtube\.com\/[^\s]+/gi.test(url);

      if (isYoutube) {
        showWarningNotification(t("docs.notifications.youtubeWarn"), {
          duration: 4500,
        });

        return setUrl("");
      }

      const isValidUrl =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w \.-]*)*\/?\??([^#\n\r]*)?#?([^#\n\r]*)?$/.test(
          url
        );

      if (!isValidUrl) {
        return showErrorNotification(t("docs.notifications.invalidUrl"));
      }

      setLoadingState({
        isLoading: true,
        isChannelCreated: false,
        isDocumentCreated: false,
      });

      setAttachmentBeingCreated(url.substring(0, 180));

      const channel: ChannelDTO = await createChannel({
        channelName: cleanUrlForChannelName(url),
        assistantId: null,
        isDocument: true,
        itemTitle: url.substring(0, 60),
      });

      updateChannelsCache(channel);

      setLoadingState({
        isLoading: true,
        isChannelCreated: true,
        isDocumentCreated: false,
      });

      await createWebsiteEmbeddings({
        url,
        channelId: channel._id,
      });

      setLoadingState({
        isLoading: true,
        isChannelCreated: true,
        isDocumentCreated: true,
      });

      await delayInMs(1000);

      dispatch(changeSelectedChat(channel));
      dispatch(getChannelJobStatus(channel._id));

      onClose();
    } catch (error: any) {
      console.error(error);
      showErrorNotification(
        error?.message ??
          "Error when sending url, please try again or contact support."
      );
    } finally {
      setLoadingState({
        isLoading: false,
        isChannelCreated: false,
        isDocumentCreated: false,
      });
    }
  }

  function updateChannelsCache(channel: ChannelDTO) {
    const cachedChannels = queryClient.getQueryData<ChannelDTO[]>(
      QUERY_KEYS.DOCUMENTS
    );

    if (cachedChannels) {
      queryClient.setQueryData(QUERY_KEYS.DOCUMENTS, [
        ...cachedChannels,
        channel,
      ]);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleCloseModal}
      tabIndex={-1}
      centered
      scrollable
      role="dialog"
      backdrop="static"
    >
      <ModalHeader
        className="modal-title-custom border-0"
        toggle={!loadingState.isLoading ? handleCloseModal : undefined}
      >
        {loadingState.isLoading ? t("processing.title") : t("page.label")}
      </ModalHeader>

      <ModalBody className="p-4 border-0 pt-2">
        {loadingState.isLoading ? (
          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex flex-column gap-2">
              <span className="fs-6">
                {t("processing.itemProcessing")}
                <strong className="text-primary">
                  {attachmentBeingCreated}
                </strong>
              </span>
              <div className="d-flex flex-column gap-2 mt-2">
                <span className="d-flex align-items-center gap-1">
                  {loadingState.isChannelCreated ? (
                    <i className="bx bx-check-circle text-primary" />
                  ) : (
                    <i className={`bx bx-loader bx-spin`} />
                  )}
                  {t("processing.creatingChat")}
                </span>
                <span className="d-flex align-items-center gap-1">
                  {loadingState.isDocumentCreated ? (
                    <i className="bx bx-check-circle text-primary" />
                  ) : (
                    <i className={`bx bx-loader bx-spin`} />
                  )}
                  {t("processing.sendingContent")}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="mb-2 d-flex align-items-center justify-content-between">
              <Label htmlFor="addgroupname-input" className="form-label m-0">
                {t("docs.title")}
                <span className="text-danger">*</span>
              </Label>
              <div>
                <button
                  className="btn btn-sm text-decoration-none"
                  id="types-popover"
                  type="button"
                >
                  <span className="link-primary">
                    {t("docs.typesSupported")}
                  </span>
                </button>
                <UncontrolledPopover target="types-popover" placement="top">
                  <PopoverBody className="row text-center gap-2">
                    {typesSupported.map(ext => {
                      return (
                        <span
                          key={ext}
                          className="col-2 text-nowrap bg-body p-2"
                        >
                          .{ext}
                        </span>
                      );
                    })}
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            </div>
            <FileUploader
              classes="dropzone"
              label={t("docs.placeholder")}
              maxSize={pdfSizeLimit}
              disabled={loadingState.isLoading}
              handleChange={file => handleAttachFile(file)}
              onSizeError={() => {
                if (!isPremiumUser) {
                  setShowFreemiumModal("pdfSize");
                } else {
                  showErrorNotification(
                    t("docs.notifications.error", { pdfSizeLimit }),
                    {
                      duration: 5000,
                    }
                  );
                }
              }}
              name="file"
              types={typesSupported}
            />
            <div className="d-flex align-items-center justify-content-center my-2 mt-3 fs-4">
              <hr style={{ flex: 1, marginRight: "16px" }} />
              <span className="mb-1">{t("page.or")}</span>
              <hr style={{ flex: 1, marginLeft: "16px" }} />
            </div>

            <div>
              <Label
                htmlFor="addgroupname-input"
                className="form-label m-0 mb-2"
              >
                {t("link.title")}
                <span className="text-danger">*</span>
              </Label>

              <div className="d-flex justify-content-center gap-3">
                <Input
                  type="text"
                  className="form-control form-control-lg chat-input"
                  id="chat-input"
                  placeholder={t("link.placeholder")}
                  autoFocus
                  disabled={loadingState.isLoading}
                  value={url}
                  onChange={(e: any) => setUrl(e.target.value)}
                />
                <Button
                  className="btn d-flex align-items-center gap-2"
                  style={{ background: "none", border: "none" }}
                  disabled={loadingState.isLoading || !url}
                  onClick={() => handleAttachUrl(url.trim())}
                >
                  {loadingState.isLoading ? (
                    <i className="bx bx-loader bx-spin align-middle"></i>
                  ) : (
                    <>
                      <div
                        className="icon btn-primary button-border-sm button-small d-flex align-items-center justify-content-center"
                      >
                        <i
                          className={"bx bxs-message-square-add"}
                        />
                      </div>
                      <p style={{ color: "#000"}}>
                        {t("button.create")}
                      </p>
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}
