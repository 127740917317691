export const SectionFeaturesI18n = {
  "pt-BR": {
    title: "Seu prompt é só seu",
    subTitleCard1: "Anonimato",
    subTitleCard2: "Privacidade",
    subTitleCard3: "Flexibilidade",
    subTitleCard4: "Segurança",
    paragraphCard1:
      "A utilidade AIZ com MetaMask permite que qualquer pessoa acesse a IA sem fornecer informações pessoais ou ser rastreada.",
    paragraphCard2:
      "Fornecemos um ambiente privado para que você possa criar seus próprios GPTs anonimamente. Cuidamos da sua identidade e seus dados.",
    paragraphCard3:
      "Você pode escolher entre diferentes modelos LLM como deseja, garantindo que obtenha os melhores resultados para suas necessidades.",
    paragraphCard4:
      "Armazene seu material de treinamento criptografado, anonimamente, separadamente e de forma segura, permitindo agentes customizados privados.",
  },
  "en-US": {
    title: "Your prompt remains yours",
    subTitleCard1: "Anonymity",
    subTitleCard2: "Privacy",
    subTitleCard3: "Flexibility",
    subTitleCard4: "Security",
    paragraphCard1:
      "AIZ Utility with MetaMask allows anyone to access AI without giving away personal information or being tracked.",
    paragraphCard2:
      "We provide a private environment so you can create your own GPTs anonymously. We care about your identity and your data.",
    paragraphCard3:
      "You can choose between different LLM models as you wish, ensuring you get the best results for your needs.",
    paragraphCard4:
      "Store your training material encrypted, anonymously, separately, and securely, allowing for private custom agents.",
  },
  "es-ES": {
    title: "Tu prompt es solo tuyo",
    subTitleCard1: "Anonimato",
    subTitleCard2: "Privacidad",
    subTitleCard3: "Flexibilidad",
    subTitleCard4: "Seguridad",
    paragraphCard1:
      "La utilidad AIZ con MetaMask permite que cualquier persona acceda a la IA sin dar información personal o ser rastreada.",
    paragraphCard2:
      "Proporcionamos un entorno privado para que puedas crear tus propios GPTs de forma anónima. Nos preocupamos por tu identidad y tus datos.",
    paragraphCard3:
      "Puedes elegir entre diferentes modelos LLM como desees, asegurándote de obtener los mejores resultados para tus necesidades.",
    paragraphCard4:
      "Almacena tu material de entrenamiento cifrado, de forma anónima, separada y segura, permitiendo agentes personalizados privados.",
  },
  "fil-PH": {
    title: "Ang iyong prompt ay sa iyo pa rin",
    subTitleCard1: "Anonimidad",
    subTitleCard2: "Pribadong",
    subTitleCard3: "Pagpili",
    subTitleCard4: "Seguridad",
    paragraphCard1:
      "Ang AIZ Utility sa MetaMask ay nagpapahintulot sa kahit sino na makapag-access sa AI nang hindi nagbibigay ng impormasyong personal o hindi tinatr ack.",
    paragraphCard2:
      "Kami ay nagbibigay ng pribadong kapaligiran para makapag-create ng sarili mong GPTs ng anonimidad. Kami ay nag-aalaga ng iyong identidad at iyong datos.",
    paragraphCard3:
      "Maaari mong piliin sa pagitan ng mga iba't ibang mga modelo ng LLM kung gusto mo, siguraduhin mo na makakakuha ng pinakamagandang resulta para sa iyong mga pangangailangan.",
    paragraphCard4:
      "I-store ang iyong mga materyal ng pagsasanay na nakakodigo, ng anonimidad, ng hiwalay at ng seguridad, pinapayagan ang mga ahenteng pribadong kostum.",
  },
};
