import useTranslation from "../../../../helpers/useTranslation";
import ChatDocument from "./ChatDocument";
import { DocumentsI18n } from "../Documents.i18n";
import { ButtonDeleteAllChats } from "../../../../components/ButtonDeleteAllChats";
import { SelectedChatDTO } from "../../../../@DTO/selectedChat";
import { ChannelDTO } from "../../../../@DTO/channel";
import ActionButton from "../../../../components/ActionButton";
interface FavouritesProps {
  documents: ChannelDTO[];
  openAddContact: () => void;
  selectedChat: SelectedChatDTO | null;
  onSelectChat: (prop: any) => void;
}
const Documents = ({
  documents,
  openAddContact,
  selectedChat,
  onSelectChat,
}: FavouritesProps) => {
  const { t } = useTranslation(DocumentsI18n);

  return (
    <>
      <div className="p-3 d-flex flex-column gap-2">
        <ActionButton
          onClick={openAddContact}
          title={t("button.label")}
          classNameBx="bx bx-message-add"
        />
        <ButtonDeleteAllChats isDocument disabled={documents?.length === 0} />
      </div>

      <div className="chat-message-list px-3">
        <ul className="list-unstyled chat-list chat-user-list px-3 d-flex flex-column gap-2">
          {(documents || []).map((user, key: number) => {
            if (user?.channelName?.toLowerCase() === "general") {
              return null;
            }

            return (
              <ChatDocument
                user={user}
                key={key}
                selectedChat={selectedChat}
                onSelectChat={onSelectChat}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Documents;
