import { useEffect, useState } from "react";

// hooks
import { useRedux } from "../../../hooks/index";

// actions
import {
  changeSelectedChat,
  getChannelJobStatus,
  toggleOpenLeftBar,
} from "../../../redux/actions";

// components
import AppSimpleBar from "../../../components/AppSimpleBar";

import Loader from "../../../components/Loader";
import Videos from "./components/Videos";
import useTranslation from "../../../helpers/useTranslation";
import { YoutubeI18n } from "./Youtube.i18n";
import { ButtonHelpLink } from "../../../components/ButtonHelpLink";
import { SelectedChatDTO } from "../../../@DTO/selectedChat";
import { NewYoutubeVideoModal } from "../../../components/NewYoutubeVideoModal";
import { ChannelDTO } from "../../../@DTO/channel";
import { useQuery } from "react-query";
import { QUERY_KEYS } from "../../../libs/react-query";
import { getChannelsAndDocuments } from "../../../api";
import MenuSideChats from "../../../components/MenuSideChats";
import { TABS } from "../../../constants";
import { useChannels } from "../../../hooks/useChannels";

interface IndexProps {}
const Index = (props: IndexProps) => {
  const [showModal, setShowModal] = useState(false);

  const { dispatch, useAppSelector } = useRedux();
  const { selectedChat, activeTab, isOpenLeftBar } = useAppSelector(state => ({
    getChannelsAndDocumentsLoading: state.Chats.getChannelsAndDocumentsLoading,
    isChannelCreated: state.Chats.isChannelCreated,
    selectedChat: state.Chats.selectedChat as SelectedChatDTO,
    activeTab: state.Layout.activeTab,
    isOpenLeftBar: state.Layout.isOpenLeftBar,
  }));

  const { data: docsAndYoutube, isLoading: isLoadingDocsAndYoutube } = useQuery<
    ChannelDTO[]
  >(
    QUERY_KEYS.DOCUMENTS,
    async () => {
      const data = await getChannelsAndDocuments();
      return data.documents ?? [];
    },
    {
      enabled: activeTab === TABS.YOUTUBE,
    }
  );

  const { t } = useTranslation(YoutubeI18n);

  const videos = docsAndYoutube?.filter(
    (document: any) => document?.isDocument && document.isYoutube
  );

  const { handleChannelClear } = useChannels();

  function toggleModal() {
    setShowModal(!showModal);
  }

  function onSelectChat(channel: any) {
    dispatch(changeSelectedChat(channel));
    dispatch(getChannelJobStatus(channel._id));
  }

  useEffect(() => {
    if (activeTab !== "pills-youtube") return;
    if (
      (!videos || (videos && videos.length === 0)) &&
      !isLoadingDocsAndYoutube
    ) {
      toggleModal();
    }
  }, [isLoadingDocsAndYoutube, activeTab]);

  function handleToggleLeftBar() {
    dispatch(toggleOpenLeftBar(!isOpenLeftBar));
  }

  return (
    <>
      {showModal && (
        <NewYoutubeVideoModal isOpen={showModal} onClose={toggleModal} />
      )}
      <div>
        {isOpenLeftBar ? (
          <>
            {isLoadingDocsAndYoutube && <Loader />}
            <AppSimpleBar className="chat-room-list">
              <div className="px-3 pt-3">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <div className="m-0 p-3 header d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">{t("page.label")}</h4>
                      <ButtonHelpLink target="youtube" />
                      <button
                        className="btn fs-3 p-0 d-lg-flex d-none"
                        style={{ marginLeft: 12 }}
                        onClick={handleToggleLeftBar}
                      >
                        <i className="bx bx-arrow-to-left" />
                      </button>
                    </div>
                    <p className="mt-2 p-3 ">{t("page.description")}</p>
                  </div>
                </div>
              </div>
              {!isLoadingDocsAndYoutube && videos && (
                <>
                  <Videos
                    videos={videos}
                    openAddContact={toggleModal}
                    selectedChat={selectedChat}
                    onSelectChat={onSelectChat}
                  />
                </>
              )}
            </AppSimpleBar>
          </>
        ) : (
          <MenuSideChats
            onClick={handleToggleLeftBar}
            handleCreateChannel={toggleModal}
            loadingState={""}
            handleChannelClear={handleChannelClear}
            activeButtons={true}
            collapsed={true}
          />
        )}
      </div>
    </>
  );
};

export default Index;
