export const MenuSideChatsI18n = {
    "pt-BR": {
      expand: "Expandir",
      newChat: "Novo chat",
      clearChat: "Limpar chat",
    },
    "en-US": {
      expand: "Expand",
      newChat: "New chat",
      clearChat: "Clear chat",
    },
    "es-ES": {
      expand: "Expandir",
      newChat: "Chat nuevo",
      clearChat: "Limpiar chat",
    },
    "fil-PH": {
      expand: "Palawakin",
      newChat: "Bagong chat",
      clearChat: "Linisin ang chat",
    },
  };
  