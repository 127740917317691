import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Price, ProductWithPrice } from "../../types";
import { getStripe } from "../utils/stripe-client";
import { ModalPix } from "./ModalPix";
import useTranslation from "../helpers/useTranslation";
import { PlanPremiumI18n } from "./PlanPremium.i18n";
import { checkIfWeb3User, getUser } from "../helpers/userHelper";
import { AIZPlanPremium } from "./AIZPlanPremium";

const getBearerToken = () => {
  return JSON.parse(localStorage.getItem("sb.token") || "")?.access_token;
};

interface PremiumPlanProps {
  products: ProductWithPrice[];
}

export function PremiumPlan({ products }: PremiumPlanProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [selectedMonths, setSelectedMonths] = useState(1);
  const [monthlyPrice, setMonthlyPrice] = useState(0);
  const [annualPrice, setAnnualPrice] = useState(0);

  const { t } = useTranslation(PlanPremiumI18n);

  const user = getUser();

  const monthlyPlan =
    // @ts-ignore
    products[0]?.prices.find(price => price.interval === "month");
  const annualPlan =
    // @ts-ignore
    products[0]?.prices.find(price => price.interval === "year");

  const discount = (12 * monthlyPrice - annualPrice).toFixed(2);

  const pixUnitPrice = 7;
  const pixDiscountPrice = pixUnitPrice * 10.7;

  const pixPrice =
    selectedMonths === 12 ? pixDiscountPrice : pixUnitPrice * selectedMonths;

  const handleMonthsChange = event => {
    const months = parseInt(event.target.value);
    setSelectedMonths(months);
  };

  const handleCheckout = async (price: Price) => {
    try {
      setIsLoading(true);
      const res = await fetch(
        process.env.REACT_APP_API_URL + "/api/stripe/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getBearerToken(),
          },
          body: JSON.stringify({ price }),
        }
      );

      if (res.status === 200) {
        const data = await res.json();
        const { sessionId } = data;
        const stripe = await getStripe();
        localStorage.removeItem("user-subscription");
        stripe?.redirectToCheckout({ sessionId });
      }
    } catch (error) {
      return alert((error as Error)?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (monthlyPlan?.unit_amount && annualPlan?.unit_amount) {
      setMonthlyPrice(monthlyPlan.unit_amount / 100);
      setAnnualPrice(annualPlan.unit_amount / 100);
    }
  }, [monthlyPlan, annualPlan]);

  return (
    <div className="">
      {products.length > 0 && (
        <>
          <h3 className="text-center">
            {t("page.title")}{" "}
            <span className="text-primary fw-bold">PREMIUM</span>
          </h3>

          <Row className="mt-4 px-3 px-sm-0 justify-content-center mb-3">
            {checkIfWeb3User() ? (
              <AIZPlanPremium />
            ) : (
              <>
                <Col sm={12} md={6} className="mb-4 p-4">
                  <Card
                    className="h-100 text-center shadow"
                    style={{
                      minHeight: 320,
                      maxHeight: 320,
                    }}
                  >
                    <CardBody className="d-flex flex-column justify-content-between">
                      <CardTitle className="fs-5">
                        {t("cards.monthly.title")}
                      </CardTitle>
                      <CardSubtitle
                        tag="h4"
                        className="mb-3 text-dark text-opacity-75"
                      >
                        {t("cards.monthly.description", {
                          monthlyPrice: monthlyPrice,
                        })}
                      </CardSubtitle>
                      <Button
                        color="primary"
                        className="fw-semibold font-size-18"
                        disabled={isLoading}
                        onClick={() => {
                          if (monthlyPlan) {
                            handleCheckout(monthlyPlan);
                          }
                        }}
                      >
                        {t("cards.monthly.planButton")}
                      </Button>
                    </CardBody>
                    <small className="px-3 pb-3">
                      {t("cards.monthly.billingNotice")}
                    </small>
                  </Card>
                </Col>

                <Col sm={12} md={6} className="mb-4 p-4">
                  <Card
                    className="h-100 text-center border border-2 border-primary shadow"
                    style={{
                      minHeight: 340,
                      maxHeight: 340,
                      transform: "scale(1.05)",
                    }}
                  >
                    <CardBody className="d-flex flex-column justify-content-between">
                      <CardTitle className="fs-5">
                        {t("cards.annual.title")}
                      </CardTitle>
                      <div>
                        <CardSubtitle
                          tag="h4"
                          className="mb-3 text-dark text-opacity-75"
                        >
                          {t("cards.annual.description", {
                            annualPrice: annualPrice,
                          })}
                        </CardSubtitle>
                        <CardSubtitle
                          tag="h5"
                          className="mb-3 text-primary fw-semibold"
                        >
                          {t("cards.annual.discountMessage", {
                            discount: discount,
                          })}
                        </CardSubtitle>
                      </div>
                      <Button
                        color="primary"
                        className="fw-semibold font-size-18"
                        disabled={isLoading}
                        onClick={() => {
                          if (annualPlan) {
                            handleCheckout(annualPlan);
                          }
                        }}
                      >
                        {t("cards.annual.planButton")}
                      </Button>
                    </CardBody>
                    <small className="pb-3 px-3">
                      {t("cards.annual.billingNotice")}
                    </small>
                  </Card>
                </Col>

                {(user?.user_metadata?.ip_info?.country_name === "Brazil" ||
                  user?.user_metadata?.ip_info?.country === "Brazil") && (
                  <Col sm={12} md={6} className="mb-4 p-4">
                    <Card
                      className="h-100 text-center shadow"
                      style={{
                        minHeight: 296,
                        maxHeight: 320,
                      }}
                    >
                      <CardBody className="d-flex flex-column justify-content-between">
                        <CardTitle className="mb-3 fs-4">
                          {t("cards.pix.title")}
                        </CardTitle>
                        <div>
                          <CardSubtitle
                            tag="h6"
                            className="mb-3 text-dark text-opacity-75"
                          >
                            {t("cards.pix.description", {
                              months: selectedMonths,
                            })}
                          </CardSubtitle>
                          <Input
                            className="custom-aiz-input"
                            type="range"
                            min="1"
                            max="12"
                            step="1"
                            value={selectedMonths}
                            onChange={handleMonthsChange}
                          />
                          <CardSubtitle tag="h6" className="mb-4 text-dark">
                            {t("cards.pix.priceByMonth", {
                              pixPrice: pixPrice.toFixed(2),
                            })}
                          </CardSubtitle>
                          {selectedMonths === 12 && (
                            <CardSubtitle
                              tag="h6"
                              className="mb-3 text-primary"
                            >
                              {t("cards.pix.discountMessage", {
                                discount: (
                                  pixUnitPrice * 12 -
                                  pixDiscountPrice
                                ).toFixed(2),
                              })}
                            </CardSubtitle>
                          )}
                        </div>
                        <ModalPix amount={pixPrice} />
                      </CardBody>
                      <small className="pb-3 px-3">
                        {t("cards.pix.billingNotice")}
                      </small>
                    </Card>
                  </Col>
                )}

                {/* {user?.user_metadata?.ip_info?.country_name === "Brazil" && (
                  <Col sm={12} md={6} className="mb-4 p-4">
                    <Card
                      className="h-100 text-center shadow"
                      style={{
                        minHeight: 296,
                        maxHeight: 320,
                      }}
                    >
                      <CardBody className="d-flex flex-column justify-content-between">
                        <CardTitle className="mb-3 fs-4">
                          {t("cards.pix.title")}
                        </CardTitle>
                        <div>
                          <CardSubtitle
                            tag="h6"
                            className="mb-3 text-dark text-opacity-75"
                          >
                            {t("cards.pix.description", {
                              months: selectedMonths,
                            })}
                          </CardSubtitle>
                          <Input
                            type="range"
                            min="1"
                            max="12"
                            step="1"
                            value={selectedMonths}
                            onChange={handleMonthsChange}
                          />
                          <CardSubtitle tag="h6" className="mb-4 text-dark">
                            {t("cards.pix.priceByMonth", {
                              pixPrice: pixPrice.toFixed(2),
                            })}
                          </CardSubtitle>
                          {selectedMonths === 12 && (
                            <CardSubtitle
                              tag="h6"
                              className="mb-3 text-primary"
                            >
                              {t("cards.pix.discountMessage", {
                                discount: (
                                  pixUnitPrice * 12 -
                                  pixDiscountPrice
                                ).toFixed(2),
                              })}
                            </CardSubtitle>
                          )}
                        </div>
                        <ModalPix amount={pixPrice} />
                      </CardBody>
                      <small className="pb-3 px-3">
                        {t("cards.pix.billingNotice")}
                      </small>
                    </Card>
                  </Col>
                )} */}
              </>
            )}
          </Row>
        </>
      )}
    </div>
  );
}
