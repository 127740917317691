export enum LAYOUT_MODES {
  LIGHT = "light",
  DARK = "dark",
}

export enum CHATS_TABS {
  ARCHIVE = "acrhive",
  DEFAULT = "default",
}

export const TABS = {
  CHAT: "pills-chat",
  DOCUMENTS: "pills-documents",
  YOUTUBE: "pills-youtube",
  ASSISTANT: "pills-assistants",
  ASSISTANT_NEW: "pills-assistants-new",
  ASSISTANT_CHATS: "pills-assistant-chats",
  ASSISTANT_TOPICS: "pills-assistant-topics",
  ASSISTANT_TOPICS_CHANNELS: "pills-assistant-topics-channels",
  IMAGES: "pills-images",
  WORDPRESS: "pills-wordpress",
  PLANS: "pills-plans",
  PROFILE: "pills-profile",
} as const;

export type TABS_VALUES = (typeof TABS)[keyof typeof TABS];
