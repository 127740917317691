import { useQuery } from "react-query";
import { QUERY_KEYS } from "../../../libs/react-query";
import { ChannelDTO } from "../../../@DTO/channel";
import { getChatUserConversationsChannels } from "../../../api";
import { ImageCard } from "./ImageCard";
import { ImageData } from "../types";
import { ImageCardSkeleton } from "../../../components/ImageCardSkeleton";
import { CreateNewImageModal } from "./CreateNewImageModal";
import useTranslation from "../../../helpers/useTranslation";
import { ImagesListI18n } from "./ImagesList.i18n";
import { useChatStore } from "../../../state/chat";
import { ModalImage } from "./ModalImage";
import { useEffect, useState } from "react";
import { removeMarkdownImageSyntax } from "../../../utils/helpers";

interface ImagesProps {
  imageChannel: ChannelDTO;
}
export function ImagesList({ imageChannel }: ImagesProps) {
  const [showModal, setShowModal] = useState(false);

  const { messages: images, lastImageGenerated, setMessages } = useChatStore();

  const { isLoading, data: imagesData } = useQuery(
    QUERY_KEYS.IMAGES_CHANNEL,
    async () => {
      const superChatAssistantId = "646d98a9440f2a9bf026d4b2";

      const conversations = await getChatUserConversationsChannels({
        assistantId: superChatAssistantId,
        channelId: imageChannel._id,
        size: 40,
      });

      const chats = conversations.chats_by_channel_and_assistant_id;

      const imagesData = Object.values(chats)[0] as ImageData[];

      return imagesData;
    }
  );

  const { t } = useTranslation(ImagesListI18n);

  const lastImage = lastImageGenerated?.content?.chat_response?.content;

  useEffect(() => {
    if (lastImage) {
      setShowModal(true);
    }
  }, [lastImage]);

  useEffect(() => {
    if (imagesData) {
      setMessages(imagesData);
    }
  }, [imagesData]);

  return (
    <div>
      {lastImage && (
        <ModalImage
          image={removeMarkdownImageSyntax(lastImage)}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {isLoading ? (
        <ImageCardSkeleton />
      ) : (
        <>
          {images?.length > 0 ? (
            <div
              className="grid-images px-3"
              aria-label="images-list"
              style={{
                paddingBottom: 100,
              }}
            >
              {images?.map(image => {
                if (!image?.chat_response?.content?.includes("![Image]")) {
                  return null;
                }

                return <ImageCard image={image} key={image._id} />;
              })}
            </div>
          ) : (
            <div
              className="d-flex flex-column align-items-center gap-3 text-center"
              style={{
                marginTop: 200,
              }}
            >
              <span className="fs-3">{t("emptyImages.message")}</span>
              <CreateNewImageModal />
            </div>
          )}
        </>
      )}
    </div>
  );
}
