import { LayoutActionTypes } from "./types";

export const changeTab = (layoutMode: any) => ({
  type: LayoutActionTypes.CHANGE_TAB,
  payload: layoutMode,
});

export const changelayoutMode = (layoutMode: any) => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_MODE,
  payload: { layoutMode },
});

export const toggleOpenLeftBar = (isOpenLeftBar: boolean) => ({
  type: LayoutActionTypes.TOGGLE_OPEN_LEFT_BAR,
  payload: { isOpenLeftBar },
});

export const toggleExpandChat = (isChatExpanded: boolean) => ({
  type: LayoutActionTypes.TOGGLE_EXPAND_CHAT,
  payload: { isChatExpanded },
});
