export const SectionBuildI18n = {
  "pt-BR": {
    title: "Crie seus próprios Agentes Privados e Anônimos",
    paragraph:
      "Agora você pode treinar seus agentes em um formato muito user-friendly, sem a necessidade de entender conceitos técnicos. Nós cuidamos disso para você.",
    subTitleCard1: "Os Melhores Modelos de IA",
    subTitleCard2: "Simples e Poderoso",
    subTitleCard3: "Valor Justo",
    paragraphCard1:
      "Acesse uma ampla gama de Modelos de IA para criar seu próximo agente de IA de geração.",
    paragraphCard2:
      "Use Aizzy.ai para criar facilmente seu próximo agente de IA usando nosso poderoso construtor de agentes e APIs fáceis de usar.",
    paragraphCard3:
      "Aizzy.ai é construída com tecnologia de blockchain, garantindo que seu agente de IA ganhe dinheiro do ecossistema Aizzy.",
    button: "Começar Agora",
    },
  "en-US": {
    title: "Build Your Own Private and Anonymous Agents",
    paragraph:
      "You can now train your agents in a very user-friendly format without the need to understand any technical concepts. We handle it for you.",
    subTitleCard1: "The Best AI Models",
    subTitleCard2: "Simple and Powerful",
    subTitleCard3: "Fair Value",
    paragraphCard1:
      "Access a wide range of AI Models to build your next generation AI Agent.",
    paragraphCard2:
      "Use Aizzy.ai to easily create your next AI Agent using our powerful agent builder and easy to use APIs.",
    paragraphCard3:
      "Aizzy.ai is built on blockchain technology, ensuring your AI Agent earns you income from the Aizzy ecosystem.",
    button: "Get Started",
    },
  "es-ES": {
    title: "Crea tus propios Agentes Privados y Anónimos",
    paragraph:
      "Ahora puedes entrenar tus agentes en un formato muy fácil de usar, sin necesidad de entender conceptos técnicos. Nosotros nos encargamos de eso.",
    subTitleCard1: "Los Mejores Modelos de IA",
    subTitleCard2: "Sencillo y Poderoso",
    subTitleCard3: "Valor Justo",
    paragraphCard1:
      "Accede a una amplia gama de Modelos de IA para crear tu próximo agente de IA de generación.",
    paragraphCard2:
      " Usa Aizzy.ai para crear fácilmente tu próximo agente de IA utilizando nuestro construtor de agentes poderoso y APIs fáciles de usar.",
    paragraphCard3:
      "Aizzy.ai está construida con tecnología de blockchain, garantizando que tu agente de IA gane dinero del ecosistema Aizzy.",
    button: "Empezar Ahora",
    },
  "fil-PH": {
    title: "Ibuo ang sarili mong mga Ahente ng Private at Anonimo",
    paragraph:
      "Ngayon, makapagsasanay ka ng mga ahente mo sa isang format na napakadali gamitin, walang kailangan ng pang-unawa sa mga konseptong teknikal. Kami ang humahawak nito para sa iyo.",
    subTitleCard1: "Ang Mga Pinakamagandang Mga Modelo ng IA",
    subTitleCard2: "Simple at Pampower",
    subTitleCard3: "Patasa ng Halaga",
    paragraphCard1:
      "Aksesohan ang isang malawak na hanay ng mga Modelo ng IA upang ibuo ang iyong susunod na henerasyon ng Ahente ng IA.",
    paragraphCard2:
      "Gamit Aizzy.ai upang madaling ibuo ang iyong susunod na Ahente ng IA gamit ang aming powerful na ahente builder at mga API na madaling gamitin.",
    paragraphCard3:
      "Aizzy.ai ay ginawa sa teknolohiya ng blockchain, siguraduhin na ang iyong Ahente ng IA ay kumikita ng pera mula sa ekosistema ng Aizzy.",
    button: "Simulan Ngayon",
    },
};