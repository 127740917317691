import { useState } from "react";
import { checkIfWeb3User, getUser } from "../../../../helpers/userHelper";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import useTranslation from "../../../../helpers/useTranslation";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../helpers/notifications";
import { ModalWhatsappNumber } from "../../../../components/ModalWhatsappNumber";
import { UserI18n } from "./User.i18n";
import { UpdateLanguageButton } from "../UpdateLanguageButton";

export function User() {
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const user = getUser();

  const supabaseClient = useSupabaseClient();

  const { t } = useTranslation(UserI18n);

  const handleUpdateProfile = async () => {
    try {
      if (!name) {
        return;
      }

      setIsLoading(true);
      const { error } = await supabaseClient.auth.updateUser({
        data: { name },
      });

      if (error?.message) {
        return showErrorNotification(error?.message);
      }

      showSuccessNotification(t("notifications.success"));
    } catch (error: any) {
      showErrorNotification(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="d-flex flex-column" style={{ gap: 36 }}>
      {!checkIfWeb3User() && (
        <div className="text-xl font-semibold">
          <h4 className="mb-3 pt-1">{t("titles.updateProfile")}</h4>
          <div className="h-8 mb-6 mt-2 d-flex flex-column">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="name"
                defaultValue={user?.user_metadata?.name}
                onChange={e => setName(e.target.value)}
              />
              <label htmlFor="name">{t("form.name")}</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="user_email"
                value={user?.email}
                readOnly
                disabled
              />
              <label htmlFor="user_email">{t("form.email")}</label>
            </div>
            <button
              disabled={!name || isLoading}
              className="btn p-0 d-flex align-items-center gap-3 border-0 align-self-start"
              onClick={handleUpdateProfile}
            >
              {isLoading ? (
                <i className="bx bx-loader bx-spin align-middle"/>
              ) : (
                <div className="btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
                  <i className="bx bx-refresh"/>
                </div>
              )}
              <h6 className="m-0">
                {t("buttons.update")}
              </h6>
            </button>
            {/* <DeleteAccountButton userId={user?.id} /> */}
          </div>
        </div>
      )}
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center gap-2 mb-3">
          <h4 className="mb-0">{t("titles.wppAizzy")}</h4>
        </div>
        <div className="align-self-start">
          <ModalWhatsappNumber />
        </div>
      </div>
      <div>
        <h4 className="mb-3">
          {t("titles.siteLanguage")}
        </h4>
        <UpdateLanguageButton title={t("buttons.changeLanguage")} />
      </div>
    </div>
  );
}
