// actions
import { changelayoutMode } from "../../redux/actions";

// components
import { useDispatch } from "react-redux";
import SideMenu from "./SideMenu";
import { useUser } from "@supabase/auth-helpers-react";
import { getUser } from "../../helpers/userHelper";
import { Toaster } from "sonner";

interface IndexProps {
  children: any;
}
const Index = (props: IndexProps) => {
  const dispatch = useDispatch();
  const user = useUser();
  const userAuth = getUser();

  const onChangeLayoutMode = (value: any) => {
    if (changelayoutMode) {
      dispatch(changelayoutMode(value));
    }
  };

  return (
    <div className="layout-wrapper d-lg-flex">
      {(user || userAuth) && true && (
        <SideMenu onChangeLayoutMode={onChangeLayoutMode} />
      )}
      {props.children}
      <Toaster
        theme={"light"}
        richColors
        position="top-center"
        duration={3000}
      />

      {/* <ModalAlertWhatsapp /> */}
    </div>
  );
};

export default Index;
