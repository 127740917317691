export const CreateNewImageModalI18n = {
  "pt-BR": {
    page: {
      label: "Descreva como a imagem deve ser",
      processingImageTitle:
        "Por favor, aguarde, estamos processando a imagem...",
    },
    input: {
      placeholder:
        "Foto glamorosa da rainha dos condenados, uma femme fatale, morbida...",
    },
    button: {
      "create-image": "Criar imagem",
      close: "Fechar",
      create: "Gerar imagem",
      example: "Criar exemplo de prompt",
    },
  },
  "en-US": {
    page: {
      label: "Describe how the image should be",
      processingImageTitle: "Please wait, we are processing the image...",
    },
    input: {
      placeholder:
        "Glamour shot of the queen of the damned, a femme fatale, morbid...",
    },
    button: {
      "create-image": "Create Image",
      close: "Close",
      create: "Generate image",
      example: "Create prompt example",
    },
  },
  "es-ES": {
    page: {
      label: "Describe cómo debe ser la imagen",
      processingImageTitle:
        "Por favor, espera, estamos procesando la imagen...",
    },
    input: {
      placeholder:
        "Foto glamorosa de la reina de los condenados, una femme fatale, mórbida...",
    },
    button: {
      "create-image": "Crear imagen",
      close: "Cerrar",
      create: "Generar imagen",
      example: "Dame un ejemplo de un prompt",
    },
  },
  "fil-PH": {
    page: {
      label: "Ilarawan kung paano dapat ang larawan",
      processingImageTitle:
        "Mangyaring maghintay, ini-process namin ang larawan...",
    },
    input: {
      placeholder:
        "Glamour shot ng reyna ng mga sumpa, isang femme fatale, morbid...",
    },
    button: {
      "create-image": "Lumikha ng Larawan",
      close: "Isara",
      create: "Gumawa ng larawan",
      example: "Bigyan mo ako ng halimbawa ng prompt",
    },
  },
};
