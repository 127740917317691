export const PlanPremiumI18n = {
  "pt-BR": {
    page: {
      title: "Escolha uma opção para ser",
    },
    cards: {
      monthly: {
        title: "PLANO MENSAL",
        description: "Assinatura mensal por ${{monthlyPrice}}/mês",
        planButton: "Escolher esse plano",
        billingNotice:
          "Você será cobrado todos os meses o valor $9.75 até o cancelamento manual",
      },
      annual: {
        title: "PLANO ANUAL",
        description: "Assinatura anual por ${{annualPrice}}/ano",
        discountMessage: "Economize ${{discount}} com este plano!",
        planButton: "Escolher esse plano",
        billingNotice:
          "Você será cobrado 1x por ano todos os anos o valor de $93.65 até o cancelamento manual",
      },
      pix: {
        title: "PLANO PIX",
        description:
          "Selecione a quantidade de meses que deseja adquirir: {{months}}",
        priceByMonth: "Assinatura por ${{pixPrice}}",
        discountMessage:
          "Desconto aplicado! Economize ${{discount}} com este plano!",
        billingNotice: "Você será cobrado manualmente",
      },
      token: {
        title: "PLANO AIZ",
        description:
          "Selecione a quantidade de meses que deseja adquirir: {{months}}",
        priceByMonth: "Assinatura por {{tokenPrice}} AIZ",
        discountMessage:
          "Desconto aplicado! Economize {{discount}}AIZ com este plano!",
        planButton: "Escolher esse plano",
        billingNotice: "Você será cobrado manualmente",
      },
    },
    handleTransferAiz: {
      alerts: {
        walletMismatch:
          "O endereço da carteira conectada não corresponde ao endereço da carteira do usuário. Por favor, conecte a carteira que você usou para entrar no Aizzy para prosseguir com a compra do plano.",
        networkError:
          "Para transferir tokens, você deve estar conectado à Base Network.",
        success: "Tokens transferidos com sucesso",
        insufficientFunds: "AIZ insuficiente para comprar o plano",
        transactionRejected: "Transação rejeitada!",
        transferError: "Ocorreu um erro ao transferir tokens",
      },
    },
  },
  "en-US": {
    page: {
      title: "Choose an option to go",
    },
    cards: {
      monthly: {
        title: "MONTHLY PLAN",
        description: "Monthly subscription for ${{monthlyPrice}}/month",
        planButton: "Choose this plan",
        billingNotice:
          "You will be charged $9.75 every month until manual cancellation",
      },
      annual: {
        title: "ANNUAL PLAN",
        description: "Annual subscription for ${{annualPrice}}/year",
        discountMessage: "Save ${{discount}} with this plan!",
        planButton: "Choose this plan",
        billingNotice:
          "You will be charged $93.65 once a year every year until manual cancellation.",
      },
      pix: {
        title: "PIX PLAN",
        description:
          "Select the number of months you want to purchase: {{months}}",
        priceByMonth: "Subscription for ${{pixPrice}}",
        discountMessage: "Discount applied! Save ${{discount}} with this plan!",
        billingNotice: "You will be charged manually",
      },
      token: {
        title: "AIZ PLAN",
        description:
          "Select the number of months you want to purchase: {{months}}",
        priceByMonth: "Subscription for {{tokenPrice}} AIZ",
        discountMessage: "Discount applied! Save {{discount}} with this plan!",
        planButton: "Choose this plan",
        billingNotice: "You will be charged manually",
      },
    },
    handleTransferAiz: {
      alerts: {
        walletMismatch:
          "The connected wallet address does not match the user's wallet address. Please connect the wallet you logged in on Aizzy to proceed with the plan purchase.",
        networkError:
          "To transfer tokens, you must be connected to the Base Network.",
        success: "Tokens transferred successfully",
        insufficientFunds: "Insufficient AIZ to purchase the plan",
        transactionRejected: "Transaction rejected!",
        transferError: "An error occurred while transferring tokens",
      },
    },
  },
  "es-ES": {
    page: {
      title: "Elige una opción para ser",
    },
    cards: {
      monthly: {
        title: "PLAN MENSUAL",
        description: "Suscripción mensual por ${{monthlyPrice}}/mes",
        planButton: "Elegir este plan",
        billingNotice:
          "Se le cobrará $9.75 cada mes hasta la cancelación manual",
      },
      annual: {
        title: "PLAN ANUAL",
        description: "Suscripción anual por ${{annualPrice}}/año",
        discountMessage: "¡Ahorra ${{discount}} con este plan!",
        planButton: "Elegir este plan",
        billingNotice:
          "Se le cobrará $93.65 una vez al año todos los años hasta la cancelación manual",
      },
      pix: {
        title: "PLAN PIX",
        description:
          "Seleccione la cantidad de meses que desea adquirir: {{months}}",
        priceByMonth: "Suscripción por ${{pixPrice}}",
        discountMessage:
          "¡Descuento aplicado! ¡Ahorra ${{discount}} con este plan!",
        billingNotice: "Se le cobrará manualmente",
      },
      token: {
        title: "PLAN AIZ",
        description:
          "Seleccione la cantidad de meses que desea adquirir: {{months}}",
        priceByMonth: "Suscripción por {{tokenPrice}} AIZ",
        discountMessage:
          "¡Descuento aplicado! ¡Ahorra {{discount}} con este plan!",
        planButton: "Elegir este plan",
        billingNotice: "Se le cobrará manualmente",
      },
    },
    handleTransferAiz: {
      alerts: {
        walletMismatch:
          "La dirección de la billetera conectada no coincide con la dirección de la billetera del usuario. Por favor, conecte la billetera con la que inició sesión en Aizzy para proceder con la compra del plan.",
        networkError:
          "Para transferir tokens, debe estar conectado a la Base Network.",
        success: "Tokens transferidos con éxito",
        insufficientFunds: "AIZ insuficiente para comprar el plan",
        transactionRejected: "¡Transacción rechazada!",
        transferError: "Ocurrió un error al transferir tokens",
      },
    },
  },
  "fil-PH": {
    page: {
      title: "Pumili ng isang opsyon upang maging",
    },
    cards: {
      monthly: {
        title: "BUWANANG PLANO",
        description: "Buwanang subscription para sa ${{monthlyPrice}}/buwan",
        planButton: "Pumili ng plano na ito",
        billingNotice:
          "Ikaw ay singilin ng $9.75 kada buwan hanggang sa manuwal na kanselasyon",
      },
      annual: {
        title: "TAUNANG PLANO",
        description: "Taunang subscription para sa ${{annualPrice}}/taon",
        discountMessage: "I-save ang ${{discount}} sa plano na ito!",
        planButton: "Pumili ng plano na ito",
        billingNotice:
          "Ikaw ay singilin ng $93.65 isang beses kada taon bawat taon hanggang sa manuwal na kanselasyon",
      },
      pix: {
        title: "PIX PLANO",
        description: "Pumili ng dami ng buwan na nais mong bilhin: {{months}}",
        priceByMonth: "Subscription para sa ${{pixPrice}}",
        discountMessage:
          "Diskwento ang ina-apply! I-save ang ${{discount}} sa plano na ito!",
        billingNotice: "Ikaw ay singilin nang manuwal",
      },
      token: {
        title: "AIZ PLANO",
        description: "Pumili ng dami ng buwan na nais mong bilhin: {{months}}",
        priceByMonth: "Subscription para sa {{tokenPrice}} AIZ",
        discountMessage:
          "Diskwento ang ina-apply! I-save ang ${{discount}} sa plano na ito!",
        planButton: "Pumili ng plano na ito",
        billingNotice: "Ikaw ay singilin nang manuwal",
      },
    },
    handleTransferAiz: {
      alerts: {
        walletMismatch:
          "Ang nakakonektang address ng wallet ay hindi tumutugma sa address ng wallet ng gumagamit. Pakikonekta ang wallet na ginamit mo sa pag-log in sa Aizzy upang magpatuloy sa pagbili ng plano.",
        networkError:
          "Upang maglipat ng mga token, dapat kang nakakonekta sa Base Network.",
        success: "Matagumpay na nailipat ang mga token",
        insufficientFunds: "Hindi sapat ang AIZ upang bilhin ang plano",
        transactionRejected: "Tinanggihan ang transaksyon!",
        transferError: "May nangyaring error habang nililipat ang mga token",
      },
    },
  },
};
