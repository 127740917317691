export const AizzyActionsI18n = {
  "pt-BR": {
    title: "Ganhe com seus agentes personalizados",
    about: {
      title: "Saiba mais sobre Aizzy",
      description:
        "A plataforma Web3 alimentada por IA que prioriza sua privacidade. Ganhe Aizzy Tokens ($AIZ) como recompensa por suas valiosas contribuições. Seu prompt é só seu.",
    },
    buttons: {
      learnMore: "Saiba mais",
      highlighted: "NOVO",
      plugin: {
        title: "Use o WordPress dentro do Aizzy",
        label: "Use o WordPress dentro do Aizzy",
        tooltip:
          "Nossa ferramenta permite gerar ideias e artigos detalhados para enriquecer o seu conteúdo. Basta escolher um tópico e idioma para obter artigos completos sobre o assunto selecionado. Essa funcionalidade agiliza a criação de conteúdo relevante, economizando tempo e permitindo que você se concentre em outras atividades importantes para o seu negócio. Essa solução é ideal para produzir conteúdo original e de qualidade, adicionando valor aos seus materiais.",
      },
      create_assistant: {
        title:
          "Clique aqui e crie seu próprio assistente virtual (Beta Premium)",
        label:
          "Projete, construa e treine seu próprio assistente virtual inteligente para realizar tarefas específicas, responder a perguntas dos clientes e aprimorar suas operações comerciais com nossa tecnologia de ponta.",
        tooltip:
          "Crie seu próprio assistente virtual, você pode personalizar o seu assistente de acordo com suas necessidades. Se você é um estudante, pode criar um assistente para ajudá-lo a estudar e aprender. Se você é um profissional de marketing, pode criar um assistente para ajudá-lo a criar campanhas vencedoras. Se você é um desenvolvedor, pode criar um assistente para ajudá-lo a resolver problemas de programação. Seja qual for a sua necessidade, você pode criar um assistente para ajudá-lo a atingir seus objetivos.",
      },
      assistant: {
        title: "Clique aqui e pergunte aos nossos assistentes",
        label:
          "Obtenha respostas instantâneas para suas perguntas de nossos assistentes amigáveis e conhecedores, disponíveis 24/7 para fornecer orientação e suporte para todas as suas perguntas.",
        tooltip:
          "Nossa ferramenta oferece assistentes especializados em várias áreas - programação, marketing ou discussão geral. Os programadores podem obter ajuda na solução de problemas, os profissionais de marketing podem receber orientação para campanhas vencedoras e aqueles que procuram discussões gerais têm um interlocutor pronto. Independentemente das suas necessidades, nossos especialistas estão aqui para ajudar.",
      },
      youtube: {
        title: "Clique aqui e pergunte sobre um vídeo do YouTube",
        label:
          "Obtenha insights personalizados, recomendações e análises para seus vídeos do YouTube, ajudando a otimizar seu conteúdo, aumentar a engajamento e crescer sua audiência.",
        tooltip:
          "Para começar, basta copiar e colar a URL do vídeo que você deseja explorar. Nossa ferramenta importará o vídeo e permitirá que você faça perguntas sobre o seu conteúdo, permitindo que você mergulhe mais fundo no material e entenda melhor a mensagem subjacente do vídeo. Se você é um estudante procurando estudar um conceito complexo, ou alguém simplesmente procurando entender o conteúdo de um vídeo do YouTube com mais detalhes, nossa ferramenta é a solução perfeita.",
      },
      pdf: {
        title:
          "Clique aqui e faça upload de dados personalizados para obter respostas contextuais poderosas",
        label:
          "Desbloqueie o pleno potencial da nossa tecnologia de IA ao fazer upload de dados personalizados, recebendo respostas personalizadas e descobrindo novas oportunidades para impulsionar o crescimento e a melhoria dos negócios.",
        tooltip:
          "Basta fazer o upload do arquivo usando o menu de anexos e nossa ferramenta o importará para você. Uma vez importado, você pode pedir para nossa ferramenta fornecer informações e detalhes sobre o conteúdo do arquivo, tornando mais fácil para você entender e estudar. Se você é um estudante procurando entender melhor as suas anotações de estudo, ou alguém procurando mergulhar em um documento de texto complexo, nossa ferramenta é perfeita para explorar e entender o conteúdo dos textos.",
      },
      website: {
        title: "Clique aqui e pergunte sobre seu site",
        label:
          "Análise, otimize e melhore o desempenho do seu site com nossa assistência especializada, abrangendo aspectos como experiência do usuário, SEO e otimização da taxa de conversão.",
        tooltip:
          "Se você está interessado em notícias, blogs ou qualquer outra coisa, você pode importar rapidamente um conteúdo simplesmente colando sua URL. Uma vez importado, você pode usar nossa ferramenta para fazer uma lista de tópicos ou resumir o conteúdo, tornando-o mais fácil de consumir e entender. Se você é um estudante, encontrará nossa ferramenta especialmente útil para importar artigos via URL e ter o conteúdo listado por tópicos para facilitar os estudos. Jornalistas podem importar e fazer uma lista de artigos de notícias, enquanto advogados podem importar e fazer uma lista de conteúdo legal. Não importa qual seja a sua área de interesse, nossa ferramenta facilita a importação e exploração de conteúdo da web.",
      },
      image: {
        title: "Clique aqui e crie suas próprias imagens",
        label:
          "Desbloqueie sua criatividade e dê vida às suas ideias com nossa ferramenta de geração de imagens inovadora, perfeita para mídias sociais, materiais de marketing e mais.",
        tooltip:
          "Se você está interessado em notícias, blogs ou qualquer outra coisa, você pode importar rapidamente um conteúdo simplesmente colando sua URL. Uma vez importado, você pode usar nossa ferramenta para fazer uma lista de tópicos ou resumir o conteúdo, tornando-o mais fácil de consumir e entender. Se você é um estudante, encontrará nossa ferramenta especialmente útil para importar artigos via URL e ter o conteúdo listado por tópicos para facilitar os estudos. Jornalistas podem importar e fazer uma lista de artigos de notícias, enquanto advogados podem importar e fazer uma lista de conteúdo legal. Não importa qual seja a sua área de interesse, nossa ferramenta facilita a importação e exploração de conteúdo da web.",
      },
      affiliate: {
        title: "Clique aqui e torne-se um afiliado",
        label:
          "Associe-se conosco e ganhe recompensas em AIZ, nossa criptomoeda, promovendo nossos produtos e serviços inovadores, ganhando acesso a recursos exclusivos, suporte e um programa de recompensas competitivo.",
        tooltip:
          "Quando você é um afiliado e convida alguém para a nossa plataforma através do seu link exclusivo, e essa pessoa assina algum dos nossos planos, você recebe 10% de comissão para cada assinatura das pessoas que convidou.",
      },
    },
  },
  "en-US": {
    title: "Earn from your custom agents",
    about: {
      title: "Lean more about Aizzy",
      description:
        "The AI-powered Web3 platform that prioritizes your privacy. Earn Aizzy Tokens ($AIZ) as a reward for your valuable contributions Your prompts belong to you.",
    },
    buttons: {
      learnMore: "Learn more",
      highlighted: "NEW",
      plugin: {
        title: "Use WordPress within Aizzy",
        label: "Use WordPress within Aizzy",
        tooltip:
          "Our tool offers you the opportunity to generate various ideas and create detailed articles, significantly contributing to enhancing your content. Simply present a specific topic and select your preferred language, and we will generate complete articles on the chosen subject. This functionality provides convenience and efficiency in generating relevant content, saving you time and allowing you to focus on other essential activities for your business. This feature is an excellent solution for creating original and quality content, adding significant value to your materials.",
      },
      create_assistant: {
        title:
          "Click here and create your own virtual assistant (Premium Beta)",
        label:
          "Design, build, and train your own AI-powered virtual assistant to perform specific tasks, answer customer inquiries, and enhance your business operations with our cutting-edge technology.",
        tooltip:
          "Create your own virtual assistant, you can customize your assistant according to your needs. If you are a student, you can create an assistant to help you study and learn. If you are a marketing professional, you can create an assistant to help you create winning campaigns. If you are a developer, you can create an assistant to help you solve programming problems. Whatever your need, you can create an assistant to help you achieve your goals.",
      },
      assistant: {
        title: "Click here and ask our assistants",
        label:
          "Get instant answers to your queries from our knowledgeable and friendly assistants, available 24/7 to provide guidance and support for all your questions.",
        tooltip:
          "Our tool offers skilled assistants adept in various fields - coding, marketing, or general discussion. Coders can get help troubleshooting issues, marketers can receive guidance for winning campaigns, and those looking for general discussions have a ready conversationalist. Regardless of your needs, our specialists are here to assist you.",
      },
      youtube: {
        title: "Click here and ask about your YouTube video",
        label:
          "Obtain personalized insights, recommendations, and analytics for your YouTube videos, helping you optimize your content, increase engagement, and grow your audience.",
        tooltip:
          "To get started, simply copy and paste the URL of the video you want to explore. Our tool will import the video and allow you to ask about its content, enabling you to dive deeper into the material and better understand the video's underlying message. Whether you're a student looking to study a complex concept, or someone simply looking to understand the content of a YouTube video in more detail, our tool is the perfect solution.",
      },
      pdf: {
        title:
          "Click here and upload custom data to get powerful contextual responses",
        label:
          "Unlock the full potential of our AI technology by uploading your custom data, receiving tailored responses, and uncovering new opportunities to drive business growth and improvement.",
        tooltip:
          "Just upload the file using the attachments menu, and our tool will import it for you. Once imported, you can ask our tool to provide information and details about the content of the file, making it easier for you to comprehend and study. Whether you're a student trying to better understand your study notes or someone looking to delve into a complex text document, our tool is perfect for exploring and comprehending text content.",
      },
      website: {
        title: "Click here and ask about your website",
        label:
          "Analyze, optimize, and improve your website's performance with our expert assistance, covering aspects such as user experience, SEO, and conversion rate optimization.",
        tooltip:
          "Whether you're interested in news, blogs, or anything else, you can quickly and easily import a piece of content by simply pasting its URL. Once imported, you can use our tool to bullet list or summarize the content, making it easier to consume and understand. If you're a student, you'll find our tool especially useful for importing articles via URL and having them bullet listed for easy studying. Journalists can import and bullet list news articles, while lawyers can import and bullet list legal content. No matter what your area of interest, our tool makes it easy to import and explore content from around the web.",
      },
      image: {
        title: "Click here and create your own images",
        label:
          "Unleash your creativity and bring your ideas to life with our innovative image generation tool, perfect for social media, marketing materials, and more.",
        tooltip:
          "If you're interested in creating your own images, you can use our tool for that. With our image creation tool, you can easily turn your ideas into reality. Just type in your specifications and instructions on how you want the image to be, and our artificial intelligence will do the hard work to create the image exactly as you envisioned. With advanced image processing capabilities and intelligent algorithms, our tool is capable of producing stunning results quickly and efficiently. Whether you need graphics for your social media, marketing materials, presentations, or other creative projects, our tool is ready to help bring your vision to life. Try it now and unlock the full potential of image creation!",
      },
      affiliate: {
        title: "Click here and become an affiliate",
        label:
          "Partner with us and earn rewards in AIZ, our cryptocurrency, by promoting our innovative products and services, gaining access to exclusive resources, support, and a competitive rewards program.",
        tooltip:
          "When you are an affiliate and invite someone to our platform through your unique link, and that person subscribes to any of our plans, you receive a 10% commission for each subscription from the people you invited.",
      },
    },
  },
  "es-ES": {
    title: "Gana dinero con tus agentes personalizados",
    about: {
      title: "Aprende más sobre Aizzy",
      description:
        "La plataforma Web3 impulsada por IA que prioriza su privacidad. Gana tokens Aizzy ($AIZ) como recompensa por tus valiosas contribuciones. Tu prompt es solo tuyo.",
    },
    buttons: {
      learnMore: "Más información",
      highlighted: "NUEVO",
      plugin: {
        title: "Use WordPress dentro de Aizzy",
        label: "Use WordPress dentro de Aizzy",
        tooltip:
          "Nuestra herramienta te ofrece la oportunidad de generar diversas ideas y crear artículos detallados, enriqueciendo significativamente tu contenido. Solo tienes que presentar un tema específico y seleccionar el idioma preferido, y generaremos artículos completos sobre el tema elegido. Esta funcionalidad ofrece comodidad y eficiencia en la creación de contenido relevante, ahorrándote tiempo y permitiéndote enfocarte en otras actividades esenciales para tu negocio. Esta característica es una excelente solución para crear contenido original y de alta calidad, agregando un valor significativo a tus materiales.",
      },
      create_assistant: {
        title:
          "Haga clic aquí y crea tu propio asistente virtual (Premium Beta)",
        label:
          "Diseña, construye y entrena a tu propio asistente virtual inteligente para realizar tareas específicas, responder a preguntas de los clientes y mejorar tus operaciones comerciales con nuestra tecnología de vanguardia.",
        tooltip:
          "Crea tu propio asistente virtual, puedes personalizar tu asistente según tus necesidades. Si eres estudiante, puedes crear un asistente para ayudarte a estudiar y aprender. Si eres un profesional del marketing, puedes crear un asistente para ayudarte a crear campañas ganadoras. Si eres desarrollador, puedes crear un asistente para ayudarte a resolver problemas de programación. Sea cual sea tu necesidad, puedes crear un asistente para ayudarte a alcanzar tus objetivos.",
      },
      assistant: {
        title: "Haga clic aquí y pregunta a nuestros asistentes",
        label:
          "Obtenga respuestas instantáneas para tus preguntas de nuestros asistentes amigables y conocedores, disponibles 24/7 para brindar orientación y soporte para todas tus preguntas.",
        tooltip:
          "Nuestra herramienta ofrece asistentes especializados en diferentes campos: programación, marketing o discusión general. Los programadores pueden obtener ayuda para solucionar problemas, los profesionales del marketing pueden recibir orientación para campañas exitosas y aquellos que buscan discusiones generales tienen a su disposición un conversador preparado. Independientemente de tus necesidades, nuestros especialistas están aquí para ayudarte.",
      },
      youtube: {
        title: "Haga clic aquí y pregunta sobre tu video de YouTube",
        label:
          "Obtenga insights personalizados, recomendaciones y análisis para tus videos de YouTube, ayudando a optimizar tu contenido, aumentar la participación y crecer tu audiencia.",
        tooltip:
          "Para comenzar, simplemente copia y pega la URL del video que quieres explorar. Nuestra herramienta importará el video y te permitirá hacer preguntas sobre su contenido, lo que te permitirá adentrarte más en el material y comprender mejor el mensaje subyacente del video. Ya seas un estudiante que busca estudiar un concepto complejo o alguien que simplemente desea comprender el contenido de un video de YouTube con más detalle, nuestra herramienta es la solución perfecta.",
      },
      pdf: {
        title:
          "Haga clic aquí y sube datos personalizados para obtener respuestas contextuales poderosas",
        label:
          "Desbloquea el pleno potencial de nuestra tecnología de IA al subir datos personalizados, recibiendo respuestas personalizadas y descubriendo nuevas oportunidades para impulsar el crecimiento y la mejora de los negocios.",
        tooltip:
          "Simplemente carga el archivo usando el menú de adjuntos, y nuestra herramienta lo importará por ti. Una vez importado, puedes solicitarle a nuestra herramienta que proporcione información y detalles sobre el contenido del archivo, facilitándote la comprensión y el estudio. Ya seas un estudiante que intenta comprender mejor tus apuntes de estudio o alguien que busca sumergirse en un documento de texto complejo, nuestra herramienta es perfecta para explorar y comprender el contenido de los textos.",
      },
      website: {
        title: "Haga clic aquí y pregunta sobre tu sitio web",
        label:
          "Analiza, optimiza y mejora el rendimiento de tu sitio web con nuestra asistencia especializada, abarcando aspectos como experiencia del usuario, SEO y optimización de la tasa de conversión.",
        tooltip:
          "Ya sea que estés interesado en noticias, blogs o cualquier otra cosa, puedes importar rápidamente un contenido simplemente pegando su URL. Una vez importado, puedes usar nuestra herramienta para hacer una lista de viñetas o resumir el contenido, lo que facilita su consumo y comprensión. Si eres un estudiante, encontrarás nuestra herramienta especialmente útil para importar artículos a través de URL y tenerlos en formato de viñetas para facilitar los estudios. Los periodistas pueden importar y hacer una lista de artículos de noticias, mientras que los abogados pueden importar y hacer una lista de contenido legal. No importa cuál sea tu área de interés, nuestra herramienta facilita la importación y exploración de contenido de toda la web.",
      },
      image: {
        title: "Haga clic aquí y crea tus propias imágenes",
        label:
          "Desbloquea tu creatividad y da vida a tus ideas con nuestra herramienta de generación de imágenes innovadora, perfecta para redes sociales, materiales de marketing y más.",
        tooltip:
          "Si estás interesado en crear tus propias imágenes, puedes usar nuestra herramienta para ello. Con nuestra herramienta de creación de imágenes, puedes convertir fácilmente tus ideas en realidad. Solo tienes que escribir tus especificaciones e instrucciones sobre cómo quieres que sea la imagen, y nuestra inteligencia artificial se encargará de crear la imagen exactamente como la imaginaste. Con capacidades avanzadas de procesamiento de imágenes y algoritmos inteligentes, nuestra herramienta es capaz de producir resultados impresionantes de forma rápida y eficiente. Ya sea que necesites gráficos para tus redes sociales, materiales de marketing, presentaciones u otros proyectos creativos, nuestra herramienta está lista para ayudarte a dar vida a tu visión. ¡Pruébala ahora y aprovecha todo el potencial de creación de imágenes!",
      },
      affiliate: {
        title: "Haga clic aquí y sé un afiliado",
        label:
          "Asóciate con nosotros y gana recompensas en AIZ, nuestra criptomoneda, promoviendo nuestros productos y servicios innovadores, ganando acceso a recursos exclusivos, soporte y un programa de recompensas competitivo.",
        tooltip:
          "Cuando eres afiliado e invitas a alguien a nuestra plataforma a través de tu enlace exclusivo, y esa persona se suscribe a cualquiera de nuestros planes, recibes un 10% de comisión por cada suscripción de las personas que invitaste.",
      },
    },
  },
  "fil-PH": {
    title: "Kumita mula sa iyong mga custom na ahente",
    buttons: {
      highlighted: "BAGONG",
      plugin: {
        title: "Gamitin ang WordPress sa loob ng Aizzy",
        label: "Gamitin ang WordPress sa loob ng Aizzy",
        tooltip:
          "Ang aming tool ay nagbibigay-daan sa iyo na magbuo ng iba't ibang mga ideya at lumikha ng mga detalyadong artikulo, na malaki ang ambag sa pagpapayaman ng iyong nilalaman. Isang simpleng paksa at piliin ang iyong nais na wika, at lalabas ang kumpletong mga artikulo ukol sa napiling paksa. Ang kakayahang ito ay nagbibigay ng kaginhawahan at kahusayan sa pagbuo ng kaugnay na nilalaman, na nagtitipid ng iyong oras at nagbibigay-daan sa iyo na magtuon sa iba pang mahahalagang gawain para sa iyong negosyo. Ang feature na ito ay isang mahusay na solusyon para sa paglikha ng orihinal at de-kalidad na nilalaman, na nag-aambag ng malaking halaga sa iyong materyal.",
      },
      create_assistant: {
        title:
          "Lumikha ng iyong sariling virtual na taga-assist (Premium Beta)",
        label:
          "Disenyo, gawin, at sanayin ang iyong sariling AI-powered virtual assistant upang gawin ang mga specific na task, sumagot sa mga katanungan ng mga customer, at pahusayin ang iyong mga operasyon ng negosyo sa pamamagitan ng aming teknolohiyang de-avant-garde.",
        tooltip:
          "Lumikha ng iyong sariling virtual na taga-assist, maaari mong i-customize ang iyong taga-assist ayon sa iyong pangangailangan. Kung ikaw ay isang estudyante, maaari kang lumikha ng taga-assist upang tulungan kang mag-aral at matuto. Kung ikaw ay propesyonal sa marketing, maaari kang lumikha ng taga-assist upang tulungan kang lumikha ng mga tagumpay na kampanya. Kung ikaw ay isang developer, maaari kang lumikha ng taga-assist upang tulungan kang malutas ang mga problema sa programming. Anuman ang iyong pangangailangan, maaari kang lumikha ng taga-assist upang tulungan kang makamit ang iyong mga layunin.",
      },
      assistant: {
        title: "Tanong sa aming mga assistants",
        label:
          "Makuha ang instant na mga sagot sa iyong mga katanungan mula sa aming mga kaalyadong assistants, available 24/7 upang magbigay ng gabay at suporta para sa lahat ng iyong mga katanungan.",
        tooltip:
          "Ang aming tool ay nag-aalok ng mga magagaling na taga-assist na bihasa sa iba't ibang larangan - coding, marketing, o pangkalahatang talakayan. Ang mga coder ay maaaring makakuha ng tulong sa pagsasaayos ng mga isyu, ang mga marketer ay maaaring makatanggap ng gabay para sa mga tagumpay na kampanya, at ang mga naghahanap ng pangkalahatang talakayan ay may handang makipag-usap. Anuman ang iyong pangangailangan, narito ang aming mga specialistang handang tumulong.",
      },
      youtube: {
        title: "Magtanong ukol sa iyong YouTube video",
        label:
          "Makuha ang mga personalizadong insights, mga rekomendasyon, at mga analisis para sa iyong mga video sa YouTube, tumutulong na paunladin ang iyong nilalaman, dagdagan ang participasyon, at lumago ang iyong audience.",
        tooltip:
          "Upang magsimula, i-copy at i-paste lamang ang URL ng video na nais mong tuklasin. Ang aming tool ay mag-i-import ng video at papayagan ka na magtanong tungkol sa nilalaman nito, nagbibigay-daan sa iyo na lumubog nang mas malalim sa materyal at mas maunawaan ang underlying message ng video. Maging ikaw ay isang estudyante na naghahanap na pag-aralan ang isang kumplikadong konsepto, o isang tao na simpleng naghahanap ng pag-unawa sa nilalaman ng isang YouTube video nang mas detalyado, ang aming tool ay ang perpektong solusyon.",
      },
      pdf: {
        title:
          "I-Upload ang mga pasadyang datos upang makakuha ng malalakas na kontekstuwal na mga tugon",
        label:
          "I-unlock ang buong potensyal ng aming teknolohiyang AI sa pamamagitan ng pag-upload ng custom data, tumatanggap ng mga tugon na personalisado, at nadiskubreng mga bagong oportunidad upang pahusayin ang mga negosyo.",
        tooltip:
          "I-upload lamang ang file gamit ang menu ng attachments, at ang aming tool ay mag-i-import nito para sa iyo. Kapag na-import na, maaari mong tanungin ang aming tool na magbigay ng impormasyon at detalye ukol sa nilalaman ng file, na nagpapadali sa iyong pag-unawa at pag-aaral. Maging ikaw ay isang estudyante na nais mas maintidihan ang iyong mga tala sa pag-aaral o isang tao na naghahanap na masusing tuklasin ang nilalaman ng isang komplikadong dokumento ng teksto, ang aming tool ay perpekto para sa pagsusuri at pag-unawa ng nilalaman ng teksto.",
      },
      website: {
        title: "Magtanong ukol sa iyong website",
        label:
          "Analisahin, i- optimize, at ipahusay ang pagganap ng iyong website sa pamamagitan ng aming mga eksperto ng suporta, na sumasaklaw sa mga aspetong gaya ng user experience, SEO, at conversion rate optimization.",
        tooltip:
          "Kung ikaw ay interesado sa balita, blogs, o anuman pa, maaari mong madaling i-import ang isang bahagi ng nilalaman sa pamamagitan ng simpleng pag-paste ng URL nito. Kapag na-import na, maaari mong gamitin ang aming tool upang gawing listahan ng mga paksa o buod ang nilalaman, na nagpapadali sa pagkonsumo at pag-unawa nito. Kung ikaw ay isang estudyante, makikita mo ang aming tool na lalong kapaki-pakinabang para sa pag-iimport ng mga artikulo sa pamamagitan ng URL at pagkakaroon ng listahan ng paksa para sa madaling pagsusuri. Ang mga journalist ay maaaring mag-import at gumawa ng listahan ng mga artikulo sa balita, samantalang ang mga abogado ay maaaring mag-import at gumawa ng listahan ng legal na nilalaman. Anuman ang iyong larangan ng interes, ang aming tool ay nagpapadali sa pag-iimport at pagsusuri ng nilalaman mula sa buong web.",
      },
      image: {
        title: "Lumikha ng iyong sariling mga larawan",
        label:
          "I-unleash ang iyong creatividad at bigyan ng buhay ang iyong mga ideya sa pamamagitan ng aming innovatibong tool sa pag-generate ng mga imahe, perpektong para sa mga social media, mga materyal ng marketing, at marami pa.",
        tooltip:
          "Kung ikaw ay interesado sa balita, blogs, o anuman pa, maaari mong madaling i-import ang isang bahagi ng nilalaman sa pamamagitan ng simpleng pag-paste ng URL nito. Kapag na-import na, maaari mong gamitin ang aming tool upang gawing listahan ng mga paksa o buod ang nilalaman, na nagpapadali sa pagkonsumo at pag-unawa nito. Kung ikaw ay isang estudyante, makikita mo ang aming tool na lalong kapaki-pakinabang para sa pag-iimport ng mga artikulo sa pamamagitan ng URL at pagkakaroon ng listahan ng paksa para sa madaling pagsusuri. Ang mga journalist ay maaaring mag-import at gumawa ng listahan ng mga artikulo sa balita, samantalang ang mga abogado ay maaaring mag-import at gumawa ng listahan ng legal na nilalaman. Anuman ang iyong larangan ng interes, ang aming tool ay nagpapadali sa pag-iimport at pagsusuri ng nilalaman mula sa buong web.",
      },
      affiliate: {
        title: "Maging isang kaakibat",
        label:
          "I-partner sa amin at kumita ng mga rekomendasyon sa AIZ, aming cryptocurrency, sa pamamagitan ng pag-promote ng aming mga innovatibong produkto at serbisyo, nadagdagan ng access sa mga eksklusibong mga rekurso, suporta, at mga programa ng mga rekomendasyon na kompetitibo.",
        tooltip:
          "Kapag ikaw ay isang kaakibat at inimbita ang isang tao sa aming plataporma sa pamamagitan ng iyong natatanging link, at ang taong iyon ay nag-subscribe sa alinman sa aming mga plano, makakatanggap ka ng 10% na komisyon para sa bawat subscription mula sa mga taong imbitado mo.",
      },
    },
  },
};
