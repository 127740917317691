import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/home/aizzy-ai.png";
import menu from "../assets/images/icons/menu-home.svg";
import HomeMenuMobile from "./HomeMenuMobile";
import useTranslation from "../helpers/useTranslation";
import { SectionHeroHomeI18n } from "./SectionHeroHome.i18n";

const HeaderHome = ({ dropdownLanguage, dropdownLanguageMobile }) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const { t } = useTranslation(SectionHeroHomeI18n);

  return (
    <section className="d-flex justify-content-center align-items-center header-home">
      <div>
        <img src={Logo} alt="" width={100} height={50} />
        <nav className={"align-items-center"}>
          <div>{dropdownLanguage}</div>
          <div>
            <Link
              target="_blank"
              className="color-white-buttons btn-lp-dark font-home mx-2"
              to={
                "https://app.uniswap.org/explore/tokens/base/0xc51d94a9936616b90e26abe61921ab3b4e66a149"
              }
            >
              Aiz Token
            </Link>
          </div>
        </nav>
        <button onClick={toggleMenu}>
          <img src={menu} alt="" />
        </button>
        {showMenu && <HomeMenuMobile dropdownLanguageMobile={dropdownLanguageMobile} onClick={toggleMenu} />}
      </div>
    </section>
  );
};

export default HeaderHome;
