import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/home/aizzy-ai.png";
import discord from "../assets/images/icons/discord.svg";
import instagram from "../assets/images/icons/instagram.svg";
import twitter from "../assets/images/icons/twitter.svg";
import linkedin from "../assets/images/icons/linkedin.svg";
import youtube from "../assets/images/icons/youtube.svg";
import useTranslation from "../helpers/useTranslation";
import { FooterHomeI18n } from "./FooterHome.i18n.ts";

const FooterHome = () => {
  const { t } = useTranslation(FooterHomeI18n);

  return (
    <>
      <section>
        <div className="section-footer-home">
          <div className="w-100 image-container d-flex align-items-start mb-3">
            <img src={Logo} alt="" width={140} height={70} />
          </div>
          <div>
            <ul>
              <span className=" size-paragrapy-landing-page">
                {t("listingOne.title")}
              </span>
              <li>
                <Link
                  target="_blank"
                  className="size-paragrapy-landing-page color-lp-dark"
                  to={"https://github.com/aizzy-ai"}
                >
                  {t("listingOne.link1")}
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  className="size-paragrapy-landing-page color-lp-dark"
                  to={"https://github.com/aizzy-ai"}
                >
                  {t("listingOne.link2")}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <span className=" size-paragrapy-landing-page">
                {t("listingTwo.title")}
              </span>
              <li>
                <Link
                  target="_blank"
                  className="size-paragrapy-landing-page color-lp-dark"
                  to={"/brand-assets"}
                >
                  Brand Assets
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  className="size-paragrapy-landing-page color-lp-dark"
                  to={"https://github.com/aizzy-ai"}
                >
                  {t("listingTwo.link1")}
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  className="size-paragrapy-landing-page color-lp-dark"
                  to={"https://github.com/aizzy-ai/aizzy-token"}
                >
                  {t("listingTwo.link2")}
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  className="size-paragrapy-landing-page color-lp-dark"
                  to={
                    "https://basescan.org/token/0xc51d94a9936616b90E26abe61921Ab3b4E66A149"
                  }
                >
                  {t("listingTwo.link3")}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <span className=" size-paragrapy-landing-page">
                {t("listingThree.title")}
              </span>
              {/* <li>
                <Link
                  target="_blank" 
                  className="size-paragrapy-landing-page color-lp-dark"
                  to={"https://www.aizzy.ai/privacy-policy"}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  target="_blank" 
                  className="size-paragrapy-landing-page color-lp-dark"
                  to={"https://www.aizzy.ai/terms-and-conditions"}
                >
                  Terms of Service
                </Link>
              </li> */}
              <li>
                <Link
                  target="_blank"
                  className="size-paragrapy-landing-page color-lp-dark"
                  to={
                    "https://aizzy-ai.gitbook.io/aizzy.ai-docs/legal/disclaimer"
                  }
                >
                  {t("listingThree.link1")}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <article className="footer-social-media mt-4">
          <p className="color-lp-dark size-paragrapy-landing-page">
            {t("copyRight")}
          </p>
          <ul>
            <li>
              <Link
                target="_blank"
                to={"https://discord.com/invite/YCwY4GtdWv"}
              >
                <img src={discord} alt="" width={"100%"} height={"100%"} />
              </Link>
            </li>
            <li>
              <Link target="_blank" to={"https://www.instagram.com/aizzy.ai/"}>
                <img src={instagram} alt="" width={"100%"} height={"100%"} />
              </Link>
            </li>
            <li>
              <Link target="_blank" to={"https://x.com/aizzyai"}>
                <img src={twitter} alt="" width={"100%"} height={"100%"} />
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                to={"https://www.linkedin.com/company/aizzy-ai"}
              >
                <img src={linkedin} alt="" width={"100%"} height={"100%"} />
              </Link>
            </li>
            <li>
              <Link target="_blank" to={"https://www.youtube.com/@AizzyAi"}>
                <img src={youtube} alt="" width={"100%"} height={"100%"} />
              </Link>
            </li>
          </ul>
        </article>
      </section>
    </>
  );
};
export default FooterHome;
