export const UpdatePasswordI18n = {
  "pt-BR": {
    titles: {
      updatePassword: "Atualizar senha",
    },
    form: {
      password: "Nova senha",
    },
    button: {
      title: "Atualizar",
    },
    notifications: {
      success: "Senha atualizada com sucesso",
      smallPassword: "Sua senha deve ter mais do que 6 caracteres",
    },
  },
  "en-US": {
    titles: {
      updatePassword: "Update password",
    },
    form: {
      password: "New password",
    },
    button: {
      title: "Update",
    },
    notifications: {
      success: "Password updated successfully",
      smallPassword: "Your password must have more than 6 characters",
    },
  },
  "es-ES": {
    titles: {
      updatePassword: "Actualiza contraseña",
    },
    form: {
      password: "Nueva contraseña",
    },
    button: {
      title: "Actualización",
    },
    notifications: {
      success: "Contraseña actualizada con éxito",
      smallPassword: "Tu contraseña debe tener más de 6 caracteres",
    },
  },
  "fil-PH": {
    titles: {
      updatePassword: "I-update ang password",
    },
    form: {
      password: "Bagong password",
    },
    button: {
      title: "I-update",
    },
    notifications: {
      success: "Matagumpay na na-update ang password",
      smallPassword: "Ang iyong password ay dapat may higit sa 6 na karakter",
    },
  },
};
