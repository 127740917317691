import classnames from "classnames";
import { UserDocumentsI18n } from "./UserDocuments.i18n";
import useTranslation from "../../../helpers/useTranslation";
import { useEffect } from "react";
import { showErrorNotification } from "../../../helpers/notifications";

interface StatusBadgeProps {
  item: any;
}

export function StatusBadge({ item }: StatusBadgeProps) {
  const { t } = useTranslation(UserDocumentsI18n);

  const translateStatus = (status: string) => {
    const translations = {
      created: t("created"),
      "extracting pdf": t("extracting_pdf"),
      "saving file to be processed": t("saving_file"),
      "processing file": t("processing_file"),
      "chunking text": t("chunking_text"),
      "converting text to ai model": t("converting_text"),
      "saving as searchable text": t("saving_searchable_text"),
      "completing the job": t("completing_job"),
      "job finished": t("job_finished"),
      "pdf extracted": t("pdf_extracted"),
      "creating job": t("creating_job"),
      "starting text extraction": t("starting_text_extraction"),
      "Extracting text": t("extracting_text"),
    };

    return translations[status] || status;
  };

  useEffect(() => {
    if (item.error_message && !item.processed) {
      console.error(item.error_message);
      showErrorNotification(item.error_message);
    }
  }, [item.error_message]);

  return (
    <div className="d-flex flex-column">
      {(!item.error_message || item.processed) && (
        <span
          className={classnames("badge mt-2 px-2", {
            "text-bg-primary": item.processed,
            "text-bg-secondary": !item.processed,
          })}
          style={{
            maxWidth: "fit-content",
          }}
        >
          {item.processed
            ? t("processed")
            : `${translateStatus(item.status)}...`}
        </span>
      )}

      {item.error_message && !item.processed && (
        <span
          className="mt-2 text-bg-danger px-2"
          style={{
            maxWidth: "fit-content",
          }}
        >
          {item.error_message}
        </span>
      )}
    </div>
  );
}
