import { useDispatch } from "react-redux";
import { leaveSharedChannel } from "../api";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../helpers/notifications";
import { changeSelectedChat } from "../redux/actions";
import { QUERY_KEYS, queryClient } from "../libs/react-query";
import { ChannelDTO } from "../@DTO/channel";

export const useSharedChat = () => {
  const dispatch = useDispatch();

  async function handleLeaveSharedChat(channelId: string) {
    const confirm = window.confirm("Do you want to leave this chat?");

    try {
      if (confirm) {
        const response = await leaveSharedChannel(channelId);

        if (response.status === 200) {
          showSuccessNotification("You have left the chat");
        }

        const channels = queryClient.getQueryData<ChannelDTO[]>(
          QUERY_KEYS.CHANNELS
        );

        if (channels) {
          const newChannels = channels.filter(
            channel => channel._id !== channelId
          );

          queryClient.setQueryData(QUERY_KEYS.CHANNELS, newChannels);
        }

        dispatch(changeSelectedChat(null));
      }
    } catch (error: any) {
      showErrorNotification(error);
    }
  }

  return { handleLeaveSharedChat };
};
