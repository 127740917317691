export const SideMenuI18n = {
  "pt-BR": {
    menu: {
      account: "Minha conta",
      theme: "Mudar tema",
      logout: "Sair",
      chat: "Chat",
      assistants: "Assistentes",
      documents: "Documentos",
      youtube: "YouTube",
      images: "Imagens",
      premium: "Premium",
    },
  },
  "en-US": {
    menu: {
      account: "My account",
      theme: "Switch theme",
      logout: "Logout",
      chat: "Chat",
      assistants: "Assistants",
      documents: "Documents",
      youtube: "YouTube",
      images: "Images",
      premium: "Premium",
    },
  },
  "es-ES": {
    menu: {
      account: "Mi cuenta",
      theme: "Cambiar tema",
      logout: "Salir",
      chat: "Chat",
      assistants: "Asistentes",
      documents: "Documentos",
      youtube: "YouTube",
      images: "Imágenes",
      premium: "Premium",
    },
  },
  "fil-PH": {
    menu: {
      account: "Aking account",
      theme: "Baguhin ang tema",
      logout: "Mag-logout",
      chat: "Chat",
      assistants: "Mga Assistant",
      documents: "Mga Dokumento",
      youtube: "YouTube",
      images: "Mga Larawan",
      premium: "Premium",
    },
  },
};
