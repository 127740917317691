import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { TabAssistansOptions } from "../pages/Dashboard/Assistants";
import useTranslation from "../helpers/useTranslation";
import iconAizzy from "../assets/images/icons/aizzy-icon-ligth.svg";
import { TabsAssistantsI18n } from "./TabsAssistants.18n";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import CreateAssitants from "../pages/Dashboard/Assistants/components/CreateAssistants";

interface TabsAssistantsProps {
  onSelectTab: (tab: TabAssistansOptions) => void;
  activeTab: TabAssistansOptions;
}

export function TabsAssistants({
  onSelectTab,
  activeTab,
}: TabsAssistantsProps) {
  const { t } = useTranslation(TabsAssistantsI18n);
  const [showNotification, setShowNotification] = useState<boolean>(true);

  useEffect(() => {
    const viewAssistants = localStorage.getItem("view_assistants");
    if (viewAssistants) {
      setShowNotification(false); // Não mostra a bolinha se a chave estiver no localStorage
    }
  }, []);

  const tabData = [
    {
      tabOption: TabAssistansOptions.AizzyAssistants,
      iconImage: iconAizzy,
      label: t("tabs.aizzyAssistants"),
    },
    {
      tabOption: TabAssistansOptions.MyAssistants,
      img: "bx bxs-user",
      label: t("tabs.myAssistants"),
    },
    {
      tabOption: TabAssistansOptions.CommunityAssistants,
      img: "bx bxs-group",
      label: t("tabs.communityAssistants"),
    },
  ];

  const toggleTab = (tab: TabAssistansOptions) => {
    onSelectTab(tab);
    if (tab === TabAssistansOptions.CommunityAssistants) {
      setShowNotification(false);
      localStorage.setItem("view_assistants", "true");
    }
  };

  return (
    <div className="d-flex vw-100 overflow-hidden">
      <Swiper
        className="pt-4 my-2 my-lg-4 my-2 my-lg-4 w-100 px-3 custom-swiper m-0 d-flex overflow-visible font-tabs-assistants"
        breakpoints={{
          0: {
            slidesPerView: "auto",
          },
          992: {
            slidesPerView: 4,
          },
        }}
      >
        {tabData.map(tabItem => (
          <>
            <SwiperSlide
              key={tabItem.tabOption}
              className={`w-auto d-flex justify-content-start align-items-center gap-3 mr-2 ${
                tabItem.tabOption === TabAssistansOptions.CommunityAssistants
              } ${tabItem.tabOption === activeTab ? "active-tab" : ""}`}
              onClick={() => toggleTab(tabItem.tabOption)}
              style={{ paddingRight: 45 }}
            >
              <div className="btn-primary button-border-sm button-small d-flex align-items-center justify-content-center">
                {tabItem.img ? (
                  <i
                    className={`${tabItem.img}`}
                    style={{ color: "#ffffff", fontSize: 19 }}
                  />
                ) : (
                  <img src={tabItem.iconImage} alt="" />
                )}
              </div>
              <p className="mb-0">{tabItem.label}</p>
            </SwiperSlide>
          </>
        ))}
        <SwiperSlide className="d-flex justify-content-start align-items-center">
          <CreateAssitants label={t("tabs.createAssistant")} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
