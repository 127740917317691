import Pricing from "../../components/Pricing";
import { getActiveProductsWithPrices } from "../../utils/supabase-client";
import { Product } from "../../../types";
import { useEffect, useState } from "react";
import { MyUserContextProvider } from "../../utils/useUser";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import AppSimpleBar from "../../components/AppSimpleBar";

import "animate.css";

export function SubscriptionPage({ hideLogo, hideTitle, description }: any) {
  const supabaseClient = useSupabaseClient();
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const fetchActiveProductsWithPrices = async () => {
      const response = await getActiveProductsWithPrices(supabaseClient);
      setProducts(response);
    };
    fetchActiveProductsWithPrices();
  }, []);

  return (
    <AppSimpleBar className="w-100 py-4 d-flex align-items-center vh-100">
      <MyUserContextProvider>
        <Pricing
          products={products}
          hideLogo={hideLogo}
          hideTitle={hideTitle}
          description={description}
        />
      </MyUserContextProvider>
    </AppSimpleBar>
  );
}
