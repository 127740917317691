export const SupportI18n = {
  "pt-BR": {
    page: {
      supportTile: "Nosso suporte",
    },
    socialList: {
      tooltipLabel: "Idioma(s)",
    },
  },
  "en-US": {
    page: {
      supportTile: "Our support",
    },
    socialList: {
      tooltipLabel: "Language(s)",
    },
  },
  "es-ES": {
    page: {
      supportTile: "Nuestro soporte",
    },
    socialList: {
      tooltipLabel: "Idioma(s)",
    },
  },
  "fil-PH": {
    page: {
      supportTile: "Ang aming suporta",
    },
    socialList: {
      tooltipLabel: "Wika/Idioma",
    },
  },
};
