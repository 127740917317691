export const AffiliateProgramTaximeterI18n = {
  "pt-BR": {
    title: "Taxímetro",
    month: "Mês",
    amounToReceive: "Valor a Receber",
    users: "Usuários",
    importantNotice:
      'Importante: Para garantir que você receba seus ganhos corretamente e no prazo, VOCÊ DEVE ler nossos <a target="_blank" href="https://aizzy-8b5d01.ingress-comporellon.ewp.live/privacy-policy/">Termos e Condições</a>. É o seu guia essencial para entender o processo de pagamento. Não perca - familiarize-se hoje mesmo!',
    notice:
      "Aviso: Seus ganhos estão esperando! Comece compartilhando seu link de afiliado exclusivo com outras pessoas. Quanto mais pessoas você convidar, mais poderá ganhar. É simples e recompensador. Comece a espalhar a palavra hoje mesmo!",
  },
  "en-US": {
    title: "Taximeter",
    month: "Month",
    amounToReceive: "Amount to Receive",
    users: "Users",
    importantNotice:
      "Important: To ensure you receive your earnings properly and on time, you MUST read our <a target='_blank' href=\"https://aizzy-8b5d01.ingress-comporellon.ewp.live/privacy-policy/\">Terms and Conditions</a>. It's your essential guide to understanding the payout process. Don't miss out - familiarize yourself today!",
    notice:
      "Notice: Your earnings are waiting! Get started by sharing your unique affiliate link with others. The more people you invite, the more you can earn. It's simple and rewarding. Start spreading the word today!",
  },
  "es-ES": {
    title: "Taxímetro",
    month: "Mes",
    amounToReceive: "Monto a recibir",
    users: "Usuarios",
    importantNotice:
      'Importante: Para garantizar que recibas tus ganancias de manera correcta y puntual, DEBES leer nuestros <a target="_blank" href="https://aizzy-8b5d01.ingress-comporellon.ewp.live/privacy-policy/">Términos y Condiciones</a>. Es tu guía esencial para comprender el proceso de pago. No te lo pierdas - familiarízate hoy mismo!',
    notice:
      "Aviso: ¡Tus ganancias están esperando! Comienza compartiendo tu enlace de afiliado único con otras personas. Cuantas más personas invites, más podrás ganar. Es simple y gratificante. ¡Comienza a correr la voz hoy mismo!",
  },
  "fil-PH": {
    title: "Taximeter",
    month: "Buwan",
    amounToReceive: "Halaga na Makukuha",
    users: "Mga Gumagamit",
    importantNotice:
      'Mahalaga: Upang tiyakin na makatanggap ka ng iyong kita nang maayos at sa oras, KAILANGAN MONG basahin ang aming <a target="_blank" href="https://aizzy-8b5d01.ingress-comporellon.ewp.live/privacy-policy/">Mga Tuntunin at Kondisyon</a>. Ito ang iyong pangunahing gabay sa pag-unawa ng proseso ng pagbabayad. Huwag palampasin - alamin ang iyong sarili ngayon!',
    notice:
      "Paalala: Ang iyong kita ay naghihintay! Simulan ang pagbabahagi ng iyong natatanging link ng kaakibat sa iba. Kapag mas maraming tao ang imbitado mo, mas marami kang maaaring kitain. Madali at mapapabuti ito. Magsimula ng pagkalat ng balita ngayon!",
  },
};
