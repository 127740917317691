import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const SelectAssistants = ({ dropdownOpen, toggle }) => {
  const [valueInputQuestion, setValueInputQuestion] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");

  const list = [
    {
      icon: "bx bxs-group",
      assitentes: "Primeiro Assitente de chat",
    },
    {
      icon: "bx bxs-user",
      assitentes: "Segundo Assitente",
    },
    {
      icon: "bx bxs-user",
      assitentes: "Terceiro Assitente",
    },
    {
      icon: "bx bxs-user",
      assitentes: "Quarto Assitente",
    },
    {
      icon: "bx bxs-user",
      assitentes: "Quinto Assitente",
    },
    {
      icon: "bx bxs-user",
      assitentes: "Sexto Assitente",
    },
    {
      icon: "bx bxs-user",
      assitentes: "Sétimo Assitente",
    },
  ];

  const handleSelectAssistant = assistant => {
    setValueInputQuestion(assistant.assitentes);
    setSelectedIcon(assistant.icon);
  };

  return (
    <Dropdown
      className="mt-auto mb-1 dropdown-select-assistants"
      direction="up"
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle
        nav
        className="flag-button d-flex align-items-center justify-content-center"
      >
        <div className="input-main-chat">
          {selectedIcon && <i className={selectedIcon} />}
          <input
          className="one-line"
            type="text"
            name="input-select-assistants"
            placeholder="Select your assistant.."
            value={valueInputQuestion}
          />
          <i
            className={`bx bx-down-arrow-alt input-group-addon ${
              dropdownOpen ? "open" : ""
            }`}
          />
        </div>
      </DropdownToggle>
      <DropdownMenu className="p-2 mb-1">
        <ul className="list-assistants-chat p-0 m-0 gap-1 d-flex flex-column">
          {list.map((item, index) => (
            <li key={index}>
              <DropdownItem
                className="d-flex align-items-center justify-content-start p-1 one-line"
                onClick={() => handleSelectAssistant(item)}
              >
                <i className={item.icon} style={{ marginRight: "0.5rem" }} />
                {item.assitentes}
              </DropdownItem>
            </li>
          ))}
        </ul>
      </DropdownMenu>
    </Dropdown>
  );
};

export default SelectAssistants;
