import { useEffect, useState } from "react";
import { DropdownShareAssistant } from "./DropdownShareAssistant";
import {
  likeCommunityAssistant,
  unlikeCommunityAssistant,
} from "../api/assistants";
import { useUser } from "@supabase/auth-helpers-react";
import { showErrorNotification } from "../helpers/notifications";
import { Link } from "react-router-dom";
import { addCommaToNumber } from "../utils/helpers";

import { CommunityAssistantI18n } from "../pages/CommunityAssistants/CommunityAssistant.i18n";
import useTranslation from "../helpers/useTranslation";

interface FooterCommunityAssistantProps {
  assistant: any;
}

export function FooterCommunityAssistant({
  assistant,
}: FooterCommunityAssistantProps) {
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  // const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [isUserLiked, setIsUserLiked] = useState(false);

  const user = useUser();
  const isOwner = user?.id === assistant?.userId;
  // const isHired = assistant?.hired;

  const { t } = useTranslation(CommunityAssistantI18n);

  // const handleCheckout = async () => {
  //   setLoadingCheckout(true);
  //   try {
  //     const res = await fetch(
  //       process.env.REACT_APP_API_URL + "/api/stripe/create-assistant-session",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + getBearerToken(),
  //         },
  //         body: JSON.stringify({ assistant_id: assistant?._id }),
  //       }
  //     );

  //     if (res.status === 200) {
  //       const data = await res.json();
  //       const { sessionId } = data;
  //       const stripe = await getStripe();
  //       stripe?.redirectToCheckout({ sessionId });
  //     }
  //   } catch (error) {
  //     return alert((error as Error)?.message);
  //   } finally {
  //     setLoadingCheckout(false);
  //   }
  // };
  // useEffect(() => {
  //   const userId = user?.id;
  //   setIsUserLiked(assistant?.likedby?.some((id: string) => id === userId));
  // }, [assistant?.likedby, user?.id]);

  async function handleLikeContact() {
    if (isLikeLoading) {
      return;
    }
    try {
      setIsLikeLoading(true);
      setIsUserLiked(true);
      await likeCommunityAssistant(assistant?._id || assistant?.objectID);
    } catch (error: any) {
      showErrorNotification(error);
    } finally {
      setIsLikeLoading(false);
    }
  }

  async function handleUnlikeContact() {
    if (isLikeLoading) {
      return;
    }
    try {
      setIsLikeLoading(true);
      setIsUserLiked(false);
      await unlikeCommunityAssistant(assistant?._id || assistant?.objectID);
    } catch (error: any) {
      showErrorNotification(error);
    } finally {
      setIsLikeLoading(false);
    }
  }
  useEffect(() => {
    setIsUserLiked(
      assistant?.likedByMe || assistant?.likedby?.includes(user?.id)
    );
  }, [assistant?.likedByMe, assistant?.likedby, user?.id]);

  return (
    <div
      className={`d-flex align-items-center justify-content-${
        !assistant?.userName && !isOwner ? "end" : "between"
      } px-3 gap-3`}
    >
      {/* {JSON.stringify(assistant)}  */}
      {isOwner && (
        <span className="badge badge-pill text-primary text-opacity-75">
          {assistant?.enabled ? t("card.enabled") : t("card.disabled")}
        </span>
      )}

      {!isOwner && (
        <>
          {assistant?.userName && (
            <div className="small">
              <span>
                {t("card.owner")}{" "}
                <span className="text-primary text-opacity-75">
                  {assistant?.userName}
                </span>
              </span>
            </div>
          )}

          {/* <div className="d-flex align-items-center">
                <span
                  id="support-community-assistant"
                  className="d-flex align-items-center small text-secondary"
                >
                  <a href={`mailto:${assistant?.userName}`}>
                    {t("card.support")} <i className="bx bxs-info-circle" />
                  </a>
                  <UncontrolledTooltip
                    target="support-community-assistant"
                    placement="right"
                  >
                    {t("card.description-helper")}
                  </UncontrolledTooltip>
                </span>
              </div> */}
        </>
      )}
      <div className="mt-1 d-flex align-items-center gap-2">
        <DropdownShareAssistant assistant={assistant} />
        {isOwner && (
          <Link
            to={{
              pathname: "/assistant/create",
            }}
            state={{
              assistantId: assistant?._id || assistant?.objectID,
            }}
            className="btn btn-sm m-0 p-0 hover-primary"
          >
            <i className="bx bxs-edit fs-4" />
          </Link>
        )}
        {/* {process.env.REACT_APP_ACTIVE_ASSISTANT_SALE === "true" &&
          !isHired &&
          !isOwner &&
          assistant?.userPrice > 0 && (
            <button
              onClick={handleCheckout}
              className="btn btn-sm m-0 p-0"
              disabled={loadingCheckout}
            >
              {loadingCheckout ? (
                <i className="bx bx-loader bx-spin fs-4" />
              ) : (
                <i className="bx bx-shopping-bag hover-primary fs-4" />
              )}
            </button>
          )} */}
        {/* {String(isUserLiked)} */}
        {isUserLiked ? (
          <button
            onClick={handleUnlikeContact}
            className="btn btn-sm heartBeat m-0 p-0"
          >
            <i className="bx bxs-heart fs-4" style={{ color: "red" }} />
          </button>
        ) : (
          <button onClick={handleLikeContact} className="btn btn-sm m-0 p-0">
            <i className="bx bx-heart fs-4" />
          </button>
        )}

        {assistant?.likes > 1 && (
          <span className="mb-1 text-secondary fs-6">
            {addCommaToNumber(assistant?.likes)}
          </span>
        )}
      </div>
    </div>
  );
}
