import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import withRouter from "../../components/withRouter";
import useTranslation from "../../helpers/useTranslation";
import { LogoutI18n } from "./Logout.i18n";
import Cookies from "js-cookie";
import { delayInMs } from "../../utils/helpers";

const Logout = (props: any) => {
  const { t } = useTranslation(LogoutI18n);

  const supabaseClient = useSupabaseClient();

  const texts = {
    expirated: {
      title: t("expired.title"),
      description: t("logout.description"),
    },
    logout: {
      title: t("logout.title"),
      description: t("logout.description"),
    },
  };

  useEffect(() => {
    const logout = async () => {
      // Clear all cookies
      const cookies = Object.keys(Cookies.get());
      cookies.forEach(cookie => {
        Cookies.remove(cookie);
      });

      // Clear all localStorage
      localStorage.clear();

      // Clear all sessionStorage
      sessionStorage.clear();

      await delayInMs(1000);

      // Sign out from Supabase
      await supabaseClient.auth.signOut();

      // Navigate to home page
      window.location.href = "/";
    };

    logout();
  }, []);

  return (
    <div className="vh-100 d-flex align-items-center w-100 logout-page">
      <NonAuthLayoutWrapper>
        <Row className="justify-content-center my-auto">
          <Col sm={8} lg={6} xl={5} className="col-xxl-4">
            <Card color="light">
              <CardBody>
                <div className="py-md-5 py-4 text-center">
                  <div className="mx-auto">
                    <Logo style={{ width: 50, height: 50 }} />
                  </div>
                  <div className="mt-4 pt-2">
                    <h5>{t("logout.title") ?? "Disconnecting..."}</h5>
                    <p className="text-muted font-size-15">
                      {t("logout.description") ??
                        "You are being disconnected from your account."}
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </NonAuthLayoutWrapper>
    </div>
  );
};

export default withRouter(Logout);
