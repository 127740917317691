import { useEffect, useRef, useState } from "react";

import { useRedux } from "../../hooks";
import {
  changeAssistant,
  changeTab,
  getChatUserConversationsReset,
} from "../../redux/actions";

import AppSimpleBar from "../../components/AppSimpleBar";
import { TABS } from "../../constants";

import CommunityAssistant from "../../components/CommunityAssistant";
import { CommunityAssistantsI18n } from "./CommunityAssistant.i18n";
import useTranslation from "../../helpers/useTranslation";
import { Header } from "./components/Header";
import { useNavigate } from "react-router-dom";
import { CommunityAssistantSearch } from "../../components/CommunityAssistantSearch";
import { getCurrentUserLanguage } from "../../helpers/userHelper";
import { useQuery } from "react-query";
import { getCommunityAssistants } from "../../api/assistants";
import { FetchCommunityAssistantResponse } from "./types";
import { PaginationAssistants } from "./components/PaginationAssistants";
import { QUERY_KEYS } from "../../libs/react-query";
import { AssistantCardSkeleton } from "../../components/AssistantCardSkeleton";

export function CommunityAssistantsPage() {
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isSearching, setIsSearching] = useState(false);

  const scrollRef = useRef<any>();

  const { dispatch } = useRedux();

  const language = getCurrentUserLanguage();

  const { isLoading, data, isFetching } =
    useQuery<FetchCommunityAssistantResponse>(
      [QUERY_KEYS.COMMUNITY_ASSISTANTS, pageNumber],
      async () => {
        return await getCommunityAssistants({ pageNumber, language });
      },
      {
        staleTime: 5 * 60 * 1000, // 5 minutes
        keepPreviousData: true,
      }
    );

  const navigation = useNavigate();

  const { t } = useTranslation(CommunityAssistantsI18n);

  function onSelectAssistant(assistant) {
    dispatch(changeAssistant(assistant));
    dispatch(getChatUserConversationsReset());
    dispatch(changeTab(TABS.ASSISTANT_CHATS));
    navigation("/dashboard");
  }

  function handleChangePage(pageNumber: number) {
    setPageNumber(pageNumber - 1);
  }

  function onSearchAssistant(query) {
    if (query) {
      return setIsSearching(true);
    }
    setIsSearching(false);
  }

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.recalculate();
      const scrollEl = scrollRef.current.getScrollElement();

      scrollEl.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [pageNumber, scrollRef]);

  useEffect(() => {
    if (data?.total_count && data.total_count > 0) {
      setTotalCount(data.total_count);
    }
  }, [data?.total_count]);

  return (
    <AppSimpleBar
      scrollRef={scrollRef}
      className="vh-100 chat-leftsidebar full overflow-auto pb-5 pb-sm-0"
    >
      <div className="mx-4 my-3">
        <Header />

        <div className="mt-4 mb-2 d-flex align-items-sm-center gap-3">
          <CommunityAssistantSearch onSearchAssistant={onSearchAssistant} />
        </div>

        {isLoading || isFetching ? (
          <AssistantCardSkeleton quantity={20} />
        ) : (
          <>
            {data?.assistants && data?.assistants.length > 0 ? (
              <div className="w-auto">
                <CommunityAssistant
                  assistants={isSearching ? [] : data?.assistants}
                  onSelectAssistant={onSelectAssistant}
                />
              </div>
            ) : (
              <span className="d-block my-5 text-center fs-4">
                {t("page.empty-community-assistant")}
              </span>
            )}
          </>
        )}

        {!isLoading && !isSearching && (
          <div className="p-3 d-flex justify-content-center">
            <PaginationAssistants
              totalCountOfRegisters={totalCount}
              currentPage={pageNumber + 1}
              onPageChange={handleChangePage}
            />
          </div>
        )}
      </div>
    </AppSimpleBar>
  );
}
