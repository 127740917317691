import { Link } from "react-router-dom";
import classnames from "classnames";

import { ChatMenuPopUp } from "../../../components/ChatMenuPopUp";
import { useUser } from "@supabase/auth-helpers-react";

import { SelectedChatDTO } from "../../../@DTO/selectedChat";
import { ChannelDTO } from "../../../@DTO/channel";
import { useSharedChat } from "../../../hooks/useSharedChat";
import { IconInfoSquareRounded } from "@tabler/icons-react";

interface ChannelProps {
  channel: any;
  selectedChat: SelectedChatDTO | null;
  onSelectChat: (channel: ChannelDTO, isChannel?: boolean) => void;
}

const ChatChannel = ({ channel, selectedChat, onSelectChat }: ChannelProps) => {
  const { handleLeaveSharedChat } = useSharedChat();

  const userLogged = useUser();

  const unRead = channel.meta && channel.meta.unRead;
  const isSelectedChat: boolean =
    selectedChat && selectedChat._id === channel._id ? true : false;
  const onClick = () => {
    if (!isSelectedChat) {
      onSelectChat(channel);
    }
  };

  return (
    <div
      className={classnames(
        {
          active: isSelectedChat,
        },
        "chat-item px-2"
      )}
      style={{ minHeight: 50, borderRadius: 6 }}
    >
      <li onClick={onClick}>
        <Link
          to="#"
          className={classnames({ "unread-msg-user": unRead }, "m-0 p-0")}
        >
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 avatar-xs me-2">
              <span className="avatar-title  bg-soft-light text-dark">
                {channel?.isSharedWithMe ||
                channel.userId !== userLogged?.id ? (
                  <i className="bx bx-group font-size-16" />
                ) : (
                  "#"
                )}
              </span>
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <p className="text-truncate mb-0">{channel.channelName}</p>
            </div>
          </div>
        </Link>
      </li>

      <div className="d-flex align-items-center gap-2">
        {!channel?.isSharedWithMe ? (
          <div className="d-flex align-items-center">
            <ChatMenuPopUp channelId={channel._id} channel={channel} />
          </div>
        ) : (
          <button
            onClick={() => handleLeaveSharedChat(channel._id)}
            className="btn text-danger button-leave-chat m-0 p-0"
          >
            <i className="bx bx-log-out fs-5" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ChatChannel;
