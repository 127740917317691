import {
  useSession,
  useSupabaseClient,
  useUser,
} from "@supabase/auth-helpers-react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import AuthHeader from "../../components/AuthHeader";
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";

import { fetchAndSaveRemainingCalls } from "../../freemium/remainingCalls";
import { getAndUpdateUser } from "../../helpers/userHelper";
import { LoadingUserData } from "../../components/LoadingUserData";
import { useLocation, useNavigate } from "react-router-dom";
import iconMetaMask from "../../assets/images/icons/metamask.svg";
import iconGoogle from "../../assets/images/icons/google.svg";

import { ethers } from "ethers";
import ModalInstallMetamask from "../../components/ModalInstallMetamask";
import { DropdownLanguageMenu } from "../../components/DropdownLanguageMenu";
import useTranslation from "../../helpers/useTranslation";
import { LoginI18n } from "./Login.i18n";
import { showErrorNotification } from "../../helpers/notifications";
import { requestChallenge, verifyChallenge } from "../../api";

interface LocationState {
  signUp?: boolean;
}

interface ChallengeData {
  success: boolean;
  data: string;
}

interface User {
  id: string;
  full_name: string | null;
  avatar_url: string | null;
  billing_address: string | null;
  payment_method: string | null;
  is_admin: boolean;
  email: string;
  last_email_at: string | null;
  auth: string | null;
  eth_address: string;
  in_mailchimp: boolean;
  language: string;
  affiliateid: string | null;
  referral_id: string | null;
  phone_number: string | null;
  country_code: string | null;
  wordpress_access_token: string | null;
  credits: number | null;
}

interface AuthResponse {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: string;
  user: User;
}

const STORAGE_KEYS = {
  USER_WALLET_SIGN: "signature",
};

export function LoginPage() {
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [isFetchingUserData, setIsFetchingUserData] = useState(false);
  const [modalInstallMetamask, setModalInstallMetamask] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const { t } = useTranslation(LoginI18n);

  const location = useLocation();
  const routeState = location.state as LocationState;

  const navigate = useNavigate();

  const supabaseClient = useSupabaseClient();
  const user = useUser();
  const session = useSession();

  const signInWeb3 = async () => {
    const signatureStorage = localStorage.getItem(
      STORAGE_KEYS.USER_WALLET_SIGN
    );

    if (signatureStorage) {
      return signatureStorage;
    }

    try {
      setLoadingLogin(true);
      const provider = new ethers.BrowserProvider(window.ethereum);

      await provider.send("eth_requestAccounts", []);

      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      const addressId = accounts[0] as string;

      const challengeResponse = await requestChallenge(addressId);
      const challenge: ChallengeData = challengeResponse;

      if (!challenge.success) {
        return showErrorNotification(
          challenge.data ?? "Failed to login with Metamask"
        );
      }

      const ethereum = await window?.ethereum;
      const signer = await new ethers.BrowserProvider(ethereum).getSigner();

      const signature = await signer.signMessage(challenge.data);

      const verifyResponse = await verifyChallenge({
        message: challenge.data,
        signature: signature,
      });

      const authData: AuthResponse = verifyResponse;

      await supabaseClient.auth.signInWithPassword({
        email: authData.user.email,
        password: addressId.toLowerCase(),
      });
    } catch (error: any) {
      console.error(error?.message);
      showErrorNotification(
        error?.message ??
          "Failed to login with Metamask, please try again or contact support"
      );
    } finally {
      setLoadingLogin(false);
    }
  };

  const handleClickMetamask = () => {
    if (loadingLogin) return;

    if (window.ethereum) {
      signInWeb3();
    } else {
      setModalInstallMetamask(true);
    }
  };

  const handleClickGoogle = async () => {
    if (loadingLogin) return;

    try {
      setLoadingLogin(true);
      const { data, error } = await supabaseClient.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${process.env.REACT_APP_PUBLIC_SITE_URL}/signin`,
        },
      });

      if (error) {
        throw new Error(error.message);
      }
    } catch (error: any) {
      console.error(error);
      showErrorNotification(
        error?.message ??
          "Failed to login with Google, please try again or contact support"
      );
    } finally {
      setLoadingLogin(false);
    }
  };

  useEffect(() => {
    const fetchAndSaveUserData = async () => {
      try {
        if (session) {
          setIsFetchingUserData(true);

          await Promise.all([fetchAndSaveRemainingCalls(), getAndUpdateUser()]);

          navigate("/");
        }
      } catch (error: any) {
        showErrorNotification(
          error?.message ||
            "Unable to load data, please try again or contact support"
        );
        console.error(error);
      } finally {
        setIsFetchingUserData(false);
      }
    };

    fetchAndSaveUserData();
  }, [session]);

  const handleLanguageSelect = languageCode => {
    setSelectedLanguage(languageCode);
  };

  return (
    <>
      {isFetchingUserData ? (
        <LoadingUserData user={user} />
      ) : (
        <NonAuthLayoutWrapper>
          <Row className="justify-content-center my-auto">
            <Col sm={8} lg={6} xl={5} className="col-xxl-4">
              <Card color="light">
                <CardBody>
                  <div className="py-3">
                    <div>
                      <AuthHeader logo />

                      {/* <Web3Button />
                    <Button onClick={() => Login()} loading={loadingLogin.toString()}>
                      Login
                    </Button> */}
                    </div>
                    <div className="d-flex align-items-center justify-content-center mb-4">
                      <DropdownLanguageMenu state={handleLanguageSelect} />
                    </div>

                    <button
                      className="button-sign-wallet-metamask"
                      onClick={handleClickMetamask}
                      disabled={loadingLogin}
                    >
                      <img src={iconMetaMask} alt="Ícone do Botão" />
                      {loadingLogin ? (
                        <div className="d-flex align-items-center gap-2">
                          <span>{t("auth.supabaseModal.loadingText")}</span>
                          <i className="bx bx-loader bx-spin align-middle" />
                        </div>
                      ) : (
                        <span>{t("auth.supabaseModal.signInMetamask")}</span>
                      )}
                    </button>
                    <button
                      className="button-sign-wallet-metamask mt-3"
                      onClick={handleClickGoogle}
                      disabled={loadingLogin}
                    >
                      <img src={iconGoogle} alt="Ícone do Botão" />

                      <span>{t("auth.supabaseModal.signInGoogle")}</span>
                    </button>
                    <Auth
                      supabaseClient={supabaseClient}
                      providers={[]}
                      magicLink={true}
                      view={routeState?.signUp ? "sign_up" : "sign_in"}
                      localization={{
                        variables: {
                          sign_in: {
                            loading_button_label: t(
                              "auth.supabaseModal.loadingText"
                            ),
                            button_label: t("auth.supabaseModal.authButton"),
                            email_label: t("auth.supabaseModal.labelEmail"),
                            email_input_placeholder: t(
                              "auth.supabaseModal.placeholderEmail"
                            ),
                            password_label: t(
                              "auth.supabaseModal.labelPassword"
                            ),
                            password_input_placeholder: t(
                              "auth.supabaseModal.placeholderPassword"
                            ),
                            link_text: t("auth.supabaseModal.linkText"),
                            social_provider_text: t(
                              "auth.supabaseModal.signInGoogle"
                            ),
                          },
                          sign_up: {
                            loading_button_label: t(
                              "signUp.supabaseModal.loadingText"
                            ),
                            button_label: t(
                              "signUp.supabaseModal.signUpButton"
                            ),
                            email_label: t("signUp.supabaseModal.labelEmail"),
                            email_input_placeholder: t(
                              "signUp.supabaseModal.placeholderEmail"
                            ),
                            password_label: t(
                              "signUp.supabaseModal.labelPassword"
                            ),
                            password_input_placeholder: t(
                              "signUp.supabaseModal.placeholderPassword"
                            ),
                            link_text: t("signUp.supabaseModal.linkText"),
                          },
                          forgotten_password: {
                            loading_button_label: t(
                              "forgot.supabaseModal.loadingText"
                            ),
                            button_label: t(
                              "forgot.supabaseModal.buttonResetPassword"
                            ),
                            email_label: t("forgot.supabaseModal.labelEmail"),
                            email_input_placeholder: t(
                              "forgot.supabaseModal.placeholderEmail"
                            ),
                            link_text: t("forgot.supabaseModal.linkText"),
                          },
                          magic_link: {
                            loading_button_label: t(
                              "magicLink.supabaseModal.loadingText"
                            ),
                            button_label: t(
                              "magicLink.supabaseModal.magicLinkButton"
                            ),
                            email_input_label: t(
                              "magicLink.supabaseModal.labelEmail"
                            ),
                            email_input_placeholder: t(
                              "magicLink.supabaseModal.placeholderEmail"
                            ),
                            link_text: t("magicLink.supabaseModal.linkText"),
                          },
                        },
                      }}
                      appearance={{
                        theme: ThemeSupa,
                        variables: {
                          default: {
                            colors: {
                              brand: "#13af9d",
                              brandAccent: "#13af9d",
                            },
                          },
                        },
                      }}
                      theme="light"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </NonAuthLayoutWrapper>
      )}
      <ModalInstallMetamask
        onclick={() => setModalInstallMetamask(false)}
        modalInstallMetamask={modalInstallMetamask}
        iconMetaMask={iconMetaMask}
      />
    </>
  );
}
