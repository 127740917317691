import { ProductWithPrice } from "../../types";

export const getActiveProductsWithPrices = async (
  supabaseClient
): Promise<ProductWithPrice[]> => {
  //check if key starts with "pk_test"
  const prodProducts =
    !process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY?.includes("pk_test");
  const { data, error } = await supabaseClient
    .from("products")
    .select("*, prices(*)")
    .eq("active", prodProducts)
    .eq("prices.active", prodProducts)
    .order("metadata->index")
    .order("unit_amount", { foreignTable: "prices" });

  // TODO: improve the typing here.
  return (data as any) || [];
};
