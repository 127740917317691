import { combineReducers } from "redux";

import Layout from "./layout/reducer";
import Chats from "./chats/reducer";
import thirdwebReducer from './thirdweb/reducer'

export default combineReducers({
  Layout,
  Chats,
  thirdwebReducer
});
