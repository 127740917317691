export const supportList = [
  {
    name: "AizzyFale",
    iconClassname: "bxl-whatsapp",
    link: "https://chat.whatsapp.com/Evt1O26vsyt5ulXjAGo28S",
    languages: {
      isPtBR: true,
      isEnUS: false,
      isEsES: false,
    },
  },
  {
    name: "AizzyTalk",
    iconClassname: "bxl-whatsapp",
    link: "https://chat.whatsapp.com/BrsIuQfRlqN7hyaKTv4PUx",
    languages: {
      isPtBR: false,
      isEnUS: true,
      isEsES: false,
    },
  },
  {
    name: "AizzyHable",
    iconClassname: "bxl-whatsapp",
    link: "https://chat.whatsapp.com/KAWSdBPCG8TAw2aE0oscgZ",
    languages: {
      isPtBR: false,
      isEnUS: false,
      isEsES: true,
    },
  },
];
