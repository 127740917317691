import React, { useEffect } from "react";
import { useState } from "react";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Configure,
} from "react-instantsearch-dom";
import { Hit } from "react-instantsearch-core";
import searchClient from "../utils/algolia";
import { FooterCommunityAssistant } from "./FooterCommunityAssistant";
import { AssistantDTO } from "../@DTO/assistant";
import { useRedux } from "../hooks";
import {
  changeAssistant,
  changeTab,
  getChatUserConversationsReset,
} from "../redux/actions";
import { TABS } from "../constants";
import { useNavigate } from "react-router-dom";
import { useUser } from "@supabase/auth-helpers-react";
import i18next from "i18next";
import clsx from "clsx";
import useTranslation from "../helpers/useTranslation";
import { CommunityAssistantSearchI18n } from "./CommunityAssistantSearch.i18n";
import { getCurrentUserLanguage } from "../helpers/userHelper";

interface HitType extends AssistantDTO {
  name: string;
  role: string;
  about: string;
  profileImage: string;
  userName: string;
  likes: number;
  hiredby: {
    id: string;
    userId: string;
    employeeId: string;
  }[];
}

function reduceRoleAssistant(value: string): string {
  if (value.length >= 60) {
    return value.substring(0, 60) + "...";
  }
  return value;
}

function reduceNameAssistant(value: string): string {
  if (value.length >= 40) {
    return value.substring(0, 40) + "...";
  }
  return value;
}

const HitComponent: React.FC<{ hit: Hit<HitType> }> = ({ hit }) => {
  const { dispatch } = useRedux();
  const navigation = useNavigate();

  const user = useUser();

  const isHiredByUser = hit?.hiredby?.some(item => item?.userId === user?.id);

  const handleSelectAssistant = hit => {
    const assistant = {
      ...hit,
      _id: hit.objectID,
      hired: isHiredByUser,
    };
    dispatch(changeAssistant(assistant));
    dispatch(getChatUserConversationsReset());
    dispatch(changeTab(TABS.ASSISTANT_CHATS));
    navigation("/dashboard");
  };

  return (
    // <div>
    //   <h2>
    //     <Highlight attribute="name" hit={hit} />
    //   </h2>
    //   <h3>
    //     <Highlight attribute="role" hit={hit.role} />
    //   </h3>
    //   <p>
    //     <Highlight attribute="about" hit={hit} />
    //   </p>
    //   <img src={hit.image} alt={hit.name} />
    // </div>

    <div
      className={`card-assistants card-assistants-loaded`}
      style={{
        maxHeight: 130,
      }}
    >
      <div
        onClick={() => handleSelectAssistant(hit)}
        className="d-flex align-items-center gap-2 p-2 animate__animated animate__fadeIn animate__faster"
      >
        <div className="flex-shrink-0 me-2">
          <div className="avatar-lg overflow-hidden">
            <img src={hit.profileImage} alt="" className="img-fluid avatar" />
          </div>
        </div>
        <div className="flex-grow-1 gap-2 justify-content-between">
          <p className="font-size-18 m-0" style={{ lineHeight: "110%" }}>
            {reduceRoleAssistant(hit.role)}
          </p>
          <p className="font-size-14 m-0 text-dark text-opacity-75">
            {reduceNameAssistant(hit.name)}
          </p>
        </div>
      </div>
      <FooterCommunityAssistant assistant={hit} />
    </div>
  );
};

interface CommunityAssistantSearchProps {
  onSearchAssistant: (query: string) => void;
}

export function CommunityAssistantSearch({
  onSearchAssistant,
}: CommunityAssistantSearchProps) {
  const [query, setQuery] = useState("");

  const { t } = useTranslation(CommunityAssistantSearchI18n);

  const isMobile = window.matchMedia("(max-width: 700px)").matches;

  useEffect(() => {
    onSearchAssistant(query);
  }, [query]);

  const language = getCurrentUserLanguage();
  return (
    <div className="w-auto px-3">
      <div className="p-0 mb-4">
        <InstantSearch searchClient={searchClient} indexName="employees">
          <SearchBox
            onChange={event => setQuery(event.currentTarget.value)}
            className={clsx("w-25 mb-2", isMobile && "w-100")}
          />
          {query && (
            <span className="m-0 p-0 text-primary">
              {t("labelIsSearch")}{" "}
              <span className="fw-semibold font-size-16">{query}</span>
            </span>
          )}
          <Configure
            filters={`private:false AND enabled:true AND language:${language}`}
          />
          {query && <Hits<HitType> hitComponent={HitComponent} />}
        </InstantSearch>
      </div>
    </div>
  );
}
