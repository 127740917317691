export const UserDocumentsI18n = {
  "pt-BR": {
    inviteMember: "Novo membro",
    members: "Membros do canal",
    title: "Documentos",
    videosTitle: "Vídeos do Youtube",
    websitesTitle: "Sites",
    "empty-document": "Nenhum documento processado",
    "empty-video": "Nenhum vídeo processado",
    "empty-link": "Nenhum site processado",
    processed: "Processado",
    processing: "Processando",
    created: "Iniciando processamento",
    extracting_pdf: "Extraindo PDF",
    saving_file: "Salvando arquivo para ser processado",
    processing_file: "Processando arquivo",
    chunking_text: "Dividindo texto",
    converting_text: "Convertendo texto para modelo de IA",
    saving_searchable_text: "Salvando como texto pesquisável",
    completing_job: "Concluindo o trabalho",
    job_finished: "Trabalho concluído",
    pdf_extracted: "PDF extraído",
    creating_job: "Criando trabalho",
    starting_text_extraction: "Iniciando extração de texto",
    extracting_text: "Extraindo texto",
    resendSite:
      "O conteúdo do site não foi encontrado nesse dispositivo ou browser. Vamos reprocessar o site para você fazer perguntas sobre ele.",
    resendVideo:
      "O conteúdo do vídeo não foi encontrado nesse dispositivo ou browser. Vamos reprocessar o vídeo para você fazer perguntas sobre ele.",
  },
  "en-US": {
    inviteMember: "New member",
    members: "Members channel",
    title: "Documents",
    videosTitle: "YouTube Videos",
    websitesTitle: "Websites",
    "empty-document": "No documents processed",
    "empty-video": "No video processed",
    "empty-link": "No website processed",
    processed: "Processed",
    processing: "Processing",
    created: "Initiating processing",
    extracting_pdf: "Extracting PDF",
    saving_file: "Saving file to be processed",
    processing_file: "Processing file",
    chunking_text: "Chunking text",
    converting_text: "Converting text to AI model",
    saving_searchable_text: "Saving as searchable text",
    completing_job: "Completing the job",
    job_finished: "Job finished",
    pdf_extracted: "PDF extracted",
    creating_job: "Creating job",
    starting_text_extraction: "Starting text extraction",
    extracting_text: "Extracting text",
    resendSite:
      "The website content was not found on this device or browser. We'll reprocess the website so you can ask questions about it.",
    resendVideo:
      "The video content was not found on this device or browser. We'll reprocess the video so you can ask questions about it.",
  },
  "es-ES": {
    inviteMember: "Nuevo miembro",
    members: "Miembros del canal",
    title: "Documentos",
    videosTitle: "Videos de YouTube",
    websitesTitle: "Websites",
    "empty-document": "No hay documentos procesados",
    "empty-video": "Ningún video procesado",
    "empty-link": "Ningún sitio web procesado",
    processed: "Procesado",
    processing: "Procesando",
    created: "Iniciando procesamiento",
    extracting_pdf: "Extrayendo PDF",
    saving_file: "Guardando archivo para ser procesado",
    processing_file: "Procesando archivo",
    chunking_text: "Dividiendo texto",
    converting_text: "Convirtiendo texto a modelo de IA",
    saving_searchable_text: "Guardando como texto buscable",
    completing_job: "Completando el trabajo",
    job_finished: "Trabajo terminado",
    pdf_extracted: "PDF extraído",
    creating_job: "Creando trabajo",
    starting_text_extraction: "Iniciando extracción de texto",
    extracting_text: "Extrayendo texto",
    resendSite:
      "El contenido del sitio web no se encontró en este dispositivo o navegador. Vamos a reprocesar el sitio web para que pueda hacer preguntas sobre él.",
    resendVideo:
      "El contenido del video no se encontró en este dispositivo o navegador. Vamos a reprocesar el video para que pueda hacer preguntas sobre él.",
  },
};
