import { Col, Row } from "reactstrap";

interface NonAuthLayoutWrapperProps {
  children: any;
}

const NonAuthLayoutWrapper = (props: NonAuthLayoutWrapperProps) => {
  return (
    <div className="authentication-page-content">
      <div className="d-flex flex-column h-100 p-3">
        {props.children}
        <Row className="">
          <Col xl={12}>
            <div className="text-center text-muted p-4">
              <p className="mb-0">&copy; {new Date().getFullYear()} AIZZY</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NonAuthLayoutWrapper;
