import FlagBrSvg from "../assets/images/icons/survey/flag-br.svg";
import FlagEsSvg from "../assets/images/icons/survey/flag-es.svg";
import FlagUsSvg from "../assets/images/icons/survey/flag-eus.svg";

interface SocialMediaLanguagesProps {
  isEnUS?: boolean;
  isPtBR?: boolean;
  isEsES?: boolean;
}

export function SocialMediaLanguages({
  isEnUS,
  isPtBR,
  isEsES,
}: SocialMediaLanguagesProps) {
  const flagImages = {
    "en-US": FlagUsSvg,
    "pt-BR": FlagBrSvg,
    "es-ES": FlagEsSvg,
  };

  return (
    <div className="d-flex align-items-center gap-2">
      {isEnUS && <img src={flagImages["en-US"]} alt="" />}
      {isPtBR && <img src={flagImages["pt-BR"]} alt="" />}
      {isEsES && <img src={flagImages["es-ES"]} alt="" />}
    </div>
  );
}
