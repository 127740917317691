import { Link } from "react-router-dom";

const CreateAssitants = ({ label }) => {
    return ( 
        <Link className="d-flex align-items-center gap-3" to={"/assistant/create"}>
            <div
              className="btn-primary button-border-sm button-small d-flex align-items-center justify-content-center"
            >
              <i
                className="bx bxs-message-square-add icon-tab-assistants"
              />
            </div>
            <p>{label}</p>
          </Link>
     );
}
 
export default CreateAssitants;