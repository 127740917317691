import { useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { format, differenceInSeconds } from "date-fns";
import useTranslation from "../../../helpers/useTranslation";
import { JobMetadaI18n } from "./JobMetadata.i18n";

export function JobMetadata({ job }) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { t } = useTranslation(JobMetadaI18n);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const isValidDate = date => {
    return !isNaN(new Date(date).getTime());
  };

  const formattedCreatedAt = isValidDate(job?.created_at)
    ? format(new Date(job.created_at), "PPpp")
    : null;

  const processingTimeInSeconds =
    isValidDate(job?.created_at) && isValidDate(job?.updated_at)
      ? differenceInSeconds(new Date(job.updated_at), new Date(job.created_at))
      : null;

  return (
    <div>
      <button
        className="btn btn-primary rounded-3 btn-sm font-size-10"
        id={`popover-${job._id}`}
        type="button"
        onClick={toggle}
      >
        {t("viewDetails")}
      </button>

      <Popover
        isOpen={popoverOpen}
        target={`popover-${job._id}`}
        toggle={toggle}
      >
        <PopoverBody style={{ fontFamily: "Arial, sans-serif" }}>
          <div>
            {formattedCreatedAt && (
              <p>
                <strong>{t("createdAt")}:</strong> {formattedCreatedAt}
              </p>
            )}
            {job?.type === "website" && job?.total_pages != null && (
              <p>
                <strong>{t("totalPages")}:</strong> {job.total_pages}
              </p>
            )}
            {job?.total_words != null && (
              <p>
                <strong>{t("totalWords")}:</strong> {job.total_words}
              </p>
            )}
            {job?.total_chars != null && (
              <p>
                <strong>{t("totalChars")}:</strong> {job.total_chars}
              </p>
            )}
            {job?.type === "document" && job?.num_readable_images != null && (
              <p>
                <strong>{t("numReadableImages")}:</strong>{" "}
                {job.num_readable_images}
              </p>
            )}
            {processingTimeInSeconds != null && (
              <p>
                <strong>{t("processingTime")}:</strong>{" "}
                {processingTimeInSeconds} {t("seconds")}
              </p>
            )}
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
}
