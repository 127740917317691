/* eslint-disable react-hooks/rules-of-hooks */
import React, { ReactNode, memo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  oneDark,
  coldarkCold,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import { showSuccessNotification } from "../helpers/notifications";
import useTranslation from "../helpers/useTranslation";
import { MarkdownRendererI18n } from "./MarkdownRenderer.i18n";
import { useAppSelector } from "../hooks";

const MarkdownComponents: object = {
  code({
    node,
    inline,
    className,
    ...props
  }: {
    node: { data: { meta: string } };
    inline: boolean;
    className: string;
  } & Record<string, unknown>): ReactNode {
    const [isCodeCopied, setIsCodeCopied] = useState(false);


    const { t } = useTranslation(MarkdownRendererI18n);

    const match = /language-(\w+)/.exec(className || "");

    const children =
      typeof props.children === "string" || Array.isArray(props.children)
        ? props.children
        : "";

    const handleCopyCode = code => {
      navigator.clipboard.writeText(code);
      showSuccessNotification(t("notifications.copy-code"));
      setIsCodeCopied(true);

      setTimeout(() => {
        setIsCodeCopied(false);
      }, 2500);
    };

    return match ? (
      <div className="bg-black overflow-hidden rounded-3">
        <div className="d-flex align-items-center justify-content-between px-3 my-2 text-white">
          <span className="mt-1">{match[1]}</span>
          <button
            className="btn btn-sm btn-outline-secondary align-content-center font-size-14 text-white-50"
            onClick={() => handleCopyCode(children)}
            disabled={isCodeCopied}
          >
            {isCodeCopied ? (
              <>
                <span className="d-flex align-items-center gap-1">
                  {t("button.copied")} <i className="bx bx-check" />
                </span>
              </>
            ) : (
              <>
                <span className="d-flex align-items-center gap-1">
                  {t("button.copy-code")} <i className="bx bx-copy" />
                </span>
              </>
            )}
          </button>
        </div>
        <SyntaxHighlighter
          style={coldarkCold}
          customStyle={{
            padding: "1rem",
            margin: 0,
            maxWidth: "100%",
            overflowX: "auto",
          }}
          language={match[1]}
          PreTag="div"
          showLineNumbers={false}
          wrapLongLines={true}
          wrapLines
          {...props}
        >
          {children}
        </SyntaxHighlighter>
      </div>
    ) : (
      <code className={className} {...props} />
    );
  },
};

type Props = {
  content: string;
  className?: string;
};

const MarkdownRenderer: React.FC<Props> = ({ content, className }) => {
  return (
    <ReactMarkdown
      className={className}
      remarkPlugins={[remarkGfm]}
      components={MarkdownComponents}
    >
      {content}
    </ReactMarkdown>
  );
};

export default memo(MarkdownRenderer);
