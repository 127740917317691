import { useEffect, useState } from "react";

// hooks
import { useRedux } from "../../../hooks/index";

// actions
import {
  changeSelectedChat,
  getChannelJobStatus,
  getChatUserConversations,
  toggleOpenLeftBar,
} from "../../../redux/actions";

// interfaces

// components
import AppSimpleBar from "../../../components/AppSimpleBar";
import { NewDocumentModal } from "./components/NewDocumentModal";
import Loader from "../../../components/Loader";
import Documents from "./components/Documents";
import { ButtonHelpLink } from "../../../components/ButtonHelpLink";
import { SelectedChatDTO } from "../../../@DTO/selectedChat";
import { showOnceADayAlert } from "../../../helpers/notifications";

import { DocumentsI18n } from "./Documents.i18n";
import useTranslation from "../../../helpers/useTranslation";
import { useQuery } from "react-query";
import { ChannelDTO } from "../../../@DTO/channel";
import { getChannelsAndDocuments } from "../../../api";
import { QUERY_KEYS } from "../../../libs/react-query";
import MenuSideChats from "../../../components/MenuSideChats";
import { TABS } from "../../../constants";
import { useChannels } from "../../../hooks/useChannels";

const Index = () => {
  const [showModal, setShowModal] = useState(false);
  const [intervalId, setIntervalId] = useState<any>(null);

  const { dispatch, useAppSelector } = useRedux();
  const { selectedChat, activeTab, channelJobs, isOpenLeftBar } =
    useAppSelector(state => ({
      isChannelCreated: state.Chats.isChannelCreated,
      selectedChat: state.Chats.selectedChat as SelectedChatDTO,
      activeTab: state.Layout.activeTab,
      channelJobs: state.Chats.channelJobs,
      isOpenLeftBar: state.Layout.isOpenLeftBar,
    }));

  const { data: docsAndYoutube, isLoading: isLoadingDocsAndYoutube } = useQuery<
    ChannelDTO[]
  >(
    QUERY_KEYS.DOCUMENTS,
    async () => {
      const data = await getChannelsAndDocuments();
      return data.documents ?? [];
    },
    {
      enabled: activeTab === TABS.DOCUMENTS,
      staleTime: 180 * 60 * 1000, // 3 hours,
    }
  );

  const { t, loaded } = useTranslation(DocumentsI18n);

  const { loadingState, handleChannelClear } = useChannels();


  const hasDocumentsProcessing =
    channelJobs?.filter(item => item?.processed === false)?.length > 0;

  const documents = docsAndYoutube?.filter(
    (document: any) => document?.isDocument && !document.isYoutube
  );

  function toggleModal() {
    setShowModal(!showModal);
  }

  function onSelectChat(channel: ChannelDTO) {
    dispatch(changeSelectedChat(channel));
    dispatch(getChannelJobStatus(channel._id));
  }

  function fetchChannelJob() {
    if (selectedChat && selectedChat.isDocument === true && selectedChat._id) {
      dispatch(getChannelJobStatus(selectedChat._id));
    }
  }

  useEffect(() => {
    if (
      activeTab === "pills-documents" &&
      documents?.length === 0 &&
      !isLoadingDocsAndYoutube
    ) {
      toggleModal();
    }
  }, [docsAndYoutube, activeTab]);

  setTimeout(() => {
    if (activeTab === "pills-documents" && loaded) {
      showOnceADayAlert(t("notifications.showOnceADayAlert"), {
        duration: 4000,
      });
    }
  }, 1000);

  useEffect(() => {
    if (!hasDocumentsProcessing) {
      return clearInterval(intervalId);
    }

    const _intervalId = setInterval(fetchChannelJob, 10000);
    setIntervalId(_intervalId);

    return () => clearInterval(intervalId);
  }, [hasDocumentsProcessing]);

  function handleToggleLeftBar() {
    dispatch(toggleOpenLeftBar(!isOpenLeftBar));
  }

  return (
    <>
      {showModal && (
        <NewDocumentModal isOpen={showModal} onClose={toggleModal} />
      )}
      <div>
        <>
          {isLoadingDocsAndYoutube && <Loader />}
          {isOpenLeftBar ? (
            <AppSimpleBar className="chat-room-list">
              <div className="px-3 pt-3">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <div className="m-0 p-3  d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">{t("page.label")}</h4>
                      <ButtonHelpLink target="pdf" />
                      <button
                        className="btn fs-3 p-0 d-lg-flex d-none"
                        style={{ marginLeft: 12 }}
                        onClick={handleToggleLeftBar}
                      >
                        <i className="bx bx-arrow-to-left" />
                      </button>
                    </div>
                    <p className="mt-2 p-3 ">{t("page.description")}</p>
                  </div>
                </div>
              </div>
              {!isLoadingDocsAndYoutube && documents && (
                <>
                  <Documents
                    documents={documents}
                    openAddContact={toggleModal}
                    selectedChat={selectedChat}
                    onSelectChat={onSelectChat}
                  />
                </>
              )}
            </AppSimpleBar>
          ) : (
            <MenuSideChats
              onClick={handleToggleLeftBar}
              activeButtons={true}
              handleCreateChannel={toggleModal}
              loadingState={loadingState.clearChannel}
              handleChannelClear={handleChannelClear}
              documents={documents}
              collapsed={true}
            />
          )}
        </>
      </div>
    </>
  );
};

export default Index;
