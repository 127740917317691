export const LoginI18n = {
  "pt-BR": {
    auth: {
      supabaseModal: {
        labelEmail: "Email",
        placeholderEmail: "Email",
        labelPassword: "Senha",
        placeholderPassword: "Senha",
        authButton: "Entrar",
        loadingText: "Carregando",
        linkText: "Já tem uma conta? Entrar",
        signInMetamask: "Entrar com Metamask",
        signInGoogle: "Entrar com Google"
      },
    },
    signUp: {
      supabaseModal: {
        labelEmail: "Email",
        placeholderEmail: "Digite seu email",
        labelPassword: "Senha",
        placeholderPassword: "Digite sua senha",
        signUpButton: "Cadastrar-se",
        loadingText: "Cadastrando...",
        linkText: "Não tem uma conta? Cadastre-se",
      },
    },
    forgot: {
      supabaseModal: {
        labelEmail: "Email",
        placeholderEmail: "Digite seu email",
        buttonResetPassword : "Enviar o link para redefinição de senha",
        loadingText: "Enviando instruções de redefinição...",
        linkText: "Esqueceu sua senha?",
      },
    },
    magicLink: {
      supabaseModal: {
        labelEmail: "Email",
        placeholderEmail: "Digite seu email",
        magicLinkButton: "Enviar Link Mágico",
        loadingText: "Enviando Link Mágico...",
        linkText: "Enviar um email com link mágico",
      },
    },
  },
  "en-US": {
    auth: {
      supabaseModal: {
        labelEmail: "Email",
        placeholderEmail: "Email",
        labelPassword: "Password",
        placeholderPassword: "Password",
        authButton: "Sign in",
        loadingText: "Loading",
        linkText: "Already have an account? Sign in",
        signInMetamask: "Sign in with Metamask",
        signInGoogle: "Sign in with Google",
      },
    },
    signUp: {
      supabaseModal: {
        labelEmail: "Email address",
        placeholderEmail: "Your email address",
        labelPassword: "Create a Password",
        placeholderPassword: "Your password",
        signUpButton: "Sign up",
        loadingText: "Signing up ...",
        linkText: "Don't have an account? Sign up",
      },
    },
    forgot: {
      supabaseModal: {
        labelEmail: "Email address",
        placeholderEmail: "Your email address",
        buttonResetPassword : "Send the password reset link",
        loadingText: "Sending reset instructions ...",
        linkText: "Forgot your password?",
      },
    },
    magicLink: {
      supabaseModal: {
        labelEmail: "Email address",
        placeholderEmail: "Your email address",
        magicLinkButton: "Send Magic Link",
        loadingText: "Sending Magic Link ...",
        linkText: "Send a magic link email",
      },
    },
  },
  "es-ES": {
    auth: {
      supabaseModal: {
        labelEmail: "Correo electrónico",
        placeholderEmail: "Correo electrónico",
        labelPassword: "Contraseña",
        placeholderPassword: "Contraseña",
        authButton: "Iniciar sesión",
        signUp: "Registrarse",
        loadingText: "Cargando",
        linkText: "¿Ya tienes una cuenta? Iniciar sesión",
        signInMetamask: "Iniciar sesión con Metamask",
        signInGoogle: "Iniciar sesión con Google"
      },
    },
    signUp: {
      supabaseModal: {
        labelEmail: "Correo electrónico",
        placeholderEmail: "Introduce tu correo electrónico",
        labelPassword: "Contraseña",
        placeholderPassword: "Introduce tu contraseña",
        signUpButton: "Registrarse",
        loadingText: "Registrando...",
        linkText: "¿No tienes una cuenta? Regístrate",
      },
    },
    forgot: {
      supabaseModal: {
        labelEmail: "Correo electrónico",
        placeholderEmail: "Introduce tu correo electrónico",
        buttonResetPassword : "Enviar el enlace para restablecer la contraseña",
        loadingText: "Enviando instrucciones de restablecimiento...",
        linkText: "¿Olvidaste tu contraseña?",
      },
    },
    magicLink: {
      supabaseModal: {
        labelEmail: "Correo electrónico",
        placeholderEmail: "Introduce tu correo electrónico",
        magicLinkButton: "Enviar Enlace Mágico",
        loadingText: "Enviando Enlace Mágico...",
        linkText: "Enviar un correo con enlace mágico",
      },
    },
  },
  "fil-PH": {
    auth: {
      supabaseModal: {
        labelEmail: "Email",
        placeholderEmail: "Ilagay ang iyong email",
        labelPassword: "Password",
        placeholderPassword: "Ilagay ang iyong password",
        authButton: "Mag-login",
        signUp: "Mag-sign up",
        loadingText: "Naglo-load",
        linkText: "Mayroon ka nang account? Mag-login",
        signInMetamask: "Mag-sign in gamit ang Metamask",
        signInGoogle: "Mag-sign in gamit ang Google",
      },
    },
    signUp: {
      supabaseModal: {
        labelEmail: "Email",
        placeholderEmail: "Ilagay ang iyong email",
        labelPassword: "Password",
        placeholderPassword: "Ilagay ang iyong password",
        signUpButton: "Mag-sign up",
        loadingText: "Nag-sign up...",
        linkText: "Wala ka pang account? Mag-sign up",
      },
    },
    forgot: {
      supabaseModal: {
        labelEmail: "Email",
        placeholderEmail: "Ilagay ang iyong email",
        buttonResetPassword : "Ipadala ang link para sa pag-reset ng password",
        loadingText: "Nagpapadala ng mga instruksyon sa pag-reset...",
        linkText: "Nakalimutan ang iyong password?",
      },
    },
    magicLink: {
      supabaseModal: {
        labelEmail: "Email",
        placeholderEmail: "Ilagay ang iyong email",
        magicLinkButton: "Ipadala ang Magic Link",
        loadingText: "Nagpapadala ng Magic Link...",
        linkText: "Ipadala ang isang magic link sa email",
      },
    },
  },
};
