import React from "react";
import HeaderHome from "../../components/HeaderHome";
import FooterHome from "../../components/FooterHome";
import { DropdownLanguageMenu } from "../../components/DropdownLanguageMenu";

const BrandAssets = () => {
  return (
    <section
      style={{ background: "#ffffff" }}
      className="d-flex flex-column align-items-center brand-assets"
    >
      <div
        style={{
          width: "75%",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <section className="w-100 d-flex justify-content-center flex-column align-items-center">
          <HeaderHome 
          dropdownLanguage={<DropdownLanguageMenu state={() => {}} />} 
          dropdownLanguageMobile={<DropdownLanguageMenu state={() => {}} />}
          />
          
          <div
            className="w-100 d-flex flex-column align-items-center banner-black"
            style={{ padding: "100px 0" }}
          >
            <div style={{ zIndex: 10 }}>
              <h1 className="font-home">Brand Assets</h1>
              <p style={{ color: "#ffffff" }} className="font-home">
                Our brand and assets reflect our community, ecosystem and values
              </p>
            </div>
          </div>
          <div className="d-flex flex-column w-100 mt-5 align-items-center">
            <h2 className=" size-title-landing-page">
              Aizzy Logo + Icon
            </h2>
            <p>
              Download our official Logos and Icons for use on light and dark
              backgrounds
            </p>

            <div className="brand-assets-logo-icon">
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <img
                  className="py-5 w-100 h-100 "
                  src="https://uploads-ssl.webflow.com/6622d27c51559995f088e48c/6622eac0206bdd052f1eba9b_AIZZY%20Logo_LB_L.png"
                  loading="lazy"
                  data-wf-id='["06374266-fe45-e99a-b0c7-0f99ac71119f"]'
                  data-w-id="06374266-fe45-e99a-b0c7-0f99ac71119f"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <img
                  className="py-5 w-100 h-100"
                  src="https://uploads-ssl.webflow.com/6622d27c51559995f088e48c/6622eac03d0284f204704328_AIZZY%20Logo_DB_L.png"
                  loading="lazy"
                  data-wf-id='["06374266-fe45-e99a-b0c7-0f99ac7111a2"]'
                  data-w-id="06374266-fe45-e99a-b0c7-0f99ac7111a2"
                  alt=""
                  style={{
                    background: "#1c1e23",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <img
                  className="py-5 w-100 h-100"
                  src="https://uploads-ssl.webflow.com/6622d27c51559995f088e48c/662bd5ae0125c04ea9f08b99_AIZZY.ai%20Logo_LB_L%20(1).png"
                  loading="lazy"
                  data-wf-id='["06374266-fe45-e99a-b0c7-0f99ac7111a5"]'
                  data-w-id="06374266-fe45-e99a-b0c7-0f99ac7111a5"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div
                style={{ background: "#1c1e23" }}
                className="w-100 h-100 d-flex justify-content-center align-items-center"
              >
                <img
                  className="py-5 w-100 h-100"
                  src="https://uploads-ssl.webflow.com/6622d27c51559995f088e48c/662bd5b5ee2740cab1721dcf_AIZZY.ai%20Logo_DB_L%20(1).png"
                  loading="lazy"
                  data-wf-id='["f6f830dc-6505-a9c3-23ba-a8b4bdf4cca8"]'
                  data-w-id="f6f830dc-6505-a9c3-23ba-a8b4bdf4cca8"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div>

            <div className="brand-assets-logo-icon">
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <img
                  className="py-5 h-100 "
                  src="https://uploads-ssl.webflow.com/6622d27c51559995f088e48c/6629251b5d7adae8f8f70e4f_Token%20Logo_V1_DB_256%20(3).png"
                  loading="lazy"
                  data-wf-id='["06374266-fe45-e99a-b0c7-0f99ac71119f"]'
                  data-w-id="06374266-fe45-e99a-b0c7-0f99ac71119f"
                  alt=""
                  style={{ objectFit: "contain", width: "60%" }}
                />
              </div>
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <img
                  className="py-5 h-100"
                  src="https://uploads-ssl.webflow.com/6622d27c51559995f088e48c/6629a0995d7a4714e881ff3b_Token%20Logo_V1_DB_256%20(4).png"
                  loading="lazy"
                  data-wf-id='["06374266-fe45-e99a-b0c7-0f99ac7111a2"]'
                  data-w-id="06374266-fe45-e99a-b0c7-0f99ac7111a2"
                  alt=""
                  style={{ objectFit: "contain", width: "60%" }}
                />
              </div>
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <img
                  className="py-5 h-100"
                  src="https://uploads-ssl.webflow.com/6622d27c51559995f088e48c/6622de1389c72f5c4a5e57be_AIZ%20Icon_Sqr%20(2).png"
                  loading="lazy"
                  data-wf-id='["06374266-fe45-e99a-b0c7-0f99ac7111a5"]'
                  data-w-id="06374266-fe45-e99a-b0c7-0f99ac7111a5"
                  alt=""
                  style={{ objectFit: "contain", width: "60%" }}
                />
              </div>
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <img
                  className="py-5 h-100"
                  src="https://uploads-ssl.webflow.com/6622d27c51559995f088e48c/6622e89001ff48a1776317d5_AIZ%20Icon_Sqr%20(3).png"
                  loading="lazy"
                  data-wf-id='["f6f830dc-6505-a9c3-23ba-a8b4bdf4cca8"]'
                  data-w-id="f6f830dc-6505-a9c3-23ba-a8b4bdf4cca8"
                  alt=""
                  style={{ objectFit: "contain", width: "60%" }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-column w-100 my-5 align-items-center">
            <h2 className="size-title-landing-page">Aizzy Brand Colors </h2>
            <p className="size-paragrapy-landing-page">
              Reference the Aizzy official Brand colors for use in Aizzy brand
              material and content.
            </p>
            <div className=" w-100">
              <div  className="grid-brand-colors">
                <div style={{ background: '#18807A', color: "#ffffff" }}>
                  <h3 style={{ color:'#ffffff' }}>Primary Dark</h3>
                  <p>#18807A</p>
                </div>
                <div style={{ background: '#3BCDBC' }}>
                  <h3>Primary</h3>
                  <p>#3BCDBC</p>
                </div>
                <div style={{ background: '#8CE6D5' }}>
                  <h3>Primary Light</h3>
                  <p>#8CE6D5</p>
                </div>
                <div style={{ background: '#2E466F', color: "#ffffff" }}>
                  <h3 style={{ color:'#ffffff' }}>Dark Shade</h3>
                  <p>#2E466F</p>
                </div>
                <div style={{ background: '#7AA6B4' }}>
                  <h3>Light Accent</h3>
                  <p>#7AA6B4</p>
                </div>
                <div style={{ background: '#F4F4F2' }}>
                  <h3>Light Shade</h3>
                  <p>#F4F4F2</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterHome />
      </div>
    </section>
  );
};

export default BrandAssets;
