import { useLocation, useNavigate } from "react-router-dom";
import AppSimpleBar from "../../components/AppSimpleBar";
import useTranslation from "../../helpers/useTranslation";
import { CreateAssistantI18n } from "./components/CreateAssistant.i18n";
import {
  AssistantToEditData,
  CreateAssistantForm,
} from "./components/CreateAssistantForm";
import { ButtonHelpLink } from "../../components/ButtonHelpLink";
import Loader from "../../components/Loader";

import "animate.css";
import { getCurrentUserPlan } from "../../helpers/userHelper";
import { PLANS_AIZZY } from "../../helpers/constants/Plans";
import { useQuery } from "react-query";
import { QUERY_KEYS } from "../../libs/react-query";
import axios from "axios";
import { getCommunityAssistant } from "../../api/assistants";

export function CreateAssistantPage() {
  const navigate = useNavigate();
  const { t } = useTranslation(CreateAssistantI18n);
  const location = useLocation();
  const assistantId = location?.state?.assistantId;

  const isPremiumUser = getCurrentUserPlan() === PLANS_AIZZY.PREMIUM;
  const isMobile = window.matchMedia("(max-width: 700px)").matches;

  const { isLoading, data: assistant } = useQuery<AssistantToEditData>(
    [QUERY_KEYS.EDIT_ASSISTANT, assistantId],
    async () => {
      return await getCommunityAssistant(assistantId);
    },
    {
      enabled: !!assistantId,
      keepPreviousData: true,
    }
  );
  async function deleteAssistant() {
    try {
      const response = await axios.delete(
        `https://aizzy-api-dev.herokuapp.com/api/assistant/${assistantId}`
      );
      window.location.reload();

      if (response.status === 200) {
        console.log("Assistant deleted successfully!", response.data);
      } else {
        console.error(
          "Error deleting assistant:",
          response.status,
          response.data
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <AppSimpleBar className="p-3 pe-lg-4 vh-100 chat-leftsidebar full overflow-auto">
      <div className="d-flex align-items-start">
        <div className="flex-grow-1">
          {assistant ? (
            <h4 className="p-3 ">{t("page.edit-assistant")}</h4>
          ) : (
            <>
              <div className="m-0 p-3  d-flex align-items-center justify-content-between">
                <h4 className="m-0 ">{t("page.label")}</h4>
                <div className="d-flex">
                  <ButtonHelpLink target="assistant" />
                  <i
                    className="bx bx-arrow-back p-1 fs-4 border border-1 border-secondary border-opacity-50"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  />
                </div>
              </div>
              <p className="mt-3 p-3 ">{t("page.description")}</p>
            </>
          )}
        </div>
      </div>
      <div className={`mx-auto mt-3 w-${isMobile ? "100" : "75"}`}>
        {isLoading ? (
          <Loader />
        ) : (
          <CreateAssistantForm assistantToEdit={assistant!} />
        )}
        {/* <button onClick={deleteAssistant}>Delete</button> */}
      </div>
    </AppSimpleBar>
  );
}
