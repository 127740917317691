import { Toast, ToastHeader, ToastBody } from "reactstrap";
import useTranslation from "../helpers/useTranslation";
import { ToastPremiumMessageI18n } from "./ToastPremiumMessage.i18n";

export function ToastPremiumMessage() {
  const { t } = useTranslation(ToastPremiumMessageI18n)

  return (
    <div className="my-3 rounded text-center">
      <Toast className="w-100 w bg-white">
        <ToastHeader className="bg-warning d-flex justify-content-center">
          <strong className="fs-4 text-black">{t("toastPremium.title")}</strong>
        </ToastHeader>
        <ToastBody className="bg-transparent">
          <p className="font-size-18 text-black">
            {t("toastPremium.message")}
          </p>
        </ToastBody>
      </Toast>
    </div>
  )
}