export const socialList = [
  // {
  //   name: "AizzyNews",
  //   iconClassname: "bxl-whatsapp",
  //   link: "https://chat.whatsapp.com/I9sLgDt4scSJye0VZeK0fy",
  //   languages: {
  //     isPtBR: true,
  //     isEnUS: true,
  //     isEsES: true,
  //   },
  // },
  // {
  //   name: "@AizzyAI",
  //   iconClassname: "bxl-instagram",
  //   link: "https://www.instagram.com/aizzy.ai",
  //   languages: {
  //     isPtBR: true,
  //     isEnUS: false,
  //     isEsES: false,
  //   },
  // },

  // {
  //   name: "Aizzy AI",
  //   iconClassname: "bxl-youtube",
  //   link: "https://www.youtube.com/@AizzyAi",
  //   languages: {
  //     isPtBR: true,
  //     isEnUS: true,
  //     isEsES: true,
  //   },
  // },
  {
    name: "@AizzyAI",
    iconClassname: "bxl-twitter",
    link: "https://twitter.com/aizzyai",
    languages: {
      isPtBR: false,
      isEnUS: true,
      isEsES: false,
    },
  },
  {
    name: "Aizzy",
    iconClassname: "bxl-discord",
    link: "https://discord.com/invite/YCwY4GtdWv",
    languages: {
      isPtBR: true,
      isEnUS: true,
      isEsES: true,
    },
  },
  // {
  //   name: "AizzyNews",
  //   iconClassname: "bxl-telegram",
  //   link: "https://t.me/aizzyai_news",
  //   languages: {
  //     isPtBR: false,
  //     isEnUS: true,
  //     isEsES: false,
  //   },
  // },
  // {
  //   name: "AizzyAI",
  //   iconClassname: "bxl-telegram",
  //   link: "https://t.me/aizzyai",
  //   languages: {
  //     isPtBR: false,
  //     isEnUS: true,
  //     isEsES: false,
  //   },
  // },
];
