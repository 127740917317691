export const RemainingTableI18n = {
  "en-US": {
    service: "Service",
    remaining: "Remaining",
    limit: "Limit",
    pdfSize: "PDF Size",
    youtubeDuration: "Youtube Video Duration",
    channelMembers: "Channel Members",
    unlimited: "Unlimited",
    aiMemory: "AI Memory",
  },
  "pt-BR": {
    service: "Serviço",
    remaining: "Restante",
    limit: "Limite",
    pdfSize: "Tamanho do PDF",
    youtubeDuration: "Duração do Vídeo do Youtube",
    channelMembers: "Membros por Canal",
    unlimited: "Ilimitado",
    aiMemory: "Memória da IA",
  },
  "es-ES": {
    service: "Servicio",
    remaining: "Restante",
    limit: "Límite",
    pdfSize: "Tamaño del PDF",
    youtubeDuration: "Duración del Video de Youtube",
    channelMembers: "Miembros por Canal",
    unlimited: "Ilimitado",
    aiMemory: "Memoria del AI",
  },
  "fil-PH": {
    service: "Serbisyo",
    remaining: "Natitirang",
    limit: "Limitasyon",
    pdfSize: "Laki ng PDF",
    youtubeDuration: "Haba ng YouTube Video",
    channelMembers: "Mga Miyembro ng Channel",
    unlimited: "Walang limitasyon",
    aiMemory: "Memorya ng AI",
  },
};
