import React from "react";
import SectionFeatureItem from "./CardFeatureItem";
import { Link } from "react-router-dom";
import { SectionBuildI18n } from "./SectionBuild.i18n";
import useTranslation from "../helpers/useTranslation";

const ListCardBuild = () => {
  const { t } = useTranslation(SectionBuildI18n);

  return (
    <section className="section-build-list">
      <div className="build-list-itens mb-3">
        <SectionFeatureItem
          img={false}
          h3={t("subTitleCard1")}
          paragraph={t("paragraphCard1")}
        />
        <SectionFeatureItem
          img={false}
          h3={t("subTitleCard2")}
          paragraph={t("paragraphCard2")}
        />
        <SectionFeatureItem
          img={false}
          h3={t("subTitleCard3")}
          paragraph={t("paragraphCard3")}
        />
      </div>
      <nav className="mt-2">
        <Link
          className="btn-lp-dark color-white-buttons"
          to={"/signin"}
          target="_blank"
        >
          {t("button")}
        </Link>
      </nav>
    </section>
  );
};

export default ListCardBuild;
