import { useEffect, useRef } from "react";
import useTranslation from "../../../../helpers/useTranslation";
import { ChatInputSectionI18n } from "./ChatInputSection.i18n";
import { isMobile } from "../../../../utils/helpers";
import { useChatStore } from "../../../../state/chat";

interface InputSectionProps {
  value: null | string;
  onChange: (value: string) => void;
  assistantDisabled?: boolean;
  onSubmit: () => void;
  selectedChat: any;
  selectedChatOption?: string;
}

const InputSection = ({
  value,
  onChange,
  assistantDisabled,
  onSubmit,
  selectedChat,
  selectedChatOption,
}: InputSectionProps) => {
  const { isReceivingMessage } = useChatStore();

  const { t } = useTranslation(ChatInputSectionI18n);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  function setPlaceholderInput() {
    if (assistantDisabled) {
      return t("placeholder.assistantDisabled");
    }
    if (selectedChatOption === "IMAGE") {
      return t("placeholder.image");
    }
    if (!selectedChat.isDocument) {
      return t("placeholder.main");
    } else {
      return t("placeholder.docs");
    }
  }

  useEffect(() => {
    if (!isReceivingMessage && !isMobile) {
      textAreaRef.current?.focus();
    }
  }, [isReceivingMessage]);

  useEffect(() => {
    const textArea = textAreaRef.current;
    const adjustHeight = () => {
      if (textArea) {
        textArea.style.height = "auto"; // Reset the height
        textArea.style.height = Math.min(textArea.scrollHeight, 600) + "px"; // Set to scrollHeight or 600px, whichever is smaller
      }
    };

    // Adjust height on component mount and input events
    adjustHeight();
    textArea?.addEventListener("input", adjustHeight);

    // Cleanup event listener
    return () => {
      textArea?.removeEventListener("input", adjustHeight);
    };
  }, [value]);

  return (
    <div className="position-relative w-100">
      <textarea
        ref={textAreaRef}
        className="form-control form-control-lg overflow-auto border-0"
        style={{
          height: 42,
          maxWidth: "100%",
        }}
        id="chat-input"
        placeholder={setPlaceholderInput()}
        value={value || ""}
        onChange={(e: any) => {
          onChange(e.target.value);
        }}
        autoComplete="off"
        autoFocus
        rows={1}
        disabled={assistantDisabled || isReceivingMessage}
        onKeyDown={(evt: any) => {
          if (evt.keyCode === 13 && !evt.shiftKey && value?.trim()) {
            evt.preventDefault();
            onSubmit();
          }
        }}
      />
    </div>
  );
};
export default InputSection;
