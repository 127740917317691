
const initialState = {
  modalOpen: false,
  stateTransaction: "",
  setLoadingTransaction: false,
  sendTokensFunction: null,
};

const thirdwebReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_MODAL_FEEDBACK':
      return {
        ...state,
        modalOpen: action.payload,
      };
    case 'SET_STATE_TRANSACTION':
      return {
        ...state,
        stateTransaction: action.payload,
      };
    case 'SET_LOADING_TRANSACTION':
      return {
        ...state,
        setLoadingTransaction: action.payload,
      };
    case 'SET_SEND_TOKENS_FUNCTION':
      return {
        ...state,
        sendTokensFunction: action.payload,
      };
    default:
      return {...state};
  }
};

export default thirdwebReducer;