import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import { Button } from "reactstrap";
import { useUser } from "../utils/useUser";

import { ProductWithPrice } from "../../types";
import useTranslation from "../helpers/useTranslation";
import { PrincingI18n } from "./Pricing.i18n";
import Loader from "./Loader";
import { getToolsPlans } from "../api/tools";
import RemainingTable from "../pages/Profile/components/RemainingTable";
import { ReactComponent as Logo } from "../assets/images/logo-aizzy-dark.svg";
import { PremiumPlan } from "./PlanPremium";
import { ToastPremiumMessage } from "./ToastPremiumMessage";
import { getCurrentUserPlan } from "../helpers/userHelper";
import { PLANS_AIZZY } from "../helpers/constants/Plans";

interface Props {
  products: ProductWithPrice[];
  hideLogo?: boolean;
  hideTitle?: boolean;
  description: string;
}

export default function Pricing({
  products,
  hideLogo = false,
  hideTitle = false,
  description,
}: Props) {
  const { user, isLoading, subscription } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation(PrincingI18n);
  const [toolPlans, setToolPlans] = useState<any>();

  const isPremiumUser = getCurrentUserPlan() === PLANS_AIZZY.PREMIUM;

  const manageSubscription = () => {
    if (!user) {
      navigate("/signin");
    }
    if (subscription) {
      navigate("/profile");
    }
  };

  useEffect(() => {
    getToolsPlans().then(data => setToolPlans(data));
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className="container pb-5 pb-sm-3">
      <div className="row gap-3">
        <div className="col-12 text-center">
          <ToastPremiumMessage />
          <div className="animate__animated animate__fadeInDown animate__fast">
            <div className="p-2 py-3 my-5 bg-primary">
              {!hideTitle && (
                <h1 className="text-light fw-bold">{t("page.label")}</h1>
              )}
              <p className="text-light font-size-18 fw-semibold">
                {description ?? t("page.description")}
              </p>
            </div>
            {products.length > 0 && !isPremiumUser && (
              <PremiumPlan products={products} />
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="row row-cols-2 plans-column">
            <div className="col-12 col-lg" key={"Community"}>
              <div className={cn("card")}>
                <div className="card-body shadow">
                  <h3 className="text-2xl leading-6 font-semibold">
                    Community
                  </h3>
                  <p className="text-zinc-300">
                    {t("card-community.description")}
                  </p>
                  {toolPlans &&
                    toolPlans
                      ?.filter((plan: any) => plan?.name === "Community")
                      .map((plan: any) => (
                        <RemainingTable
                          key={plan}
                          plan={plan}
                          hideRemainingCollumn
                        />
                      ))}
                </div>
              </div>
            </div>
            {products.map(product => {
              return (
                <div className="col-12 col-lg" key={product.id}>
                  <div
                    className={cn("card", {
                      "border border-pink-500": subscription
                        ? product.name === subscription?.prices?.products?.name
                        : product.name === "Freelancer",
                    })}
                  >
                    <div className="card-body shadow">
                      <h3 className="text-2xl leading-6 font-semibold">
                        {product.name}
                      </h3>
                      <p className="text-zinc-300">
                        {t("card-premium.description")}
                      </p>
                      {toolPlans &&
                        toolPlans
                          ?.filter(
                            (plan: any) =>
                              plan?.name ===
                              product?.name?.replace(" - TEST", "")
                          )
                          .map((plan: any) => (
                            <RemainingTable key={plan} plan={plan} />
                          ))}
                      {isPremiumUser && (
                        <div className="d-flex gap-2">
                          <Button
                            disabled={isLoading}
                            onClick={() => manageSubscription()}
                            className="w-100 btn-primary"
                          >
                            {t("card-premium.button-manage")}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-12 text-center">
            <em>
              *'{t("page.aiMemory")}' {t("page.aiMemoryNote")}
            </em>
          </div>
        </div>
      </div>
    </div>
  );
}
