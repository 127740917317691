export const AttachDocumentMenuI18n = {
  "pt-BR": {
    chat: {
      label:
        "Para interagir com um site ou vídeo do YouTube, basta copiar o URL e deixar nossa função de chat extrair o conteúdo.",
    },
    button: {
      label: "Anexar",
    },
    placeholder: {
      main: "Coloque sua URL aqui",
    },
  },
  "en-US": {
    chat: {
      label:
        "To chat with a website or YouTube video, simply paste its URL and let our chat feature extract the content.",
    },
    button: {
      label: "Attach",
    },
    placeholder: {
      main: "Paste your URL here",
    },
  },
  "es-ES": {
    chat: {
      label:
        "Para interactuar con un sitio web o un video de YouTube, simplemente pegue la URL y permita que nuestra función de chat extraiga el contenido.",
    },
    button: {
      label: "Adjuntar",
    },
    placeholder: {
      main: "Pegue su URL aquí",
    },
  },
  "fil-PH": {
    chat: {
      label:
        "Para makipag-usap sa isang website o video sa YouTube, i-paste lamang ang URL at hayaang ang aming chat feature ay ekstrahin ang nilalaman.",
    },
    button: {
      label: "I-attach",
    },
    placeholder: {
      main: "I-paste ang iyong URL dito",
    },
  },
};
