// ConversationUserI18n
export const ConversationUserI18n = {
  "pt-BR": {
    notifications: {
      invalidUrl: "Essa URL não é válida, por favor insira uma válida",
      documentProcessing:
        "Já existe um documento em processo, por favor aguarde sua finalização. Pode levar até uma hora dependendo do tamanho do documento.",
      sendingContent: "Estamos enviando o seu conteúdo, por favor aguarde.",
    },
  },
  "en-US": {
    notifications: {
      invalidUrl: "This URL is invalid, please insert a valid one",
      documentProcessing:
        "There is already a document in process, please wait for its completion. It may take up to an hour depending on the size of the document.",
      sendingContent: "We are sending your content, please wait.",
    },
  },
  "es-ES": {
    notifications: {
      invalidUrl: "Esta URL no es válida, por favor inserte una válida",
      documentProcessing:
        "Ya existe un documento en proceso, por favor espere a su finalización. Puede tardar hasta una hora dependiendo del tamaño del documento.",
      sendingContent: "Estamos enviando su contenido, por favor espere.",
    },
  },
  "fil-PH": {
    notifications: {
      documentProcessing:
        "Mayroon nang isang dokumento sa proseso, mangyaring maghintay para sa kanyang pagtatapos. Maaaring umabot ng hanggang isang oras depende sa laki ng dokumento.",
      sendingContent:
        "Iniipadala namin ang iyong nilalaman, mangyaring maghintay.",
    },
  },
};

export const DeviceInfoI18n = {
  "pt-BR": {
    otherDeviceMessage: {
      title: "Esse chat foi criado em outro dispositivo ou navegador",
      message:
        "Para continuar a conversa, acesse o chat no dispositivo ou navegador que foi criado, ou comece uma nova conversa.",
    },
  },
  "en-US": {
    otherDeviceMessage: {
      title: "This chat was created on another device or browser",
      message:
        "To continue the conversation, access the chat on the device or browser where it was created, or start a new conversation.",
    },
  },
  "es-ES": {
    otherDeviceMessage: {
      title: "Este chat se creó en otro dispositivo o navegador",
      message:
        "Para continuar la conversación, acceda al chat en el dispositivo o navegador donde se creó, o comience una nueva conversación.",
    },
  },
};

// MessagesdI18n
export const MessagesdI18n = {
  "pt-BR": {
    chat: {
      user: "Você",
    },
    notifications: {
      successCopyClipboard: "Copiado para a área de transferência!",
      successDeleteMessage: "Mensagem deletada com sucesso!",
    },
    confirm: "Deseja deletar essa mensagem do chat?",
  },
  "en-US": {
    chat: {
      user: "You",
    },
    notifications: {
      successCopyClipboard: "Copied to clipboard!",
      successDeleteMessage: "Message deleted successfully!",
    },
    confirm: "Would you like to delete this message from the chat?",
  },
  "es-ES": {
    chat: {
      user: "Tú",
    },
    notifications: {
      successCopyClipboard: "Copiado al portapapeles!",
      successDeleteMessage: "¡Mensaje eliminado con éxito!",
    },
    confirm: "¿Desea eliminar este mensaje del chat?",
  },
  "fil-PH": {
    chat: {
      user: "Ikaw",
    },
    notifications: {
      successCopyClipboard: "Nakopya sa clipboard!",
      successDeleteMessage: "Matagumpay na natanggal ang mensahe!",
    },
    confirm: "Gusto mo bang tanggalin ang mensaheng ito mula sa chat?",
  },
};

// UserHeaderI18n
export const UserHeaderI18n = {
  "pt-BR": {
    header: {
      "shared-chat": "Esse chat é compartilhado com você",
      assistantDisabled: "Este assistente está desabilitado",
    },
    button: {
      "leave-shared-chat": "Sair chat",
    },
    notifications: {
      "confirm-leave-chat": "Deseja sair do chat compartilhado?",
    },
  },
  "en-US": {
    header: {
      "shared-chat": "This chat is shared with you",
      assistantDisabled: "This assistant is disabled",
    },
    button: {
      "leave-shared-chat": "Leave channel",
    },
    notifications: {
      "confirm-leave-chat": "Would you like to leave the shared chat?",
    },
  },
  "es-ES": {
    header: {
      "shared-chat": "Este chat se comparte contigo",
      assistantDisabled: "Este asistente está deshabilitado",
    },
    button: {
      "leave-shared-chat": "Deje canal",
    },
    notifications: {
      "confirm-leave-chat": "¿Deseas salir del chat compartido?",
    },
  },
  "fil-PH": {
    header: {
      "shared-chat": "Ang chat na ito ay ibinahagi sa iyo",
      assistantDisabled: "Ang asistente na ito ay hindi pinagana",
    },
    button: {
      "leave-shared-chat": "Umalis sa channel",
    },
    notifications: {
      "confirm-leave-chat": "Gusto mo bang umalis sa ibinahaging chat?",
    },
  },
};
