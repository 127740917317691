export const UserI18n = {
  "pt-BR": {
    titles: {
      updateProfile: "Atualizar perfil",
      wppAizzy: "Aizzy no Whatsapp",
      siteLanguage: "Idioma do site",
    },
    buttons: {
      update: "Atualizar",
      changeLanguage: "Alterar idioma",
    },
    form: {
      name: "Nome",
      email: "E-mail",
    },
    notifications: {
      success: "Perfil atualizado com sucesso",
    },
  },
  "en-US": {
    titles: {
      updateProfile: "Update Profile",
      wppAizzy: "Aizzy on Whatsapp",
      siteLanguage: "Site Language",
    },
    buttons: {
      update: "Update",
      changeLanguage: "Change Language",
    },
    form: {
      name: "Name",
      email: "E-mail",
    },
    notifications: {
      success: "Profile updated successfully",
    },
  },
  "es-ES": {
    titles: {
      updateProfile: "Actualizar perfil",
      wppAizzy: "Aizzy en Whatsapp",
      siteLanguage: "Idioma del sitio",
    },
    buttons: {
      update: "Actualizar",
      changeLanguage: "Cambiar idioma",
    },
    form: {
      name: "Nombre",
      email: "E-mail",
    },
    notifications: {
      success: "Perfil actualizado con éxito",
    },
  },
  "fil-PH": {
    titles: {
      updateProfile: "I-update ang Profil",
      wppAizzy: "Aizzy sa Whatsapp",
      siteLanguage: "Wika ng Site",
    },
    buttons: {
      update: "I-update",
      changeLanguage: "Baguhin ang Wika",
    },
    form: {
      name: "Pangalan",
      email: "E-mail",
    },
    notifications: {
      success: "Matagumpay na na-update ang Profil",
    },
  },
};
