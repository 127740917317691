export const LoadingUserDataI18n = {
  "pt-BR": {
    loader: {
      greetings: "Olá",
      message: "Estamos carregando os seus dados",
    },
  },
  "en-US": {
    loader: {
      greetings: "Hello",
      message: "We are loading your data",
    },
  },
  "es-ES": {
    loader: {
      greetings: "Hola",
      message: "Estamos cargando tus datos",
    },
  },
  "fil-PH": {
    loader: {
      greetings: "Kamusta",
      message: "Naglo-load ng iyong mga Impormasyon",
    },
  },
};
