import { all, call, fork, put, take, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  changeSelectedChat,
  chatsApiResponseError,
  chatsApiResponseSuccess,
} from "./actions";
import { ChatsActionTypes } from "./types";

import {
  createChannel as createChannelApi,
  createDocumentEmbedding as createDocumentEmbeddingApi,
  createVideoEmbedding as createDocumentEmbeddingVideoApi,
  createTopicChannel as createTopicChannelApi,
  deleteImage as deleteImageApi,
  deleteMessage as deleteMessageApi,
  deleteUserMessages as deleteUserMessagesApi,
  forwardMessage as forwardMessageApi,
  getChannelDetails as getChannelDetailsApi,
  getChannelJobStatus as getChannelJobStatusApi,
  getChannelUrls as getChannelUrlsApi,
  getChannelsAndDocuments as getChannelsAndDocumentsApi,
  getChatTopicChannels as getChatTopicChannelsApi,
  getChatUserConversationsChannels,
  getChatUserConversationsChannelsTopic,
  getChatUserConversationsDocuments,
  getChatUserDetails as getChatUserDetailsApi,
  getDirectMessages as getDirectMessagesApi,
  getDocuments as getDocumentsApi,
  readConversation as readConversationApi,
  readMessage as readMessageApi,
  receiveMessage as receiveMessageApi,
  receiveMessageFromUser as receiveMessageFromUserApi,
} from "../../api/index";

import { createDocumentEmbeddingUpload as createDocumentEmbeddingUploadApi } from "../../api/embeddings";

import {
  showErrorNotification,
  showSuccessNotification,
} from "../../helpers/notifications";

//actions
import { nanoid } from "nanoid";
import { eventChannel } from "redux-saga";
import {
  getDirectMessages as getDirectMessagesAction,
  getDocuments as getDocumentsAction,
} from "./actions";

function* getChannelsAndDocuments() {
  try {
    const response: Promise<any> = yield call(getChannelsAndDocumentsApi);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_CHANNELS_AND_DOCUMENTS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(
        ChatsActionTypes.GET_CHANNELS_AND_DOCUMENTS,
        error.message
      )
    );
  }
}

function* getDocuments() {
  try {
    const response: Promise<any> = yield call(getDocumentsApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_DOCUMENTS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_DOCUMENTS, error.message)
    );
  }
}

function* getDirectMessages() {
  try {
    const response: Promise<any> = yield call(getDirectMessagesApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_DIRECT_MESSAGES, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_DIRECT_MESSAGES, error.message)
    );
  }
}

function* createChannel({ payload: channelData }: any) {
  try {
    const response: Promise<any> = yield call(createChannelApi, channelData);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.CREATE_CHANNEL, response)
    );
    yield call(showSuccessNotification, "Chat created with success.");
  } catch (error: any) {
    yield call(showErrorNotification, error.message);
    yield put(
      chatsApiResponseError(ChatsActionTypes.CREATE_CHANNEL, error.message)
    );
  }
}

function* createYoutubeChannel({ payload: channelData }: any) {
  try {
    const { youtubeLink } = channelData;
    const createChannelResponse: any = yield call(
      createDocumentEmbeddingVideoApi,
      { urls: [youtubeLink] }
    );
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.CREATE_CHANNEL,
        createChannelResponse.channel
      )
    );
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.CREATE_YOUTUBE_CHANNEL, null)
    );
  } catch (error: any) {
    yield call(showErrorNotification, error.message);
    yield put(
      chatsApiResponseError(ChatsActionTypes.CREATE_CHANNEL, error.message)
    );
  }
}

function* createTopicChannel({ payload: channelData }: any) {
  try {
    const response = yield call(createTopicChannelApi, channelData);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.CREATE_TOPIC_CHANNEL, {
        channelData,
        response,
      })
    );
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, [])
    ); // reset get chat user conversations
    if (response.chat_response.content) {
      yield put(
        chatsApiResponseSuccess(ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER, {
          conversationId: 1,
          userId: response.user,
          messages: [
            {
              mId: 1,
              chat_response: {
                content: response.chat_response.content,
              },
              time: response.datetime,
              meta: {
                receiver: response.user,
                sender: response.assistantId,
                sent: true,
                received: false,
                read: false,
              },
            },
          ],
        })
      );
    }
    yield call(showSuccessNotification, "Channel created with success.");
  } catch (error: any) {
    yield call(showErrorNotification, error.message);
    yield put(
      chatsApiResponseError(
        ChatsActionTypes.CREATE_TOPIC_CHANNEL,
        error.message
      )
    );
  }
}

function* getChatUserDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChatUserDetailsApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(
        ChatsActionTypes.GET_CHAT_USER_DETAILS,
        error.message
      )
    );
  }
}

function* getChatUserConversations({ payload }: any) {
  try {
    let getChatUserConversationsApi = payload.isDocument
      ? getChatUserConversationsDocuments
      : getChatUserConversationsChannels;
    if (payload.topicId) {
      getChatUserConversationsApi = getChatUserConversationsChannelsTopic;
    }
    const response: Promise<any> = yield call(getChatUserConversationsApi, {
      assistantId: payload.assistantId,
      channelId: payload._id,
    });

    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(
        ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
        error.message
      )
    );
  }
}

function* getChatTopicChannels({ payload }: any) {
  try {
    const response: Promise<any> = yield call(getChatTopicChannelsApi, payload);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_CHAT_TOPIC_CHANNELS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(
        ChatsActionTypes.GET_CHAT_TOPIC_CHANNELS,
        error.message
      )
    );
  }
}

function* receiveMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(receiveMessageApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.RECEIVE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.RECEIVE_MESSAGE, error.message)
    );
  }
}

function* readMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readMessageApi, id);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.READ_MESSAGE, response));
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.READ_MESSAGE, error.message)
    );
  }
}

function* receiveMessageFromUser({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(receiveMessageFromUserApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(
        ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
        error.message
      )
    );
  }
}

function* deleteMessage({ payload: { userId, messageId } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteMessageApi,
      userId,
      messageId
    );
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.DELETE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.DELETE_MESSAGE, error.message)
    );
  }
}

function* forwardMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(forwardMessageApi, data);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.FORWARD_MESSAGE, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error.message + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.FORWARD_MESSAGE, error.message)
    );
  }
}

function* deleteUserMessages({ payload: userId }: any) {
  try {
    const response: Promise<any> = yield call(deleteUserMessagesApi, userId);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.DELETE_USER_MESSAGES, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error.message + "");
    yield put(
      chatsApiResponseError(
        ChatsActionTypes.DELETE_USER_MESSAGES,
        error.message
      )
    );
  }
}

function* getChannelDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChannelDetailsApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHANNEL_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHANNEL_DETAILS, error.message)
    );
  }
}

function* readConversation({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readConversationApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.READ_CONVERSATION, response)
    );
    yield put(getDirectMessagesAction());
    yield put(getDocumentsAction());
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.READ_CONVERSATION, error.message)
    );
  }
}

function* deleteImage({ payload: { userId, messageId, imageId } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteImageApi,
      userId,
      messageId,
      imageId
    );
    yield put(chatsApiResponseSuccess(ChatsActionTypes.DELETE_IMAGE, response));
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.DELETE_IMAGE, error.message)
    );
  }
}

function* createDocumentEmbedding({ payload }: any) {
  try {
    yield call(getChannelsAndDocuments);
    yield put(changeSelectedChat(payload.channel));

    const data = {
      urls: payload.urls,
      channelId: payload.channel._id,
    };
    const response: Promise<any> = yield call(createDocumentEmbeddingApi, data);
    const MAX_RETRIES = 6;
    const INITIAL_DELAY = 4000;
    let retries = 0;
    let delay = INITIAL_DELAY;
    while (retries < MAX_RETRIES) {
      const getChannelUrlsApiResponse = yield call(
        getChannelUrlsApi,
        payload.channel._id
      );
      yield put(
        chatsApiResponseSuccess(
          ChatsActionTypes.GET_CHANNEL_DOCUMENTS_URLS,
          getChannelUrlsApiResponse
        )
      );
      if (
        getChannelUrlsApiResponse.find(({ url }) => url === payload.urls[0])
      ) {
        break;
      } else {
        retries++;
        delay *= 2;
        yield new Promise(resolve => setTimeout(resolve, delay));
      }
    }
    if (retries === MAX_RETRIES) {
      console.warn("Max retries reached. URL not found.");
    } else {
      yield put(
        chatsApiResponseSuccess(
          ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING,
          response
        )
      );
      yield call(
        showSuccessNotification,
        "Document embedding created successfully."
      );
      yield call(getChannelsAndDocuments);
    }
  } catch (error: any) {
    if (!error.planLimit) {
      showErrorNotification(error.message);
    }
    yield put(
      chatsApiResponseError(ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING, error)
    );
  }
}

function* createDocumentEmbeddingUpload({ payload }: any) {
  try {
    yield call(getChannelsAndDocuments);
    yield put(changeSelectedChat(payload.channel));

    const response: Promise<any> = yield call(
      createDocumentEmbeddingUploadApi,
      payload.channel._id,
      payload.file
    );
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING_UPLOAD,
        response
      )
    );
    yield call(
      showSuccessNotification,
      "Document embedding created successfully."
    );
    yield call(getChannelJobStatus, { payload: payload.channel._id });
    yield call(getChannelsAndDocuments);
  } catch (error: any) {
    yield put(
      chatsApiResponseError(
        ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING_UPLOAD,
        error
      )
    );
    yield call(
      showErrorNotification,
      error.message || "Error to send document."
    );
  }
}

function* getChannelJobStatus({ payload }: any) {
  try {
    const response: Promise<any> = yield call(getChannelJobStatusApi, payload);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHANNEL_JOB_STATUS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(
        ChatsActionTypes.GET_CHANNEL_JOB_STATUS,
        error.message
      )
    );
  }
}

function* getChannelDocumentsUrls({ payload }: any) {
  try {
    const response: Promise<any> = yield call(getChannelUrlsApi, payload);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_CHANNEL_DOCUMENTS_URLS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(
        ChatsActionTypes.GET_CHANNEL_DOCUMENTS_URLS,
        error.message
      )
    );
  }
}

export function* watchGetChannelsAndDocuments() {
  yield takeEvery(
    ChatsActionTypes.GET_CHANNELS_AND_DOCUMENTS,
    getChannelsAndDocuments
  );
}

export function* watchGetDocuments() {
  yield takeEvery(ChatsActionTypes.GET_DOCUMENTS, getDocuments);
}

export function* watchGetDirectMessages() {
  yield takeEvery(ChatsActionTypes.GET_DIRECT_MESSAGES, getDirectMessages);
}

export function* watchCreateChannel() {
  yield takeEvery(ChatsActionTypes.CREATE_CHANNEL, createChannel);
}
export function* watchCreateYoutubeChannel() {
  yield takeEvery(
    ChatsActionTypes.CREATE_YOUTUBE_CHANNEL,
    createYoutubeChannel
  );
}
export function* watchCreateTopicChannel() {
  yield takeEvery(ChatsActionTypes.CREATE_TOPIC_CHANNEL, createTopicChannel);
}
export function* watchGetChatUserDetails() {
  yield takeEvery(ChatsActionTypes.GET_CHAT_USER_DETAILS, getChatUserDetails);
}
export function* watchGetChatUserConversations() {
  yield takeEvery(
    ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
    getChatUserConversations
  );
}
export function* watchGetChatTopicChannels() {
  yield takeEvery(
    ChatsActionTypes.GET_CHAT_TOPIC_CHANNELS,
    getChatTopicChannels
  );
}
export function* watchReceiveMessage() {
  yield takeEvery(ChatsActionTypes.RECEIVE_MESSAGE, receiveMessage);
}
export function* watchReadMessage() {
  yield takeEvery(ChatsActionTypes.READ_MESSAGE, readMessage);
}
export function* watchReceiveMessageFromUser() {
  yield takeEvery(
    ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
    receiveMessageFromUser
  );
}
export function* watchDeleteMessage() {
  yield takeEvery(ChatsActionTypes.DELETE_MESSAGE, deleteMessage);
}
export function* watchForwardMessage() {
  yield takeEvery(ChatsActionTypes.FORWARD_MESSAGE, forwardMessage);
}
export function* watchDeleteUserMessages() {
  yield takeEvery(ChatsActionTypes.DELETE_USER_MESSAGES, deleteUserMessages);
}
export function* watchGetChannelDetails() {
  yield takeEvery(ChatsActionTypes.GET_CHANNEL_DETAILS, getChannelDetails);
}

export function* watchReadConversation() {
  yield takeEvery(ChatsActionTypes.READ_CONVERSATION, readConversation);
}
export function* watchDeleteImage() {
  yield takeEvery(ChatsActionTypes.DELETE_IMAGE, deleteImage);
}
export function* watchCreateDocumentEmbedding() {
  yield takeEvery(
    ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING,
    createDocumentEmbedding
  );
}
export function* watchCreateDocumentEmbeddingUpload() {
  yield takeEvery(
    ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING_UPLOAD,
    createDocumentEmbeddingUpload
  );
}
export function* watchGetChannelJobStatus() {
  yield takeEvery(ChatsActionTypes.GET_CHANNEL_JOB_STATUS, getChannelJobStatus);
}
export function* watchGetChannelDocumentsUrls() {
  yield takeEvery(
    ChatsActionTypes.GET_CHANNEL_DOCUMENTS_URLS,
    getChannelDocumentsUrls
  );
}

function* chatsSaga() {
  yield all([
    fork(watchGetChannelsAndDocuments),
    fork(watchGetDocuments),
    fork(watchGetDirectMessages),
    fork(watchCreateChannel),
    fork(watchCreateTopicChannel),
    fork(watchGetChatUserDetails),
    fork(watchGetChatUserConversations),
    fork(watchReceiveMessage),
    fork(watchReadMessage),
    fork(watchReceiveMessageFromUser),
    fork(watchDeleteMessage),
    fork(watchForwardMessage),
    fork(watchDeleteUserMessages),
    fork(watchGetChannelDetails),
    fork(watchReadConversation),
    fork(watchDeleteImage),
    fork(watchCreateDocumentEmbedding),
    fork(watchCreateDocumentEmbeddingUpload),
    fork(watchGetChatTopicChannels),
    fork(watchGetChannelJobStatus),
    fork(watchCreateYoutubeChannel),
    fork(watchGetChannelDocumentsUrls),
  ]);
}

export default chatsSaga;
