import { useState } from "react";
import { useAppSelector, useRedux } from "../../../hooks/index";
import AppSimpleBar from "../../../components/AppSimpleBar";

import { changeAssistant, changeTab } from "../../../redux/actions";

import { TABS } from "../../../constants";
import useTranslation from "../../../helpers/useTranslation";
import { AssistantsI18n } from "./Assistants.i18n";
import { TabsAssistants } from "../../../components/TabsAssistants";
import { AizzyAssistants } from "./components/AizzyAssistants";
import { AssistantDTO } from "../../../@DTO/assistant";
import { MyAssistants } from "./components/MyAssistants";
import { CommunityAssistants } from "./components/CommunityAssistants";
// import { Header } from "./components/Header";

export enum TabAssistansOptions {
  MyAssistants = "myAssistants",
  CommunityAssistants = "communityAssistants",
  AizzyAssistants = "aizzyAssistants",
}

const Index = () => {
  const { dispatch } = useRedux();

  const [activeTab, setActiveTab] = useState<TabAssistansOptions>(
    TabAssistansOptions.AizzyAssistants
  );

  const { isOpenLeftBar } = useAppSelector(state => ({
    isOpenLeftBar: state.Layout.isOpenLeftBar,
  }));

  const { t } = useTranslation(AssistantsI18n);

  function handleSelectAssistant(assistant: AssistantDTO) {
    dispatch(changeAssistant(assistant));
    dispatch(changeTab(TABS.ASSISTANT_CHATS));
  }

  function onSelectTab(tab: TabAssistansOptions) {
    setActiveTab(tab);
  }

  return (
    <>
      <div style={{ overflowX: "hidden" }}>
          <AppSimpleBar className="chat-message-list chat-group-list">
            <div className="px-2 px-lg-5 overflow-hidden">
              {/* <Header /> */}

              <TabsAssistants activeTab={activeTab} onSelectTab={onSelectTab} />

              {activeTab === "aizzyAssistants" && (
                <AizzyAssistants
                  onSelectAssistant={handleSelectAssistant}
                  t={t}
                />
              )}

              {activeTab === "myAssistants" && (
                <MyAssistants onSelectAssistant={handleSelectAssistant} />
              )}

              {activeTab === "communityAssistants" && (
                <CommunityAssistants
                  onSelectAssistant={handleSelectAssistant}
                  t={t}
                />
              )}
            </div>
          </AppSimpleBar>
      </div>
    </>
  );
};

export default Index;
