import React from "react";
import { Link } from "react-router-dom";
import metamask from "../assets/images/icons/metamask.svg";
import basescan from "../assets/images/home/base-scan.png";
import dextools from "../assets/images/home/dextools.png";
import dexscreener from "../assets/images/home/dexscreener.png";
import uniswap from "../assets/images/home/uniswap.png";
import logoai from "../assets/images/logo.svg";
import { web3Service } from "../helpers/web3";

const ButtonsHome = () => {
  return (
    <section className="d-flex justify-content-center">
      <div className="m-0 d-flex flex-column align-items-center">
        <nav className="">
          <div className="">
            <button
              onClick={web3Service.importTokenToMetaMask}
              className="btn border-dark color-lp-dark"
            >
              <img src={metamask} alt="" width={15} height={15} />
              Metamask
            </button>
            <Link
              target="_blank"
              className="btn border-dark color-lp-dark"
              to={"https://github.com/aizzy-ai"}
            >
              <img src={logoai} alt="" width={15} height={15} />
              Docs
            </Link>
            <Link
              target="_blank"
              className="btn border-dark color-lp-dark"
              to={
                "https://basescan.org/token/0xc51d94a9936616b90E26abe61921Ab3b4E66A149"
              }
            >
              <img src={basescan} alt="" width={15} height={15} />
              Basescan
            </Link>
            <Link
              target="_blank"
              className="btn border-dark color-lp-dark"
              to={
                "https://app.uniswap.org/explore/tokens/base/0xc51d94a9936616b90e26abe61921ab3b4e66a149"
              }
            >
              <img src={uniswap} alt="" width={20} height={20} />
              Get AIZ
            </Link>
            <Link
              target="_blank"
              className="btn border-dark color-lp-dark"
              to={
                "https://www.dextools.io/app/en/base/pair-explorer/0xbdfbe31d152f57a717a240629e4b6a65435404dc?t=1718203057848"
              }
            >
              <img src={dextools} alt="" width={18} height={18} />
              DEXTools
            </Link>
            <Link
              target="_blank"
              className="btn border-dark color-lp-dark"
              to={
                "https://dexscreener.com/base/0xbdfbe31d152f57a717a240629e4b6a65435404dc"
              }
            >
              <img src={dexscreener} alt="" width={18} height={18} />
              DEX Screener
            </Link>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default ButtonsHome;
