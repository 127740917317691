import { Collapse } from "reactstrap";
import useTranslation from "../helpers/useTranslation";
import { ButtonDeleteAllChats } from "./ButtonDeleteAllChats";
import { MenuSideChatsI18n } from "./MenuSideChats.i18n";
import { useChannels } from "../hooks/useChannels";

const MenuSideChats = ({
  onClick,
  handleCreateChannel,
  loadingState,
  activeButtons,
  handleChannelClear,
  collapsed,
  documents,
  assistantId,
}: any) => {
  const { t } = useTranslation(MenuSideChatsI18n);

  return (
    <div
      className="p-3 d-flex flex-column align-items-start gap-3 bg-body vh-100 me-3 shadow-left-bar"
      style={{width:70}}
    >
      <button className="btn fs-4 m-0 p-1 border-0" onClick={onClick}>
        <i className="bx bx-arrow-to-right" />
        <p className=" font-size-10">{t("expand")}</p>
      </button>
      {activeButtons ? (
        <>
          <button
            className="btn fs-4 mt-1 p-1 border-0"
            onClick={handleCreateChannel}
            disabled={loadingState.createChannel}
          >
            {loadingState.createChannel ? (
              <i className="bx bx-loader-circle bx-spin" />
            ) : (
              <i className="bx bxs-message-square-add" />
            )}
            <p className=" font-size-10">{t("newChat")}</p>
          </button>
          <button
            className="btn fs-4 m-0 p-1 border-0"
            onClick={handleChannelClear}
            disabled={loadingState.clearChannel}
          >
            {loadingState.clearChannel ? (
              <i className="bx bx-loader-circle bx-spin" />
            ) : (
              <i className="bx bxs-message-square-x" />
            )}
            <p className=" font-size-10">{t("clearChat")}</p>
          </button>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default MenuSideChats;
