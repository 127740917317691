import { useEffect, useState } from "react";
import errorImage from "../assets/images/icons/error-image.svg";
import STATES from "../assets/thirdweb/statesTransaction";
import useTranslation from "../helpers/useTranslation";
import { ModalFeedbackTransactionI18n } from "./ModalFeedbackTransaction.i18n";
import { Player } from "@lottiefiles/react-lottie-player";
import { Modal, ModalBody } from "reactstrap";

export function ModalFeedbackTransaction({
  stateTransaction = "",
  onClose,
  transactionHash,
}) {
  const { t } = useTranslation(ModalFeedbackTransactionI18n);

  const [seconds, setSeconds] = useState(16);

  useEffect(() => {
    if (stateTransaction === STATES.SUCCESS) {
      const interval = setInterval(() => {
        setSeconds(prev => {
          if (prev === 0) {
            return prev;
          }
          return prev - 1;
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [stateTransaction]);

  return (
    <Modal isOpen={true} backdrop centered>
      <ModalBody className="p-4 d-flex flex-column align-items-center justify-content-center">
        <>
          {stateTransaction === STATES.SENDING && (
            <>
              <Player
                autoplay
                loop={true}
                src="https://lottie.host/8d844952-2e72-486e-a23d-042c92409859/507mSWldrv.json"
                style={{ height: "150px", width: "350px" }}
              />
              <h4 className="mt-4">{t("modal.sending.title")}</h4>
              <p>{t("modal.sending.description")}</p>
            </>
          )}
          {stateTransaction === STATES.CHECKING && (
            <>
              <Player
                autoplay
                loop={true}
                src="https://lottie.host/8d844952-2e72-486e-a23d-042c92409859/507mSWldrv.json"
                style={{ height: "150px", width: "350px" }}
              />
              <h4 className="mt-4">{t("modal.checking.title")}</h4>
              <p>{t("modal.checking.description")}</p>
            </>
          )}
          {stateTransaction === STATES.INSUFICIENT_FUNDS && (
            <>
              <h4 className="mb-4">{t("modal.insufficientFunds.title")}</h4>
              <p>{t("modal.insufficientFunds.description")}</p>
              <button onClick={onClose} className="btn btn-primary mt-3">
                {t("modal.insufficientFunds.button")}
              </button>
            </>
          )}
          {stateTransaction === STATES.ERROR && (
            <>
              <img
                src={errorImage}
                loading="lazy"
                height={90}
                width={100}
                alt="Error"
              />
              <h4 className="mt-4">{t("modal.error.title")}</h4>
              <p>{t("modal.error.description")}</p>
              <button onClick={onClose} className="btn btn-primary mt-3">
                {t("modal.error.button")}
              </button>
            </>
          )}
          {stateTransaction === STATES.SUCCESS && (
            <>
              {/* <img
            src={checkImage}
            loading="lazy"
            height={60}
            width={70}
            alt="Check success"
          /> */}
              <Player
                autoplay
                keepLastFrame={true}
                src="https://lottie.host/11fa73a7-ad6b-4ef4-8988-7e512e437c1b/e7jLTCzK2Z.json"
                style={{ height: "100px", width: "100px" }}
              />
              <h4 className="mt-4">{t("modal.success.title")}</h4>
              <p>
                {t("modal.success.description")}{" "}
                <a
                  className="link-primary"
                  href={`https://basescan.org/tx/${transactionHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Ver transação
                </a>
              </p>

              <button
                onClick={() => {
                  localStorage.removeItem("user-subscription");
                  localStorage.removeItem("user");
                  window.location.reload();
                }}
                className="btn btn-primary mt-1"
              >
                {t("modal.success.button")}
              </button>
              <p className="text-center text-muted font-size-14 mt-3">
                {t("modal.success.timeoutText").replace(
                  "{seconds}",
                  seconds.toString()
                )}
              </p>
            </>
          )}
        </>
      </ModalBody>
    </Modal>
  );
}
