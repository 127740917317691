import { debounce } from "lodash";
import { useContext, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Button, Card, CardBody, Collapse, Input } from "reactstrap";
import { showErrorNotification } from "../../../../helpers/notifications";
import useTranslation from "../../../../helpers/useTranslation";
import { AttachDocumentMenuI18n } from "./AttachDocumentMenu.i18n";
import { ChatInputSectionI18n } from "./ChatInputSection.i18n";
import RemainingCallsContext from "../../../../freemium/RemainingCallsContext";
import { getCurrentUserPlan } from "../../../../helpers/userHelper";
import { PLANS_AIZZY } from "../../../../helpers/constants/Plans";

interface AttachDocumentMenuProps {
  isOpen: boolean;
  onToggle: () => void;
  onAttachDocumentByUrl: (url: string) => void;
  onAttachDocumentFile: (file: any) => void;
}

const AttachDocumentMenu = ({
  isOpen,
  onToggle,
  onAttachDocumentByUrl,
  onAttachDocumentFile,
}: AttachDocumentMenuProps) => {
  const { pdfSizeLimit, setShowFreemiumModal } = useContext(
    RemainingCallsContext
  );
  const [showAttachByUrl, setShowAttachByUrl] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");
  const { t } = useTranslation(AttachDocumentMenuI18n);

  const isPremiumUser = getCurrentUserPlan() === PLANS_AIZZY.PREMIUM;

  const typesSupported = [
    "pdf",
    "docx",
    "pptx",
    "odt",
    "odp",
    "jpg",
    "jpeg",
    "png",
    "bmp",
    "gif",
    "tif",
    "tiff",
    "tex",
    "txt",
    "rtf",
  ];

  const AttachByURL = () => (
    <div className="row">
      <div className="col-12">
        <h5 className="font-size-14 mb-3">{t("chat.label")}</h5>
      </div>
      <div className="col">
        <Input
          type="text"
          className="form-control form-control-lg chat-input"
          id="chat-input"
          placeholder={t("placeholder.main")}
          autoFocus
          defaultValue={url}
          onChange={debounce((e: any) => setUrl(e.target.value), 700)}
        />
      </div>
      <div className="col-auto">
        <Button
          color="primary"
          type="submit"
          className="btn btn-primary btn-lg chat-send waves-effect waves-light"
          onClick={() => onAttachDocumentByUrl(url.trim())}
        >
          <span className="font-size-13 nowrap">{t("button.label")}</span>
        </Button>
      </div>
    </div>
  );
  const SliderButtonOptions = () => {
    const { t } = useTranslation(ChatInputSectionI18n);

    return (
      <div className="d-flex flex-column align-items-center gap-2">
        <p className="text-center mb-3 fs-6">{t("chat.label")}</p>
        <FileUploader
          classes="dropzone"
          label={t("button.docs")}
          maxSize={pdfSizeLimit}
          handleChange={file => onAttachDocumentFile(file)}
          onSizeError={() => {
            if (!isPremiumUser) {
              setShowFreemiumModal("pdfSize");
            } else {
              showErrorNotification(
                t("notifications.error", { pdfSizeLimit }),
                {
                  duration: 5000,
                }
              );
            }
          }}
          name="file"
          types={typesSupported}
          // types={["pdf"]}
        />
        <div className="my-3 d-flex flex-column align-items-center">
          <h5>{t("docs.label")}</h5>
          <ul
            className="list-unstyled sm row d-flex justify-content-center align-items-center gap-2"
            style={{
              maxWidth: 600,
            }}
          >
            {typesSupported.map(type => (
              <li
                className="mt-2 col-2 text-center  py-2"
                key={type}
                style={{
                  width: 55,
                }}
              >
                {type}
              </li>
            ))}
          </ul>
        </div>
        <button
          className="btn btn-outline-secondary font-size-13"
          onClick={() => setShowAttachByUrl(!showAttachByUrl)}
        >
          <i className="bx bx-link-alt me-2"></i>
          {t("button.link")}
        </button>
      </div>
    );
  };

  useEffect(() => {
    setUrl("");
  }, [isOpen]);
  return (
    <Collapse
      isOpen={isOpen}
      className="chat-input-collapse"
      id="chatinputmorecollapse"
    >
      {isOpen && (
        <Card>
          <CardBody>
            <div className="d-flex flex-column">
              <div
                className="d-flex justify-content-end"
                style={{ position: "relative" }}
              >
                <button className="btn p-0 hover-primary" onClick={onToggle}>
                  <i className="bx bx-x fs-3" />
                </button>
              </div>
              <div>
                {!showAttachByUrl ? <SliderButtonOptions /> : <AttachByURL />}
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </Collapse>
  );
};

export default AttachDocumentMenu;
