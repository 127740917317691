import { useLayoutEffect, useRef, useState } from "react";
import Message from "./Message";
import { ButtonGoFinalChat } from "./ButtonGoFinalChat";
import { useChatStore } from "../../../state/chat";

import { SelectedChatDTO } from "../../../@DTO/selectedChat";
import { DeviceInfo } from "./DeviceInfo";

const Conversation = ({ selectedChat }: { selectedChat: SelectedChatDTO }) => {
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const conversationRef = useRef<HTMLDivElement>(null);
  const { messages } = useChatStore();

  const isSameUserAgent =
    selectedChat?.deviceInfo?.userAgent === navigator.userAgent;

  const handleScroll = () => {
    const element = conversationRef.current;
    if (!element) return;
    const isAtBottom =
      element.scrollHeight - element.scrollTop - 1 <= element.clientHeight;
    setShouldScrollToBottom(isAtBottom);
  };

  const handleGoBackFinalChat = () => {
    if (conversationRef.current) {
      const scrollHeight = conversationRef.current.scrollHeight;
      const behavior = "smooth";
      conversationRef.current.scrollTo({ top: scrollHeight, behavior });
    }
  };

  useLayoutEffect(() => {
    if (conversationRef.current && shouldScrollToBottom) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  }, [messages, shouldScrollToBottom]);

  return (
    <>
      <div
        className="chat-conversation me-0 me-lg-3 h-100"
        ref={conversationRef}
        onScroll={handleScroll}
      >
        {!isSameUserAgent &&
        !selectedChat?.isDocument &&
        (!messages || messages?.length === 0) &&
        selectedChat?.channelName?.toLowerCase() !== "general" ? (
          <DeviceInfo selectedChat={selectedChat} />
        ) : (
          <div
            className="list-unstyled chat-conversation-list"
            id="chat-conversation-list"
          >
            {messages?.map(message => {
              return (
                <Message
                  message={message}
                  key={`${message?._id || message?.mId}`}
                />
              );
            })}
          </div>
        )}
      </div>
      {!shouldScrollToBottom && (
        <ButtonGoFinalChat onClick={handleGoBackFinalChat} />
      )}
    </>
  );
};

export default Conversation;
