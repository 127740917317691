import classnames from "classnames";

interface PaginationItemProps {
  number: number;
  isCurrent?: boolean;
  disabled?: boolean;
  onSelectItem: (number: number) => void;
}

export function PaginationItem({
  number,
  isCurrent,
  disabled = false,
  onSelectItem,
}: PaginationItemProps) {
  return (
    <div>
      <button
        onClick={() => onSelectItem(number)}
        disabled={disabled}
        className={classnames("btn font-size-18 button-border-sm button-small d-flex align-items-center justify-content-center", {
          "btn-primary": isCurrent,
        })}
      >
        {number}
      </button>
    </div>
  );
}
