import { all } from "redux-saga/effects";

//auth

import LayoutSaga from "./layout/saga";
import chatsSaga from "./chats/saga";

export default function* rootSaga() {
  yield all([LayoutSaga(), chatsSaga()]);
}
