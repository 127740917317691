import { Player } from "@lottiefiles/react-lottie-player";
import AuthHeader from "./AuthHeader";

import loadAnimation from "../assets/animations/load-animation.json";
import useTranslation from "../helpers/useTranslation";
import { LoadingUserDataI18n } from "./LoadingUserData.i18n";
import { User } from "@supabase/supabase-js";

export function LoadingUserData({ user }: { user: User | null }) {
  const { t } = useTranslation(LoadingUserDataI18n);

  return (
    <div
      className="px-3 d-flex flex-column align-content-center justify-content-center"
      style={{
        minHeight: "100vh",
      }}
    >
      <div
        className="position-absolute mt-3"
        style={{
          top: "0",
          left: "0",
          right: "0",
        }}
      >
        <AuthHeader logo />
      </div>
      <div className="mx-auto d-flex flex-column align-content-center justify-content-center text-center">
        <span className="fs-1">
          {t("loader.greetings")},{" "}
          {user?.email && user?.email?.includes("@") && (
            <strong className="fw-normal text-primary fs-2">
              {user?.email?.split("@")[0]}!
            </strong>
          )}
        </span>
        <p className="fs-2">{t("loader.message")}</p>
        <strong className="text-center">
          <Player
            autoplay
            loop
            keepLastFrame={true}
            src={loadAnimation}
            style={{ height: "200px", width: "200px" }}
          />
        </strong>
      </div>
    </div>
  );
}
