import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import useTranslation from "../helpers/useTranslation";
import { ModalPixI18n } from "./ModalPix.i18n";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../helpers/notifications";

import { Player } from "@lottiefiles/react-lottie-player";
import "animate.css";
import { useNavigate } from "react-router-dom";
import { checkPixPayment, checkoutPix } from "../api/subscription";

interface ModalPixProps {
  amount: number;
}

export function ModalPix({ amount }: ModalPixProps) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [hasPaidPix, setHasPaidPix] = useState(false);
  const [secondsToRedirect, setSecondsToRedirect] = useState(10);

  const [qrcodeImage, setQRCodeImage] = useState("");
  const [pixCode, setPixCode] = useState("");

  const navigate = useNavigate();

  const { t } = useTranslation(ModalPixI18n);

  const toggleModal = () => {
    setOpen(!open);
    setQRCodeImage("");
  };

  async function handleSubscribePix() {
    try {
      setIsLoading(true);

      const amountInCents = amount * 100;

      const response = await checkoutPix(amountInCents);
      toggleModal();

      // //@ts-ignore
      // const pixPosition = response.length - 1;

      const qrcodeBase64 = response.info.qrcode as string;
      const pixCode = response.info.txinfoemvqrcps;

      const qrcodeImage = qrcodeBase64?.includes("data:image")
        ? qrcodeBase64
        : `data:image/png;base64,${qrcodeBase64}`;

      setQRCodeImage(qrcodeImage);
      setPixCode(pixCode);
    } catch (error: any) {
      showErrorNotification(error);
    } finally {
      setIsLoading(false);
    }
  }

  let intervalId: NodeJS.Timeout | null = null;

  async function checkIfPixHasBeenPaid() {
    const response = await checkPixPayment();

    //@ts-ignore
    const pixPosition = response.length - 1;

    if (response[pixPosition]?.finished === true) {
      //@ts-ignore
      clearInterval(intervalId!);
      if (!open) {
        toggleModal();
      }
      setHasPaidPix(true);
    }
  }

  if (qrcodeImage && !intervalId) {
    intervalId = setInterval(() => {
      checkIfPixHasBeenPaid();
    }, 3000);
  }

  useEffect(() => {
    if (hasPaidPix) {
      const timer = setTimeout(() => {
        if (secondsToRedirect > 0) {
          setSecondsToRedirect(secondsToRedirect - 1);
        } else {
          navigate("/signin");
        }

        return () => {
          clearTimeout(timer);
        };
      }, 1000);
    }
  }, [secondsToRedirect, hasPaidPix]);

  return (
    <>
      <button
        onClick={handleSubscribePix}
        className="w-100 btn btn-primary fw-semibold font-size-18"
        disabled={isLoading}
      >
        {isLoading ? (
          <i className="bx bx-loader-circle bx-spin align-middle" />
        ) : (
          <>{t("buttonPix")}</>
        )}
      </button>
      <Modal
        isOpen={open}
        backdrop
        centered
        toggle={hasPaidPix ? toggleModal : () => {}}
      >
        <ModalHeader className="border-0 mb-0 pb-0" toggle={toggleModal} />

        <ModalBody className="px-3 mb-1">
          {hasPaidPix ? (
            <div className="d-flex flex-column align-content-center text-center gap-3">
              <span className="fs-3 fw-semibold">{t("textPaidPix")}</span>
              <Player
                autoplay
                keepLastFrame={true}
                src="https://lottie.host/11fa73a7-ad6b-4ef4-8988-7e512e437c1b/e7jLTCzK2Z.json"
                style={{ height: "200px", width: "200px" }}
              />
              <span className="font-size-18 fw-semibold">
                {t("messageLogoutLogin")}
              </span>
              <span>
                {t("messageRedirect", {
                  seconds: secondsToRedirect,
                })}
              </span>
              {/* <a className="btn btn-primary mt-1 w-75 mx-auto" href="/logout">
                {t("buttonLogout")}
              </a> */}
            </div>
          ) : (
            <div>
              <p className="font-size-18 text-center">
                {t("modalDescription")}
              </p>
              {qrcodeImage && (
                <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                  <img
                    src={qrcodeImage}
                    alt="QR Code"
                    width={300}
                    height={300}
                    className="shadow animate__animated animate__fadeInUp animate__fast"
                  />
                  <button
                    onClick={() => {
                      navigator.clipboard
                        .writeText(pixCode)
                        .then(() =>
                          showSuccessNotification(
                            t("alerts.copySuccessMessage")
                          )
                        );

                      setIsCopied(true);

                      setTimeout(() => {
                        setIsCopied(false);
                      }, 2500);
                    }}
                    className="d-flex justify-content-center align-items-center gap-2 btn btn-outline-primary font-size-18"
                    style={{
                      width: 300,
                    }}
                  >
                    {isCopied ? (
                      <>
                        <span>{t("buttonPixCopied")}</span>
                        <i className="bx bx-check fs-3" />
                      </>
                    ) : (
                      <>
                        <span>{t("buttonCopyPix")}</span>
                        <i className="bx bx-copy" />
                      </>
                    )}
                  </button>
                  <span className="font-size-16 fst-italic">
                    {t("waitingPaymentMessage")}...
                  </span>
                </div>
              )}
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}
