import { useState } from "react";
import { ImageMenuPopUp } from "../../../components/ImageMenuPopUp";
import MarkdownRenderer from "../../../components/MarkdownRenderer";
import { removeMarkdownImageSyntax } from "../../../utils/helpers";
import { ImageData } from "../types";
import { ModalImage } from "./ModalImage";

interface ImageCardProps {
  image: ImageData;
}

export function ImageCard({ image }: ImageCardProps) {
  const [showModal, setShowModal] = useState(false);

  function handleZoomImage() {
    setShowModal(true);
  }

  return (
    <div
      style={{
        marginBottom: 8,
      }}
      key={image?._id}
      className="rounded-circle"
    >
      <ModalImage
        image={removeMarkdownImageSyntax(image.chat_response.content)}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div className="position-relative">
        <button className="button-image rounded-3" onClick={handleZoomImage}>
          <MarkdownRenderer
            className="image-ia"
            content={image?.chat_response?.content}
          />
        </button>
        <div
          style={{
            position: "absolute",
            top: 2,
            right: 2,
          }}
        >
          <ImageMenuPopUp
            chatId={image?._id}
            image={image?.chat_response?.content}
          />
        </div>
      </div>
    </div>
  );
}

/* <p>{JSON.stringify(message, null, 2)}</p> */

/* {!message?.isMinted && (
                     <Web3Button
                       className="web3-button btn bg-primary text-light w-75 fw-semibold fs-6"
                       contractAddress={
                         process.env.REACT_APP_NFT_COLLECTION_ADDRESS!
                       }
                       action={() => onMint(message?._id)}
                     >
                       Mint NFT
                     </Web3Button>
                   )} */

/* {message?.isMinted && (
                      <a
                        className="btn btn-primary w-75 fw-semibold fs-6"
                        href={`https://${
                          process.env.REACT_APP_NFT_CHAIN === "mumbai"
                            ? "testnets."
                            : ""
                        }opensea.io/assets/${
                          process.env.REACT_APP_NFT_CHAIN === "mumbai"
                            ? "mumbai"
                            : "matic"
                        }/${process.env.REACT_APP_NFT_COLLECTION_ADDRESS}/${
                          message.nftId
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View NFT
                      </a>
                    )} */
