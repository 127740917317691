import { create } from "zustand";
import { MessageEndData } from "../hooks/useChatWebSocket";
import { MessageData } from "../@DTO/chat";
import { ImageGeneratedData } from "../@DTO/image";

interface NewMessageData {
  mId: string;
  chat_request: string;
  chat_response: {
    content: string;
  };
}

const chatOptions = {
  CHAT: "CHAT",
  CODE: "CODE",
};

type ChatOption = keyof typeof chatOptions;

interface ChatState {
  status: string;
  messages: any[];
  chatOption: ChatOption;
  newMessageIndex: number | null;
  lastImageGenerated: ImageGeneratedData | null;
  isReceivingMessage: boolean;
  disableCancelMessage: boolean;
  isGeneratingImage: boolean;
  hasReceiveFirstMessage: boolean;
  setStatus: (status: string) => void;
  setMessages: (messages: MessageData[]) => void;
  changeChatOption: (chatOption: ChatOption) => void;
  setImageMessage: (image: any) => void;
  setIsGeneratingImage: (isGeneratingImage: boolean) => void;
  setNewMessage: (newMessage: NewMessageData) => void;
  setEndMessage: (message: MessageEndData) => void;
  setDisableCancelMessage: (disableCancelMessage: boolean) => void;
  setBroadcastMessage: (message: MessageEndData) => void;
  updateMessage: (message: any) => void;
  removeMessage: (messageId: string) => void;
  errorReceiveMessageManager: () => void;
  reset: () => void;
}

export const useChatStore = create<ChatState>(set => ({
  status: "",
  messages: [],
  chatOption: "CHAT",
  newMessageIndex: null,
  lastImageGenerated: null,
  isReceivingMessage: false,
  disableCancelMessage: false,
  isGeneratingImage: false,
  hasReceiveFirstMessage: false,

  setStatus: status => {
    set({ status });
  },

  setMessages: messages => {
    set({ messages });
  },

  changeChatOption: chatOption => {
    set({ chatOption });
  },

  setNewMessage: newMessage => {
    set(state => {
      const newMessages = state.messages
        ? [...state.messages, newMessage]
        : [newMessage];
      return {
        messages: newMessages,
        newMessageIndex: newMessages.length - 1,
        isReceivingMessage: true,
        disableCancelMessage: false,
      };
    });
  },

  setEndMessage: message => {
    set(state => {
      const { messages } = state;

      const newMessages = messages
        ?.map(m => {
          if (m.mId === message.messageId) {
            m = message.data;
          }

          return m;
        })
        .filter(m => m.chat_response.content !== "");

      state.reset();

      return {
        messages: newMessages,
      };
    });
  },

  setDisableCancelMessage: (disableCancelMessage: boolean) => {
    set(state => {
      return {
        ...state,
        disableCancelMessage: disableCancelMessage,
      };
    });
  },

  setBroadcastMessage: message => {
    set(state => {
      const { messages } = state;

      const messagesWithBrodcastMessage = [...messages, message.data];

      return {
        messages: messagesWithBrodcastMessage,
      };
    });
  },

  setImageMessage: image => {
    set(state => {
      const newMessages = state.messages.slice();
      newMessages.unshift(image);

      return {
        messages: newMessages,
        lastImageGenerated: image,
        isGeneratingImage: false,
      };
    });
  },

  setIsGeneratingImage: (isGeneratingImage: boolean) => {
    set({ isGeneratingImage });
  },

  updateMessage: updatedMessage => {
    set(state => {
      if (!state.hasReceiveFirstMessage) {
        set({ hasReceiveFirstMessage: true });
      }

      const messagesCopy = [...state.messages];

      if (state.newMessageIndex !== null) {
        const messageCopy = { ...messagesCopy[state.newMessageIndex] };
        messageCopy.chat_response.content = updatedMessage.content;
        messagesCopy[state.newMessageIndex] = messageCopy;
      }

      return {
        messages: messagesCopy,
      };
    });
  },

  removeMessage: messageId => {
    set(state => {
      const newMessages = state.messages.filter(
        (message: MessageData) => message._id !== messageId
      );

      return {
        messages: newMessages,
      };
    });
  },

  errorReceiveMessageManager: () => {
    set(state => {
      if (!state.newMessageIndex) {
        return {};
      }

      const newMessages = state.messages.slice(0, state.newMessageIndex);

      return {
        messages: newMessages,
        isReceivingMessage: false,
        hasReceiveFirstMessage: false,
      };
    });
  },

  reset: () => {
    set({
      newMessageIndex: null,
      isReceivingMessage: false,
      hasReceiveFirstMessage: false,
      isGeneratingImage: false,
      lastImageGenerated: null,
    });
  },
}));
