export const SupportI18n = {
  "pt-BR": {
    titles: {
      wppSupport: "Suporte no Whatsapp",
      discordSupport: "Canal do Discord",
      emailSupport: "Nosso e-mail de suporte:",
    },
    description: {
      wppSupport:
        "Para receber suporte direto no Whatsapp, escolha a opção correspondente ao seu idioma entre as alternativas abaixo:",
      discordSupport:
        "Junte-se à nossa comunidade no Discord do Aizzy! Conecte-se diretamente com outros usuários, participe de discussões animadas e receba suporte personalizado. Clique aqui para entrar agora!",
    },
    buttons: {
      "copy-code": "Copiar",
      copied: "Copiado",
    },
  },
  "en-US": {
    titles: {
      wppSupport: "Whatsapp support",
      discordSupport: "Discord channel",
      emailSupport: "Our support email:",
    },
    description: {
      wppSupport:
        "To receive direct support on WhatsApp, choose the option that corresponds to your language from the alternatives below:",
      discordSupport:
        "Join our community on Aizzy's Discord! Connect directly with other users, participate in lively discussions, and receive personalized support. Click here to join now!",
    },
    buttons: {
      "copy-code": "Copy",
      copied: "Copied",
    },
  },
  "es-ES": {
    titles: {
      wppSupport: "Soporte Whatsapp",
      discordSupport: "Canal de Discord",
      emailSupport: "Nuestro e-mail de soporte:",
    },
    description: {
      wppSupport:
        "Para recibir soporte directo en WhatsApp, elija la opción que corresponda a su idioma entre las alternativas a continuación:",
      discordSupport:
        "Únete a nuestra comunidad en Discord de Aizzy. Conéctate directamente con otros usuarios, participa en animadas discusiones y recibe soporte personalizado. Haz clic aquí para unirte ahora.",
    },
    buttons: {
      "copy-code": "Copiar",
      copied: "Copiado",
    },
  },
  "fil-PH": {
    titles: {
      wppSupport: "Suporta sa Whatsapp",
      discordSupport: "Discord channel",
      emailSupport: "Aming email ng suporta:",
    },
    description: {
      wppSupport:
        "Upang makatanggap ng direktang suporta sa WhatsApp, pumili ng opsyon na katugma sa iyong wika mula sa mga alternatibong ibaba:",
      discordSupport:
        "Sumali sa aming komunidad sa Discord ng Aizzy! Makipag-ugnayan nang direkta sa iba pang mga user, makisali sa mga masiglang talakayan, at makatanggap ng personalisadong suporta. Mag-click dito para sumali ngayon!",
    },
    buttons: {
      "copy-code": "Kopyahin",
      copied: "Nakopya",
    },
  },
};
