export const LogoutI18n = {
  "pt-BR": {
    expired: {
      title: "Sua sessão expirou",
      description: "Você está sendo redirecionado para a página de login.",
    },
    logout: {
      title: "Desconectando...",
      description: "Você está sendo desconectado da sua conta.",
    },
  },
  "en-US": {
    expired: {
      title: "Your session has expired",
      description: "You are being redirected to the login page.",
    },
    logout: {
      title: "Disconnecting...",
      description: "You are being disconnected from your account.",
    },
  },
  "es-ES": {
    expired: {
      title: "Su sesión ha expirado",
      description: "Serás redirigido a la página de inicio de sesión.",
    },
    logout: {
      title: "Desconectando...",
      description: "Estás siendo desconectado de tu cuenta.",
    },
  },
  "fil-PH": {
    expired: {
      title: "Ang iyong sesyon ay nag-expire",
      description: "Ikaw ay inu-redirect patungo sa pahina ng login.",
    },
    logout: {
      title: "Nagdi-disconnect...",
      description: "Ikaw ay ini-di-disconnect mula sa iyong account.",
    },
  },
};
