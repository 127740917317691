import { yupResolver } from "@hookform/resolvers/yup";
import { useUser } from "@supabase/auth-helpers-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form, FormGroup, Label, UncontrolledTooltip } from "reactstrap";
import * as yup from "yup";
import { AssistantDTO } from "../../../@DTO/assistant";
import { createAssistant } from "../../../api/assistants";
import { ErrorText } from "../../../components/ErrorText";
import Loader from "../../../components/Loader";
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from "../../../helpers/notifications";
import useTranslation from "../../../helpers/useTranslation";
import { useRedux } from "../../../hooks";
import {
  changeAssistant,
  changeTab,
  chatsApiResponseError,
  getChatUserConversationsReset,
} from "../../../redux/actions";
import { CreateAssistantI18n } from "./CreateAssistant.i18n";
import { GenerateImagesButton } from "./GenerateImagesButton";
import { AssistantTranslationFormData, ModalLanguage } from "./ModalLanguage";
import { TranslationCard } from "./TranslationCard";
import { TABS } from "../../../constants";
import CurrencyInputField from "react-currency-input-field";
import { DeleteAssistantButton } from "./DeleteAssistantButton";
import { formatCurrency } from "../../../utils/helpers";
import { getCurrentUserPlan } from "../../../helpers/userHelper";
import { PLANS_AIZZY } from "../../../helpers/constants/Plans";
import { QUERY_KEYS, queryClient } from "../../../libs/react-query";

export interface AssistantToEditData extends AssistantDTO {
  prompt: string;
}

interface CreateAssistantProps {
  assistantToEdit: AssistantToEditData | null;
}

const createAssistantFormSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .max(30, "Cannot exceed 30 characters"),
  role: yup
    .string()
    .required("Role is required")
    .max(150, "Cannot exceed 150 characters"),
  prompt: yup.string().required("Prompt is required"),
  language: yup.string().required("Language is required"),
  about: yup.string().required("About is required"),
  profileImage: yup.string().required("Assistant Image is required"),
  defaultGreetings: yup.string().nullable(),
  enabled: yup.boolean(),
  private: yup.boolean(),
  category: yup.string(),
  languages: yup.array().nullable().default([]),
});

type CreateAssistantFormData = yup.InferType<typeof createAssistantFormSchema>;

const TEXT_AREA_HEIGHT = 90;

export function CreateAssistantForm({ assistantToEdit }: CreateAssistantProps) {
  const [showTranslateModal, setShowTranslateModal] = useState(false);

  const [languageSelected, setLanguageSelected] = useState<
    "en" | "pt" | "es" | string
  >("");
  const [assistantPrice, setAssistantPrice] = useState("");

  const [translations, setTranslations] = useState<
    AssistantTranslationFormData[]
  >([]);

  const [translationToEdit, setTranslationToEdit] =
    useState<AssistantTranslationFormData | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const isMobile = window.matchMedia("(max-width: 700px)").matches;

  const { t } = useTranslation(CreateAssistantI18n);
  const navigate = useNavigate();
  const { dispatch } = useRedux();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreateAssistantFormData>({
    resolver: yupResolver(createAssistantFormSchema),
    defaultValues: {
      category: "Assistant",
      enabled: assistantToEdit?.enabled ?? true,
      about: assistantToEdit?.about,
      defaultGreetings: assistantToEdit?.defaultGreetings,
      language: assistantToEdit?.language,
      name: assistantToEdit?.name,
      profileImage: assistantToEdit?.profileImage,
      role: assistantToEdit?.role,
      private: assistantToEdit?.private ?? false,
      prompt: assistantToEdit?.prompt,
    },
  });

  const user = useUser();
  const isPremiumUser = getCurrentUserPlan() === PLANS_AIZZY.PREMIUM;
  function toggleTranslateModal() {
    if (!languageSelected) {
      return showWarningNotification(t("notifications.without-language"));
    }

    if (showTranslateModal) {
      setTranslationToEdit(null);
      return setShowTranslateModal(false);
    }
    setShowTranslateModal(true);
  }

  async function onSendAssistant(data: CreateAssistantFormData) {


    // const userPrice = Number(assistantPrice);
    // const minPrice = Number(process.env.REACT_APP_MIN_PRICE_ASSISTANT_FOR_SALE);

    // if (userPrice && userPrice < minPrice) {
    //   return showWarningNotification(t("notifications.minimum-price"));
    // }

    try {
      setIsLoading(true);

      if (assistantToEdit) {
        const params = {
          ...data,
          // userPrice: userPrice * 100,
          userName: assistantToEdit?.userName,
          _id: assistantToEdit._id,
        };

        const redirectAssistant = {
          ...data,
          userId: assistantToEdit?.userId,
          userName: assistantToEdit?.userName,
          _id: assistantToEdit._id,
        };

        await createAssistant(params);
        showSuccessNotification(t("notifications.edited-assistant"));

        dispatch(changeAssistant(redirectAssistant));
        dispatch(getChatUserConversationsReset());
        dispatch(changeTab(TABS.ASSISTANT_CHATS));

        queryClient.invalidateQueries([
          QUERY_KEYS.EDIT_ASSISTANT,
          redirectAssistant._id,
        ]);

        queryClient.invalidateQueries(QUERY_KEYS.MY_ASSISTANTS);

        return navigate("/dashboard");
      }

      // const assistantPriceInCents = userPrice * 100;

      const assistant = {
        ...data,
        // userPrice: assistantPriceInCents,
        userName: user?.user_metadata?.name,
      };

      await createAssistant(assistant);
      showSuccessNotification(t("notifications.created-assistant"));

      queryClient.invalidateQueries(QUERY_KEYS.MY_ASSISTANTS);
      navigate("/dashboard");
    } catch (error: any) {
      showErrorNotification(error);
    } finally {
      setIsLoading(false);
    }
  }

  function onCreateTranslation(translationToAdd: AssistantTranslationFormData) {
    const isTranslationAlreadyExist = translations.some(
      translation => translation.language === translationToAdd.language
    );

    if (isTranslationAlreadyExist) {
      return showWarningNotification(
        t("notifications.already-exist-translation")
      );
    }

    setTranslations(state => [...state, translationToAdd]);

    toggleTranslateModal();

    setValue("languages", [
      ...(getValues()?.languages ?? []),
      translationToAdd,
    ]);
    showSuccessNotification(t("notifications.created-translation"));
  }

  function onDeleteTranslation(language) {
    const filteredTranslations = translations.filter(
      translation => translation.language !== language
    );

    setTranslations(filteredTranslations);
    showSuccessNotification(t("notifications.deleted-translation"));
  }

  function onEditTranslation(language: string) {
    const translation = translations.find(
      translation => translation.language === language
    );

    if (translation) {
      setTranslationToEdit(translation);
      toggleTranslateModal();
    }
  }

  function onSendEditedTranslation(
    translationEdited: AssistantTranslationFormData
  ) {
    const translationsFiltered = translations.filter(
      translation => translation.language !== translationEdited.language
    );

    setTranslations([...translationsFiltered, translationEdited]);
    setTranslationToEdit(null);
    toggleTranslateModal();
    showSuccessNotification(t("notifications.edited-translation"));
  }

  function onSelectGeneratedImage(imageUrl: string) {
    setValue("profileImage", imageUrl);
  }



  useEffect(() => {
    if (assistantToEdit) {
      if (assistantToEdit.userPrice) {
        const assistantPriceInUSD = assistantToEdit.userPrice / 100;
        const userPriceString = assistantPriceInUSD.toString();
        setAssistantPrice(userPriceString);
      }

      setLanguageSelected(assistantToEdit?.language);
      if (assistantToEdit?.languages) {
        const translationsFromAPI = assistantToEdit?.languages?.map(
          languageObj => ({
            language: languageObj?.language,
            role: languageObj?.role,
            about: languageObj?.about,
            defaultGreetings: languageObj?.defaultGreetings,
            prompt: languageObj?.prompt,
          })
        );
        setTranslations(translationsFromAPI);
      }
    }
  }, [assistantToEdit]);

  useEffect(() => {
    setValue("languages", translations);
  }, [translations]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className="px-4 py-4 mb-5  animate__animated animate__fadeInLeft animate__fast"
          style={{
            boxShadow: "2px 2px 20px rgba(0, 0, 0, 0.15)",
          }}
        >
          <ModalLanguage
            showModal={showTranslateModal}
            onClose={toggleTranslateModal}
            languageDefault={languageSelected}
            translationToEdit={translationToEdit!}
            onCreateTranslation={onCreateTranslation}
            onSendEditedTranslation={onSendEditedTranslation}
          />

          {isLoading ? (
            <Loader />
          ) : (
            <Form onSubmit={handleSubmit(onSendAssistant)}>
              <FormGroup>
                <Label for="name">
                  {t("form.name")} <span className="text-danger">*</span>
                </Label>
                <input
                  className={`form-control bg-secondary bg-opacity-10 border-opacity-100 ${errors.name && "border-1 border-danger"
                    }`}
                  id="name"
                  placeholder={t("form.placeholder.name")}
                  {...register("name")}
                />
                {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
              </FormGroup>

              <FormGroup>
                <Label for="profileImage">
                  {t("form.assistant-image")}{" "}
                  <span className="text-danger">*</span>
                </Label>
                {watch("profileImage") && (
                  <div className="mb-2">
                    <img
                      className="d-block rounded-2"
                      style={{ width: 300, height: 300 }}
                      src={getValues("profileImage")}
                      alt=""
                    />
                    <span className="text-secondary">
                      {t("form.details.image-selected")}
                    </span>
                  </div>
                )}
                <GenerateImagesButton
                  onSelectGeneratedImage={onSelectGeneratedImage}
                  currentImageSelected={getValues("profileImage")}
                />
                {errors.profileImage && (
                  <ErrorText>{errors.profileImage.message}</ErrorText>
                )}
              </FormGroup>
              <FormGroup>
                <Label>
                  {t("form.assistant-language")}{" "}
                  <span className="text-danger">*</span>
                </Label>
                <select
                  className={`form-control bg-secondary bg-opacity-10 border-opacity-100 ${errors.language && "border-1 border-danger"
                    }`}
                  {...register("language", {
                    onChange: value => {
                      setLanguageSelected(value.target.value);
                    },
                  })}
                >
                  <option value="">
                    {t("form.placeholder.assistant-language")}
                  </option>
                  <option value="en">English</option>
                  <option value="pt">Portuguese</option>
                  <option value="es">Spanish</option>
                </select>
                {errors.language && (
                  <ErrorText>{errors.language.message}</ErrorText>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="role">
                  {t("form.role")} <span className="text-danger">*</span>
                </Label>
                <input
                  className={`form-control bg-secondary bg-opacity-10 border-opacity-100 ${errors.role && "border-1 border-danger"
                    }`}
                  id="role"
                  placeholder={t("form.placeholder.role")}
                  {...register("role")}
                />
                {errors.role && <ErrorText>{errors.role.message}</ErrorText>}
              </FormGroup>

              <FormGroup>
                <Label for="prompt">
                  {t("form.prompt")} <span className="text-danger">*</span>
                </Label>
                <textarea
                  className={`form-control bg-secondary bg-opacity-10 border-opacity-100 ${errors.prompt && "border-1 border-danger"
                    }`}
                  id="prompt"
                  placeholder={t("form.placeholder.prompt")}
                  style={{
                    minHeight: TEXT_AREA_HEIGHT,
                    resize: "vertical",
                  }}
                  {...register("prompt")}
                />
                {errors.prompt && (
                  <ErrorText>{errors.prompt.message}</ErrorText>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="about">
                  {t("form.about")} <span className="text-danger">*</span>
                </Label>
                <textarea
                  className={`form-control bg-secondary bg-opacity-10 border-opacity-100 ${errors.about && "border-1 border-danger"
                    }`}
                  id="about"
                  placeholder={t("form.placeholder.about")}
                  style={{
                    minHeight: TEXT_AREA_HEIGHT,
                    resize: "vertical",
                  }}
                  {...register("about")}
                />
                {errors.about && <ErrorText>{errors.about.message}</ErrorText>}
              </FormGroup>

              <FormGroup>
                <Label for="greetings">{t("form.default-greetings")}</Label>
                <textarea
                  className="form-control bg-secondary bg-opacity-10 border-opacity-100"
                  id="greetings"
                  placeholder={t("form.placeholder.default-greetings")}
                  style={{
                    minHeight: TEXT_AREA_HEIGHT,
                    resize: "vertical",
                  }}
                  {...register("defaultGreetings", {
                    required: "Default Greetings is required",
                  })}
                />
                {errors.defaultGreetings && (
                  <ErrorText>{errors.defaultGreetings.message}</ErrorText>
                )}
              </FormGroup>

              <Label className="d-block align-self-start">
                {t("form.privateLabelMain")}
              </Label>
              <FormGroup
                id="private-group"
                className="mb-3 d-flex flex-column gap-2"
              >
                <div className="form-check form-check-inline">
                  <input
                    id="public"
                    type="radio"
                    className="form-check-input"
                    value="false"
                    defaultChecked={!getValues("private") && true}
                    {...register("private")}
                  />
                  <label className="form-check-label" htmlFor="public">
                    {t("form.publicLabel")}
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    id="private"
                    type="radio"
                    value="true"
                    defaultChecked={getValues("private") && true}
                    className="form-check-input"
                    {...register("private")}
                  />
                  <label className="form-check-label" htmlFor="private">
                    {t("form.privateLabel")}
                  </label>
                </div>
              </FormGroup>

              <Label className="d-block align-self-start mt-4">
                {t("form.enabledLabel")}
              </Label>
              <FormGroup className="form-check form-switch">
                <input
                  id="enabled"
                  type="checkbox"
                  className="form-check-input"
                  defaultChecked={getValues("enabled")}
                  {...register("enabled")}
                />
                <Label check for="enabled">
                  {watch("enabled") ? t("form.enabled") : t("form.disabled")}
                </Label>
              </FormGroup>

              {/* {process.env.REACT_APP_ACTIVE_ASSISTANT_SALE === "true" && (
                <FormGroup className="my-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <Label for="userPrice" className="m-0">
                      {t("form.price")}
                      {"  "}
                      <span className="text-primary fw-light">
                        {t("form.priceDetails")}
                      </span>
                    </Label>
                    <span
                      id="question-assistant-price"
                      className=" bg-primary bg-opacity-75 rounded-2 m-0 p-1 d-flex align-items-center"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <i className="bx bx-question-mark font-size-18 fw-bold text-white" />
                    </span>
                  </div>
                  <UncontrolledTooltip target="question-assistant-price">
                    {t("form.priceTooltip")}
                  </UncontrolledTooltip>
                  <CurrencyInputField
                    id="userPrice"
                    name="userPrice"
                    className="form-control bg-secondary bg-opacity-10 border-opacity-100"
                    placeholder={t("form.priceDescription")}
                    style={{
                      maxWidth: 300,
                    }}
                    value={assistantPrice}
                    onValueChange={value => {
                      setAssistantPrice(value!);
                    }}
                  />
                  {assistantPrice && (
                    <span>
                      {t("form.priceUser")}{" "}
                      <span className="text-primary fw-semibold">
                        $ {formatCurrency(Number(assistantPrice) / 2)}
                      </span>
                    </span>
                  )}
                </FormGroup>
              )} */}

              <FormGroup className="mt-3 d-flex flex-column align-items-start">
                <Label for="Languages">{t("form.translation")}</Label>
                <button
                  type="button"
                  onClick={toggleTranslateModal}
                  disabled={translations.length === 2}
                  className="btn btn-outline-primary"
                >
                  {t("form.button.add-new-language")}
                </button>
              </FormGroup>

              <div className="mt-3 d-flex justify-content-start gap-4">
                {translations.map(translation => {
                  return (
                    <TranslationCard
                      key={translation.language}
                      translation={translation}
                      onDeleteTranslation={onDeleteTranslation}
                      onEditTranslation={onEditTranslation}
                    />
                  );
                })}
              </div>

              <div className="mt-4 w-100 d-flex flex-column align-items-center justify-content-center gap-3">
                <button
                  className={`fs-5 fw-semibold btn btn-lg w-${isMobile ? "75" : "50"
                    } btn-primary`}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <i className="bx bx-loader bx-spin align-middle" />
                  ) : (
                    t("form.button.save")
                  )}
                </button>

                {/* {assistantToEdit?._id && (
                  <DeleteAssistantButton assistantId={assistantToEdit?._id} />
                )} */}
              </div>
            </Form>
          )}
        </div>
      )}
    </>
  );
}
