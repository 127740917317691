import { useEffect, useState } from "react";
import { useRedux } from "../../../../hooks";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { ChatMenuPopUp } from "../../../../components/ChatMenuPopUp";
import { useUser } from "@supabase/auth-helpers-react";
import { SelectedChatDTO } from "../../../../@DTO/selectedChat";
import { ChannelDTO } from "../../../../@DTO/channel";
import { useSharedChat } from "../../../../hooks/useSharedChat";

interface VideoLinkProps {
  user: any;
  selectedChat: SelectedChatDTO | null;
  onSelectChat: (channel: ChannelDTO) => void;
}

const VideoLink = ({ user, selectedChat, onSelectChat }: VideoLinkProps) => {
  const { useAppSelector } = useRedux();

  const { handleLeaveSharedChat } = useSharedChat();

  const userLogged = useUser();

  const { getChannelsAndDocumentsLoading } = useAppSelector(state => ({
    getChannelsAndDocumentsLoading: state.Chats.getChannelsAndDocumentsLoading,
  }));

  const [isLoading, setIsLoading] = useState(false);

  const unRead = user.meta && user.meta.unRead;
  const isSelectedChat = selectedChat && selectedChat._id === user._id;

  const onClick = () => {
    if (!isSelectedChat) {
      onSelectChat(user);
    }
  };

  useEffect(() => {
    if (isLoading) {
      setIsLoading(getChannelsAndDocumentsLoading);
    }
  }, [getChannelsAndDocumentsLoading]);

  return (
    <div
      className={classnames(
        {
          active: isSelectedChat,
        },
        "chat-item d-flex align-items-center justify-content-between"
      )}
      style={{ minHeight: 50, borderRadius: 6 }}
    >
      <li className="w-75" onClick={onClick}>
        <Link to="#" className={classnames({ "unread-msg-user": unRead })}>
          <div className="d-flex align-items-center">
            <div
              className={classnames(
                "chat-user-img",
                "align-self-center",
                "me-2",
                "ms-0"
              )}
            >
              <div className="avatar-xs">
                <span
                  className={classnames(
                    "avatar-title",
                    "",
                    "font-size-10",
                    "text-uppercase",
                    "bg-soft-secondary",
                    "text-secondary"
                  )}
                >
                  {user?.isSharedWithMe || user.userId !== userLogged?.id ? (
                    <i className="bx bx-group font-size-16" />
                  ) : (
                    <i className="bx bxs-chat font-size-16" />
                  )}
                </span>
              </div>
            </div>
            <div className="overflow-hidden">
              <p className="text-truncate mb-0">{user?.channelName}</p>
            </div>
          </div>
        </Link>
      </li>
      {isLoading ? (
        <div className="btn">
          <i className="bx bx-loader-circle bx-spin" />
        </div>
      ) : !user?.isSharedWithMe ? (
        <div className="d-flex align-items-center">
          <ChatMenuPopUp channelId={user._id} channel={user} />
        </div>
      ) : (
        <button
          onClick={() => handleLeaveSharedChat(selectedChat?._id!)}
          className="btn d-flex align-items-center text-danger button-leave-chat"
        >
          <i className="bx bx-log-out fs-5" />
        </button>
      )}
    </div>
  );
};

export default VideoLink;
