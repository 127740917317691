import React from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import useTranslation from "../../../helpers/useTranslation";
import { SliderSelectRatioI18n } from "./SliderSelectRatio.i18n";

interface SliderSelectRatioProps {
  selectedRatio: string;
  setSelectedRatio: React.Dispatch<React.SetStateAction<string>>;
  disabled: boolean;
}

export function SliderSelectRatio({
  selectedRatio,
  setSelectedRatio,
  disabled,
}: SliderSelectRatioProps) {
  const { t } = useTranslation(SliderSelectRatioI18n);

  const ratios = [
    {
      label: "21:9",
      value: "1536x640",
    },
    {
      label: "16:9",
      value: "1344x768",
    },
    {
      label: "3:2",
      value: "1216x832",
    },
    {
      label: "5:4",
      value: "1152x896",
    },
    {
      label: "1:1",
      value: "1024x1024",
    },
    {
      label: "4:5",
      value: "896x1152",
    },
    {
      label: "2:3",
      value: "832x1216",
    },
    {
      label: "9:16",
      value: "768x1344",
    },
    {
      label: "9:21",
      value: "640x1536",
    },
  ];

  const handleSliderChange = event => {
    const selectedValue = ratios[event.target.value].value;
    setSelectedRatio(selectedValue);
  };

  return (
    <div style={{ width: "100%" }}>
      <Row>
        <Col>
          <FormGroup>
            <Label for="slider">{t("slider.label")}</Label>
            <InputGroup>
              <div className="w-100 d-flex align-items-center gap-4 gap-sm-5 mt-0">
                <Input
                  type="range"
                  name="slider"
                  id="slider"
                  min="0"
                  max="8"
                  step="1"
                  value={ratios.findIndex(
                    ratio => ratio.value === selectedRatio
                  )}
                  onChange={handleSliderChange}
                  disabled={disabled}
                />
                <div>
                  <div
                    className="d-flex justify-content-center flex-wrap mt-2"
                    style={{ width: "100%" }}
                  >
                    <InputGroupText>{selectedRatio}</InputGroupText>
                  </div>
                  <div
                    className="d-flex justify-content-center flex-wrap mt-2"
                    style={{ width: "100%" }}
                  >
                    <strong className="fs-5">
                      {
                        ratios.find(ratio => ratio.value === selectedRatio)
                          ?.label
                      }
                    </strong>
                  </div>
                </div>
              </div>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}
