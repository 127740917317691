// DocumentsI18n
export const DocumentsI18n = {
  "pt-BR": {
    page: {
      label: "Documentos e Websites",
      description:
        "Crie um novo chat para perguntar sobre seus documentos ou sites da web.",
    },
    button: {
      label: "Novo chat",
    },
    notifications: {
      showOnceADayAlert:
        "Todos os documentos serão excluídos automaticamente após 30 dias de inatividade.",
    },
  },
  "en-US": {
    page: {
      label: "Docs & Websites",
      description:
        "Create a new chat to inquire about your documents or websites.",
    },
    button: {
      label: "New chat",
    },
    notifications: {
      showOnceADayAlert:
        "All documents will be automatically deleted after 30 days of inactivity.",
    },
  },
  "es-ES": {
    page: {
      label: "Documentos y Sitios Web",
      description:
        "Crea un nuevo chat para consultar sobre tus documentos o sitios web.",
    },
    button: {
      label: "Nuevo chat",
    },
    notifications: {
      showOnceADayAlert:
        "Todos los documentos se eliminarán automáticamente después de 30 días de inactividad.",
    },
  },
  "fil-PH": {
    page: {
      label: "Mga Dokumento at Website",
      description:
        "Gumawa ng bagong chat upang magtanong tungkol sa iyong mga dokumento o website.",
    },
    button: {
      label: "Bagong chat",
    },
    notifications: {
      showOnceADayAlert:
        "Ang lahat ng dokumento ay awtomatikong mabubura pagkatapos ng 30 araw ng hindi paggamit.",
    },
  },
};
