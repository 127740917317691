export const ToastPremiumMessageI18n = {
  "pt-BR": {
    toastPremium: {
      title: "Atenção",
      message:
        "Ao concluir a assinatura do plano PREMIUM, pedimos por favor que faça logout, limpe o cache do seu navegador e realize o login novamente para evitar quaisquer possíveis problemas relacionados a isso.",
    },
  },
  "en-US": {
    toastPremium: {
      title: "Attention",
      message:
        "Upon completing your subscription to the PREMIUM plan, please log out, clear your browser cache, and log in again to avoid any potential issues related to this.",
    },
  },
  "es-ES": {
    toastPremium: {
      title: "Atención",
      message:
        "Al finalizar la suscripción al plan PREMIUM, le pedimos que cierre sesión, borre la caché de su navegador y vuelva a iniciar sesión para evitar posibles problemas relacionados con esto.",
    },
  },
  "fil-PH": {
    toastPremium: {
      title: "Paalala",
      message:
        "Pagkatapos mag-subscribe sa PREMIUM na plano, mangyaring mag-logout, linisin ang cache ng iyong browser, at mag-login muli upang maiwasan ang anumang posibleng isyu kaugnay dito.",
    },
  },
};
